import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import { connectData } from '../../../redux';
import getSlides from '../../functions/getSlides';
import Footer1 from '../../common/footer/Footer1';
import Header from '../../common/header/Header';
import MultiSlider3 from '../../common/MultiSlider/MultiSlider3';
import ThemeTenTitle from './ThemeTenTitle';


const ThemeTenDestinations = () => {
  const operator = JSON.parse(localStorage.getItem('op'));

  return (
    <IonPage>
      <IonContent>
        {/* <div style={{ position: 'absolute', width: '100vw', zIndex: 1000 }}>
          <Header1 />
        </div> */}
        <Header />
        <HeroSection
          heroImage={getIcons('DestinationBanner',operator)}
          // height={'60vh'}
          height={'45vh'}
          title={'DESTINOS'}
          description={'Home / Destinos'}
        />
        <ThemeTenTitle title={'  DESTINOS  '}  />
        <MultiSlider3
          // smallHeading="Conoce"
          // boldHeading="GALERIA"
          multiSlides={getSlides('Destination',operator)}
          sanantonioArrowStyle
          width={'80%'}
        />

        <div style={{ marginTop: '50px' }}></div>

        <Footer1 operator={operator} isSanantonio />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTenDestinations);
