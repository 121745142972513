import { IonContent, IonPage } from "@ionic/react";

import Header from "../common/header/Header";
import React from "react";
import TarapacaContactForm from "./common/TarapacaContactForm";
import TarapacaFooter from "./common/TarapacaFooter";
import TarapacaHeader from "./components/TarapacaHeader";
import TarapacaHeroSection from "./common/TarapacaHeroSection/TarapacaHeroSection";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";

const TarapacaContactUsScreen = (props) => {
	const operator = JSON.parse(localStorage.getItem('op'));

	return (
		<IonPage>
			<IonContent>
				{!(operator && operator.operator_name === "tarapaca") && <Header />}
				{operator && operator.operator_name === "tarapaca" && (
					<TarapacaHeader
						// boldTitle={"Contacto"} 
						handleHomeSubmit={() => props.history.push("/")}
						handleBackSubmit={() => props.history.goBack()}
						logo={operator && operator.header_logo}
					/>
				)}
				<TarapacaHeroSection
					height={"30vh"}
					heroImage={getIcons("Banner")}
					title={"Contacto"}
					description={"Home / Contacto"}
				/>

				<TarapacaContactForm history={props.history} />

				<TarapacaFooter operator={props.data.operator} />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(TarapacaContactUsScreen);
