import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectData } from '../../../redux';

export const AboutUsIconSection = (props) => {
  const operator = JSON.parse(localStorage.getItem('op'));

  const renderIconsAndText = (item, key, length) => {
    return (
      <div
        className={
          key + 1 != length && (key + 1) % 3 != 0
            ? 'single-item-container borderRight-about-icons'
            : 'single-item-container'
        }
        // style={{ width: length <= 3 ? `${100 / length}%` : '33%' }}
        style={{ margin: '10px 0' }}
      >
        <div className='single-item' style={styles.singleItem}>
          <div>
            <img
              src={item.icon}
              alt={item.caption}
              className='about-icon'
              style={{ width: 50 }}
            />
          </div>
          <div className='font14' style={{ textAlign: 'center' }}>
            {item.caption}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='themes-about-us-icon-section new-container mt10 '>
      <div className='about-us-icons-main-container' style={{ padding: 20 }}>
        <div>
          {props.smallHeading && (
            <div className='font10 less-bold-text mb5'>
              {props.smallHeading}
            </div>
          )}

          {props.boldHeading && (
            <div className='font13 bold-text mb5 black-text-color'>
              {props.boldHeading}
            </div>
          )}
        </div>
        <div
          className='icons-container'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            flexDirection: 'column',
          }}
        >
          {props.iconsAndTexts.map((item, key) => {
            return renderIconsAndText(item, key, props.iconsAndTexts.length);
          })}
        </div>
      </div>
    </div>
  );
};

const styles = {
  singleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
export default connectData()(AboutUsIconSection);
