import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import ThemeFourAboutIconSection from '../common/ThemeFourAboutIconSection';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourTitle from '../common/ThemeFourTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeFourAboutUsScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	const name = operator && operator.operator_name;

	const createMarkup = (data) => {
		return { __html: data };
	};

	const renderFirstParagraph = () => {
		let firstParagraphHTML = operator && operator.about_us ? operator.about_us.split("///")[0] : "";

		// For operator "santajuana", hide first four words and add static text
		if (name === "santajuana") {
			const words = firstParagraphHTML.split(' ');
			if (words.length >= 4) {
				const staticText = "TRANSPORTES SANTA JUANA SpA ";
				firstParagraphHTML = `<span style="color: #3ca2db;">${staticText}</span> ${words.slice(4).join(' ')}`;
			}
		}

		return firstParagraphHTML;
	};

	return (
		<IonPage>
			<IonContent>
				<Header />
				<HeroSection
					heroImage={getIcons("aboutUsBanner", operator)}
					title={"La empresa"}
					description={"Home / La empresa"}
				/>

				<ThemeFourTitle title="Conocer acerca de nosotros" />

				<div className="new-container ">
					<div>
						<img
							style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
							src={getIcons("aboutUsImage", operator)}
							alt=""
						/>
					</div>
					<div
						dangerouslySetInnerHTML={createMarkup(renderFirstParagraph())}
						className={operator && operator.operator_name === 'chilebus' ? "lh-1-3 justify-text " : 'lh-1-3 font11 justify-text'}
					/>
				</div>

				{name === "pullmandelsur" ? null : (
					<ThemeFourTitle title="Ofrecemos un servicio de calidad" />
				)}

				<ThemeFourAboutIconSection />

				{operator && operator.operator_name === "saopaulo" ? (<div className="new-container ">
					{getIcons('aboutUsSecondBanner', operator) && (
						<div>
							<img
								style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
								src={getIcons('aboutUsSecondBanner', operator)}
								alt=""
							/>
						</div>
					)}
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator && operator.about_us ? operator.about_us.split('///')[1] : '',
						)}
						className={operator && operator.operator_name === 'chilebus' ? "lh-1-3 justify-text " : 'lh-1-3 font11 justify-text'}
					/>
				</div>)
					: ('')

				}
				<ThemeFourFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeFourAboutUsScreen);

