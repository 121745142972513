import { IonContent, IonPage } from "@ionic/react";

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import { PBText } from "../../components";
// import HeroSection from "../../common/HeroSection/HeroSection";
import React from "react";
import { connectData } from "../../redux";

// import getIcons from "../../functions/getIcons/getIcons";

const Theme11TnCScreen = props => {
  const operator = props.data.operator;

  const createMarkup = data => {
    return { __html: data };
  };

  return (
    <IonPage>
      <IonContent>
        <Header />
        {/* <HeroSection
          title={"Términos y condiciones"}
          height={"30vh"}
          description={"Home / Términos y condiciones"}
          heroImage={getIcons("tncBanner")}
        /> */}
        <div className="new-container mb10 mt10" style={{ padding: 20 }}>
          <div
            // dangerouslySetInnerHTML={createMarkup(
            //   operator.terms ? operator.terms : "",
            // )}
            className="lh-1-3 font14 justify-text"
          >
            <PBText>
              Este pasaje tiene una validez de 90 días desde su emisión. El
              pasaje será válido para cualquier paradero, ruta y dirección del
              servicio de Centropuerto. No es posible cambios de hora o fecha en
              los pasajes, ni en oficinas ni en internet. No se aceptarán
              boletos rotos, enmendados, ilegibles o con adulteración. Cada
              pasajero tendrá derecho a llevar 2 piezas de equipaje y el tamaño
              máximo no puede exceder el de una maleta grande. El traslado de
              mascotas está permitido siempre que estas vayan en jaulas o cajas
              de traslado correspondientes. En caso que su equipaje tenga un
              valor mayor a 5 UTM, declárelo solicitando en nuestras oficinas de
              aeropuerto un comprobante por cada equipaje, previo inventario.
              Todo reclamo de equipaje sólo es válido al momento de descender
              del bus y previa presentación de su comprobante de pago.
            </PBText>
          </div>
        </div>
        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(Theme11TnCScreen);
