import "./routes.css";

import { IonContent, IonPage } from "@ionic/react";

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import HeroSection from "../common/HeroSection/HeroSection";
import KuposHeader from "../../components/KuposHeader";
import { PBText } from "../../components";
import React from "react";
import { connectData } from "../../redux";
// import HeroSection from "../../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";

const Theme11RutaPajaritos = ({ operator, history }) => {
		return (
			<IonPage>
   <IonContent>
			<KuposHeader
            // data={props.data}
            title={"Ruta Pajaritos "}
            boldTitle={"- Aeropuerto"}
            handleHomeSubmit={() => history.push("/")}
            handleBackSubmit={() => history.goBack()}
          />
   
      <div className="new-container route-container mb10 mt10" style={{ padding: 20 }}>
							<div className="image-route-container">
								    <img src="/assets/centropuerto/routes/pajaritos.png"/>
												<img src="/assets/centropuerto/routes/ruta-pajaritos.png" className="route-image"/>
							</div>
					



       <p className="lh-1-3 font11 center-text"><b>		Fines de semana y festivos de acuerdo a horario de operación del Metro de Santiago</b></p>
      <ul className="lh-1-3 font13 justify-text"> 
							<li>Las salidas, rutas y tiempos de viaje pueden variar de acuerdo a las condiciones de tránsito y seguridad en la ruta. Los horarios de salidas corresponden a los lugares de inicio de cada recorrido.</li>
<li>
El equipaje en el interior del bus es de exclusiva responsabilidad del pasajero..</li>
<li>
De acuerdo al D.S. 212 del Ministerio de Transportes y Telecomunicaciones, si el valor de su equipaje excede las 5 UTM declárelo solicitando en nuestras oficinas de aeropuerto un comprobante por cada equipaje, previo inventario. Todo reclamo de equipaje sólo es válido al momento de descender del bus y previa presentación de su comprobante de pago.
</li>
						</ul>
      </div>
      {/* 
      <Footer2 operator={operator} />
      */}
   </IonContent>
</IonPage>
		)
}

export default Theme11RutaPajaritos