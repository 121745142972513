import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenCompanyScreen.css';

const data = [
  {
    title: 'Paralelo 53',
    des: `
    <p>La agencia de viajes Paralelo 53, forma parte de nuestra empresa, como función cumple, con servicios integrales para el turista privado en la región de Magallanes.</p>
    <p>Principalmente entregamos los servicios de viajes, con Buses Fernandez, a Torres del Paine, Cueva del Milodón, Navegación por el fiordo de Ultima Esèranza, City tour en Punta Arenas, y Agroturismo, en Estancia Fitz Roy.</p>
    <a href="https://www.facebook.com/Paralelo-53-Agencia-de-Viajes-468411976589685/" target="_blank">Visite el sitio web de la empresa aquí.</a>
    `,
    img: '/assets/fernandez/our-companies/BusesFernandez-Website-OurCompanies-Paralelo53.png',
    active: true,
  },

  {
    title: 'Agroturismo Fitz Roy',
    des: `
    <p>En estancia Fitz roy, podrá encontrar la tradición en un 100% de nuestra zona, donde tendrá inolvidables momentos, conmemorando la esquila, nuestra historia, con un completo museo histórico, además de disfrutar de un exquisito asado al palo de cordero magallánico, y divertidas actividades como avistamiento de fauna, como animales de granja, pumas, y de aves, o también disfrutar, de la pesca en nuestro sitio.</p>
    <a href="http://www.estanciafitzroy.com/index.html" target="_blank">Visite el sitio web de la empresa aquí.</a>
    `,

    img: '/assets/fernandez/our-companies/BusesFernandez-Website-OurCompanies-FitzRoy.png',
    active: false,
  },

  {
    title: ' Hotel Eberhard',
    des: `
    <p>Hotel Eberhard es el primer Hotel establecido en Puerto Natales, con una de las más privilegiadas vistas, podemos garantizar una placentera estadía en nuestra ciudad, si su destino es Torres del Paine, Cueva del Milodón, o nuestra ciudad, no olvide, visitar Hotel Captian Eberhard.</p>
    <a href="https://www.hoteleberhard.com/" target="_blank">Visite el sitio web de la empresa aquí.</a>
    `,
    img: '/assets/fernandez/our-companies/BusesFernandez-Website-OurCompanies-HotelEberhard.png',
    active: false,
  },

  {
    title: 'Estancia Agronomía',
    des: `<p>Nos dedicamos a la Agronomía, si su negocio es la venta de carnes de excelencia, contáctenos, estaremos complacidos de atenderlo.</p>`,
    img: '/assets/fernandez/our-companies/BusesFernandez-Website-OurCompanies-Agronomia.png',
    active: false,
  },
];

const ThemeSevenCompanyScreen = (props) => {
  const [state, setState] = useState(data);
  const [selected, setSelected] = useState(state[0]);

  const handleSubmit = (item) => {
    const newState = state.map((d) => {
      d.active = d.title === item.title ? true : false;
      return d;
    });
    setState(newState);
    setSelected(item);
  };

  const createMarkup = (data) => {
    return {
      __html: data,
    };
  };

  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-company-screen'>
          <div id='company'>
            <div class='new-container'>
              <div class='heading'>
                <h1>Reserva y viaja con nosotros</h1>
              </div>
            </div>
          </div>

          {/* ----------- */}

          <div class='company'>
            <div class='new-container'>
              <h3>
                <span>Nuestras empresas</span>
              </h3>
              <ul class='tabs-menu nav nav-tabs' role='tablist'>
                {state.map((val, i) => {
                  return (
                    <div
                      key={i}
                      className={`tab ${val.active ? 'active' : ''}`}
                      onClick={() => handleSubmit(val)}
                    >
                      {val.title}
                    </div>
                  );
                })}
              </ul>

              <div class='tab-content seo-card'>
                <div class='col-md-4'>
                  <img src={selected.img} alt='company1' />
                </div>
                <div class='col-md-8'>
                  <h5>
                    <span>{selected.title}</span>
                  </h5>
                  <div dangerouslySetInnerHTML={createMarkup(selected.des)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenCompanyScreen);
