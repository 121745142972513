// may need to check the tepual icons again

// "Andimar" sliding images

// import ChilebusSlides from './getSlides/chilebusSlides';
import { CommonService } from '../../services';
import aeroquintaSlides from './getSlides/aeroquintaSlides';
import andimarDestinationSlide1 from '../assets/andimar/destinations/santiago.png';
import andimarDestinationSlide2 from '../assets/andimar/destinations/vallenar.png';
import andimarDestinationSlide3 from '../assets/andimar/destinations/antofagasta.png';
import andimarDestinationSlide4 from '../assets/andimar/destinations/copiapo.png';
import andimarDestinationSlide5 from '../assets/andimar/destinations/curico.png';
import andimarDestinationSlide6 from '../assets/andimar/destinations/ovalle.png';
import andimarDestinationSlide7 from '../assets/andimar/destinations/sanfernando.png';
import andimarDestinationSlide8 from '../assets/andimar/destinations/santacruz.png';
import andimarDestinationSlide9 from '../assets/andimar/destinations/temuco.png';
import andimarFleetSlide1 from '../assets/andimar/fleet/2.jpg';
import andimarFleetSlide10 from '../assets/andimar/fleet/11.jpg';
import andimarFleetSlide11 from '../assets/andimar/fleet/12.jpg';
import andimarFleetSlide12 from '../assets/andimar/fleet/13.jpg';
import andimarFleetSlide13 from '../assets/andimar/fleet/14.jpg';
import andimarFleetSlide14 from '../assets/andimar/fleet/15.jpg';
import andimarFleetSlide15 from '../assets/andimar/fleet/16.jpg';
import andimarFleetSlide16 from '../assets/andimar/fleet/17.jpg';
import andimarFleetSlide2 from '../assets/andimar/fleet/3.jpg';
import andimarFleetSlide3 from '../assets/andimar/fleet/4.jpg';
import andimarFleetSlide4 from '../assets/andimar/fleet/5.jpg';
import andimarFleetSlide5 from '../assets/andimar/fleet/6.jpg';
import andimarFleetSlide6 from '../assets/andimar/fleet/7.jpg';
import andimarFleetSlide7 from '../assets/andimar/fleet/8.jpg';
import andimarFleetSlide8 from '../assets/andimar/fleet/9.jpg';
import andimarFleetSlide9 from '../assets/andimar/fleet/10.jpg';
import bahiaazulDestination1 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-1.jpg';
import bahiaazulDestination10 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-10.jpg';
import bahiaazulDestination11 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-11.jpg';
import bahiaazulDestination2 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-2.jpg';
import bahiaazulDestination3 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-3.jpg';
import bahiaazulDestination4 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-4.jpg';
import bahiaazulDestination5 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-5.jpg';
import bahiaazulDestination6 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-6.jpg';
import bahiaazulDestination7 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-7.jpg';
import bahiaazulDestination8 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-8.jpg';
import bahiaazulDestination9 from '../assets/bahia/Destinations/BahiaAzul-Website-Destinations-9.jpg';
import bahiaazulGallery1 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-1.jpg';
import bahiaazulGallery2 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-2.jpg';
import bahiaazulGallery3 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-3.jpg';
import bahiaazulGallery4 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-4.jpg';
import bahiaazulGallery5 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-5.jpg';
import bahiaazulGallery6 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-6.jpg';
import bahiaazulGallery7 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-7.jpg';
import bahiaazulGallery8 from '../assets/bahia/Gallery/BahiaAzul-Website-Gallery-8.jpg';
import busnorteSlides from './getSlides/busnorteSlides';
import centralTourSlides from './getSlides/centralTourSlides';
import chilebusSlides from './getSlides/chilebusSlides';
import erbucSlides from './getSlides/erbucSlides';
import expresonorteSlides from './getSlides/expresonorteSlides';
import fernandezSlides from './getSlides/fernandezSlides';
import ivergramaSlides from './getSlides/ivergramaSlides';
import liquineSlides from './getSlides/liquineSlides';
import pullmandelsurSlides from './getSlides/pullmandelsurSlides';
import sanantonioSlides from './getSlides/sanantonioSlides';
import santajuanaSlides from './getSlides/santajuanaSlides';
import saopauloSlides from './getSlides/saopauloSlides';
import tacohaSlides from './getSlides/tacohaSlides';
import tepualSlides from './getSlides/tepualSlides';
import trrassSlides from './getSlides/trrassSlides';
import jeldresSlides from './getSlides/jeldresSlides';

const banner1 = '/assets/pullmanSanAndres/PullmanSanAndres-Slide-1.jpg';
const banner2 = '/assets/pullmanSanAndres/PullmanSanAndres-Slide-2.jpg';

const PullmanSanAndreasCompanies1 = '/assets/pullmanSanAndres/company1.png';
const PullmanSanAndreasCompanies2 = '/assets/pullmanSanAndres/company2.png';
const PullmanSanAndreasCompanies3 = '/assets/pullmanSanAndres/company3.png';
const PullmanSanAndreasCompanies4 = '/assets/pullmanSanAndres/company4.png';
const PullmanSanAndreasCompanies5 = '/assets/pullmanSanAndres/company5.png';

const homeSliders1 = '/assets/pullmanSanAndres/arica.png';
const homeSliders2 = '/assets/pullmanSanAndres/iquique.png';
const homeSliders3 = '/assets/pullmanSanAndres/antofagasta.png';
const homeSliders4 = '/assets/pullmanSanAndres/calamaa.png';
const homeSliders5 = '/assets/pullmanSanAndres/santiago.png';
const homeSliders6 =
	'/assets/pullmanSanAndres/PullmanSanAndres-Destinations-Copiapo.png';

const slides = {
	andimarDestination: [
		andimarDestinationSlide1,
		andimarDestinationSlide2,
		andimarDestinationSlide3,
		andimarDestinationSlide4,
		andimarDestinationSlide5,
		andimarDestinationSlide6,
		andimarDestinationSlide7,
		andimarDestinationSlide8,
		andimarDestinationSlide9,
	],

	andimarFleet: [
		andimarFleetSlide1,
		andimarFleetSlide2,
		andimarFleetSlide3,
		andimarFleetSlide4,
		andimarFleetSlide5,
		andimarFleetSlide6,
		andimarFleetSlide7,
		andimarFleetSlide8,
		andimarFleetSlide9,
		andimarFleetSlide10,
		andimarFleetSlide11,
		andimarFleetSlide12,
		andimarFleetSlide13,
		andimarFleetSlide14,
		andimarFleetSlide15,
		andimarFleetSlide16,
	],

	bahiaazulDestination: [
		bahiaazulDestination1,
		bahiaazulDestination2,
		bahiaazulDestination3,
		bahiaazulDestination4,
		bahiaazulDestination5,
		bahiaazulDestination6,
		bahiaazulDestination7,
		bahiaazulDestination8,
		bahiaazulDestination9,
		bahiaazulDestination10,
		bahiaazulDestination11,
	],
	bahiaazulGallery: [
		bahiaazulGallery1,
		bahiaazulGallery2,
		bahiaazulGallery3,
		bahiaazulGallery4,
		bahiaazulGallery5,
		bahiaazulGallery6,
		bahiaazulGallery7,
		bahiaazulGallery8,
	],
	pullmansanandresComapanies: [
		{ banner: PullmanSanAndreasCompanies1 },
		{ banner: PullmanSanAndreasCompanies2 },
		{ banner: PullmanSanAndreasCompanies3 },
		{ banner: PullmanSanAndreasCompanies4 },
		{ banner: PullmanSanAndreasCompanies5 },
	],

	pullmansanandresBanner: [banner1, banner2],

	pullmansanandreshomeSliders: [
		{ banner: homeSliders1, text: 'Arica' },
		{ banner: homeSliders2, text: 'Iquique' },
		{ banner: homeSliders3, text: 'Antofagasta' },
		{ banner: homeSliders4, text: 'Calama' },
		{ banner: homeSliders5, text: 'Santiago' },
		{ banner: homeSliders6, text: 'Copiapó' },
	],

	kennybus: [34, 343, 4, 3, 43, 43, 4],
	tepualDestination: tepualSlides.destination,
	tepualGallery: tepualSlides.gallery,
	jeldresDestination: jeldresSlides.destination,

	trrassDestination: trrassSlides.destination,
	trrassGallery: trrassSlides.gallery,


	centraltourBanner: centralTourSlides.banner,
	centraltourDestination: centralTourSlides.destination,
	centraltourGallery: centralTourSlides.gallery,

	// Expreso norte
	expressonorteDestination: expresonorteSlides.destination,
	expressonorteGallery: expresonorteSlides.gallery,
	// Expreso norte

	// saopauloBanner: saopauloSlides.banner,
	saopauloDestination: saopauloSlides.destination,
	santajuanaDestination: santajuanaSlides.destination,

	// pullmansur
	pullmandelsurDestination: pullmandelsurSlides.destination,

	// Bus Norte
	busnorteBanner: busnorteSlides.banner,
	busnorteDestination: busnorteSlides.destination,
	busnorteGallery: busnorteSlides.gallery,

	//aeroquinta slides

	aeroquintaBanner: aeroquintaSlides.banner,
	aeroquintaDestination: aeroquintaSlides.destination,
	aeroquintaGallery: aeroquintaSlides.gallery,


	ivergramaBanner: ivergramaSlides.banner,
	ivergramaDestination: ivergramaSlides.destination,

	erbucBanner: erbucSlides.banner,
	erbucDestination: erbucSlides.destination,
	erbucGallery: erbucSlides.gallery,

	// fernandezBanner: fernandezSlides.banner,
	// fernandezDestination: fernandezSlides.destination,
	fernandezGallery: fernandezSlides.gallery,
	sanantonioDestination: sanantonioSlides.destination,

	liquineBanner: liquineSlides.banner,
	liquineDestination: liquineSlides.destination,
	liquineAgencyTabs: liquineSlides.AgencyTabs,




	chilebusDestination: chilebusSlides.destination,
	chilebusGallery: chilebusSlides.gallery,
	// Bus Norte
	busnorteBanner: busnorteSlides.banner,
	busnorteDestination: busnorteSlides.destination,
	busnorteGallery: busnorteSlides.gallery,

	// chilebusBanner: chilebusSlides.banner,
	// chilebusDestination: chilebusSlides.destination,
	// chilebusGallery: chilebusSlides.gallery,

	// chilebusBanner: chilebusSlides.banner,
	//chilebusDestination: chilebusSlides.destination,
	// chilebusGallery: chilebusSlides.gallery,

	ivergramaBanner: ivergramaSlides.banner,
	ivergramaDestination: ivergramaSlides.destination,

	erbucBanner: erbucSlides.banner,
	erbucDestination: erbucSlides.destination,
	erbucGallery: erbucSlides.gallery,

	// fernandezBanner: fernandezSlides.banner,
	// fernandezDestination: fernandezSlides.destination,
	fernandezGallery: fernandezSlides.gallery,
	sanantonioDestination: sanantonioSlides.destination,

	liquineBanner: liquineSlides.banner,
	liquineDestination: liquineSlides.destination,
	liquineAgencyTabs: liquineSlides.AgencyTabs,

	tacohaBanner: tacohaSlides.banner,
	tacohaDestination: tacohaSlides.destination,
	tacohaAgencyTabs: tacohaSlides.AgencyTabs,


};

const getSlides = (type) => {
	const operator = JSON.parse(localStorage.getItem('op'));

	const name =
		operator && operator.operator_name
			? `${operator.operator_name}${type}`
			: '';
	let data = slides[name];
	//   console.log(data, 'data----');
	return data ? data : [];
};

export default getSlides;
