import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import ThemeThreeFooter from '../common/ThemeThreeFooter';
import ThemeThreeTitle from '../common/ThemeThreeTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const boldTexts = [
	"seguro y confiable, sino tambi&eacute;n acogedor y personalizado",
	"fundamos la agencia de viajes Vip Travel Spa",
	"brindando un servicio de alta calidad que se adapta a sus necesidades espec&iacute;ficas",
	"cada traslado sea una experiencia &uacute;nica y satisfactoria.",
	"comodidad, puntualidad y trato amigable.",
];

const makeBold = (text, operator) => {
	if (operator.operator_name !== 'aeroquinta') {
		return text;
	}

	boldTexts.forEach((boldText) => {
		const regex = new RegExp(`(${boldText})`, 'g');
		text = text.replace(regex, '<strong style="font-family: DMSans-Bold;">$1</strong>');
	});

	return text;
};

const createMarkup = (data, operator) => {
	const processedData = makeBold(data, operator);
	return { __html: processedData };
};

const ThemeThreeAboutUsScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	const tabs = ['Misión', 'Visión'];
	const initialState = {
		selectedTab: 1,
	};

	const [state, setState] = useState(initialState);
	const onTabChange = (tab) => {
		setState({ ...state, selectedTab: tab });
	};

	return (
		<IonPage>
			<IonContent>
				<Header />

				<HeroSection
					heroImage={getIcons('aboutUsBanner', operator)}
					title={'Quiénes somos'}
					height={'45vh'}
					description={'Home / Quiénes somos'}
				/>

				<ThemeThreeTitle operator={operator} title='Quiénes somos' />

				<div className='new-container ' style={{ gap: '30px' }}>
					<div>
						<img src={getIcons('aboutUsImage', operator)} alt='' />
					</div>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator && operator.about_us
								? operator.about_us.split('///')[0]
								: '',
							operator
						)}
						className='lh-1-3  justify-text '
						style={{ fontSize: '14px' }}
					/>
				</div>

				<KTabs
					tabs={tabs}
					onTabChange={onTabChange}
					selectedTab={state.selectedTab}
					operator={operator}
				/>

				<div
					className='new-container tabs-data'
					style={{
						textAlign: 'center',
						background:
							operator &&
							operator.color_codes &&
							operator.color_codes.primary_color,

						borderRadius: '10px',
						padding: '30px 60px',
					}}
				>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator && operator.about_us && state.selectedTab === 1
								? operator.about_us.split('///')[1]
								: operator.about_us.split('///')[2],
							operator
						)}
						className='lh-1-3  justify-text '
						style={{ flex: 1, fontSize: '14px' }}
					/>
				</div>

				<ThemeThreeFooter />
			</IonContent>
		</IonPage>
	);
};

const KTabs = ({ tabs, onTabChange, selectedTab, operator, width }) => {
	const styles = {
		tabPadding: {
			paddingTop: 15,
			paddingBottom: 15,
			width: width,
			paddingLeft: 20,
			paddingRight: 20,
		},
		active: {
			backgroundColor: '#fff',
			borderRadius: '75px',
			textAlign: 'center',
		},
	};

	return (
		<div className='recharge-tabs-container less-bold-text'>
			<div
				className='map-container-selector'
				style={{
					margin: '20px 0',
					color:
						operator &&
						operator.color_codes &&
						operator.color_codes.secondary_text_color,
					backgroundColor:
						operator &&
						operator.color_codes &&
						operator.color_codes.tertiary_color,
					width: width ? 400 : ' ',
					fontSize: '14px',
				}}
			>
				<style>
					{`
						.active {
							background: ${
								operator && operator.color_codes && operator.color_codes.secondary_color
							} !important;
						}
						.selector-item {
							width: 120px;
						}
					`}
				</style>
				{tabs.map((tab, key) => (
					<div
						key={key}
						className={
							'selector-item ' + (selectedTab === key + 1 ? 'active' : '')
						}
						onClick={() => onTabChange(key + 1)}
						style={styles.tabPadding}
					>
						{tab}
					</div>
				))}
			</div>
		</div>
	);
};

export default connectData()(ThemeThreeAboutUsScreen);
