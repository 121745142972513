import React, { PureComponent } from "react";
// import Modal from "react-native-modal";

import { connectData } from "../../redux";
import { dimensions, commonStyles } from "../../theme";
import { CommonService, DateSerivce } from "../../services";
import {
  BGCarpoolInnerBottom,
  View,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  TransactionListCard,
  NoData,
} from "../../components";
// import { CARPOOL_TRANSACTION_DETAILS_SCREEN } from '../../navigation';
import {
  IonDatetime,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  withIonLifeCycle,
} from "@ionic/react";

const month = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
const year = [2021, 2020, 2019, 2018, 2017];

class CarpoolMyTransactionsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      date: DateSerivce.getToday(),
      month: DateSerivce.getMonthNameFromDate(
        DateSerivce.getToday(),
        "yyyy-mm-dd",
        "full"
      ),
      year: DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      selectedData: [
        DateSerivce.getMonthNameFromDate(
          DateSerivce.getToday(),
          "yyyy-mm-dd",
          "full"
        ),
        DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      ],
    };
  }
  ionViewWillEnter() {
    // this.state = {
    //   showModal: false,
    //   date: DateSerivce.getToday(),
    //   month: DateSerivce.getMonthNameFromDate(
    //     DateSerivce.getToday(),
    //     "yyyy-mm-dd",
    //     "full"
    //   ),
    //   year: DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
    //   selectedData: [
    //     DateSerivce.getMonthNameFromDate(
    //       DateSerivce.getToday(),
    //       "yyyy-mm-dd",
    //       "full"
    //     ),
    //     DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
    //   ],
    // };
    // this.props.getTxnHistory({
    //   callback: (result) => this.onHistoryLoad(result),
    //   path:
    //     "month_str=" +
    //     DateSerivce.getMonthYearFromDate(this.state.date, "yyyy-mm-dd"),
    // });
  }

  componentDidMount() {
    this.state = {
      showModal: false,
      date: DateSerivce.getToday(),
      month: DateSerivce.getMonthNameFromDate(
        DateSerivce.getToday(),
        "yyyy-mm-dd",
        "full"
      ),
      year: DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      selectedData: [
        DateSerivce.getMonthNameFromDate(
          DateSerivce.getToday(),
          "yyyy-mm-dd",
          "full"
        ),
        DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      ],
    };
    const operator = JSON.parse(localStorage.getItem('op'))
    this.props.getTxnHistory({
      callback: (result) => this.onHistoryLoad(result),
      path:
        ("month_str=" +
          DateSerivce.getMonthYearFromDate(this.state.date, "yyyy-mm-dd") ) ,
      operator_id: operator && operator.operator_id || null,
    });

    this._isMounted = true;
    window.onpopstate = (e) => {
      //your code...
      if (this._isMounted) {
        console.log(e);
        var link = document.getElementsByTagName("ion-backdrop");
        if (link && link[0]) {
          link[0].click();
          setTimeout(() => {
            // this.props.history.push('/transactions')
            // window.history.pushState(null, document.title, '/transactions');
          }, 250);
        }
      }
    };
  }

  refresh = (event) => {
    // return new Promise((resolve) => {
      const operator = JSON.parse(localStorage.getItem('op'))
    console.log(
      "Selected data is-------",
      DateSerivce.getMonthFromDate(DateSerivce.getTodayString())
    );

    let monthToFetch = this.state.fullDateObject
      ? DateSerivce.getMonthFromDate(
          DateSerivce.changeDateFormat(
            this.state.fullDateObject.split("T")[0],
            "yyyy-mm-dd",
            "dd/mm/yyyy"
          )
        )
      : DateSerivce.getMonthFromDate(DateSerivce.getTodayString());

    this.props.getTxnHistory({
      callback: (result) => {
        // resolve();
        event.detail.complete();
      },
      path:
        "month_str=" +
          monthToFetch +
          // (month.indexOf(this.state.selectedData[0]) + 1) +
          "-" +
          this.state.selectedData[1],
          operator_id: operator && operator.operator_id || null,
    });
    // });
  };

  buttonReload = () => {
    const operator = JSON.parse(localStorage.getItem('op'))
    this.props.getTxnHistory({
      callback: (result) => {},
      path:
        "month_str=" +
          (month.indexOf(this.state.selectedData[0]) + 1) +
          "-" +
          this.state.selectedData[1],
      operator_id: operator && operator.operator_id || null
    });
  };

  onHistoryLoad = (result) => {
    if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "../../assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  editProfile = () => {
    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,LOGIN_SCREEN,'Ingresa a tu cuenta');
  };

  txnDetailsScreen = (item) => {
    console.log("Item to sent", item);
    // this.props.history.push(`/transactions/${item.uuid}`);
    this.props.history.push({
      pathname: `/transactions/details/${item.id}`,
      selectedTxn: item,
    });

    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,CARPOOL_TRANSACTION_DETAILS_SCREEN,'',{selectedTxn:item},false,false,true);
  };

  historyScreen = () => {
    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,LOGIN_SCREEN,'Ingresa a tu cuenta');
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      authFailed: false,
      modalButtonText: "",
      modalButtonTap: null,
      modalBackButtonPress: null,
    });
  };

  goToHome = () => {
    // Picker.hide();
    this.closeModal();
    CommonService.goToHome(this.props.componentId);
  };

  goToLogin = () => {
    // Picker.hide();
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      this.props.history.push("/");
      setTimeout(() => {
        this.props.history.push("/login-options");
      }, 150);
    }
  };

  // showPicker = () => {
  //   this.setState({
  //       showPickerModal:true
  //   })
  //   let data = [];

  //   data = [month,year];

  //   Picker.init({
  //       pickerConfirmBtnColor:[251, 117, 57,1],
  //       pickerCancelBtnColor:[251, 117, 57,1],
  //       pickerData: data,
  //       selectedValue: [this.state.month,this.state.year],
  //       pickerTitleText:'',
  //       pickerCancelBtnText:'   Cancelar',
  //       pickerConfirmBtnText:'Confirmar   ',
  //       onPickerConfirm: data => {
  //           console.log(data);
  //           this.setState({
  //               month:data[0],
  //               year:data[1],
  //               selectedData:data,
  //               showPickerModal:false
  //           })
  //           this.props.getTxnHistory({callback:(result) => this.onHistoryLoad(result),path:'month_str='+(month.indexOf(data[0])+1 <= 9 ? '0' + (month.indexOf(data[0])+1) : month.indexOf(data[0])+1 )+'-'+data[1]});
  //       },
  //       onPickerCancel: data => {
  //           console.log(data);
  //           this.setState({
  //               showPickerModal:false
  //           })
  //       },
  //       onPickerSelect: data => {
  //           // Picker.hide();
  //           // this.setState({
  //           //     month:data[0],
  //           //     year:data[1],
  //           //     selectedData:data
  //           // })
  //           // this.props.getTxnHistory({callback:(result) => this.onHistoryLoad(result),path:'month_str='+(month.indexOf(data[0])+1 <= 9 ? '0' + (month.indexOf(data[0])+1) : month.indexOf(data[0])+1 )+'-'+data[1]});
  //       }
  //   });
  //   setTimeout(() => {
  //       Picker.show();
  //   },100)
  // }

  changeDate = (val) => {
    console.log("Value Selected", val);
    const operator = JSON.parse(localStorage.getItem('op'))
    this.setState({
      fullDateObject: val,
      month: DateSerivce.getMonthNameFromDate(val, "yyyy-mm-dd", "full"),
      year: val.split("-")[0],
      selectedData: [
        DateSerivce.getMonthNameFromDate(
          val.split("-")[1] + 1,
          "yyyy-mm-dd",
          "full"
        ),
        val.split("-")[0],
      ],
    });

    this.props.getTxnHistory({
      callback: (result) => this.onHistoryLoad(result),
      path:
        "month_str=" +
          val.split("-")[1] +
          "-" +
          val.split("-")[0] ,
          operator_id: operator && operator.operator_id || null
          
    });
  };

  renderHeader = () => {
			const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    // console.log("data------------", this.props.data);
    return (
      <View>
        <View style={{ marginBottom: 15, marginTop: 5 }}>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <PBText
              bold={true}
              style={{ fontSize: 15, color: "#9ACD32", textAlign: "center" }}
														fontStyle={
															operator && operator.operator_name === "busnorte"
																	? "new-bold-font"
																	: "bold-text"
													}
            >
              {" "}
              {this.props.data.loginData && this.props.data.loginData.balances
                ? CommonService.currency(
                    this.props.data.loginData.balances.clp.money
                  ).split(",")[0]
                : "$0.0"}
            </PBText>
          </View>
          <View>
            <PBText
              dark={true}
              style={{ textAlign: "center", fontSize: 14 }}
														fontStyle={
															operator && operator.operator_name === "busnorte"
																	? "new-bold-font"
																	: "bold-text"
													}
            >
              Saldo disponible
            </PBText>
          </View>
        </View>

        <View className="transactions-header-container">
          <PBTouchable
            className="full-flex"
            // onPress={this.showPicker}
          >
            <View className="transactions-header-inner">
              <View className="display-flex">
                <PBText
                   fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "new-bold-font"
																						: "bold-text"
																		}
                  fontSize={"font14"}
                  fontColor={"white-font"}
                  style={{ marginRight: 10 }}
                >
                  {CommonService.capitalize(this.state.month)}
                </PBText>
                <PBText
                   fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "new-bold-font"
																						: "bold-text"
																		}
                  fontSize={"font14"}
                  fontColor={"white-font"}
                  style={{ marginRight: 10 }}
                >
                  {this.state.year}
                </PBText>
              </View>
              <img width={16} src="../../assets/icons/arrow-down-white.png" />
            </View>
          </PBTouchable>

          <div className="transactions-deep_date-picker">
            <IonDatetime
              cancelText={"Cancelar"}
              doneText={"OK"}
              displayFormat="MMM YYYY"
              placeholder="Select Date"
              onIonChange={(e) => this.changeDate(e.detail.value)}
              monthShortNames={month}
														className={operator && operator.operator_name === "busnorte"
														? "side-menu-new-bold-font"
														: ""}
              // onIonChange={e => console.log(e.detail.value)}
            ></IonDatetime>
          </div>
        </View>
      </View>
    );
  };

  renderTransactions = () => {
			const operator =
			JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    return (
      // <View style={styles.innerMovements}>
      <View
        style={{
          height: `calc(${CommonService.getWindowHeight()}px - 160px)`,
          overflowY: "scroll",
        }}
      >
        <View className="transaction-list-header-container-parent">
          <View className="transaction-list-details-container transaction-list-container-padding">
            <View>
              <PBText
                fontColor={"dark-font"}
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "new-bold-font"
																			: "bold-text"
															}
                fontSize={"font14"}
                textAlign={"text-center"}
              >
                Fecha
              </PBText>
            </View>
            <div className="transaction-list-v-line"></div>
            <View style={{ flex: 1 }}>
              <PBText
                fontColor={"dark-font"}
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "new-bold-font"
																			: "bold-text"
															}
                fontSize={"font14"}
              >
                Movimientos
              </PBText>
            </View>
            <div className="transaction-list-v-line"></div>
            <View>
              <PBText
                fontColor={"dark-font"}
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "new-bold-font"
																			: "bold-text"
															}
                fontSize={"font14"}
              >
                Monto
              </PBText>
            </View>
          </View>
        </View>

        {this.props.data.TxnHistory ? (
          this.props.data.TxnHistory.map((item, i) => (
            <TransactionListCard
              key={item.id}
              transaction={item}
              onPress={() => {
                this.txnDetailsScreen(item);
              }}
            />
          ))
        ) : (
          <NoData
            icon={"/assets/carpool/no-transaction.png"}
            title="No tienes movimientos registrados actualmente."
            desc="Recarga tu wallet y planifica tu próximo viaje."
            buttonText="¡Recarga tu KuposPay aquí!"
            onPress={() => this.props.history.push("/recharge-wallet")}
          />
        )}
      </View>
    );
  };

  render() {
			const operator =
			JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    return (
      <IonPage>
        <IonContent>
          <View style={styles.mainPage}>
            <BGCarpoolInnerBottom />

            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                />
              </div>
              <div className="my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}
																 fontStyle={
																		operator && operator.operator_name === "busnorte"
																				? "side-menu-new-bold-font"
																				: ""
																}
																>
                  Mis
                </PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  movimientos
                </PBText>
              </div>
              <div>&nbsp;</div>
            </div>

            <View className="position-relative">
              {/* <BGCarpoolInnerBottom /> */}
              <div>
                {this.renderHeader()}
                {this.renderTransactions()}
                <PBModal
                  showModal={this.state.showModal}
                  titleText={this.state.modalTitle}
                  bodyText={this.state.modalBody}
                  type="alert"
                  showButton1={true}
                  button1Text={this.state.modalButtonText}
                  button1Press={
                    this.state.modalButtonTap
                      ? this.state.modalButtonTap
                      : this.closeModal
                  }
                  icon={this.state.modalIcon}
                  onBackButtonPress={
                    this.state.modalBackButtonPress
                      ? this.state.modalBackButtonPress
                      : this.goBack
                  }
                />
              </div>

              {/* <Modal
                backdropOpacity={.4}
                style={{ margin: 0,marginBottom:200 }}
                isVisible={this.state.showPickerModal} 
                animationInTiming={50}
                // onBackButtonPress={() => {Picker.hide();this.setState({showPickerModal:false})}}
                // onBackdropPress={() => {Picker.hide();this.setState({showPickerModal:false})}}
            >
                </Modal> */}
            </View>
          </View>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  mainPage: {
    flex: 1,
    height: "100%",
    overflow: "hidden",
  },

  accountBGContainer: {
    width: "100%",
    borderRadius: 8,
    padding: 15,
    alignItems: "center",
    zIndex: 1,
  },
  hLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginVertical: 10,
    width: "100%",
  },
  accountInfo: {
    marginLeft: 10,
    flex: 1,
  },
  setting: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 9,
    borderRadius: 18,
  },
  //------

  //renderTransactions
  txns: {
    width: "100%",
    marginBottom: 20,
    marginTop: 10,
  },
  txnHead: {
    width: dimensions.vw * 92,
    marginHorizontal: dimensions.vw * 4,
    flexDirection: "row",
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    marginTop: 25,
    justifyContent: "space-between",
    alignItems: "flex-end",
  },

  footerLink: {
    padding: 15,
  },
  vLine: {
    marginRight: 5,
    width: 1,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
  },

  dateHeader: {
    flexDirection: "row",
    width: dimensions.vw * 92,
    marginLeft: dimensions.vw * 4,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginTop: 10,
  },
  innerMovements: {
    height: "78vh",
    overflowY: "scroll",
  },
};

export default connectData()(withIonLifeCycle(CarpoolMyTransactionsScreen));
