import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import * as actions from '../redux/actions';
import { connectData } from '../../../redux';

export const AboutUs1 = (props) => {
  const createMarkup = () => {
    return { __html: props.aboutUsBody.replaceAll("///"," ") };
  };
  const operator =
		JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <div className='themes-about-us-1 mt10'>
      <div className='about-us-main-container'>
        <div className='left-side' style={{ padding: 20 }}>
          <div className='font12 less-bold-text mb5'>{props.smallHeading}</div>
          <div className='bold-text mb15 black-text-color capitalize'
           style={{ textAlign: operator?.operator_name === "jeldres" ? 'center' : '',
             fontSize: operator?.operator_name === "jeldres" ? 'font18' : 'font15 '
            }} >
            {props.boldHeading}
          </div>
          <div
            className='pr10 lh-1-3 font12 '
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
        <div className='right-side' style={{ padding: 20 }}>
          <img src={props.imageSource} alt='About Us' />
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
// function mapStateToProps(state) {
//   return {
//     themes: state.themes,
//   };
// }

/* istanbul ignore next */
// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators({ ...actions }, dispatch),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(AboutUs1);
export default connectData()(AboutUs1);
