const email = '/assets/arzola/General/icon_email.png';
const BookingActive =
	'/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
	'/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-CancelTicket.svg';
const OriginIcon =
	'/assets/arzola/mainimages/origin.svg';
const DestinationIcon =
	'/assets/arzola/mainimages/destination.svg';
const CalendarIcon =
	'/assets/arzola/mainimages/calendar.svg';
const titleUnderline = '/assets/arzola/mainimages/underline.svg';
const BG =
	'/assets/arzola/mainimages/home-banner.webp';
const menuDot = '/assets/aeroquinta/mainimages/dots.svg';
const sendBtn = '/assets/busnorte/Home/Ivergrama-Website-Home-SendContact.svg';
const contactFormImg =
	'/assets/busnorte/Home/Ivergrama-Website-Home-BannerSpecialServices.jpg';

const fb =
	'/assets/arzola/mainimages/facebook.svg';
const fbHover =
	'/assets/arzola/mainimages/facebook.svg';
const twitter =
	'/assets/arzola/mainimages/twitter.svg';
const twitterHover =
	'/assets/arzola/mainimages/twitter.svg';
const instagram =
    '/assets/arzola/mainimages/instagram.svg';
const instagramHover =
    '/assets/arzola/mainimages/instagram.svg';
const whatsapp =
	'/assets/arzola/mainimages/whatsapp.svg';


    const LocationIcon = '/assets/busnorte/General/LocationIcon.svg';
    const PhoneIcon = '/assets/busnorte/General/PhoneIcon.svg';
    const HeaderLogo = '/assets/busnorte/General/Header-BusesTepualLogo.svg';
    const FooterLogo = '/assets/busnorte/General/Footer-BusesTepualLogo.svg';
    const checkboxIcon = '/assets/tepual/checkbox.png';
    
    const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';
    const animation =
        '/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
    const pnr =
        '/assets/arzola/mainimages/wheretofindpnr.png';
    
    const contactMail =
        '/assets/arzola/mainimages/contact-email.svg';
    const contactAddress =
        '/assets/arzola/mainimages/contact-telephone.svg';
    
    const aboutUsImage =
        '/assets/arzola/mainimages/aboutus.webp';
    // banners
    const destinationBanner = '/assets/busnorte/destinations/destination-main.jpg';
    const serviceBanner =
        '/assets/busnorte/Services/Ivergrama-Website-Services-MainBanner.jpg';
    const contactUsBanner =
        '/assets/arzola/mainimages/contact.png';
    const aboutUsBanner =
        '/assets/arzola/mainimages/about-banner.webp';
    const tncBanner =
        '/assets/arzola/mainimages/terms-banner.png';
    const officeBanner = '/assets/busnorte/offices/offices-banner.jpg';
    
    const OfficePhoneIcon = '/assets/busnorte/General/officePhone.svg';
    
    
    // accounts section
    const pay = '/assets/blue/Andipay.png';
    const backimage = '/assets/blue/Andimar-BackgroundMobile.png';
    
    const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
    const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
    const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
    const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
    const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
    const camera = '/assets/arzola/mainimages/my-account.png';
    const nortecamera = '/assets/busnorte/Home/login.png';
    const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
    const loginblue = '/assets/arzola/mainimages/my-account.png';
    const recoverpswd = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-RestorePassword-Blue.svg';
    const success = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-CheckMark-Blue.svg';
    const failure = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-Information-Blue.svg';
    const warning = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-Information-Blue.svg';
    const userIcon = '/assets/arzola/mainimages/my-account.png';
    const tick = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-Tick-Blue.svg';
    const heartOn = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-HeartOn-Blue.svg';
    const heartOff = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-HeartOff-Blue.svg';
    const downArrow = '/assets/arzola/mainimages/downarrow.svg';
    const registrationSuccess = '/assets/aeroquinta/Blue/MyAccount-IllustratedIcons-RightDocument-Blue.svg';
    
    //  pet seat 
    const petseatref = '/assets/busnorte/pet/Ivergrama-ResultPage-SeatPick-PetsOnBoard_Pet Paw Icon (Amenite).svg';
    const petseatModal = '/assets/busnorte/pet/PopUp.png';
    const petfilteroff = '/assets/busnorte/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_off.svg';
    const petfilteron = '/assets/busnorte/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_On.svg';
    
    const operatorPay = ''
    
    const arzolaIcons = [
        { icon: PhoneIcon, name: 'phone' },
        { icon: email, name: 'email' },
        { icon: LocationIcon, name: 'location' },
        { icon: HeaderLogo, name: 'headerLogo' },
        { icon: FooterLogo, name: 'footerLogo' },
        { icon: contactFormImg, name: 'contactFormImg' },
        { icon: titleUnderline, name: 'titleUnderline' },
        { icon: menuDot, name: 'menuDot' },
        { icon: sendBtn, name: 'sendBtn' },
        { icon: fb, name: 'fb' },
        { icon: fbHover, name: 'fbHover' },
        { icon: twitter, name: 'twitter' },
        { icon: twitterHover, name: 'twitterHover' },
        { icon: instagram, name: 'instagram' },
        { icon: instagramHover, name: 'instagramHover' },
        { icon: whatsapp, name: 'whatsapp' },
        { icon: BG, name: 'BG' },
    
        { icon: BookingActive, name: 'BA' },
        { icon: CancelActive, name: 'CA' },
        { icon: OriginIcon, name: 'Origin' },
        { icon: DestinationIcon, name: 'Destination' },
        { icon: CalendarIcon, name: 'Calendar' },
        // { icon: SearchIcon, name: 'Search' },
        // { icon: PopupIcon, name: 'Popup' },
        { icon: checkboxIcon, name: 'Checkbox' },
        { icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
        { icon: animation, name: 'animation' },
        { icon: pnr, name: 'pnr' },
        { icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },
    
        { icon: contactMail, name: 'contactMail' },
        { icon: contactAddress, name: 'contactAddress' },
        { icon: destinationBanner, name: 'destinationBanner' },
        { icon: serviceBanner, name: 'serviceBanner' },
        { icon: contactUsBanner, name: 'contactUsBanner' },
        { icon: aboutUsImage, name: 'aboutUsImage' },
        { icon: aboutUsBanner, name: 'aboutUsBanner' },
        { icon: tncBanner, name: 'tncBanner' },
        { icon: officeBanner, name: 'officeBanner' },
    
        // accounts section
        { icon: pay, name: 'pay' },
        { icon: backimage, name: 'backimage' },
        { icon: tarjetas, name: 'tarjetas' },
        { icon: viajes, name: 'viajes' },
        { icon: movimientos, name: 'movimientos' },
        { icon: wallet, name: 'wallet' },
        { icon: shareMoney, name: 'shareMoney' },
        { icon: camera, name: 'camera' },
        { icon: checkmark, name: 'checkmark' },
        { icon: tick, name: 'tick' },
        { icon: downArrow, name: 'downArrow' },
        { icon: loginblue, name: 'loginblue' },
        { icon: recoverpswd, name: 'recoverpswd' },
        { icon: success, name: 'success' },
        { icon: failure, name: 'failure' },
        { icon: heartOn, name: 'heartOn' },
        { icon: heartOff, name: 'heartOff' },
        { icon: registrationSuccess, name: 'registrationSuccess' },
        { icon: userIcon, name: 'userIcon' },
        { icon: warning, name: 'warning' },
        { icon: nortecamera, name: 'nortecamera' },
    
        //  pet seat 
        { icon: petseatref, name: 'petseatref' },
        { icon: petseatModal, name: 'petseatModal' },
        { icon: petfilteron, name: 'petfilteron' },
        { icon: petfilteroff, name: 'petfilteroff' },
    
        { icon: operatorPay, name: 'operatorPay' },
    
    ];
    
    export default arzolaIcons;
