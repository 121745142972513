import React, { PureComponent } from 'react';

import { connectData } from '../../redux';
import { PBCard, PBText } from '../../components';
import { commonStyles } from '../../theme';
import { CommonService } from '../../services';
import { IonPage, IonContent } from '@ionic/react';
import { Link } from 'react-router-dom';


class TxnDetailsScreen extends PureComponent {
  constructor(props) {
    super(props);
    // CommonService.bindNav(this);
  }

  navigationButtonPressed({ buttonId }) {
    // CommonService.rightButonHome(buttonId,this.props.componentId,this.props.returnDateChange);
  }


  renderLabelValueItem = (label, value, align) => {
    return (
      <div style={{ width: "100%", paddingVertical: 5 }}>
        <PBText fontSize={"font11"} >
          {label}
        </PBText>
        <PBText
          fontStyle={"bold-font"}
          fontSize={"font16"}
        >
          {value}
        </PBText>
      </div>
    );
  }

  renderTxnInfo = (txnInfo) => {
    return (
      <div style={{ width: '100%' }}>
        <PBText>Detalles de transacción</PBText>
        {
          Object.keys(txnInfo).map(key => {
            return (
              <div key={key} className="display-flex">
                <PBText
                  fontSize={"font14"}
                  fontStyle={"bold-font"}
                >
                  {key}:{" "}
                </PBText>
                <PBText
                  fontSize={"font14"}
                  fontStyle={"bold-font"}
                >
                  {txnInfo[key] ? txnInfo[key] : "-"}
                </PBText>
              </div>
            );
          })
        }
      </div>
    );
  }

  render() {

    let selectedTxn;

    this.props.data.TxnHistory && this.props.data.TxnHistory.map(
      (item, i) => {
        if (i == this.props.match.params.item)
          selectedTxn = item
      }
    );

    // console.log("slected txn is:", this.props.match.params);


    //  const selectedTxn = this.props.selectedTxn;
    return (
      <IonPage>
        <IonContent>
          <div className="model-top-strip" style={{ position: "fixed" }}>
            <Link to={"/my-account"}>
              <img src="../../assets/carpool/arrow-back.png" />
            </Link>

            <div className="font14 model-top-strip-label">
              Detalle de la transacción
            </div>


            <a onClick={() => { this.props.history.replace('', null); this.props.history.push('/') }}>
              <img src="/assets/carpool/home.svg" />
            </a>
          </div>
          <div className="top-strip-margin full-flex">
            <div className="full-flex">
              <PBCard style={{ marginBottom: 10, marginTop: 35 }} padding={25}>
                {this.renderLabelValueItem(
                  "Categoría",
                  selectedTxn.category_info.category
                    ? selectedTxn.category_info.label
                    : "-"
                )}
                <div className="txn-details-h-line"></div>
                {this.renderLabelValueItem(
                  selectedTxn.category_info.category != "booking" ||
                    selectedTxn.category_info.category != "cancellation"
                    ? "N° de Reserva"
                    : "Número de transacción",
                  selectedTxn.category_info.txn_code
                    ? selectedTxn.category_info.txn_code
                    : "-"
                )}
                {selectedTxn.category_info.txn_info &&
                  Object.keys(selectedTxn.category_info.txn_info).length ? (
                  <div className="txn-details-h-line"></div>
                ) : null}
                {selectedTxn.category_info.txn_info &&
                  Object.keys(selectedTxn.category_info.txn_info).length
                  ? this.renderTxnInfo(selectedTxn.category_info.txn_info)
                  : null}
                <div className="txn-details-h-line"></div>
                {selectedTxn.category_info.description
                  ? this.renderLabelValueItem(
                    "Descripción",
                    selectedTxn.category_info.description
                      ? selectedTxn.category_info.description
                      : ""
                  )
                  : null}
                {selectedTxn.category_info.description ? (
                  <div className="txn-details-h-line"></div>
                ) : null}
                <div className="flex-100">
                  <div style={{ width: "50%" }}>
                    {this.renderLabelValueItem(
                      "Fecha de transacción:",
                      selectedTxn.created_date
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    {this.renderLabelValueItem(
                      "Hora de transacción:",
                      selectedTxn.updated_time,
                      "right"
                    )}
                  </div>
                </div>
                <div className="txn-details-flex">
                  <div className="full-flex">
                    <PBText fontSize={"font18"} fontColor={"primary-font"}>
                      Total:
                    </PBText>
                  </div>
                  <div className="my-account-flex-end">
                    <div style={{ marginBottom: 2 }}>
                      <PBText fontSize={"font18"} fontColor={"primary-font"}>
                        CLP
                      </PBText>
                    </div>
                    <PBText
                      fontSize={"font18"}
                      fontColor={"primary-font"}
                      fontStyle={"bold-font"}
                    >
                      {selectedTxn.amount ? selectedTxn.amount : "0.0"}
                    </PBText>
                  </div>
                </div>
              </PBCard>
            </div>
          </div>
        </IonContent>
      </IonPage >
    );
  }
}


const styles = {
  hLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    width: '100%',
    marginVertical: 10
  }
};

export default connectData()(TxnDetailsScreen);
