import './ThemeFourFooter.css';

import { CommonService } from '../../../services';
import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeFourFooter = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	const operatorName = operator && operator.operator_name;

	const fbLink = operatorName === 'pullmandelsur' ? ''
		: operatorName === 'chilebus' ? 'https://www.facebook.com/chilebus/?locale=es_LA' : 'https://www.facebook.com/busessaopaulo';


	const instaLink =
		operatorName === 'pullmandelsur'
			? 'https://www.instagram.com/pullmandelsur.cl/ ' : operatorName === 'chilebus' ?
				'#' : 'https://www.instagram.com/buses.sao.paulo/';

	const background = operator && operator.color_codes && operator.color_codes.footer_color;

	return (
		operator && (
			<div className="themes-theme-four-footer ">
				<div
					className="footer-arc"
					style={{
						background,
					}}
				>
					<div className="footer-container ">
						<div>
							<a href="/">
								<img
									src={
										operator && operator.footer_logo ? operator.footer_logo : ""
									}
									alt=""
									style={{
										width:
											operator && operator.footer_logo_width
												? operator.footer_logo_width
												: "",
										height: "auto",
									}}
								/>
							</a>
						</div>
						<div className="footer-links">
							{getIcons("fb", operator) && (
								<a
									href={fbLink}
									target="_blank"
								>
									<img
										src={getIcons("fb", operator)}
										alt=""
										style={{ width: 20 }}
										className="footer-icon fb"
									/>
								</a>
							)}

							{getIcons("insta", operator) && (
								<a
									href={instaLink}
									target="_blank"
								>
									<img
										src={getIcons("insta", operator)}
										alt=""
										className="footer-icon insta"
									/>
								</a>
							)}

							<div>
								<span className={operator.operator_name === "santajuana" ? 'white-text' : ''}>
									{" | "}
								</span>
							</div>


							<img
								src={getIcons("phone", operator)}
								className="footer-icon phone-icon"
								alt=""
							/>

							{operator && operator.phone_number ? (
								<div
									className="phone"
									style={{
										color:
											operator &&
											operator.color_codes &&
											operator.color_codes.secondary_text_color,
									}}
								>
									{operator.operator_name === "santajuana" ? "(+569) 99217771" : operator.phone_number}
								</div>
							) : null}
						</div>
					</div>
				</div>

				{operator && operator.show_bottom_footer ? (
					<ShowBottomFooter operator={operator} />
				) : null}
			</div>
		)
	);
};

const ShowBottomFooter = ({ operator }) => {
	// const background =
	//   operator && operator.color_codes && operator.color_codes.sub_footer_color;
	// const color =
	//   operator &&
	//   operator.color_codes &&
	//   operator.color_codes.secondary_text_color;

	let background, color;

	if (operator.operator_name === "santajuana") {
		background =
			operator && operator.color_codes && operator.color_codes.button_color;
	} else {
		background =
			operator && operator.color_codes && operator.color_codes.sub_footer_color;
	}

	color =
		operator &&
		operator.color_codes &&
		operator.color_codes.secondary_text_color;

	return (
		<div className='d-flex-c f-13 bottom p-2' style={{ background, color }}>
			{/* <span>
        {operator.name} {new Date().getFullYear()}{' '}
      </span>
      <span>- Todos los derechos reservados © | Powered by</span>
      <a
        href='https://kupos.cl'
        // eslint-disable-next-line react/jsx-no-target-blank
        target='_blank'
        className='less-bold-text'
        style={{ color }}
      >
        {' '}
        Kupos.cl
      </a> */}
			{operator.operator_name === "santajuana" ? (
				<span>Transportes Santa Juana SpA 2024 </span>
			) : (
				<span>{operator.operator_name} 2024 </span>
			)}
			<span>- Todos los derechos reservados © | Powered by</span>
			<a
				href='https://kupos.cl'
				// eslint-disable-next-line react/jsx-no-target-blank
				target='_blank'
				className='less-bold-text'
				style={{ color }}
			>
				{' '}
				Kupos.cl
			</a>
		</div>
	);
};
export default connectData()(ThemeFourFooter);
