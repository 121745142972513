
const destination1 =
  '/assets/jeldres/destinations/destination1.webp';
const destination2 =
  '/assets/jeldres/destinations/destination2.webp';
const destination3 =
  '/assets/jeldres/destinations/destination3.webp';
const destination4 =
  '/assets/jeldres/destinations/destination4.webp';



const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
];



const jeldresSlides = {
  destination,
};

export default jeldresSlides;
