import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";

import { AndimarTitle } from "../../common/Title/AndimarTitle";
import Footer1 from "../../common/footer/Footer1";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import KuposHelmet from "../../common/KuposHelmet";
import { connectData } from "../../../redux";

const FleetBanner = "/assets/traveltur/North/banner.png";
const Icon1 = "/assets/traveltur/North/icon1.png";
const Icon2 = "/assets/traveltur/North/icon2.png";
const Icon3 = "/assets/traveltur/North/icon3.png";
const BusImage = "/assets/traveltur/North/bus.png";

const amenitiesItems = [
  {
    text: "Servicios de transporte a la minería",
    image: Icon1,
  },
  {
    text: "Buses de última generación",
    image: Icon2,
  },
  {
    text: "Contratos y convenios especiales",
    image: Icon3,
  },
];

const ThemeTenFleetScreen = (props) => {
  const operator =
    JSON.parse(localStorage.getItem("op")) || props.data.operator;

  const styles = {
    singleTab: {
      marginTop: 20,
      background:
        operator && operator.theme_color2 ? operator.theme_color2 : "",
      padding: 15,
      justifyContent: "space-around",
      color: "#fff",
      borderRadius: 50,
      display: "flex",
    },
    interCityContainer: {
      background: "#F3F7FF",
      // height: 300,
      marginTop: -40,
      borderRadius: 10,
      paddingTop: 50,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
    whiteColor: {
      color: "#fff",
    },
  };

  return (
    <IonPage>
      {/* <KuposHelmet /> */}

      <IonContent>
        <Header />
        <HeroSection
          heroImage={FleetBanner}
          // height={'60vh'}
          height={"45vh"}
          title={"Travel Norte"}
          description={"Home / Travel Norte"}
        />

        {/* Amenities section  */}
        <AndimarTitle
          title={"TRANSPORTE PARA LA MINERÍA"}
          isSanantonio={false}
          isTraveltur={true}
        />
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <div className="light-blue-background"></div>
          <div
            className="take-care d-flex "
            style={{
              justifyContent: "space-around",
              marginTop: -60,
            }}
          >
            {amenitiesItems.map((item) => (
              <div className="take-care-item d-flex flex-column align-items-center text-center">
                <img src={item.image} alt="" style={{ width: 100 }} />
                <p className="mt-2 montserrat">{item.text}</p>
              </div>
            ))}
          </div>

          <div style={{ marginTop: 70 }}>
            Nuestros modernos buses, con salidas periódicas hacia ciudades como
            Calama y Antofagasta, están configurados para acceder con facilidad
            a las faenas mineras. Son vehículos acondicionados especialmente en
            cuanto a performance, seguridad, conectividad y confort.
            <br />
            <br />
            El transporte de personal hacia y dentro de las faenas ofrece a las
            compañias mineras importantes desafíos de logística, seguridad y
            bienestar de su personal que influyen significativamente en la
            viabilidad de sus operaciones.
            <br />
            <br />
            Por otro lado, la preparación y pericia de nuestros choferes y
            controladores es vital al manejar un bus de 14 metros de longitud o
            más, en alta cordillera y con nieve, y es por tanto un tema de
            suprema importancia. Para ello nos aseguramos que posean
            capacitaciones al día y suficiente experiencia.
          </div>
          <div
            className="take-care-item d-flex flex-column align-items-center text-center"
            style={{ marginTop: 30, marginBottom: 20 }}
          >
            <img
              src={BusImage}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>

        {/* <Footer1 operator={operator} /> */}
        <Footer1 operator={operator} isSanantonio />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTenFleetScreen);
