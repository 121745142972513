import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import { connectData } from "../../redux";
import HeroSection from "../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
import Header from "../common/header/Header";

const ThemeTwoContactUsScreen = (props) => {
  const createMarkup = (data) => {
    return { __html: data };
  };
  return (
    <IonPage>
      <IonContent>
        <Header />
        {/* <HeroSection
          height={"30vh"}
          heroImage={getIcons("Banner")}
          title={"Contacto"}
          description={"Home / Contacto"}
        /> */}

        <div style={{ width: "90%", marginLeft: "5%" }}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              props.data &&
                props.data.operator &&
                props.data.operator.privacy_policy
                ? props.data.operator.privacy_policy
                : ""
            )}
            className="lh-1-3"
            style={{ fontSize: "13px" }}
          />
        </div>

        <ThemeTwoFooter operator={props.data.operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoContactUsScreen);
