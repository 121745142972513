// passing the path into variables

// const banner1 = '/assets/tepual/home/banner1.jpg';
// const banner2 = '/assets/tepual/home/banner2.jpg';
// const banner3 = '/assets/tepual/home/banner3.jpg';

const destination1 =
  '/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-1.png';
const destination2 =
'/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-2.png';
const destination3 =
'/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-3.png';
const destination4 =
'/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-4.png';
const destination5 =
'/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-5.png';




const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
  destination5,
];



const sanantonioSlides = {
  // banner,
  destination,
};

export default sanantonioSlides;
