import React, { Component } from "react";
import PBTouchable from "./PBTouchable";
import {  dimensions } from "../theme";
import PBText from "./PBText";
import View from "./View";
import getIcons from "../themes/functions/getIcons/getIcons";

export default class RecipientInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let props = this.props;
        console.log("Props are", this.props)
        return (
          <View style={styles.recipientContainer}>
            <View style={styles.dpContainer}>
              {props.recipient && props.recipient.image_link ? (
                <View className="recipient-image-circle">
                  <img
                    src={props.recipient.image_link}
                    className="recipient-info-image"
                    // onLoadEnd={(e) => {this.onImageLoadEnd(e)}}
                    alt=''
                  />
                </View>
              ) : (
                <img
                  className="recipient-info-image"
                  // src={"../assets/carpool/user-circle.png"}
                src={getIcons('userIcon')}

                  alt=''
                />
              )}
            </View>
            <View style={styles.recipientInfoContainer}>
              <PBText bold={true}>
                {props.recipient.recipient_name} {props.recipient.last_name}
              </PBText>
              <PBText>
                {props.recipient.identity_type_label
                  ? props.recipient.identity_type_label.split(" ")[0]
                  : "RUT"}
                : {props.recipient.recipient_identity_val}{" "}
              </PBText>
              <PBText>{props.recipient.phone} </PBText>
              <PBText>{props.recipient.recipient_email} </PBText>
            </View>
            {!props.hideButtons ? (
              <View style={styles.recipientIconContainer}>
                <PBTouchable
                  dontDisableOnClick={true}
                  onPress={() => props.addRemoveFavourite(props.recipient)}
                >
                  <img
                    style={styles.recipientIcon}
                    height={22}
                    src={
                      props.isFavourite ?
                        // ? "../assets/icons/heart.png"
                        // : "../assets/icons/heart-empty.png"
                        getIcons('heartOn'):
                        getIcons('heartOff')
                        
                    }
                    alt=''
                  />
                </PBTouchable>
                <PBTouchable
                  dontDisableOnClick={true}
                  // onPress={() => {
                  //     this.setState({ recipient: null, rut: null, amount: null });
                  // }}
                  onPress={props.removeRecipient}
                >
                  <img
                    style={styles.recipientIcon}
                    height={22}
                    src={"../assets/icons/cross-grey.png"}
                    alt=''
                  />
                </PBTouchable>
              </View>
            ) : null}
          </View>
        );
    }
}


const styles = {

    recipientContainer: {
        display:"flex",
        flexDirection: 'row',
        alignItems: 'center',
    },
    dpContainer: {
        // marginTop: 5
    },
    imgCircle: {
        width: 15,
        height: 15,
        borderRadius: 7,
        overflow: "hidden",
        borderColor: "#fff",
        borderWidth: 2,
        alignItems: "center",
        justifyContent: "center",
    },

    recipientIconContainer: {
        position: "absolute",
        top: 15,
        right: 30,
        display:"flex",
        width: 56,
        justifyContent: "flex-end",
    },
    recipientInfoContainer: {
        paddingVertical: 15,
        paddingTop: 0,
        marginLeft: 10,
    },
    recipientIcon: {
        marginLeft: 10,
    }
}
