import { IonContent, IonPage } from '@ionic/react';

import { AndimarTitle } from '../../common/Title/AndimarTitle';
import ContactForm4 from '../../common/contact/ContactForm4';
import Footer1 from '../../common/footer/Footer1';
import Header1 from '../../common/header/Header1';
import HeroSection from '../../common/HeroSection/HeroSection';
import KuposHelmet from '../../common/KuposHelmet';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import getSlides from '../../functions/getSlides';
import Header from '../../common/header/Header';

// import contactBy1 from '../../assets/andimar/contact-by/contact.svg';
// import contactBy2 from '../../assets/andimar/contact-by/email.svg';
// import contactBy3 from '../../assets/andimar/contact-by/location.svg';
// import socialMedia1 from "../../assets/andimar/social-media/fb.png";
// import socialMedia2 from "../../assets/andimar/social-media/insta.png";

const destinationBanner = '/assets/andimar/destinations/destino_banner.jpg';
const contactUsBanner = '/assets/andimar/andimar-contact-banner.jpg';

let contactByArr = [
  {
    text: '(+56 9) 4915 6683',
    image: '/assets/andimar/contact-by/contact.svg',
  },
  {
    text: 'ayuda@pasajebus.com',
    image: '/assets/andimar/contact-by/email.svg',
  },
  {
    text: 'Compañía 4368 - Quinta Normal. Santiago - Región Metropolitana.',
    image: '/assets/andimar/contact-by/location.svg',
  },
];

const socialMedia = [
  {
    icon: '/assets/andimar/social-media/fb.png',
    link: 'https://www.facebook.com/NuevaAndimarVIP',
  },
  {
    icon: '/assets/andimar/social-media/insta.png',
    link: 'https://www.instagram.com/andimarvip/?utm_source=ig_embed&ig_mid=327903A9-8631-4919-BB95-D5083ADB2AAD&hl=es',
  },
];

const AndimarContactUs = () => {
  const operator = JSON.parse(localStorage.getItem('op'));

  contactByArr[0].text = operator && operator.phone_number
  contactByArr[1].text = operator && operator.email

  return (
    <IonPage>
      {/* <KuposHelmet /> */}

      <IonContent>
        {/* <div
          style={{
            position: 'absolute',
            width: '100vw',
            zIndex: 1000,
          }}
        >
          <Header1 />
        </div> */}
        <Header />
        <HeroSection
          heroImage={contactUsBanner}
          // height={'60vh'}
          height={'45vh'}
          title={'Contacto'}
          description={'Home / Contacto'}
        />
        <AndimarTitle title={'  CONTACTO  '} />
        <div
          style={{
            width: '100%',
            // marginLeft: '10%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ContactForm4 operator={operator} showMap andimar={true} />

          <div className='contact-by w-50 d-flex justify-center align-items-start flex-column '>
            {contactByArr.map((item, key) => (
              <div
                className='contact-by-item d-flex '
                style={{
                  display: 'flex',
                  // flexDirection: "column",
                  alignItems: 'center',
                  lineHeight: 1.5,
                  borderBottom: key != 2 ? '2px solid #919191' : '',
                  padding: 25,
                  width: '80%',
                  margin: 'auto',
                }}
              >
                <img src={item.image} alt='' style={{ width: 40 }} />
                <span className='lh-1-3 font13' style={{ marginLeft: 10 }}>
                  {item.text}
                </span>
              </div>
            ))}
            <div
              className='social-media'
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                className='font16'
                style={{
                  color: '#2e3191',
                  marginBottom: 10,
                  fontWeight: 'bold',
                }}
              >
                SÍGUENOS EN REDES SOCIALES
              </div>
              <div>
                {socialMedia.map((item) => (
                  <a href={item.link} target='_blank'>
                    <img
                      src={item.icon}
                      alt=''
                      style={{
                        width: 40,
                        margin: 5,
                      }}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div
            style={{
              textAlign: 'center',
              fontSize: '12px',
              lineHeight: 1.3,
              margin: 30,
            }}
          >
            <div style={{ textAlign: 'center' }} className='font13'>
              Para reclamos, sugerencias o felicitaciones te invitamos a acceder
              a nuestro sistema de Servicio al Cliente aquí
            </div>
          </div>
          <a href='#' target='_blank' className='andimar-btn-link  font15'>
            Ingresa al sistema Aquí
          </a>
          <div
            style={{
              textAlign: 'center',
              fontSize: '12px',
              lineHeight: 1.3,
              margin: 30,
            }}
          >
            <div style={{ textAlign: 'center' }} className='font13'>
              En caso de problemas con compras en línea, envía tu <br />{' '}
              solicitud a{' '}
              <span
                style={{
                  color:
                    operator && operator.theme_color2
                      ? operator.theme_color2
                      : '',
                }}
              >
                {' '}
                {operator && operator.email}{' '}
              </span>{' '}
              o comunícate al
              <br />{' '}
              <span
                style={{
                  color:
                    operator && operator.theme_color2
                      ? operator.theme_color2
                      : '',
                }}
              >
                {operator && operator.phone_number}{' '}
              </span>
            </div>
          </div>
        </div>

        <Footer1 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(AndimarContactUs);
