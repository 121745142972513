import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import IvergramaContactForm from '../common/IvergramaContactForm';
import IvergramaFooter from '../common/IvergramaFooter';
import IvergramaTitle from '../common/IvergramaTitle';
import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const IvergramaServiceScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	return (
		<IonPage>
			<IonContent>
				<Header />

				<HeroSection
					heroImage={getIcons('serviceBanner', operator)}
					title='Servicios'
					description='Home / Servicios'
					height={'45vh'}
				/>

				<IvergramaTitle
					operator={operator}
					title='Conoce nuestros servicios'
				/>

				<IvergramaContactForm service />
				<IvergramaFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(IvergramaServiceScreen);
