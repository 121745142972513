// import "./ThemeFourContactForm.css";

// import { CommonService } from "../../../services";
// import { PBInput } from "../../../components";
// import React from "react";
// import { connectData } from "../../../redux";
// import getIcons from "../../functions/getIcons/getIcons";

// const ThemeFourContactForm = (props) => {
// 	const operator = CommonService.getLocal("op") || props.data.operator;

// 	return (
// 		<div className="themes-theme-four-contact-form new-container">
// 			{props.homeForm ? <HomeContactForm operator={operator} /> : null}

// 			{props.contactForm ? <ContactForm operator={operator} /> : null}
// 		</div>
// 	);
// };

// const HomeContactForm = ({ operator }) => {
// 	return (
// 		<div className="home-contact-container">
// 			<h2
// 				style={{
// 					color:
// 						operator &&
// 						operator.color_codes &&
// 						operator.color_codes.secondary_text_color &&
// 						operator.color_codes.secondary_text_color,
// 				}}
// 			>
// 				<span
// 					style={{
// 						background: "#f7315c",
// 						display: "inline-block",
// 					}}
// 				>
// 					<span
// 						style={{
// 							position: "relative",
// 							top: "-8px",
// 						}}
// 					>
// 						Conoce junto a nosotros la
// 						<span className="bold-text"> carretera Austral</span>
// 					</span>
// 				</span>
// 			</h2>

// 			<div className="home-contact-form-section ">
// 				<h2
// 					className="less-bold-text"
// 					style={{
// 						color:
// 							operator &&
// 							operator.color_codes &&
// 							operator.color_codes.primary_text_color &&
// 							operator.color_codes.primary_text_color,
// 					}}
// 				>
// 					<img src={getIcons("titleLeftIcon")} alt="" className="title-icon" />{" "}
// 					Contáctanos{" "}
// 					<img src={getIcons("titleRightIcon")} alt="" className="title-icon" />
// 				</h2>

// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Nombre</div>
// 					<PBInput
// 						type="text"
// 						placeholder="Ej. Pedro"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item ">
// 					<div className="contact-input-label less-bold-text ">Teléfono</div>
// 					<PBInput
// 						type="text"
// 						placeholder="+56 9 0000 0000"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Email</div>
// 					<PBInput
// 						type="email"
// 						placeholder="ejemplo@gmail.com"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Comentario</div>
// 					<PBInput
// 						type="text"
// 						placeholder="opcional"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<style>
// 					.btn{"{"}
// 					background:
// 					{operator &&
// 						operator.color_codes &&
// 						operator.color_codes.button_color &&
// 						operator.color_codes.button_color}
// 					{"}"}
// 					.btn:hover{"{"}
// 					background:
// 					{operator &&
// 						operator.color_codes &&
// 						operator.color_codes.tertiary_color &&
// 						operator.color_codes.tertiary_color}
// 					{"}"}
// 				</style>

// 				<div className="contact-input-item ">
// 					<button
// 						class="btn submit-btn  bold-text"
// 						style={{
// 							color:
// 								operator &&
// 								operator.color_codes &&
// 								operator.color_codes.secondary_text_color,
// 						}}
// 					>
// 						ENVIAR
// 					</button>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// const getContactInfo = (operator) => {
// 	const saopauloContactInfo = [
// 		{
// 			image: getIcons("contactHeadphone", operator),
// 			caption: operator && operator.phone_number && operator.phone_number,
// 			line: true,
// 		},
// 		{
// 			image: getIcons("contactMail", operator),
// 			caption: operator && operator.email && operator.email,
// 			line: true,
// 		},
// 	];
// 	const santajuanaContactInfo = [
// 		{
// 			image: getIcons("contactHeadphone", operator),
// 			// caption: operator && operator.phone_number && operator.phone_number,
// 			caption: operator && operator.operator_name === "santajuana" ? "(+569) 99217771" : operator.phone_number,
// 			line: true,
// 		},
// 		{
// 			image: getIcons("contactMail", operator),
// 			caption: operator && operator.email && operator.email,
// 			line: true,
// 		},
// 	];

// 	const pullmandelsurContactInfo = [
// 		{
// 			image: getIcons('contactHeadphone', operator),
// 			caption: operator && operator.phone_number && operator.phone_number,
// 			line: true,
// 		},
// 		{
// 			image: getIcons('contactMail', operator),
// 			caption: operator && operator.email && operator.email,
// 			line: true,
// 		},
// 	];

// 	const chilebusContactInfo = [
// 		{
// 			image: getIcons('contactAddress', operator),
// 			caption: operator && operator.phone_number && operator.phone_number,
// 			line: true,
// 		},
// 		{
// 			image: getIcons('contactMail', operator),
// 			caption: operator && operator.email && operator.email,
// 			line: true,
// 		},
// 	];
// 	const name = operator && operator.operator_name;
// 	if (name === "saopaulo") {
// 		return saopauloContactInfo;
// 	}
// 	if (name === 'pullmandelsur') {
// 		return pullmandelsurContactInfo;
// 	}
// 	if (name === 'santajuana') {
// 		return santajuanaContactInfo;
// 	}
// 	if (name === 'chilebus') {
// 		return chilebusContactInfo;
// 	}
// 	else {
// 		return [];
// 	}
// };

// const ContactForm = ({ operator }) => {
// 	return (
// 		<div className="contact-container">
// 			<div className="contact-form-section">
// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Nombre</div>
// 					<PBInput
// 						type="text"
// 						placeholder="Ej. Pedro"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item ">
// 					<div className="contact-input-label less-bold-text ">Teléfono</div>
// 					<PBInput
// 						type="text"
// 						placeholder="+56 9 0000 0000"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Email</div>
// 					<PBInput
// 						type="email"
// 						placeholder="ejemplo@gmail.com"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<div className="contact-input-item">
// 					<div className="contact-input-label less-bold-text ">Comentario</div>
// 					<PBInput
// 						type="text"
// 						placeholder="opcional"
// 						inputOuterStyle={{ border: "1px solid #cccc" }}
// 					/>
// 				</div>

// 				<style>
// 					.btn{"{"}
// 					background:
// 					{operator &&
// 						operator.color_codes &&
// 						operator.color_codes.button_color &&
// 						operator.color_codes.button_color}
// 					{"}"}
// 					.btn:hover{"{"}
// 					background:
// 					{operator &&
// 						operator.color_codes &&
// 						operator.color_codes.tertiary_color &&
// 						operator.color_codes.tertiary_color}
// 					{"}"}
// 				</style>

// 				<div className="contact-input-item ">
// 					<button
// 						class="btn submit-btn  bold-text"
// 						style={{
// 							color:
// 								operator &&
// 								operator.color_codes &&
// 								operator.color_codes.secondary_text_color,
// 						}}
// 					>
// 						ENVIAR
// 					</button>
// 				</div>
// 			</div>

// 			<ContactInfo operator={operator} />
// 		</div>
// 	);
// };

// const ContactInfo = ({ operator }) => {
// 	const operatorName = operator && operator.operator_name;
// 	// const fbLink = operatorName === 'pullmandelsur' ? '' : 'https://www.facebook.com/busessaopaulo';
// 	// const instaLink =
// 	//   operatorName === 'pullmandelsur'
// 	//     ? 'https://www.instagram.com/pullmandelsur.cl/ '
// 	//     : 'https://www.instagram.com/buses.sao.paulo/';

// 	const fbLink = operatorName === 'pullmandelsur'
// 		? ''
// 		: operatorName === 'santajuana'
// 			? 'https://www.facebook.com/share/e2oWZgDus1RzB2Ea/?mibextid=qi2Omg' : operatorName === 'chilebus'
// 				? '#'
// 				: 'https://www.facebook.com/busessaopaulo';

// 	const instaLink = operatorName === 'pullmandelsur'
// 		? 'https://www.instagram.com/pullmandelsur.cl/'
// 		: operatorName === 'santajuana'
// 			? 'https://www.instagram.com/transportessantajuana?igsh=MWp5NWk1bGdzcHdsNQ=='
// 			: 'https://www.instagram.com/buses.sao.paulo/';

// 	const renderIconsAndText = (item, key, length) => {
// 		return (
// 			<React.Fragment>
// 				<div className="item" key={key}>
// 					<div>
// 						<img src={item.image} alt="Giras de estudio" className="icon" />
// 					</div>
// 					<div className="caption less-bold-text ">{item.caption}</div>
// 				</div>
// 				{item.line ? (
// 					<hr
// 						style={{
// 							borderColor: "#999999",
// 							// using hardcoded color here
// 							// borderColor: operator && operator.color_codes && operator.color_codes.primary_color,

// 							borderTopWidth: "2px",
// 						}}
// 					/>
// 				) : null}
// 			</React.Fragment>
// 		);
// 	};
// 	return (
// 		<div className="contact-us-info">
// 			{getContactInfo(operator).map((item, key) => {
// 				return renderIconsAndText(item, key);
// 			})}

// 			<div className="social-links">
// 				<p className="less-bold-text">Síguenos en redes sociales</p>
// 				{getIcons("contactFb", operator) && (
// 					<a href={fbLink} target="_blank">
// 						<img
// 							src={getIcons("contactFb", operator)}
// 							alt=""
// 							className="social-icon fb"
// 						/>
// 					</a>
// 				)}

// 				{getIcons("contactInsta", operator) && (
// 					<a
// 						href={instaLink}
// 						target="_blank"
// 						style={{
// 							display: "flex",
// 							alignItems: "center",
// 							justifyContent: "center",
// 							color: "black",
// 						}}
// 					>
// 						<img
// 							src={getIcons("contactInsta", operator)}
// 							alt=""
// 							className="social-icon insta"
// 						/>
// 						{operatorName === "pullmandelsur" && (
// 							<span style={{ fontSize: "14px" }}>pullmandelsur.cl</span>
// 						)}
// 					</a>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

// export default connectData()(ThemeFourContactForm);
import "./ThemeFourContactForm.css";

import { CarpoolSuccessFailureModal, PBInput } from "../../../components";
import React, { useState } from "react";

import { CommonService } from "../../../services";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const initialState = {
	name: null,
	phone: null,
	email: null,
	comment: null,
	nameError: false,
	phoneError: false,
	emailError: false,
	commentError: false,
};

const ThemeFourContactForm = (props) => {



	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	return (
		<div className="themes-theme-four-contact-form new-container">
			{props.homeForm ? <HomeContactForm operator={operator} /> : null}

			{props.contactForm ? <ContactForm operator={operator} props={props} /> : null}
		</div>
	);
};

const HomeContactForm = ({ operator }) => {
	return (
		<div className="home-contact-container">
			<h2
				style={{
					color:
						operator &&
						operator.color_codes &&
						operator.color_codes.secondary_text_color &&
						operator.color_codes.secondary_text_color,
				}}
			>
				<span
					style={{
						background: "#f7315c",
						display: "inline-block",
					}}
				>
					<span
						style={{
							position: "relative",
							top: "-8px",
						}}
					>
						Conoce junto a nosotros la
						<span className="bold-text"> carretera Austral</span>
					</span>
				</span>
			</h2>

			<div className="home-contact-form-section ">
				<h2
					className="less-bold-text"
					style={{
						color:
							operator &&
							operator.color_codes &&
							operator.color_codes.primary_text_color &&
							operator.color_codes.primary_text_color,
					}}
				>
					<img src={getIcons("titleLeftIcon")} alt="" className="title-icon" />{" "}
					Contáctanos{" "}
					<img src={getIcons("titleRightIcon")} alt="" className="title-icon" />
				</h2>

				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Nombre</div>
					<PBInput
						type="text"
						placeholder="Ej. Pedro"
						inputOuterStyle={{ border: "1px solid #cccc" }}
					/>
				</div>

				<div className="contact-input-item ">
					<div className="contact-input-label less-bold-text ">Teléfono</div>
					<PBInput
						type="text"
						placeholder="+56 9 0000 0000"
						inputOuterStyle={{ border: "1px solid #cccc" }}
					/>
				</div>

				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Email</div>
					<PBInput
						type="email"
						placeholder="ejemplo@gmail.com"
						inputOuterStyle={{ border: "1px solid #cccc" }}
					/>
				</div>

				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Comentario</div>
					<PBInput
						type="text"
						placeholder="opcional"
						inputOuterStyle={{ border: "1px solid #cccc" }}
					/>
				</div>

				<style>
					.btn{"{"}
					background:
					{operator &&
						operator.color_codes &&
						operator.color_codes.button_color &&
						operator.color_codes.button_color}
					{"}"}
					.btn:hover{"{"}
					background:
					{operator &&
						operator.color_codes &&
						operator.color_codes.tertiary_color &&
						operator.color_codes.tertiary_color}
					{"}"}
				</style>

				<div className="contact-input-item ">
					<button
						class="btn submit-btn  bold-text"
						style={{
							color:
								operator &&
								operator.color_codes &&
								operator.color_codes.secondary_text_color,
						}}
					>
						ENVIAR
					</button>
				</div>
			</div>
		</div>
	);
};

const getContactInfo = (operator) => {
	const saopauloContactInfo = [
		{
			image: getIcons("contactHeadphone", operator),
			caption: operator && operator.phone_number && operator.phone_number,
			line: true,
		},
		{
			image: getIcons("contactMail", operator),
			caption: operator && operator.email && operator.email,
			line: true,
		},
	];
	const santajuanaContactInfo = [
		{
			image: getIcons("contactHeadphone", operator),
			// caption: operator && operator.phone_number && operator.phone_number,
			caption: operator && operator.operator_name === "santajuana" ? "(+569) 99217771" : operator.phone_number,
			line: true,
		},
		{
			image: getIcons("contactMail", operator),
			caption: operator && operator.email && operator.email,
			line: true,
		},
	];

	const pullmandelsurContactInfo = [
		{
			image: getIcons('contactHeadphone', operator),
			caption: operator && operator.phone_number && operator.phone_number,
			line: true,
		},
		{
			image: getIcons('contactMail', operator),
			caption: operator && operator.email && operator.email,
			line: true,
		},
	];

	const chilebusContactInfo = [
		{
			image: getIcons('contactAddress', operator),
			caption: operator && operator.phone_number && operator.phone_number,
			line: true,
		},
		{
			image: getIcons('contactMail', operator),
			caption: operator && operator.email && operator.email,
			line: true,
		},
	];
	const name = operator && operator.operator_name;
	if (name === "saopaulo") {
		return saopauloContactInfo;
	}
	if (name === 'pullmandelsur') {
		return pullmandelsurContactInfo;
	}
	if (name === 'santajuana') {
		return santajuanaContactInfo;
	}
	if (name === 'chilebus') {
		return chilebusContactInfo;
	}
	else {
		return [];
	}
};



const ContactForm = ({ operator, props }) => {
	const inpChange = (value, type) => {
		if (type == "phone") {
			value = CommonService.phoneNumberFormat(value);
			setState({
				...state,
				[type]: value,
				[type + "Error"]: false,
			});
		} else {
			setState({ ...state, [type]: value, [type + "Error"]: false });
		}
	};

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (!state.comment) {
			setState({
				...state,
				commentError: true,
				commentErrorMessage: "Inserta tu comentario",
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};

	const [state, setState] = useState(initialState);
	// const operator =
	// 			JSON.parse(localStorage.getItem("op")) || props.data.operator;


	const onSubmit = () => {
		if (!validate()) {
			return;
		}

		let data = {
			name: state.name,
			email: state.email,
			phone_number: state.phone,
			comments: state.comment,
			operator_id: operator.operator_id,
			operator_name: operator.operator_name,
		};

		const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

		if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
			data.last_name = state.last_name;
		}
		if (operator && (operator.operator_name === "pullmandelsur" || operator.operator_name === "saopaulo" || operator.operator_name === "chilebus")) {

			// if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "biaggini")) {

			props.opsiteContactInfo({
				callback: (result) => onContactInfoResponse(result),
				data: data,
			});
		}
	};
	const onContactInfoResponse = (res) => {
		if (res && res.data && res.data.message) {
			setState({
				...state,
				showModal: true,
				responseMessage: res.data.message,
				status: res.data.status,
			});
		}
	};
	return (
		<div className="contact-container">
			<div className="contact-form-section">
				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Nombre</div>
					<PBInput
						// label='Nombre'
						placeholder="Nombre"
						value={state.name}
						onChange={(text) => inpChange(text, "name")}
						// onBlur={(text) => onBlur(text, "name")}
						error={state.nameError ? true : false}
						errorMessage={state.nameError ? state.nameErrorMessage : ""}
						containerStyle={{
							padding: "0",
							border: 0,
						}}
						inputClass={{
							padding: "5px",
						}}
					/>
				</div>

				<div className="contact-input-item ">
					<div className="contact-input-label less-bold-text ">Teléfono</div>
					<PBInput
						// label='Nombre'
						placeholder="9 XXXX XXXX"
						value={state.phone}
						onChange={(text) => inpChange(text, "phone")}
						// onBlur={(text) => onBlur(text, "name")}
						error={state.nameError ? true : false}
						errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
						containerStyle={{
							padding: "0",
							border: 0,
						}}
						inputClass={{
							padding: "5px",
						}}
					/>
				</div>

				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Email</div>
					<PBInput
						// label='Nombre'
						placeholder="abc@gmail.com"
						value={state.email}
						onChange={(text) => inpChange(text, "email")}
						// onBlur={(text) => onBlur(text, "name")}
						error={state.emailError ? true : false}
						errorMessage={state.emailError ? state.emailErrorMessage : ""}
						containerStyle={{
							padding: "0",
							border: 0,
						}}
						inputClass={{
							padding: "5px",
						}}
					/>
				</div>

				<div className="contact-input-item">
					<div className="contact-input-label less-bold-text ">Comentario</div>
					<PBInput
						// label='Nombre'
						placeholder="Comentario"
						value={state.comment}
						onChange={(text) => inpChange(text, "comment")}
						// onBlur={(text) => onBlur(text, "name")}
						error={state.commentError ? true : false}
						errorMessage={state.commentError ? state.commentErrorMessage : ""}
						containerStyle={{
							padding: "0",
							border: 0,
						}}
						inputClass={{
							padding: "5px",
						}}
					/>
				</div>

				<style>
					.btn{"{"}
					background:
					{operator &&
						operator.color_codes &&
						operator.color_codes.button_color &&
						operator.color_codes.button_color}
					{"}"}
					.btn:hover{"{"}
					background:
					{operator &&
						operator.color_codes &&
						operator.color_codes.tertiary_color &&
						operator.color_codes.tertiary_color}
					{"}"}
				</style>

				<div className="contact-input-item ">
					<button onClick={onSubmit}
						class="btn submit-btn  bold-text"
						style={{

							color:
								operator &&
								operator.color_codes &&
								operator.color_codes.secondary_text_color,
						}}
					>
						ENVIAR
					</button>
					<CarpoolSuccessFailureModal
						showModal={state.showModal}
						success={state.status ? true : false}
						smallIcon={true}
						bodyText={state.responseMessage}
						buttonText={"OK"}
						onButtonPress={() => {
							setState({
								showModal: false,
							});
							props.history.push("/");
						}}
					/>
					{/* <PButton title={"ENVIAR"} onPress={onSubmit} /> */}
				</div>
			</div>

			<ContactInfo operator={operator} />
		</div>
	);
};

const ContactInfo = ({ operator }) => {
	const operatorName = operator && operator.operator_name;
	// const fbLink = operatorName === 'pullmandelsur' ? '' : 'https://www.facebook.com/busessaopaulo';
	// const instaLink =
	//   operatorName === 'pullmandelsur'
	//     ? 'https://www.instagram.com/pullmandelsur.cl/ '
	//     : 'https://www.instagram.com/buses.sao.paulo/';

	const fbLink = operatorName === 'pullmandelsur'
		? ''
		: operatorName === 'santajuana'
			? 'https://www.facebook.com/share/e2oWZgDus1RzB2Ea/?mibextid=qi2Omg' : operatorName === 'chilebus'
				? 'https://www.facebook.com/chilebus/?locale=es_LA'
				: 'https://www.facebook.com/busessaopaulo';

	const instaLink = operatorName === 'pullmandelsur'
		? 'https://www.instagram.com/pullmandelsur.cl/'
		: operatorName === 'santajuana'
			? 'https://www.instagram.com/transportessantajuana?igsh=MWp5NWk1bGdzcHdsNQ=='
			: 'https://www.instagram.com/buses.sao.paulo/';

	const renderIconsAndText = (item, key, length) => {
		return (
			<React.Fragment>
				<div className="item" key={key}>
					<div>
						<img src={item.image} alt="Giras de estudio" className="icon" />
					</div>
					<div className="caption less-bold-text ">{item.caption}</div>
				</div>
				{item.line ? (
					<hr
						style={{
							borderColor: "#999999",
							// using hardcoded color here
							// borderColor: operator && operator.color_codes && operator.color_codes.primary_color,

							borderTopWidth: "1px",
						}}
					/>
				) : null}
			</React.Fragment>
		);
	};



	return (

		operator && operator.operator_name === 'chilebus' ?
			(


				<div className="contact-us-info text-left">
					<p className='less-bold-text'>Envíanos tus dudas o consultas a:</p>
					{getContactInfo(operator).map((item, key) => {
						return renderIconsAndText(item, key);
					})}

					<div className="social-links">
						<p className="less-bold-text">Síguenos en nuestras redes sociales</p>
						{getIcons("contactFb", operator) && (
							<a href={fbLink} target="_blank">
								<img
									src={getIcons("contactFb", operator)}
									alt=""
									className="social-icon fb"
								/>
							</a>
						)}

						{getIcons("contactInsta", operator) && (
							<a
								href={instaLink}
								target="_blank"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									color: "black",
								}}
							>
								<img
									src={getIcons("contactInsta", operator)}
									alt=""
									className="social-icon insta"
								/>
								{operatorName === "pullmandelsur" && (
									<span style={{ fontSize: "14px" }}>pullmandelsur.cl</span>
								)}
							</a>
						)}
					</div>
				</div>) : (<div className="contact-us-info">
					{getContactInfo(operator).map((item, key) => {
						return renderIconsAndText(item, key);
					})}

					<div className="social-links">
						<p className="less-bold-text">Síguenos en redes sociales</p>
						{getIcons("contactFb", operator) && (
							<a href={fbLink} target="_blank">
								<img
									src={getIcons("contactFb", operator)}
									alt=""
									className="social-icon fb"
								/>
							</a>
						)}

						{getIcons("contactInsta", operator) && (
							<a
								href={instaLink}
								target="_blank"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									color: "black",
								}}
							>
								<img
									src={getIcons("contactInsta", operator)}
									alt=""
									className="social-icon insta"
								/>
								{operatorName === "pullmandelsur" && (
									<span style={{ fontSize: "14px" }}>pullmandelsur.cl</span>
								)}
							</a>
						)}
					</div>
				</div>)


	);
};

export default connectData()(ThemeFourContactForm);

