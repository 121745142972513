import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourSlider from '../common/ThemeFourSlider';
import ThemeFourTitle from '../common/ThemeFourTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import getSlides from '../../functions/getSlides';

const ThemeFourDestinationScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	// console.log("hello", getSlides('Destination', operator))
	return (
		<IonPage>
			<IonContent>
				<Header />
				<HeroSection
					heroImage={getIcons('destinationBanner', operator)}
					title={'Destinos'}
					description={'Home / Destinos'}
				/>
				<ThemeFourTitle title='Descubre nuestros destinos' />
				<ThemeFourSlider slides={getSlides('Destination', operator)} />
				<ThemeFourFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeFourDestinationScreen);
