import React, { PureComponent } from 'react';

import { connectData } from '../../redux';
import { BGCarpoolInnerBottom, CarpoolHeader, View, PBCard, PBText, PBModal, PBTouchable, PButton } from '../../components';
import { commonStyles, dimensions } from '../../theme';
import { IonContent, IonPage } from "@ionic/react";
import { DateSerivce } from '../../services';

class CarpoolTransactionDetailsScreen extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selectedTxn: {}
    };
  }

  componentWillMount () {
    this.setState({ selectedTxn: this.props.location.selectedTxn });
  }

  renderLabelValueItem = (label, value, align) => {
    return (
      <View style={{paddingVertical: 5 }}>
        <PBText
          fontStyle={"bold-text"}
          fontColor={"dark-font"}
          fontSize={"font14"}
          style={{ textAlign: align ? align : null }}
        >
          {label}
        </PBText>
        <PBText
          fontColor={"dark-font"}
          fontSize={"font14"}
          style={{ textAlign: align ? align : null, marginTop:5 }}
        >
          {value}
        </PBText>
      </View>
    );
  };

//   componentWillMount() {
//     this.setState({ selectedTxn :this.props.location});
//     console.log("Txn details", this.state.selectedTxn? this.state.selectedTxn: "no data");
//   }

  renderTxnInfo = txnInfo => {
    return (
      <View style={{ width: "100%" }}>
        <PBText fontStyle={"bold-text"} fontColor={"dark-font"}>Detalles de transacción</PBText>
        {Object.keys(txnInfo).map(key => {
          return (
            <View className="display-flex" key={key}>
              <View className="display-flex">
                <PBText fontColor={"dark-font"}>{key}</PBText>
                <PBText fontColor={"dark-font"}>: </PBText>
              </View>

              <PBText fontColor={"dark-font"}>
                {txnInfo[key] ? txnInfo[key] : "-"}
              </PBText>
            </View>
          );
        })}
      </View>
    );
  };

  render() {
    console.log("Selected Txn", this.state.selectedTxn);
    const {selectedTxn} = this.props.location;
    // console.log("Data TXN is", selectedTxn);
    return (
      <IonPage>
        <IonContent>
          <View className="full-flex">
            <BGCarpoolInnerBottom />

            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                />
              </div>
              <div className="my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}>
                  Detalle de
                </PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
                  fontStyle={"bold-font"}
                >
                  transacción
                </PBText>
              </div>
              <div>&nbsp;</div>
            </div>

            <div
              className="kupos-card position-relative"
              style={{ marginTop: 5 }}
            >
              {/* <View style={commonStyles.carpoolCard}> */}
              {this.state.selectedTxn
                ? this.renderLabelValueItem(
                    "Categoría",
                    this.state.selectedTxn.category_info
                      ? this.state.selectedTxn.category_info.label
                      : "-"
                  )
                : null}
              <View className="transaction-details-h-line"></View>
              {this.state.selectedTxn
                ? this.renderLabelValueItem(
                    "Número de transacción",
                    this.state.selectedTxn.category_info.txn_code
                      ? this.state.selectedTxn.category_info.txn_code
                      : "-"
                  )
                : null}
              {this.state.selectedTxn &&
              Object.keys(this.state.selectedTxn.category_info.txn_info)
                .length ? (
                <View className="transaction-details-h-line"></View>
              ) : null}
              {this.state.selectedTxn &&
              Object.keys(this.state.selectedTxn.category_info.txn_info).length
                ? this.renderTxnInfo(
                    this.state.selectedTxn.category_info.txn_info
                  )
                : null}
              {this.state.selectedTxn.category_info.description ? (
                <View className="transaction-details-h-line"></View>
              ) : null}
              {this.state.selectedTxn.category_info.description
                ? this.renderLabelValueItem(
                    "Descripción",
                    this.state.selectedTxn.category_info.description
                      ? this.state.selectedTxn.category_info.description
                      : ""
                  )
                : null}
              {this.state.selectedTxn ? (
                <View className="transaction-details-h-line"></View>
              ) : null}
              <View className="transaction-details-time-hour-container full-flex">
                <View>
                  {this.renderLabelValueItem(
                    "Fecha transacción:",
                    DateSerivce.changeDateFormat(
                      this.state.selectedTxn.created_date, "yyyy-mm-dd", "dd/mm/yyyy"
                    )
                  )}
                </View>
                <View style={{ display: "flex", justifyContent: "flex-end" }}>
                  {this.renderLabelValueItem(
                    "Hora transacción:",
                    this.state.selectedTxn.updated_time,
                    "right"
                  )}
                </View>
              </View>
              <View className="transaction-details-amount-container transaction-details-time-hour-container">
                <View style={{ flex: 1 }}>
                  <PBText
                    fontStyle={"bold-text"}
                    fontColor={"dark-font"}
                    fontSize={"font15"}
                  >
                    TOTAL:
                  </PBText>
                </View>
                <View
                  className="flex-end display-flex"
                  style={{ flexDirection: "row", alignItems: "flex-end" }}
                >
                  <PBText
                    fontStyle={"bold-text"}
                    fontColor={"dark-font"}
                    fontSize={"font15"}
                  >
                    CLP
                  </PBText>
                  <PBText
                    fontStyle={"bold-text"}
                    fontColor={"dark-font"}
                    fontSize={"font15"}
                  >
                    {this.state.selectedTxn
                      ? this.state.selectedTxn.amount
                      : "0.0"}
                  </PBText>
                </View>
              </View>
            </div>
          </View>
        </IonContent>
      </IonPage>
    );
  }
}

export default connectData()(CarpoolTransactionDetailsScreen);

