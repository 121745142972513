import React, { useEffect, useState } from 'react';

function PullmanBanners({ bgImage, text, textPosition }) {
	console.log('first', bgImage);
	const [justifyText, setJustifyText] = useState('flex-start');
	useEffect(() => {
		if (textPosition === 'center') {
			setJustifyText('center');
		} else if (textPosition === 'end') {
			setJustifyText('flex-end');
		}
	});
	return (
		<div
			style={{
				height: '30vh',
				background: `url(${bgImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div>
				<div
					// className="new-container"
					style={{
						display: 'flex',
						// justifyContent: justifyText,
					}}
				>
					<span
						style={{
							fontSize: 22,
							color: '#fff',
							textTransform: 'capitalize',
						}}
						className="bold-text font-italic"
					>
						{text}
					</span>
				</div>
			</div>
		</div>
	);
}

export default PullmanBanners;
