// @flow

import { set, isEmpty } from "lodash";
import GlobalService from "./GlobalService";
import { CommonService } from ".";
import { AppData } from ".";
// import store from "../redux/store";

export default async function App_Service({
  url,
  method,
  params,
  carpool,
  at,
  bip,
  noContentJson,
  noAuth,
  noheader,
  isPassengerInfo,
}) {
  const headers = {};

  if (!noheader && !at) {
    set(headers, "Accept", "application/json, text/plain, */*");
    if (!noContentJson)
      set(headers, "Content-Type", "application/json; charset=utf-8");
  }

  if (!noAuth) {
    set(
      headers,
      "x-op-app-domain",
      AppData.X_OP_APP_DOMAIN || window.location.host
    );
    set(headers, "x-opsite", AppData.X_OPSITE);
  }

  if (!at && !bip && !noheader && !noAuth) {
    set(headers, "X-Api-Key", "QHH79qF2fsWEx98pvNeZpQ");
    let key = CommonService.jwt_key;
    if (!CommonService.jwt_key) {
      key = CommonService.metaSecret(localStorage.getItem("jwt_key"), false);
    }
    set(headers, "x-jwt-token", key);

    let x_auth_key = CommonService.x_auth_key;
    if (!CommonService.x_auth_key && typeof window !== "undefined") {
      x_auth_key = CommonService.akd(sessionStorage.getItem("x_auth_key"), false);
    }
    set(headers, "x-auth-key", x_auth_key?.s);
    set(headers, "x-auth-uuid", x_auth_key?.u);


    var metaTagToken = document.getElementById('api-auth-token');
    var x_api_token = metaTagToken ? metaTagToken.getAttribute('content') : null;

    var metaTagKey = document.getElementById('api-auth-key');
    var x_api_key = metaTagKey ? metaTagKey.getAttribute('content') : null;


    if (AppData.generateServerData && !x_api_token) {
      let serverData = AppData.generateServerData()
      x_api_token = serverData ? serverData.signedToken : ''
      x_api_key = serverData ? serverData.key : ''
    }

    set(headers, "x-api-auth-token", x_api_token);
    set(headers, "x-api-auth-key", x_api_key);
    

    // { key: "x-api-auth-token", value: serverData?.signedToken },
    // { key: "x-api-auth-key", value: serverData?.key },
  }

  try {
    // const state = store().getState();
    // let token = GlobalService.setGlobal('token');
    if (!noheader && !at && !bip)
      if (global.token && !noAuth) {
        if (carpool) {
          if (global.userMode == "DRIVER") {
            set(headers, "XKEY", global.xkey);
            set(
              headers,
              "XTOKEN",
              CommonService.generateXAccess(global.xkey, global.xtoken)
            );
            set(headers, "XACCESS", global.driverXAccess);
          } else {
            set(headers, "XKEY", global.xkey);
            set(
              headers,
              "XTOKEN",
              CommonService.generateXAccess(global.xkey, global.xtoken)
            );
            set(headers, "XACCESS", global.xAccess);
          }
        } else {
          set(headers, "Authorization", global.token);
          set(headers, "XACCESS", global.xAccess);
        }
      } else {
        if (carpool && !noAuth) {
          set(headers, "XKEY", global.xkey);
          set(
            headers,
            "XTOKEN",
            CommonService.generateXAccess(global.xkey, global.xtoken)
          );
        }
      }

    if (at) {
      console.log("Inside at headers.......");
      set(headers, "Accept", "application/json");
      set(headers, "Content-Type", "application/json; charset=utf-8");
      // set(headers, 'Authorization', "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoX3V1aWQiOiJmMzk3N2RmZC1iYzFkLTQ4NTQtYTRlNi1hZWE3MDA4MmZmNTMiLCJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE2MTAxMDk1Njh9.fdzzUOPhIPMT6OixIxym3t9YMwRunWGit8tSZ9en7c0");
      set(headers, "api_key", "012345678");
    }

    if (bip) {
      console.log("Inside bip headers.......");
      set(headers, "Accept", "application/json");
      set(headers, "Content-Type", "application/json; charset=utf-8");
    }
    if (isPassengerInfo) {
      set(headers, "x-is-psi", "true");
    }
  } catch (e) {
    console.log("fffffff");
  }

  const reqBody = {
    method,
    headers: new Headers(headers),
  };

  console.log("Request body is", reqBody);

  if (!isEmpty(params)) {
    reqBody.body = JSON.stringify(params);
  }

  return fetch(url, reqBody)
    .then((response) => {
      // return {
      //   result: 'ok',
      //   data:response.json(),
      //   status:response.status
      // };
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then((data) => {
      return {
        result: "ok",
        data: data[1],
        statusCode: data[0],
      };
    })
    .catch((e) => {
      return {
        result: "error",
        message: "Please check your internet connection!",
        errorDetails: e,
      };
    });
}
