import React, { useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeroSection from '../../common/HeroSection/HeroSection';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import getIcons from '../../functions/getIcons/getIcons';
import { connectData } from '../../../redux';
import getSlides from '../../functions/getSlides';
import { AndimarTitle } from '../../common/Title/AndimarTitle';
import Footer1 from '../../common/footer/Footer1';
import KuposTabs from '../../../components/KuposTabs';

import address1 from '../../assets/andimar/favoriteDestinations/address1.jpg';
import address2 from '../../assets/andimar/favoriteDestinations/address2.jpg';
import address3 from '../../assets/andimar/favoriteDestinations/address3.jpg';
import address4 from '../../assets/andimar/favoriteDestinations/address4.jpg';
import address5 from '../../assets/andimar/favoriteDestinations/address5.jpg';
import address6 from '../../assets/andimar/favoriteDestinations/address6.jpg';
import address7 from '../../assets/andimar/favoriteDestinations/address7.jpg';
import address8 from '../../assets/andimar/favoriteDestinations/address8.jpg';
import address9 from '../../assets/andimar/favoriteDestinations/address9.jpg';
import address10 from '../../assets/andimar/favoriteDestinations/address10.jpg';
import address11 from '../../assets/andimar/favoriteDestinations/address11.jpg';
import address12 from '../../assets/andimar/favoriteDestinations/address12.jpg';
import MultiSlideType3 from '../../common/MultiSlider3/MultiSlideType3';
import MultiSlider3 from '../../common/MultiSlider/MultiSlider3';
import Header1 from '../../common/header/Header1';
import Header from '../../common/header/Header';

const officeBanner =
  '/assets/andimar/favoriteDestinations/andimar-offices-banner.jpg';

const stepOneItem = [
  {
    image: address3,
    address: ' Colipí Nº114, Esquina Ramón Freire.',
    phone: ' (+56 52) 221 98 79',
    timing: ' Horario: 5:30 Hrs. - 23:30 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Terminal+Andimar/@-27.3696986,-70.3365261,17z/data=!3m1!4b1!4m5!3m4!1s0x96980477eb3c6121:0xe1ff09a96fd77fc4!8m2!3d-27.3696986!4d-70.3343374?shorturl=1',
  },
  {
    image: address5,
    address: ' Arturo Prat Nº137 Of. 09',
    phone: ' (+56 51) 261 36 17',
    timing: 'Horario: 7:15 Hrs. - 23:50 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Arturo+Prat,+Vallenar,+Atacama,+Chile/@-28.5787175,-70.7574976,17z/data=!3m1!4b1!4m5!3m4!1s0x969748850ff9eaab:0xf207540d93ccf1d7!8m2!3d-28.5787175!4d-70.7553089',
  },
  {
    image: address4,
    address: ' Amunategui Nº107, B-3',
    phone: ' (+56 51) 236 03 83',
    timing: 'Horario: Todo el día.',
    mapLink:
      'https://www.google.com/maps/place/Amun%C3%A1tegui+107,+La+Serena,+Coquimbo,+Chile/@-29.9105751,-71.2586288,17z/data=!3m1!4b1!4m5!3m4!1s0x9691ca6e95ab142d:0x40209dc6c6d84bdf!8m2!3d-29.9105751!4d-71.2564401',
  },
  {
    image: address11,
    address: ' Aristía Pte. N°849, of. N°2',
    phone: ' (+56 53) 262 04 27',
    timing: 'Horario: 9:00 Hrs. - 00:00 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Amun%C3%A1tegui+107,+La+Serena,+Coquimbo,+Chile/@-29.9105751,-71.2586288,17z/data=!3m1!4b1!4m5!3m4!1s0x9691ca6e95ab142d:0x40209dc6c6d84bdf!8m2!3d-29.9105751!4d-71.2564401',
  },
  {
    image: address12,
    address: '  Pedro Aguirre Cerda 5750, of. 13B',
    phone: ' (+56 55) 260 14 89',
    timing: 'Horario: 8:00 Hrs. - 22:00 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Amun%C3%A1tegui+107,+La+Serena,+Coquimbo,+Chile/@-29.9105751,-71.2586288,17z/data=!3m1!4b1!4m5!3m4!1s0x9691ca6e95ab142d:0x40209dc6c6d84bdf!8m2!3d-29.9105751!4d-71.2564401',
  },
];

const stepTwoItem = [
  {
    image:
      '/assets/andimar/favoriteDestinations/AndimarWebsite-Offices-Santiago-SanBorja1.jpg',
    address: 'San Borja Nº184, of. 37B, 49B y 65',
    phone: '(+56 2) 276 48 902',
    timing: 'Horario: 6:30 Hrs. - 23:00 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/San+Francisco+de+Borja+184,+Santiago,+Estaci%C3%B3n+Central,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4533945,-70.6821419,17z/data=!3m1!4b1!4m5!3m4!1s0x9662c4f9cb22860d:0x61d107f0e42444b5!8m2!3d-33.4533945!4d-70.6799532?shorturl=1',
  },
  {
    image:
      '/assets/andimar/favoriteDestinations/AndimarWebsite-Offices-Santiago-TerminalSur2.jpg',
    address: ' Avda. Lib. Bernardo O. Nº3850, Ofs. 15, 20, 21, 132, 147 y 148',
    phone: ' (+56 2) 2779 38 10',
    timing:
      'Horario: Lunes a viernes: 4:30 Hrs. – 23:00 Hrs. Sábados y domingos: 5:30 Hrs. - 23:00 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Santiago/@-33.4543722,-70.6903149,17z/data=!3m1!4b1!4m5!3m4!1s0x9662c4f5cd039dad:0x7b9be22dd33b4d73!8m2!3d-33.4545765!4d-70.6880542',
  },
];

const stepThreeItem = [
  {
    image: address6,
    address: ' Prat Nº 780 - of. 12.',
    phone: ' (+56 75) 274 69 89',
    timing:
      ' Horario: 4:30 Hrs. - 20:30 Hrs. Lunes apertura a las 4:00 Hrs. y domingos a las 6:00 Hrs.',
    mapLink:
      'https://www.google.com/maps/place/Arturo+Prat+780,+Curic%C3%B3,+Maule,+Chile/@-34.984478,-71.2476617,17z/data=!3m1!4b1!4m5!3m4!1s0x966457092bd5605d:0x48af338d73999a0a!8m2!3d-34.984478!4d-71.245473',
  },
  {
    image: address7,
    address: '  Av. Bernardo O’ Higgins, Nº 0484 - of. 06.',
    phone: ' (+56 72) 223 15 01',
    timing: ' Horario: 6:00 Hrs. - 21:00 Hrs.',
    mapLink:
      "https://www.google.com/maps/place/Av+Libertador+Bernardo+O'Higgins+484,+of.+06,+Santiago,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4421156,-70.6450753,17z/data=!3m1!4b1!4m5!3m4!1s0x9662c59fa978b15d:0xcedf2d804209d3a1!8m2!3d-33.4420988!4d-70.642899",
  },
  {
    image: address8,
    address: ' Av. Manso de Velasco - of. 11.',
    phone: ' (+56 72) 271 18 17',
    timing: ' Horario: 5:30 Hrs. - 21:30 Hrs.',
    mapLink:
      "https://www.google.com/maps/place/Av.+Manso+de+Velasco,+San+Fernando,+O'Higgins,+Chile/@-34.581566,-70.9881278,17z/data=!3m1!4b1!4m5!3m4!1s0x9664900e5bcfc065:0x621c867a0688e5af!8m2!3d-34.581566!4d-70.9859391",
  },
  {
    image: address9,
    address: ' Rafael Casanova Nº 480.',
    phone: ' (+56 72) 282 39 42',
    timing: ' Horario: 5:00 Hrs. - 18:00 Hrs.',
    mapLink:
      "https://www.google.com/maps/place/Av.+Rafael+Casanova+480,+Santa+Cruz,+O'Higgins,+Chile/@-34.6415665,-71.3735707,17z/data=!3m1!4b1!4m5!3m4!1s0x96646551c53ee551:0x42dc825ec6dc82f6!8m2!3d-34.6415709!4d-71.371382?shorturl=1",
  },
];

const AndimarOffices = () => {
  const operator = JSON.parse(localStorage.getItem('op'));

  const initalState = {
    selectedTab: 1,
  };

  const [state, setState] = useState(initalState);

  const tabs = ['Zona norte', 'Santiago', 'Zona Sur'];

  const onTabChange = (tab) => {
    setState({ ...state, selectedTab: tab });
  };

  return (
    <IonPage>
      <IonContent>
        {/* <div style={{ position: 'absolute', width: '100vw', zIndex: 1000 }}>
          <Header1 />
        </div> */}
        <Header />
        <HeroSection
          heroImage={officeBanner}
          // height={'60vh'}
          height={'45vh'}
          title={'Oficinas'}
          description={'Home / Oficinas'}
        />
        <AndimarTitle title={'  OFICINAS  '} />
        <KuposTabs
          tabs={tabs}
          onTabChange={onTabChange}
          selectedTab={state.selectedTab}
          operator={operator}
          width={'27vw'}
        />

        <div style={{ marginTop: 20 }}>
          <MultiSlider3
            multiSlides={
              state.selectedTab == 1
                ? stepOneItem
                : state.selectedTab == 2
                ? stepTwoItem
                : stepThreeItem
            }
            textCard={true}
            hideArrow
            andrimarArrowStyle
            width={'80%'}
          />
        </div>

        <Footer1 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(AndimarOffices);
