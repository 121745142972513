import React, { useEffect, useState } from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import getIcons from '../../functions/getIcons/getIcons';
import './ThemeFourOfficeSection.css';
import ThemeFourTitle from './ThemeFourTitle';

const ThemeFourOfficeSection = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;

  const [offices, setOffices] = useState([]);

  useEffect(() => {
    props.getOfficeDetails({ callback: (res) => onResponse(res) });
  }, []);

  const onResponse = (res) => {
    console.log('onResponse', res);
    if (
      res &&
      res.data &&
      res.data.data.offices &&
      res.data.data.offices.length > 0
    ) {
      setOffices(res.data.data.offices);
    }
  };

  return (
    <div className='themes-theme-four-office-section new-container'>
      {props.office &&
        offices &&
        offices.length > 0 &&
        offices.map((val, i) => {
          return (
            <OfficeItem
              key={i}
              item={val}
              operator={operator}
              offices={offices}
            />
          );
        })}

      {props.contactOffice && (
        <ContactOfficeInfo offices={offices} operator={operator} />
      )}
    </div>
  );
};

const ContactOfficeInfo = ({ operator, offices }) => {
  return (
    <div>
      <ThemeFourTitle title='Conoce nuestros terminales de salida' />
      <div className='contact-office-info'>
        {offices &&
          offices.length > 0 &&
          offices.map((val, i) => {
            return (
              <ContactItem
                key={i}
                item={val}
                operator={operator}
                offices={offices}
              />
            );
          })}
      </div>
    </div>
  );
};

const ContactItem = ({ key, item, operator, offices }) => {
  return (
    <div className='item' key={key}>
      <div
        className='details'
        style={{
          background: item.bg_color,
        }}
      >
        <div className='info'>
          <div className='location'>
            <img src={getIcons('pin')} alt='' className='icon' />
            {item.location && item.location.includes(':') ? (
              <span>
                <span className='less-bold-text'>
                  {item.location.split(':')[0]}:
                </span>
              </span>
            ) : null}
          </div>
          <div className='contact'>
            <span>{item.timings}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Iframe = (props) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
};
const OfficeItem = ({ key, item, operator, offices }) => {
  return (
    <div className="office" key={key}>
      <div
        className="heading less-bold-text"
        style={{
          color:
            operator &&
            operator.color_codes &&
            operator.color_codes &&
            operator.color_codes.secondary_text_color,
          background: item.head_c,
        }}
      >
        {item.city}
      </div>

      <div
        className="details"
        style={{
          background: item.bg_color,
        }}
      >
        <div className="info">
          <div className="location">
            <img src={getIcons("pin")} alt="" className="icon" />
            {/* {item.location && item.location.includes(':') ? (
              <span>
                <span className='less-bold-text'>
                  {item.location.split(':')[0]}:
                </span>
                <span>{item.location.split(':')[1]}</span>
              </span>
            ) : null} */}

            {item.location ? (
              <span>
                {/* <span className='less-bold-text'>
                  {item.location.split(':')[0]}:
                </span> */}
                <span>{item.location}</span>
              </span>
            ) : null}
          </div>

          {/* <div className="contact">
            <img src={getIcons("contactHeadphone")} alt="" className="icon" />
            <span>{item.contact}</span>
          </div> */}
          {operator.operator_name !== "santajuana" && (
            <div className="contact">
              <img src={getIcons("contactHeadphone")} alt="" className="icon" />
              <span>{item.contact}</span>
            </div>
          )}
        </div>

        <div
          style={{
            // height: 200,
            width: "100%",
            marginBottom: 30,
            // borderRadius: 10,
            padding: 15,
          }}
        >
          <Iframe
            iframe={`<iframe src=${item.src} width="100%" height="200" style="border:0;  allowfullscreen="" loading="lazy"></iframe>`}
          />
        </div>
      </div>
    </div>
  );
};

export default connectData()(ThemeFourOfficeSection);
