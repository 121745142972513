const HeaderLogo = "/assets/andimar/andimar-logo.svg";
const FooterLogo = "/assets/tepual/general/Footer-BusesTepualLogo.svg";
const ContactBg = "/assets/tepual/home/home-background.jpg";
const PopupIcon = "/assets/andimar/Andimar-PopUp.jpg";

const BookingActive = "/assets/tepual/home/BuyTicketIcon-Active.svg";
const BookingInActive = "/assets/tepual/home/BuyTicketIcon-Inactive.svg";
const CancelActive = "/assets/tepual/home/CancelTicketIcon-Active.svg";
const CancelInActive = "/assets/tepual/home/CancelTicketIcon-Inactive.svg";
const OriginIcon =
  "/assets/traveltur/Home/SanAntonio-Website-Home-NavBar-OriginIcon.svg";
const ContactIcon = "/assets/andimar/contact-by/contact.svg";
const EmailIcon = "/assets/andimar/contact-by/email.svg";
const TelephoneIcon = "/assets/andimar/contact-by/telephone.svg";
const DestinationIcon =
  "/assets/traveltur/Home/SanAntonio-Website-Home-NavBar-DestinationIcon.svg";
const Calendar =
  "/assets/traveltur/Home/SanAntonio-Website-Home-NavBar-DateIcon.svg";
const SearchIcon = "/assets/andimar/navbar-searchIcon.svg";
const BlueArrow = "/assets/andimar/favoriteDestinations/andimar-blue-arrow.svg";
const ClockIcon = "/assets/andimar/favoriteDestinations/andimar-clock.svg";
const FleetBanner = "/assets/andimar/fleet/fleet-banner.jpg";
const FleetTv = "/assets/andimar/fleet/18.svg";
const FleetToilet = "/assets/andimar/fleet/19.svg";
const FleetAc = "/assets/andimar/fleet/20.svg";
const FleetSnacks = "/assets/andimar/fleet/21.svg";
const FleetBed = "/assets/andimar/fleet/22.svg";
const AmenitiesLeftImage = "/assets/andimar/fleet/24.jpg";
const BuyIconWhite = "/assets/andimar/buy-ticket.svg";
const BuyIconBlue = "/assets/andimar/buy-ticket-blue.svg";
const CancelIconWhite = "/assets/andimar/cancel-ticket.svg";
const CancelIconBlue = "/assets/andimar/cancel-ticket-blue.svg";
const TelephoneWhite = "/assets/andimar/contact-by/telephone-white.svg";
const EmailWhite = "/assets/andimar/contact-by/email-white.svg";
const BG = "/assets/traveltur/Home/Mobile-Banner.png";
const checkboxIcon = "/assets/andimar/checkbox.png";

const animation = "/assets/animation-screen/animation.gif";
const pnr = "/assets/traveltur/Home/WhereToFindMyPNR.jpg";
const close = "/assets/viatur/icons/close.svg";
const menuDot =
  "/assets/sanantonio/Home/SanAntonio-Website-MobileVersion-SideMenuIcon.svg";

const DestinationBanner =
  "/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-Banner-Mobile.jpg";
const ContactBanner =
  "/assets/sanantonio/Contact/SanAntonio-Website-Contact-Banner-Mobile.jpg";
const TermsBanner =
  "/assets/sanantonio/TermsAndConditions/SanAntonio-Website-TermsAndConditions-Banner.jpg";
const OfficeBanner =
  "/assets/sanantonio/Offices/SanAntonio-Website-Offices-Banner-Mobile.jpg";

const Support = "/assets/traveltur/Contact/phone.png";
const ContactEmail = "/assets/traveltur/Contact/mail.png";
const ContactLocation = "/assets/traveltur/Contact/office.png";
const Fb = "/assets/traveltur/Contact/facebook.png";
const Insta = "/assets/traveltur/Contact/instagram.png";
const TelephoneIconFooter =
  "/assets/sanantonio/Home/SanAntonio-Website-Home-Footer-Telephone.svg";
const EmailIconFooter =
  "/assets/sanantonio/Home/SanAntonio-Website-Home-Footer-Email.svg";

const Office1 =
  "/assets/sanantonio/Offices/SanAntonio-Website-Offices-Arica.png";
const Office2 =
  "/assets/sanantonio/Offices/SanAntonio-Website-Offices-Iquique.png";

// const success = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
// const failure = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Information-Orange.svg';

// const tick = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Tick-Orange.svg';
// const heartOn = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-HeartOn-Orange.svg';
// const heartOff = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-HeartOff-Orange.svg';
// const downArrow = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-DropDown-Orange.svg';
// const registrationSuccess = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RightDocument-Orange.svg';
// const userIcon = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Login-Orange.svg';
const trips =
  "/assets/sanantonio/Orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const cards =
  "/assets/sanantonio/Orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
// const wallet = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const transaction =
  "/assets/sanantonio/Orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const otp =
  "/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RestorePassword-Orange.svg";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

const tarjetas = "/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
const viajes = "/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const movimientos = "../../../assets/carpool/pigi-bank-circle.png";
const wallet =
  "/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg";
const shareMoney =
  "/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
const success =
  "/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg";
const failure =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const warning =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "../assets/carpool/user-circle.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";


//  pet seat 
const petseatref = '/assets/traveltur/pet/Traveltur-ResultPage-SeatPick-PetsOnBoard_Pet Paw Icon (Amenite).png';
const petseatModal = '/assets/traveltur/pet/PopUp-Traveltur.png';
const petfilteroff = '/assets/traveltur/pet/PetsOnBoard-Button-Filter_Off.png';
const petfilteron = '/assets/traveltur/pet/PetsOnBoard-Button-Filter_on.png';


const originoIcon = '/assets/traveltur/service/traveltur-origino.svg';
const destinoIcon = '/assets/traveltur/service/traveltur-destino.svg';






const operatorPay = '/assets/traveltur/travelturpay.png'

const travelturIcons = [
	{ icon: originoIcon, name: "originoIcon" },
	{ icon: destinoIcon, name: "destinoIcon" },

  { icon: downArrow, name: "downArrow" },
  { icon: userIcon, name: "userIcon" },
  { icon: trips, name: "trips" },
  { icon: cards, name: "cards" },
  { icon: wallet, name: "wallet" },
  { icon: transaction, name: "transaction" },
  { icon: success, name: "success" },
  { icon: failure, name: "failure" },
  { icon: tick, name: "tick" },
  { icon: heartOn, name: "heartOn" },
  { icon: heartOff, name: "heartOff" },
  { icon: registrationSuccess, name: "registrationSuccess" },
  { icon: otp, name: "otp" },

  { icon: HeaderLogo, name: "headerLogo" },
  { icon: FooterLogo, name: "footerLogo" },
  { icon: ContactBg, name: "ContactBg" },
  { icon: BookingInActive, name: "BIA" },
  { icon: BookingActive, name: "BA" },
  { icon: CancelInActive, name: "CIA" },
  { icon: CancelActive, name: "CA" },
  { icon: OriginIcon, name: "Origin" },
  { icon: DestinationIcon, name: "Destination" },
  { icon: Calendar, name: "Calendar" },
  { icon: SearchIcon, name: "Search" },
  { icon: PopupIcon, name: "Popup" },
  { icon: DestinationBanner, name: "DestinationBanner" },
  { icon: ContactIcon, name: "ContactIcon" },
  { icon: TelephoneIcon, name: "TelephoneIcon" },
  { icon: TelephoneIcon, name: "TelephoneIcon" },
  { icon: BlueArrow, name: "BlueArrow" },
  { icon: ClockIcon, name: "ClockIcon" },
  { icon: FleetBanner, name: "FleetBanner" },
  { icon: FleetTv, name: "FleetTv" },
  { icon: FleetToilet, name: "FleetToilet" },
  { icon: FleetAc, name: "FleetAc" },
  { icon: FleetSnacks, name: "FleetSnacks" },
  { icon: FleetBed, name: "FleetBed" },
  { icon: AmenitiesLeftImage, name: "AmenitiesLeftImage" },
  { icon: EmailIcon, name: "EmailIcon" },
  { icon: BuyIconWhite, name: "BuyIconWhite" },
  { icon: BuyIconBlue, name: "BuyIconBlue" },
  { icon: CancelIconWhite, name: "CancelIconWhite" },
  { icon: CancelIconBlue, name: "CancelIconBlue" },
  { icon: TelephoneIconFooter, name: "TelephoneWhite" },
  { icon: EmailIconFooter, name: "EmailWhite" },
  { icon: checkboxIcon, name: "Checkbox" },
  { icon: BG, name: "BG" },
  { icon: animation, name: "animation" },
  { icon: pnr, name: "pnr" },
  { icon: close, name: "close" },
  { icon: menuDot, name: "menuDot" },
  { icon: DestinationBanner, name: "DestinationBanner" },
  { icon: ContactBanner, name: "ContactBanner" },
  { icon: Support, name: "Support" },
  { icon: ContactEmail, name: "ContactEmail" },
  { icon: ContactLocation, name: "ContactLocation" },
  { icon: TermsBanner, name: "TermsBanner" },
  { icon: OfficeBanner, name: "OfficeBanner" },
  { icon: Office1, name: "Office1" },
  { icon: Office2, name: "Office2" },
  { icon: Fb, name: "fb" },
  { icon: Insta, name: "insta" },

  // accounts section
  { icon: pay, name: "pay" },
  { icon: backimage, name: "backimage" },
  { icon: tarjetas, name: "tarjetas" },
  { icon: viajes, name: "viajes" },
  { icon: movimientos, name: "movimientos" },
  { icon: wallet, name: "wallet" },
  { icon: shareMoney, name: "shareMoney" },
  { icon: camera, name: "camera" },
  { icon: checkmark, name: "checkmark" },
  { icon: tick, name: "tick" },
  { icon: downArrow, name: "downArrow" },
  { icon: loginblue, name: "loginblue" },
  { icon: recoverpswd, name: "recoverpswd" },
  { icon: success, name: "success" },
  { icon: failure, name: "failure" },
  { icon: heartOn, name: "heartOn" },
  { icon: heartOff, name: "heartOff" },
  { icon: registrationSuccess, name: "registrationSuccess" },
  { icon: userIcon, name: "userIcon" },
  { icon: warning, name: "warning" },


  //  pet seat 
  { icon: petseatref, name: 'petseatref' },
  { icon: petseatModal, name: 'petseatModal' },
  { icon: petfilteron, name: 'petfilteron' },
  { icon: petfilteroff, name: 'petfilteroff' },

  { icon: operatorPay, name: 'operatorPay' },

];

export default travelturIcons;
