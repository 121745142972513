const HeaderLogo = '/assets/bahia/BahiaAzul-Website-Header-Logo.svg';
const DestinationBanner =
	'/assets/bahia/Destinations/BahiaAzul-Website-MainBanner-Destinations.jpg';
const FooterLogo = '/assets/tepual/general/Footer-BusesTepualLogo.svg';
const ContactBg = '/assets/tepual/home/home-background.jpg';
// const PopupIcon = '/assets/andimar/Andimar-PopUp.jpg';

const BookingActive =
	'/assets/bahia/Home/BahiaAzul-Website-Home-SearchBar-BuyTicketIcon-Active.svg';
const BookingInActive = '/assets/tepual/home/BuyTicketIcon-Inactive.svg';
const CancelActive = '/assets/tepual/home/CancelTicketIcon-Active.svg';
const CancelInActive = '/assets/tepual/home/CancelTicketIcon-Inactive.svg';
const OriginIcon =
	'/assets/bahia/Home/BahiaAzul-Website-Home-SearchBar-OriginIcon.svg';
const ContactIcon = '/assets/andimar/contact-by/contact.svg';
const EmailIcon = '/assets/andimar/contact-by/email.svg';
const TelephoneIcon = '/assets/andimar/contact-by/telephone.svg';
const DestinationIcon =
	'/assets/bahia/Home/BahiaAzul-Website-Home-SearchBar-DestinationIcon.svg';
const Calendar =
	'/assets/bahia/Home/BahiaAzul-Website-Home-SearchBar-CalendarIcon.svg';
const SearchIcon = '/assets/andimar/navbar-searchIcon.svg';
const BlueArrow = '/assets/andimar/favoriteDestinations/andimar-blue-arrow.svg';
const ClockIcon = '/assets/andimar/favoriteDestinations/andimar-clock.svg';
const FleetBanner = '/assets/andimar/fleet/fleet-banner.jpg';
const FleetTv = '/assets/andimar/fleet/18.svg';
const FleetToilet = '/assets/andimar/fleet/19.svg';
const FleetAc = '/assets/andimar/fleet/20.svg';
const FleetSnacks = '/assets/andimar/fleet/21.svg';
const FleetBed = '/assets/andimar/fleet/22.svg';
const AmenitiesLeftImage = '/assets/andimar/fleet/24.jpg';
const BuyIconWhite = '/assets/andimar/buy-ticket.svg';
const BuyIconBlue = '/assets/andimar/buy-ticket-blue.svg';
const CancelIconWhite = '/assets/andimar/cancel-ticket.svg';
const CancelIconBlue = '/assets/andimar/cancel-ticket-blue.svg';
const TelephoneWhite = '/assets/andimar/contact-by/telephone-white.svg';
const EmailWhite = '/assets/andimar/contact-by/email-white.svg';
const BG = '/assets/bahia/Home/BahiaAzul-Website-Home-Slide-Mobile-1.jpg';
const checkboxIcon =
	'/assets/bahia/ResultpageCheck-Out/BusOperatorsWebsite-Checkbox.svg';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const animation = '/assets/animation-screen/Bus-LoadingAnimation-Andimar.gif';
const pnr = '/assets/bahia/Home/BahiaAzul-Website-Home-WhereToFindMyPNR.svg';
const aboutUsBanner =
	'/assets/bahia/AboutUs/BahiaAzul-Website-MainBanner-AboutUs.jpg';

const aboutUsImage =
	'/assets/bahia/AboutUs/BahiaAzul-Website-AboutUs-Image2.jpg';

const officeBanner =
	'/assets/bahia/Offices/BahiaAzul-Website-MainBanner-Offices.jpg';

const OfficePhoneIcon =
	'/assets/bahia/General/BahiaAzul-Website-PhoneIcon-dark.svg';

const galleryBanner =
	'/assets/bahia/Gallery/BahiaAzul-Website-MainBanner-Gallery.jpg';

const contactUsBanner =
	'/assets/bahia/Contact/BahiaAzul-Website-MainBanner-Contact.jpg';
const contactHeadphone =
	'/assets/bahia/Contact/BahiaAzul-Website-Contact-PhoneNumberIcon-03.svg';

const contactMail =
	'/assets/bahia/Contact/BahiaAzul-Website-Contact-EmailAddressIcon.svg';

const tncBanner =
	'/assets/bahia/Terms and conditions/BahiaAzul-Website-MainBanner-TermsAndConditions.jpg';

const menuDot =
	'/assets/bahia/General/BahiaAzul-Website-MobileVersion-SideMenuIcon.svg';

// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png';

const bahiaazulIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: menuDot, name: 'menuDot' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: ContactBg, name: 'ContactBg' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: Calendar, name: 'Calendar' },
	{ icon: SearchIcon, name: 'Search' },
	// { icon: PopupIcon, name: 'Popup' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },

	{ icon: DestinationBanner, name: 'destinationBanner' },
	{ icon: ContactIcon, name: 'ContactIcon' },
	{ icon: TelephoneIcon, name: 'TelephoneIcon' },
	{ icon: TelephoneIcon, name: 'TelephoneIcon' },
	{ icon: BlueArrow, name: 'BlueArrow' },
	{ icon: ClockIcon, name: 'ClockIcon' },
	{ icon: FleetBanner, name: 'FleetBanner' },
	{ icon: FleetTv, name: 'FleetTv' },
	{ icon: FleetToilet, name: 'FleetToilet' },
	{ icon: FleetAc, name: 'FleetAc' },
	{ icon: FleetSnacks, name: 'FleetSnacks' },
	{ icon: FleetBed, name: 'FleetBed' },
	{ icon: AmenitiesLeftImage, name: 'AmenitiesLeftImage' },
	{ icon: EmailIcon, name: 'EmailIcon' },
	{ icon: BuyIconWhite, name: 'BuyIconWhite' },
	{ icon: BuyIconBlue, name: 'BuyIconBlue' },
	{ icon: CancelIconWhite, name: 'CancelIconWhite' },
	{ icon: CancelIconBlue, name: 'CancelIconBlue' },
	{ icon: TelephoneWhite, name: 'TelephoneWhite' },
	{ icon: EmailWhite, name: 'EmailWhite' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BG, name: 'BG' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: aboutUsImage, name: 'aboutUsImage' },
	{ icon: officeBanner, name: 'officeBanner' },
	{ icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },
	{ icon: galleryBanner, name: 'galleryBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: contactHeadphone, name: 'contactHeadphone' },
	{ icon: contactMail, name: 'contactMail' },
	{ icon: tncBanner, name: 'tncBanner' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
];

export default bahiaazulIcons;
