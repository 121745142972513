import React from 'react';
import { menuController } from '@ionic/core';

const operator = JSON.parse(localStorage.getItem('op'));

const ThemeTwoHeader = ({ showBackground = true }) => {
	return (
		<div
			className='themes-header home-container'
			style={{
				background:
					showBackground &&
						operator &&
						operator.color_codes &&
						operator.color_codes.header_color
						? operator.color_codes.header_color
						: '#e4e5e9',
			}}
		>
			<div onClick={() => menuController.open()}>
				<img
					className='qr-back-arrow'
					src='../../assets/carpool/menu-dot.png'
					alt=''
				/>
			</div>
			<div className='new-header_logo'>
				<a href='/'>
					<img
						src={
							operator && operator.header_logo ? operator.header_logo : ''
							// getIcons('headerLogo')
						}
						alt=''
						id='logo'
					/>
				</a>
			</div>
			<div>&nbsp;</div>
		</div>
	);
};

export default ThemeTwoHeader;
