// passing the path into variables

const banner1 =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-Slide-MainBanner-Desktop1.jpg';
const banner2 =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-Slide-MainBanner-Desktop2.jpg';
const banner3 =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-Slide-MainBanner-Desktop3.jpg';

const destination1 =
  '/assets/saopaulo/General/BusesSaoPaulo-Website-Destination1-Coyhaique.png';
const destination2 =
  '/assets/saopaulo/General/BusesSaoPaulo-Website-Destination2-CerroCastillo.png';
const destination3 =
  '/assets/saopaulo/General/BusesSaoPaulo-Website-Destination3-Cochrane.png';
const destination4 =
  '/assets/saopaulo/General/BusesSaoPaulo-Website-Destination4-PuertoTranquilo.png';

const banner = [banner1, banner2, banner3];

const destination = [destination1, destination2, destination3, destination4];

// const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

const saopauloSlides = {
  banner,
  destination,
  // gallery
};

export default saopauloSlides;
