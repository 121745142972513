const HeaderLogo = '/assets/andimar/andimar-logo.svg';
const FooterLogo = '/assets/tepual/general/Footer-BusesTepualLogo.svg';
const ContactBg = '/assets/tepual/home/home-background.jpg';
const PopupIcon = '/assets/andimar/Andimar-PopUp.jpg';
// const OriginIcon = '/assets/tacoha/Home/origen.svg';
// const DestinationIcon =
// 	'/assets/tacoha/Home/destino.svg';
const BookingActive = '/assets/tepual/home/BuyTicketIcon-Active.svg';
const BookingInActive = '/assets/tepual/home/BuyTicketIcon-Inactive.svg';
const CancelActive = '/assets/tepual/home/CancelTicketIcon-Active.svg';
const CancelInActive = '/assets/tepual/home/CancelTicketIcon-Inactive.svg';
const OriginIcon = '/assets/tacoha/Home/Tacoha-Website-Home-SearchBar-OriginIcon-01.svg';
const ContactIcon = '/assets/andimar/contact-by/contact.svg';
const EmailIcon = '/assets/andimar/contact-by/email.svg';
const TelephoneIcon = '/assets/andimar/contact-by/telephone.svg';
const DestinationIcon = '/assets/tacoha/Home/Tacoha-Website-Home-SearchBar-DestinationIcon-01.svg';
const Calendar = '/assets/tacoha/Home/Tacoha-Website-Home-SearchBar-CalendarIcon-01.svg';
const SearchIcon = '/assets/andimar/navbar-searchIcon.svg';
const BlueArrow = '/assets/andimar/favoriteDestinations/andimar-blue-arrow.svg';
const ClockIcon = '/assets/andimar/favoriteDestinations/andimar-clock.svg';
const FleetBanner = '/assets/andimar/fleet/fleet-banner.jpg';
const FleetTv = '/assets/andimar/fleet/18.svg';
const FleetToilet = '/assets/andimar/fleet/19.svg';
const FleetAc = '/assets/andimar/fleet/20.svg';
const FleetSnacks = '/assets/andimar/fleet/21.svg';
const FleetBed = '/assets/andimar/fleet/22.svg';
const AmenitiesLeftImage = '/assets/andimar/fleet/24.jpg';
const BuyIconWhite = '/assets/andimar/buy-ticket.svg';
const BuyIconBlue = '/assets/andimar/buy-ticket-blue.svg';
const CancelIconWhite = '/assets/andimar/cancel-ticket.svg';
const CancelIconBlue = '/assets/andimar/cancel-ticket-blue.svg';
const TelephoneWhite = '/assets/andimar/contact-by/telephone-white.svg';
const EmailWhite = '/assets/andimar/contact-by/email-white.svg';
const BG = '/assets/tacoha/Home/Tacoha-Website-Home-Mobile.png';
const checkboxIcon = '/assets/andimar/checkbox.png';

const fb = '/assets/tacoha/Home/Tacoha-Website-FacebookIcon.svg';
const insta = '/assets/tacoha/Home/Tacoha-Website-InstagramIcon.svg';


const animation = '/assets/animation-screen/animation.gif';
const pnr = '/assets/tacoha/Home/Tacoha-Website-Home-WhereToFindMyPNR.jpg';
const close = '/assets/viatur/icons/close.svg';
const menuDot = '/assets/tacoha/Home/Tacoha-Website-MobileVersion-SideMenuIcon.svg';

const DestinationBanner = '/assets/sanantonio/Destinations/SanAntonio-Website-Destinations-Banner-Mobile.jpg';
const ContactBanner = '/assets/sanantonio/Contact/SanAntonio-Website-Contact-Banner-Mobile.jpg';
const TermsBanner = '/assets/sanantonio/TermsAndConditions/SanAntonio-Website-TermsAndConditions-Banner.jpg'
const OfficeBanner = '/assets/sanantonio/Offices/SanAntonio-Website-Offices-Banner-Mobile.jpg'
const agenciesBanner = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Banner-Desktop.png'


const Support = '/assets/tacoha/Home/Tacoha-Website-PhoneIcon.svg';
const ContactEmail = '/assets/tacoha/Home/Tacoha-Website-EmailIcon.svg';
const ContactLocation = '/assets/tacoha/Home/Tacoha-Website-LocationIcon.svg';
const TelephoneIconFooter = '/assets/tacoha/Home/Liquiñe-Website-PhoneIcon.png';
const EmailIconFooter = '/assets/sanantonio/Home/SanAntonio-Website-Home-Footer-Email.svg';

const Office1 = '/assets/sanantonio/Offices/SanAntonio-Website-Offices-Arica.png';
const Office2 = '/assets/sanantonio/Offices/SanAntonio-Website-Offices-Iquique.png';


// const success = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
// const failure = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Information-Orange.svg';

// const tick = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Tick-Orange.svg';
// const heartOn = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-HeartOn-Orange.svg';
// const heartOff = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-HeartOff-Orange.svg';
// const downArrow = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-DropDown-Orange.svg';
// const registrationSuccess = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RightDocument-Orange.svg';
// const userIcon = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-Login-Orange.svg';
const trips = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const cards = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
// const wallet = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const transaction = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const otp = '/assets/sanantonio/Orange/MyAccount-IllustratedIcons-RestorePassword-Orange.svg';


// accounts section

// new
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';
const tarjetas = '/assets/blue/IllustratedIcons-MyCards-Blue.png';
const viajes = '/assets/blue/IllustratedIcons-MyTrips-Blue.png';
const movimientos = '/assets/blue/IllustratedIcons-MyMovements-Blue.png';
const wallet = '/assets/blue/MyAccount-IllustratedIcons-RechargeWallet-Blue.png';
const shareMoney = '/assets/blue/MyAccount-IllustratedIcons-ShareMoney-Blue.png';
const camera = '/assets/blue/IllustratedIcons-Camera-Blue.png';
const checkmark = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/blue/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/blue/IllustratedIcons-DropDown-Blue.png';
const loginblue = '/assets/blue/IllustratedIcons-Login-Blue-c.png';
const recoverpswd = '/assets/blue/IllustratedIcons-RecoverPassword-Blue.png';
const success = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const failure = '/assets/blue/IllustratedIcons-Information-Blue.png';
const warning = '/assets/blue/IllustratedIcons-Information-Blue.png';
const userIcon = '/assets/blue/IllustratedIcons-user.png';



const heartOn = '/assets/blue/IllustratedIcons-HeartOn-Blue.svg';
const heartOff = '/assets/blue/IllustratedIcons-HeartOff-Blue.svg';
const registrationSuccess = '/assets/blue/IllustratedIcons-RightDocument-Blue.png';

const CCA = '/assets/tacoha/searchbar/changetick.svg';
const CCIA = '/assets/tacoha/searchbar/changetick.svg';
const BuyTick = '/assets/tacoha/searchbar/buytick.svg';
const Cuponera = '/assets/tacoha/searchbar/cuponera.svg';

const operatorPay = '/assets/tacoha/tacohapay.png'

const cuponeraIcon = '/assets/blue/IllustratedIcons-MyCards-Blue.png';

const tacohaIcons = [


	{ icon: cuponeraIcon, name: 'cuponeraIcon' },
	{ icon: CCA, name: 'CTA' },
	{ icon: CCIA, name: 'CTIA' },

	{ icon: BuyTick, name: 'CCA' },
	{ icon: BuyTick, name: 'CCIA' },
	{ icon: Cuponera, name: 'CUA' },
	{ icon: Cuponera, name: 'CUIA' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: trips, name: 'trips' },
	{ icon: cards, name: 'cards' },
	{ icon: wallet, name: 'wallet' },
	{ icon: transaction, name: 'transaction' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: tick, name: 'tick' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: otp, name: 'otp' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: ContactBg, name: 'ContactBg' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: Calendar, name: 'Calendar' },
	{ icon: SearchIcon, name: 'Search' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: DestinationBanner, name: 'DestinationBanner' },
	{ icon: ContactIcon, name: 'ContactIcon' },
	{ icon: TelephoneIcon, name: 'TelephoneIcon' },
	{ icon: TelephoneIcon, name: 'TelephoneIcon' },
	{ icon: BlueArrow, name: 'BlueArrow' },
	{ icon: ClockIcon, name: 'ClockIcon' },
	{ icon: FleetBanner, name: 'FleetBanner' },
	{ icon: FleetTv, name: 'FleetTv' },
	{ icon: FleetToilet, name: 'FleetToilet' },
	{ icon: FleetAc, name: 'FleetAc' },
	{ icon: FleetSnacks, name: 'FleetSnacks' },
	{ icon: FleetBed, name: 'FleetBed' },
	{ icon: AmenitiesLeftImage, name: 'AmenitiesLeftImage' },
	{ icon: EmailIcon, name: 'EmailIcon' },
	{ icon: BuyIconWhite, name: 'BuyIconWhite' },
	{ icon: BuyIconBlue, name: 'BuyIconBlue' },
	{ icon: CancelIconWhite, name: 'CancelIconWhite' },
	{ icon: CancelIconBlue, name: 'CancelIconBlue' },
	{ icon: TelephoneIconFooter, name: 'TelephoneWhite' },
	// { icon: EmailIconFooter, name: 'EmailWhite' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BG, name: 'BG' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },
	{ icon: menuDot, name: 'menuDot' },
	{ icon: DestinationBanner, name: 'DestinationBanner' },
	{ icon: ContactBanner, name: 'ContactBanner' },
	{ icon: Support, name: 'Support' },
	{ icon: ContactEmail, name: 'ContactEmail' },
	{ icon: ContactLocation, name: 'ContactLocation' },
	{ icon: TermsBanner, name: 'TermsBanner' },
	{ icon: OfficeBanner, name: 'OfficeBanner' },
	{ icon: Office1, name: 'Office1' },
	{ icon: Office2, name: 'Office2' },

	{ icon: fb, name: 'fb' },
	{ icon: insta, name: 'insta' },
	{ icon: agenciesBanner, name: 'agenciesBanner' },


	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
	{ icon: operatorPay, name: 'operatorPay' },


];

export default tacohaIcons;
