import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { commonStyles, colors, dimensions } from "../../theme";
import {
  PBCard,
  PBText,
  PBInput,
  PButton,
  Loader,
  PBModal,
  PBTabs,
  AccountInfoCard,
  PBCarpoolBG,
} from "../../components";
// import { REGISTER_SCREEN, LOGIN_SCREEN,  RECHARGE_MAP_SCREEN, RECHARGE_STEPS_SCREEN } from '../../navigation/Screens';
import { CommonService, AppData } from "../../services";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

var google;

class RechargeMapScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: {},
      showModal: false,
      mapCenter: {
        lat: -33.4724728,
        lng: -70.9100195,
      },
      myLocation: {
        lat: -33.4724728,
        lng: -70.9100195,
      },
    };
  }

  componentDidMount() {
    this.getLocation();
    // this.renderAutoComplete();
  }
  componentDidUpdate(prevProps) {
    // if (this.props !== prevProps.map) this.renderAutoComplete();
  }

  getLocation = () => {
    if (navigator.geolocation) {
      var options = {
        enableHighAccuracy: true,
        maximumAge: 100,
        timeout: 60000,
      };
      var watchID = navigator.geolocation.watchPosition(
        this.showLocation,
        this.errorHandler,
        options
      );
      var timeout = setTimeout(function () {
        navigator.geolocation.clearWatch(watchID);
      }, 5000);
    } else {
      console.log("Sorry, browser does not support geolocation!");
    }
  };

  getRechargeLocations = (lat, lng) => {
    let path = "latitude=" + lat + "&longitude=" + lng;
    this.props.getRechargeLocations({
      callback: (result) => this.onRechargeLocationsLoad(result),
      path: path,
    });
  };

  onRechargeLocationsLoad = (result) => {
    if (result.success && result.data) {
      let res = result.data;
      if (res && res.length) {
        this.setState({ locations: res });
      } else {
        this.setState({ showModal: true });
      }
    }
  };

  showLocation = (position) => {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    let loc = {
      lat: latitude,
      lng: longitude,
    };
    this.setState({ myLocation: loc, mapCenter: loc });
    console.log("Latitude : " + latitude + " Longitude: " + longitude);
    this.getRechargeLocations(latitude, longitude);
  };

  errorHandler(err) {
    if (err.code == 1) {
      console.log("Error: Access is denied!");
    } else if (err.code == 2) {
      console.log("Error: Position is unavailable!");
    }
  }

  fetchPlaces(mapProps, map) {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    console.log(service);
    // ...
  }

  renderAutoComplete = (mapProps, map) => {
    const { google } = mapProps;

    if (!google || !map) return;

    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);
    autocomplete.bindTo("bounds", map);
    console.log("hshshsjk---------------------------------", autocomplete);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      // if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
      // else {
      map.setCenter(place.geometry.location);
      map.setZoom(15);
      // }

      console.log(
        "place.geometry.location.lat()",
        place.geometry.location.lat()
      );
      this.setState({
        myLocation: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
      this.getRechargeLocations(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    });
  };

  renderInput = () => {
    return (
      <div className="autocomplete-places kupos-border font14">
        <img src="/assets/icons/icon_search-white.png" />
        <input
          placeholder="Ingresa la ubicación que buscas."
          ref={(ref) => (this.autocomplete = ref)}
          type="text"
        />
      </div>
    );
  };

  onMarkerClick = (props, marker, e, selectedMarekrData) => {
    this.setState({
      selectedPlace: selectedMarekrData,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="my-account-header transparent-bg">
            <div
              className="header-back-button"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="qr-back-arrow"
                src="/assets/carpool/icon_back_arrow.png"
              />
            </div>

            <div className="font19 flex-center display-flex dark-font">
              <span>Agentes de</span>&nbsp;
              <span className="bold-text ">recarga</span>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="common-kupos-map">
            <Map
              google={this.props.google}
              zoom={14}
              initialCenter={this.state.mapCenter}
              center={this.state.mapCenter}
              onReady={this.renderAutoComplete}
              onClick={this.onMapClicked}
            >
              <Marker
                position={this.state.myLocation}
                icon={{
                  url: "/assets/svgs/my-location.svg",
                  anchor: new this.props.google.maps.Point(32, 32),
                  scaledSize: new this.props.google.maps.Size(30, 30),
                }}
              />

              {this.state.locations &&
                this.state.locations.map((val, i) => (
                  <Marker
                    key={i}
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{ lat: val.latitude, lng: val.longitude }}
                    icon={{
                      // url: '/assets/svgs/destination.svg',
                      url: val.marker_icon,
                      anchor: new this.props.google.maps.Point(32, 32),
                      scaledSize: new this.props.google.maps.Size(30, 30),
                    }}
                  />
                ))}

              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
              >
                {this.state.selectedPlace ? (
                  <div className="info-window font14">
                    <div className="marker-icon-name primary-font black-text">
                      <span className="marker-icon">
                        <img src={this.state.selectedPlace.marker_icon} />
                      </span>
                      <span className="marker-name">
                        {this.state.selectedPlace.bank
                          ? this.state.selectedPlace.bank.name
                          : ""}
                      </span>
                    </div>
                    <div className="marker-service">
                      <span className="bold-text">Servicios:</span>&nbsp;
                      <span>Recargas</span>
                    </div>
                    <div className="marker-service">
                      <span className="bold-text">Dirección:</span>&nbsp;
                      <span>
                        {this.state.selectedPlace.bank
                          ? this.state.selectedPlace.bank.address
                          : ""}
                      </span>
                    </div>
                    <hr />
                    <div className="marker-service">
                      <span className="bold-text">PasajeBus.com</span>
                    </div>
                    <div className="marker-service">
                      <span className="bold-text">Código de comercio</span>
                    </div>
                    <a
                      href={`https://www.google.com/maps/dir/${this.state.myLocation.lat},${this.state.myLocation.lng}/${this.state.selectedPlace.latitude},${this.state.selectedPlace.longitude}`}
                      target="_blank"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="kupos-button"
                      >
                        ¿Cómo llegar?
                      </div>
                    </a>
                  </div>
                ) : (
                  <div></div>
                )}
              </InfoWindow>
            </Map>
            {this.renderInput()}
          </div>

          <PBModal
            showModal={this.state.showModal}
            titleText={"¡Lo sentimos!"}
            bodyText={
              "No hay puntos de recarga cercanos. Por favor intenta con otra ubicación."
            }
            type="alert"
            showButton1={true}
            button1Text={"OK"}
            button1Press={() => this.setState({ showModal: false })}
            icon="/assets/icons/circular/icon-failure.png"
            onBackButtonPress={this.goBack}
          ></PBModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default connectData()(
  GoogleApiWrapper({
    apiKey: AppData.MAP_API_KEY,
  })(RechargeMapScreen)
);
