import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { CommonService } from "../../services";
import {
  PBCard,
  PBText,
  BGCarpoolInnerBottom,
  PButton,
} from "../../components";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import getIcons from "../../themes/functions/getIcons/getIcons";

class RegistrationSuccessScreen extends PureComponent {
  constructor(props) {
    super(props);
    // CommonService.bindNav(this);
  }

  myTrips = () => {};

  contact = () => {
    if (this.props.match.params.type == "success") {
      sessionStorage.clear();
      if (CommonService.isLoggedIn(this.props.data.loginData) || CommonService.loginType == "phone") {
        if(CommonService.idThirdPartyApp()) 
          this.props.history.push("/es/pasajes-bus") 
        else 
          this.props.history.push("/my-account");
      } else {
        if(CommonService.idThirdPartyApp()) 
          this.props.history.push("/es/pasajes-bus") 
        else 
          this.props.history.push("/");
      }
    } else {
      this.props.showHideContactModal(true);
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          {/* <div className="relative dark-font"> */}
          <BGCarpoolInnerBottom zIndex={"zIndex:1"} />
          <div className="cancel-success-screen">
            <div
              className="cancel-success-main-image"
              style={{ marginTop: 30 }}
            >
              <img
                height={100}
                src={
                  this.props.match.params.type == "success"?
                    // ? "/assets/carpool/registration-done-circle.png"
                    // : "/assets/icons/circular/icon-failure.png"
                    getIcons('registrationSuccess'):
                    getIcons('failure')
                }
                alt=''
              />
            </div>
            <div className="cancel-success-title dark-font bold-text font19">
              {this.props.match.params.type == "success"
                ? "¡Tu registro está listo!"
                : "¡Lo sentimos!"}
            </div>
            <div className="wallet-success-container">
              {this.props.match.params.type == "success" ? (
                CommonService.isLoggedIn(this.props.data.loginData) || CommonService.loginType == "phone" ? (
                  <div className="cancel-success-text-container font14 text-center">
                    Has finalizado el registro de tu cuenta. Anímate y comienza a ganar puntos reservando tu primer viaje.
                    
                    <br />
                    <br />
                    ¡Tu próximo viaje te está esperando!
                  </div>
                ) : (
                  <div className="cancel-success-text-container font14 text-center">
                    Has finalizado tu registro.
                    <br />
                    Por favor verifica tu cuenta en el enlace que enviamos a tu correo electrónico.
                  </div>
                )
              ) : (
                <div className="cancel-success-text-container font14 text-center">
                  Algo salio mal y tu pasaje no pudo ser anulado.
                  <br />
                  <br />
                  Si estás teniendo problemas para cancelar tu pasaje,
                  contáctanos por nuestros canales de atención.
                </div>
              )}
            </div>

            <div className="cancellation-button-container">
              {!CommonService.idThirdPartyApp() ? (
                <PButton
                  title={
                    this.props.match.params.type == "success"
                      ? "VER PERFIL"
                      : "CONTÁCTANOS"
                  }
                  onPress={this.contact}
                />
              ) : null}
              <PButton
                className="white-button"
                style={{
                  borderRadius: 10,
                  color: "#FC8332",
                  backgroundColor: "#fff",
                }}
                title="IR AL INICIO"
                onPress={() => CommonService.idThirdPartyApp() ? this.props.history.push("/es/pasajes-bus") : this.props.history.push("/")}
              />
            </div>
          </div>
          {/* </div> */}
        </IonContent>
      </IonPage>
    );
  }
}

export default connectData()(RegistrationSuccessScreen);
