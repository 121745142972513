import './PullmanSanAndreasContact.css';

import { IonContent, IonPage } from '@ionic/react';

import { AppData } from '../../services';
import Footer6 from '../common/footer/Footer6';
import GoogleMapReact from 'google-map-react';
import Header6 from '../common/header/Header6';
import PullmanBanners from './components/PullmanBanners';
import React from 'react';
import ThemeSixOfficeSection from './components/ThemeSixOfficeSection';
import { connectData } from '../../redux';
import getIcons from '../functions/getIcons/getIcons';
import ThemeSixHeader from './components/ThemeSixHeader';

const AnyReactComponent = ({ img }) => (
  <div>
    <img
      style={{
        display: 'inline-block',
        width: '20px',
      }}
      src={img}
      alt=''
    />
  </div>
);

function PullmanSanAndreasContact(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <ThemeSixHeader history={props.history} operator={operator} />

        <div className='pullman-san-andreas-contact'>
          <PullmanBanners
            bgImage={getIcons('contactBanner')}
            text={'Hablemos'}
            textPosition={'center'}
          />
          <div className='main-heading'>
            <h3
              className='bold-text text-center font-22px padding-x-30px margin-0 font-italic'
              style={{
                lineHeight: 1.1,
                color: operator && operator.theme_color && operator.theme_color,
              }}
            >
              La unidad y compromiso de nuestra gente, es nuestra fortaleza
            </h3>
            <p className='bottom-para text-center font18 font-italic margin-0'>
              sé parte de <span className='bold-text'>Pullman San Andrés</span>
            </p>
          </div>
          <div className='new-container'>
            <div className='pullman-san-andreas-contact-content'>
              <div className='pullman-san-andreas-contact-content-left'>
                <h3 className='sub-main-heading bold-text font-italic'>
                  Información de contacto
                </h3>
                <div
                  className='margin-b-30px font-weight-semi-bold'
                  style={{
                    lineHeight: 1.42857143,
                  }}
                >
                  <span>Av. Las Parcelas #1234, Alto Hospicio.</span>
                  <br />
                  <span>Región de Tarapacá.</span>
                </div>

                <div
                  className='margin-b-30px font-weight-semi-bold'
                  style={{
                    lineHeight: 1.42857143,
                  }}
                >
                  <span>Callcenter: +56 57 2</span>
                  <br />
                  <span>WhatsApp: +56 9</span>
                </div>
                <div
                  className='margin-b-30px font-weight-semi-bold'
                  style={{
                    lineHeight: 1.42857143,
                  }}
                >
                  <span>info@pullmansanandres.cl</span>
                </div>

                <div
                  className='margin-b-30px font-weight-semi-bold'
                  style={{
                    lineHeight: 1.42857143,
                  }}
                >
                  <span>Redes sociales</span>
                </div>

                <div
                  className='margin-b-30px font-weight-semi-bold'
                  style={{
                    lineHeight: 1.42857143,
                  }}
                >
                  <span
                    onClick={() => {
                      window.open(
                        'https://www.facebook.com/pullmansanandres.cl'
                      );
                    }}
                  >
                    @PullmanSanAndresCL
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      window.open(
                        'https://www.instagram.com/pullmansanandres.cl/'
                      );
                    }}
                  >
                    @PullmanSanAndresCL
                  </span>
                </div>
                <h3 className='sub-main-heading bold-text font-italic'>
                  Ubicación
                </h3>
                <div
                  style={{
                    width: '100%',
                    height: '300px',
                  }}
                >
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1871.3240217806574!2d-70.0933396!3d-20.2734264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915215192cfe93cd%3A0x2a4432f03e68651!2sLas%20Parcelas%201234%2C%20Alto%20Hospicio%2C%20Tarapac%C3%A1%2C%20Chile!5e0!3m2!1sen!2sin!4v1648977603842!5m2!1sen!2sin'
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 0,
                    }}
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                  ></iframe>
                  {/* <GoogleMapReact
										bootstrapURLKeys={{
											key: AppData.MAP_API_KEY,
										}}
										center={{
											lat: parseFloat(
												-20.273064089035827
											),
											lng: parseFloat(
												-70.09177319007587
											),
										}}
										defaultZoom={18}
									>
										<AnyReactComponent
											lat={parseFloat(
												-20.273064089035827
											)}
											lng={parseFloat(
												-70.09177319007587
											)}
											img="/assets/bahia/General/pin.png"
										/>
									</GoogleMapReact> */}
                </div>
              </div>

              <div className='pullman-san-andreas-contact-content-right'>
                <h3 className='sub-main-heading bold-text font-italic'>
                  Formulario de contacto
                </h3>
                <form>
                  <div className='form-group'>
                    <label htmlFor=''>Motivo del mensaje:</label>
                    <select type='text'>
                      <option value>---Seleccione motivo---</option>
                      <option value='1'>Cotización</option>
                      <option value='2'>Información</option>
                      <option value='3'>Reclamo y/o denuncia</option>
                      <option value='3'>Sugerencia</option>
                      <option value='3'>Felicitaciones</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>RUT:</label>
                    <input type='text' placeholder='Ingrese su rut' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>Nombre completo:</label>
                    <input type='text' placeholder='Nombres y apellidos' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>Teléfono / WhatsApp:</label>
                    <input type='text' placeholder='+56 9' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>Email:</label>
                    <input
                      type='text'
                      placeholder='ejemplo@pullmansanandres.cl'
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>Ciudad de residencia:</label>
                    <input type='text' placeholder='Ciudad/Comuna' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor=''>Mensaje:</label>
                    <textarea type='text' rows='8' />
                  </div>
                  <div className='form-group d-flex flex-row-reverse'>
                    <button
                      className='  px-4 py-2 font-14px color-white font18'
                      style={{
                        backgroundColor:
                          operator &&
                          operator.color_codes &&
                          operator.color_codes.primary_color
                            ? operator.color_codes.primary_color
                            : '',
                        border: 'none',
                        alignSelf: 'flex-end',
                        padding: '10px 20px',
                      }}
                    >
                      ENVIAR
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <h3 className='sub-main-heading bold-text font-italic text-center'>
              Sucursales
            </h3>
            <ThemeSixOfficeSection />
          </div>
        </div>
        <Footer6 />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(PullmanSanAndreasContact);
