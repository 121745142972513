// import { IonContent, IonPage } from "@ionic/react";
// import React from "react";
// import { connectData } from "../../redux";
// import KuposHeader from "../../components/KuposHeader";
// import ThemeTwoQuiénesSomos from "./common/ThemeTwoQuiénesSomos";

// const ThemeTwoGalleryScreen = ({ props }) => {
//   const operator = JSON.parse(localStorage.getItem("op")) || props.data.operator;
//   return (
//     <IonPage>
//       <IonContent>
//         <KuposHeader
//           title={"       "}
//           boldTitle={"Quiénes somos"}
//           handleHomeSubmit={() => props.history.push("/")}
//           handleBackSubmit={() => props.history.goBack()}
//         />
//         <div className="new-container">
//           <ThemeTwoQuiénesSomos operatorName={operator.name} />
//         </div>
//       </IonContent>
//     </IonPage>
//   );
// };
// export default connectData()(ThemeTwoGalleryScreen);

import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { withRouter } from "react-router-dom";  // Import withRouter from react-router-dom
import { connectData } from "../../redux";
import KuposHeader from "../../components/KuposHeader";
import ThemeTwoQuienesSomos from "./common/ThemeTwoQuienesSomos";

const ThemeTwoGalleryScreen = ({ data, history }) => {  // Destructure data and history from props
  const operator = JSON.parse(localStorage.getItem("op")) || data.operator;

  return (
    <IonPage>
      <IonContent>
        <KuposHeader
          title={"       "}
          boldTitle={"Quiénes somos"}
          handleHomeSubmit={() => history.push("/")}  // Use history.push
          handleBackSubmit={() => history.goBack()}  // Use history.goBack
        />
        <div className="new-container">
          <ThemeTwoQuienesSomos operatorName={operator.name} />
        </div>
      </IonContent>
    </IonPage>
  );
};

// Wrap the component with withRouter to get access to the history object
export default connectData()(withRouter(ThemeTwoGalleryScreen));

