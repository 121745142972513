// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  View,
  PBTabs,
} from "../../components";
import { commonStyles, dimensions, colors } from "../../theme";
import { CommonService } from "../../services";
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { Link } from "react-router-dom";
import getIcons from "../../themes/functions/getIcons/getIcons";
// import { NEW_REGISTER_SCREEN } from '../../../navigation';

class NewForgotPasswordScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      emailError: null,
      selectedTab: 0,
      mobile: null,
      mobileError: null,
      countryCode: "+56",
    };
				console.log("??????", this.props)
  }

  componentDidMount() {
    this.setState({
      email: null,
      emailError: null,
      selectedTab: 0,
      mobile: null,
      mobileError: null,
      countryCode: "+56",
    });
  }

  emailChange = (text) => {
    this.setState({
      emailError: null,
      email: text,
    });
  };

  mobileChange = (text) => {
    // console.log("Mobile Number Received", this.state.mobile)
    if (text) {
      text = CommonService.phoneNumberFormat(text);
    }
    this.setState({
      mobileError: null,
      mobile: text,
    });
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "email") {
        this.setState({
          emailError: {
            message: "Ingresa tu correo electrónico",
          },
        });
      }
    } else if (inp == "email") {
      if (!CommonService.isEMailValid(text)) {
        this.setState({
          emailError: {
            message: "Ingresa un correo electrónico válido",
          },
        });
      }
    }
  };

  onSubmit = () => {
    if (this.state.selectedTab === 1) {
      let errorCount = 0;
      if (!this.state.email) {
        this.setState({
          emailError: {
            message: "Ingresa tu correo electrónico",
          },
        });
        errorCount++;
      } else if (!CommonService.isEMailValid(this.state.email)) {
        this.setState({
          emailError: {
            message: "Ingresa un correo electrónico válido",
          },
        });
        errorCount++;
      }
      if (errorCount > 0) {
        return;
      }
      let data = { user: { email: this.state.email }, is_kupos: true };
      if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
        data['operator_id'] = CommonService.getOperatorId(this.props.data.operator)
      }
      this.props.forgotPassword({
        callback: (result) => this.onResponse(result),
        data: data,
      });
    } else {
      let errorCount = 0;
      if (!this.state.mobile) {
        this.setState({
          mobileError: {
            message: "Ingresa tu número de teléfono",
          },
        });
        errorCount++;
      } else if (this.state.mobile.toString().length != 9) {
        this.setState({
          mobileError: {
            message: "El teléfono debe contener mínimo 9 dígitos",
          },
        });
        errorCount++;
      }
      if (errorCount > 0) {
        return;
      }

      this.props.generateOtp({
        data: {
          country_code: this.state.countryCode,
          mobile_number: this.state.mobile + "",
          cat_type: "change_pwd_valid",
										operator_id: this.props.data.operator.is_pay_enabled === true ? this.props.data.operator.operator_id : null
        },
        callback: (res) => {
          console.log(res);
          if (res.success && res.data && res.data.status === 200) {
            let data = {
              mobile: this.state.mobile,
              country: this.state.countryCode,
            };
            this.props.registerSetUserPhoneDataForOtp(data);
            //goto otp screen
            this.props.history.push("/reset-password-otp");
          } else {
            // this.setState({
            //   showSuccessFailureModal: true,
            //   showSuccessFailureModalStatus: false,
            //   showSuccessFailureModalBodyText: res.data.message,
            // });
            this.setState({
              mobileError: {
                message: res.data.message,
              },
            });
          }
        },
      });
    }
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      // this.refs.toast.show("El correo electrónico no está registrado");
      // this.setState({
      //   showModal: true,
      //   modalTitle: "¡Lo Sentimos!",
      //   modalBody: "El correo electrónico no está registrado",
      //   modalIcon: require("../../../assets/carpool/booking-failure-circle.png"),
      //   showRegisterLink: true,
      // });
      this.setState({
        emailError: {
          message: "El correo electrónico no está registrado",
        },
      });
      return;
    } else if (result.data.message) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        modalBody:
          "Te hemos enviado un correo a tu dirección registrada con las instrucciones para recuperar tu contraseña.",
        // modalBody:result.data.message,
        // modalIcon: "/assets/carpool/booking-success-circle.png",
        modalIcon: getIcons("success"),

        showRegisterLink: false,
      });
    }
  };

  register = () => {
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    // CommonService.goToScreen(this.props.componentId,NEW_REGISTER_SCREEN,'Regístrate');
  };

  goBack = () => {
    let modalTitle = this.state.modalTitle;
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    if (modalTitle != "¡Lo Sentimos!") this.props.history.push("/login");
    // CommonService.goBack(this.props.componentId);
  };

  render() {
    const selectedTxn = this.props.selectedTxn;
    return (
      <IonPage>
        <IonContent>
          <div className="">
            <div className="white-model-top-strip">
              {/* <Link to={"/login"}> */}
              <img
                className="header-back-arrow"
                src="../../assets/carpool/icon_back_arrow.png"
                onClick={() => this.props.history.goBack()}
                alt=""
              />
              {/* </Link> */}
            </div>
            <div className="full-flex">
              <div className="login-main-icon-container">
                <img
                  style={styles.cityBoxIcon}
                  // src="../../assets/carpool/forgot-circle.png"
                  src={getIcons("recoverpswd")}
                  alt="='"
                />
              </div>

              <div className="login-title">
                <PBText
                  fontSize={"font15"}
                  fontStyle={"bold-font"}
                  fontColor={"dark-font"}
                >
                  Recupera tu contraseña
                </PBText>
              </div>

              <div
                className="login-title"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <PBText fontSize={"font14"}>
                  Ingresa tu correo electrónico y recibirás las instrucciones
                  para cambiar tu contraseña.
                </PBText>
              </div>
              <View className="forgot-tabs-container">
                <PBTabs
                  height={30}
                  tabsArray={["Teléfono", "Correo electrónico"]}
                  onTabSelect={(i) =>
                    this.setState({
                      selectedTab: i,
                      emailError: null,
                      mobileError: null,
                      mobile: null,
                      email: null,
                    })
                  }
                  activeTabColor={colors.secondaryBG}
                />
              </View>
              <div className="kupos-card forget-password-card-container">
                {this.state.selectedTab === 1 ? (
                  <div>
                    <PBInput
                      type="email-address"
                      placeholder="Ingresa tu correo electrónico registrado"
                      value={this.state.email}
                      onChange={(text) => this.emailChange(text)}
                      onBlur={(text) => this.onBlur(text, "email")}
                      error={this.state.emailError ? true : false}
                      errorMessage={
                        this.state.emailError
                          ? this.state.emailError.message
                          : ""
                      }
                      // containerStyle={commonStyles.carpoolInput}
                      textStyle={styles.fontSize}
                    ></PBInput>
                  </div>
                ) : (
                  <View style={{ width: "100%", marginTop: 5 }}>
                    <View className="flex-row phone-inp-with-flag">
                      <View className="chile-inp-flag forgot-flag-container">
                        <img src={"/assets/carpool/img/chile-flag.png"} />
                      </View>
                      <View style={styles.flex}>
                        <PBInput
                          type={"tel"}
                          placeholder="Ingresa tu número telefónico"
                          value={this.state.mobile}
                          onTextChange={(text) => this.mobileChange(text)}
                          onBlur={(text) => this.onBlur(text, "mobile")}
                          style={{ borderWidth: 0, height: 36 }}
                          textStyle={{ fontSize: 14 }}
                        ></PBInput>
                      </View>
                    </View>
                    {this.state.mobileError ? (
                      <View>
                        <PBText className="error-text">
                          {this.state.mobileError.message}
                        </PBText>
                      </View>
                    ) : null}
                  </View>
                )}
                <div className="login-button-container">
                  <PButton
                    style={{ marginTop: 15 }}
                    title={"ENVIAR"}
                    onPress={this.onSubmit}
                    capitalize={true}
                  />
                </div>
              </div>
            </div>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={
                !this.state.showRegisterLink ? this.goBack : this.register
              }
              icon={this.state.modalIcon}
              buttonTextStyle={{ fontSize: 18 }}
            >
              {/* {this.state.showRegisterLink && <div style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </div>} */}
            </PBModal>
            {/* <Toast ref="toast"/> */}
          </div>
          <IonRefresher
            slot="fixed"
            onIonRefresh={() => window.location.reload()}
          >
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 14,
  },
  buttonContainer: {
    marginTop: 10,
  },
};

export default connectData()(NewForgotPasswordScreen);
