const BG =
	'/assets/santajuana/Home/Mweb-home-mage.webp';

const PhoneIcon =
	'/assets/santajuana/General/icon-whatsapp.png';

const BookingActive =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-SearchBar-CancelTicket.svg';

const OriginIcon =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-SearchBar-OriginIcon.svg';
const DestinationIcon =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-SearchBar-DestinationIcon.svg';
const CalendarIcon =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-SearchBar-CalendarIcon.svg';
const pnr =
	'/assets/santajuana/General/pnr.svg';

const ContactBg =
	'/assets/santajuana/Home/BusesSaoPaulo-Website-Home-Background-ContactSection.jpg';

// footerIcons

const fb =
	'/assets/santajuana/General/BusesSaoPaulo-Website-Footer-SocialMedia-Facebook.svg';
const insta =
	'/assets/santajuana/General/BusesSaoPaulo-Website-Footer-SocialMedia-Instagram.svg';

const contactFb =
	'/assets/santajuana/Contact/facebook-icon.svg';
const contactInsta =
	'/assets/santajuana/Contact/insta-icon.svg';
// -------------

// banners
const destinationBanner =
	'/assets/santajuana/Destinations/Mweb-trabaja-con-nosotros-image.png';
const contactUsBanner =
	'/assets/santajuana/Contact/TransportesSantaJuana-Website-Contact-Banner.png';
const aboutUsBanner =
	'/assets/santajuana/about-us/mweb-la-empresa.webp';
const tncBanner =
	'/assets/santajuana/tnc/BusesSaoPaulo-Website-TermsAndConditions-BannerMobile.jpg';
const officeBanner =
	'/assets/santajuana/Offices/BusesSantajuana-Website-Offices-BannerMobile.png';

const aboutUsImage =
	'/assets/santajuana/about-us/conocer-acerca-de-nosotros.webp';

const contactMail =
	'/assets/santajuana/Contact/Contacto-icon.svg';
const contactHeadphone =
	'/assets/santajuana/Contact/Correo-icon.svg';

const pin =
	'/assets/santajuana/Offices/pin ubicacion.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';

// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santajuana/santajaunapay.jpeg'


const santajuanaIcons = [
	{ icon: BG, name: 'BG' },

	//   { icon: PopupIcon, name: 'Popup' },
	{ icon: PhoneIcon, name: 'phone' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: pnr, name: 'pnr' },

	// Title icons
	// { icon: titleLeftIcon, name: 'titleLeftIcon' },
	// { icon: titleRightIcon, name: 'titleRightIcon' },

	{ icon: ContactBg, name: 'ContactBg' },

	// footerIcons
	{ icon: fb, name: 'fb' },
	{ icon: insta, name: 'insta' },

	{ icon: contactFb, name: 'contactFb' },
	{ icon: contactInsta, name: 'contactInsta' },

	{ icon: destinationBanner, name: 'destinationBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: tncBanner, name: 'tncBanner' },
	{ icon: officeBanner, name: 'officeBanner' },

	{ icon: contactMail, name: 'contactMail' },
	{ icon: contactHeadphone, name: 'contactHeadphone' },

	{ icon: aboutUsImage, name: 'aboutUsImage' },

	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: pin, name: 'pin' },


	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },

];

export default santajuanaIcons;
