const BG = "/assets/jm/BusesJM-BannerMobile.jpg";
const headphone = "/assets/jm/BusesJM-WebDesign-Header-ContactButtom.svg";

const BookingInActive =
  "/assets/jm/BusesJM-WebDesign-SearchBar-BuyTicket-Off.svg";
const BookingActive = "/assets/jm/BusesJM-WebDesign-SearchBar-BuyTicket-On.svg";
const CancelInActive =
  "/assets/jm/BusesJM-WebDesign-SearchBar-CancelTicket-Off.svg";
const CancelActive =
  "/assets/jm/BusesJM-WebDesign-SearchBar-CancelTicket-On.svg";

const OriginIcon = "/assets/jm/BusesJM-WebDesign-SearchBar-Origin.svg";
const DestinationIcon =
  "/assets/jm/BusesJM-WebDesign-SearchBar-Destination.svg";
const CalendarIcon = "/assets/jm/BusesJM-WebDesign-SearchBar-Calendar.svg";

const animation =
  "/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif";
const pnr = "/assets/jm/BusesJM-Website-Home-WhereToFindMyPNR.svg";

const close = "/assets/jm/BusesJM-WebDesign-SearchBar-XMark.svg";
const contactUsBanner = "/assets/jm/BusesJM-WebDesign-ContactBanner.jpg";
const menuDot = "/assets/jm/BusesJM-Website-MobileVersion-SideMenuIcon.svg";

const checkboxIcon = "/assets/tepual/checkbox.png";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

const tarjetas = "/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
const viajes = "/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const movimientos = "../../../assets/carpool/pigi-bank-circle.png";
const wallet =
  "/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg";
const shareMoney =
  "/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
const success =
  "/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg";
const failure =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const warning =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "../assets/carpool/user-circle.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";
const cuponeraIcon = "/assets/carpool/cuponera.svg";

const operatorPay = "/assets/jm/JMPay.svg";

const busesjmIcons = [
  { icon: BG, name: "BG" },
  { icon: menuDot, name: "menuDot" },

  { icon: headphone, name: "headphone" },

  { icon: contactUsBanner, name: "contactUsBanner" },
  // { icon: HeaderLogo, name: 'headerLogo' },
  // { icon: FooterLogo, name: 'footerLogo' },

  { icon: OriginIcon, name: "Origin" },
  { icon: DestinationIcon, name: "Destination" },
  { icon: CalendarIcon, name: "Calendar" },

  { icon: BookingInActive, name: "BIA" },
  { icon: BookingActive, name: "BA" },
  { icon: CancelInActive, name: "CIA" },
  { icon: CancelActive, name: "CA" },

  // { icon: PopupIcon, name: 'Popup' },

  { icon: animation, name: "animation" },
  { icon: checkboxIcon, name: "Checkbox" },

  { icon: pnr, name: "pnr" },
  { icon: close, name: "close" },

  // accounts section
  { icon: pay, name: "pay" },
  { icon: backimage, name: "backimage" },
  { icon: tarjetas, name: "tarjetas" },
  { icon: viajes, name: "viajes" },
  { icon: movimientos, name: "movimientos" },
  { icon: wallet, name: "wallet" },
  { icon: shareMoney, name: "shareMoney" },
  { icon: camera, name: "camera" },
  { icon: checkmark, name: "checkmark" },
  { icon: tick, name: "tick" },
  { icon: downArrow, name: "downArrow" },
  { icon: loginblue, name: "loginblue" },
  { icon: recoverpswd, name: "recoverpswd" },
  { icon: success, name: "success" },
  { icon: failure, name: "failure" },
  { icon: heartOn, name: "heartOn" },
  { icon: heartOff, name: "heartOff" },
  { icon: registrationSuccess, name: "registrationSuccess" },
  { icon: userIcon, name: "userIcon" },
  { icon: warning, name: "warning" },
  { icon: operatorPay, name: "operatorPay" },
  { icon: cuponeraIcon, name: "cuponeraIcon" },
];

export default busesjmIcons;
