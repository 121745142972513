import React from 'react';
import { connectData } from '../../../redux';
import './ThemeSevenFooter.css';


const ThemeSevenFooter = (props) => {

let operator = JSON.parse(localStorage.getItem("op"))
// console.log(operator.operator_name,"=====> 00000 ");

const footerStyle = operator && operator.operator_name === 'fernandez'
    ? { position: 'relative', backgroundColor: '#f4f4f4',color: '#203b68',
    marginTop: '30px',
    fontSize: '15px',height:'250px'}
    : {};

  return (
    <div className='themes-theme-seven-footer' >
      <div className='footer-wrapper new-container' style={footerStyle}>
        <div className='left'>
          <div className='footer-logo '>
            <a href='/'>
              <img
                src={props.data.operator && props.data.operator.footer_logo}
                alt=''
              />
            </a>
          </div>
          <div className='left-text bold-text'>
            <span>Síguenos</span>
            <a
              href='https://www.facebook.com/busesfernandez1947/'
              target='_blank'
            >
              <span className='fb'></span>
            </a>
            <a href='https://www.instagram.com/busesfernandez/' target='_blank'>
              <span className='insta'></span>
            </a>
          </div>
        </div>
        <div className='right bold-text'>
          <div className='text-right'>
            <p>Buses Fernández 2022.</p>
            <p>Todos los derechos reservados ©</p>
            <p>
              Powered by{' '}
              <a href='https://kupos.cl' target='_blank'>
                kupos.cl
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connectData()(ThemeSevenFooter);
