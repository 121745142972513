import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getIcons from '../themes/functions/getIcons/getIcons';

export default class KuposCheckbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    circular: PropTypes.bool,
    t: PropTypes.func,
  };

  render() {
    console.log('radio', this.props);
    return (
      <div className='common-kupos-checkbox'>
        <label onClick={this.props.onChange}>
          <style>
            .icon-selection-circle-not-selected,
            .icon-selection-circle-selected, .icon-check-square-pink,
            .icon-check-square-pink-line{'{'}
            background: url({getIcons('Checkbox')}) no-repeat; overflow: hidden;
            text-indent: -9999px; text-align: left; background-size: 200% 200%;
            position: relative; top: 2px; width: 17px; height: 17px;
            {'}'}
            .icon-selection-circle-not-selected {'{'}
            background-position: -0 -0;
            {'}'}
            .icon-selection-circle-selected {'{'}
            background-position: 100% -0%;
            {'}'}
            .icon-check-square-pink{'{'}
            background-position: -0% 100%
            {'}'}
            .icon-check-square-pink-line{'{'}
            background-position: 100% 100%;
            {'}'}
          </style>
          <div className={'kupos-checkbox '}>
            {/* <input type="checkbox" /> */}
            {this.props.circular ? (
              this.props.checked ? (
                <div className='icon-selection-circle-selected'></div>
              ) : (
                <div className='icon-selection-circle-not-selected'></div>
              )
            ) : this.props.checked ? (
              <div className='icon-check-square-pink'></div>
            ) : (
              <div className='icon-check-square-pink-line'></div>
            )}
          </div>
          {this.props.showLogo &&
            <div>
              <img style={{ width: 150 ,display:'inline-block'}} src={this.props.showLogo} alt="logo" /><br />
              <span className="checkbox-label" >{this.props.label}</span>
            </div>
          }
           {!this.props.showLogo && this.props.label ?
          <span className='checkbox-label' style={this.props.labelStyle}>
            {this.props.t
              ? this.props.t('PROFILE.' + this.props.label)
              : this.props.label}
          </span>:null}
        </label>
      </div>
    );
  }
}