import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeroSection from '../../common/HeroSection/HeroSection';
import { connectData } from '../../../redux';
import { AndimarTitle } from '../../common/Title/AndimarTitle';
import Footer1 from '../../common/footer/Footer1';
import Header from '../../common/header/Header';

const termsBanner = '/assets/andimar/terms-and-conditions-banner.jpg';

const AndimarTermsAndConditions = (props) => {
  const createMarkup = (data) => {
    return { __html: data };
  };

  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        {/* <div style={{ position: 'absolute', width: '100vw', zIndex: 1000 }}> */}
        {/* <Header1 /> */}
        <Header />
        {/* </div> */}
        <HeroSection
          heroImage={termsBanner}
          // height={'60vh'}
          height={'45vh'}
          title={'Términos y condiciones'}
          description={'Home / Términos y  condiciones'}
        />
        <AndimarTitle title={'  TÉRMINOS Y CONDICIONES  '} />

        <div style={{ width: '90%', marginLeft: '5%' }}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              operator && operator.terms ? operator.terms : ''
            )}
            className='lh-1-3'
            style={{ fontSize: '13px' }}
          />
        </div>

        <Footer1 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(AndimarTermsAndConditions);
