// @flow

import React, { PureComponent } from "react";
// import { BarcodeScanner } from "@ionic-native/barcode-scanner";

// import {CameraKitCameraScreen} from 'react-native-camera-kit';
// import jwt_decode from 'jwt-decode';
// import Toast from 'react-native-easy-toast';

import { connectData } from "../../redux";
import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBInput,
  PButton,
} from "../../components";
import { commonStyles, dimensions } from "../../theme";
import { CommonService } from "../../services";
import { IonContent, IonPage, IonButton } from "@ionic/react";
// import { NEW_TRANSFER_RECHARGE_SCREEN } from '../../navigation';

class NewScanQRScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rut: null,
      rutError: null,
      amount: null,
      amountError: null,
      recipient: null,
      favourites: null,
      favAdd: [],
      favRemove: [],
      showCamera: false,
    };
    // this.requestPermissions();
  }

  //   requestPermissions = async () => {
  //     try {
  //         const granted = await PermissionsAndroid.request(
  //           PermissionsAndroid.PERMISSIONS.CAMERA,
  //           {
  //             title: 'Cool Photo App Camera Permission',
  //             message:
  //               'Cool Photo App needs access to your camera ' +
  //               'so you can take awesome pictures.',
  //             buttonNeutral: 'Ask Me Later',
  //             buttonNegative: 'Cancel',
  //             buttonPositive: 'OK',
  //           },
  //         );
  //         if (granted === PermissionsAndroid.RESULTS.GRANTED) {
  //             this.setState({ isCameraAuthorized: true })
  //         } else {
  //             this.setState({ isCameraAuthorized: false })
  //         }
  //       } catch (err) {
  //         console.warn(err);
  //       }
  // }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCamera: true });
    }, 300);
  }

  onQrCodeRead = (event) => {
    this.props.scanQR(false);
    // this.props.data.scanQR ? this.props.getRecipientInfo({callback:(result) => this.onResponse(result),rut:event.nativeEvent.codeStringValue}) : null
    // let data = {
    //   "data":event.nativeEvent.codeStringValue
    // }
    // if(this.props.data.scanQR){
    //   // Vibration.vibrate(200);
    //   // let data = event.nativeEvent.codeStringValue ? jwt_decode(event.nativeEvent.codeStringValue) : null;
    //   if(data){
    //     let jsonData = JSON.parse(data);
    //     if(jsonData.recipient_id){
    //       // CommonService.goToScreenHideTopBar(this.props.componentId,NEW_TRANSFER_RECHARGE_SCREEN,{recipient:jsonData})
    //     }
    //   }
    // }
    // this.props.data.scanQR ? this.props.getQRInfo({callback:(result) => this.onResponse(result),data:data}) : null
  };

  openScanner = async () => {
    // const data = await BarcodeScanner.scan();
    // console.log(`Barcode data: ${data.text}`);
  };

  parseJwt = (token) => {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url
        ? base64Url.replace(/-/g, "+").replace(/_/g, "/")
        : "";
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
  };

  onBlur = (text) => {
    if (!text) {
      this.setState({
        rutError: {
          message:
            "Por favor, introduzca el N° de documento de identificación.",
        },
      });
    } else {
      let rut = CommonService.formatRut(text);
      this.setState({
        rut: rut,
      });
      if (!CommonService.isRutValid(rut)) {
        this.setState({
          rutError: {
            message: "Por favor ingrese RUT válido.",
          },
        });
      }
    }
  };

  onChange = (text) => {
    this.setState({
      rutError: null,
    });
    if (text) {
      let rut = text;
      this.setState({
        rut: rut,
      });
    } else {
      this.setState({
        rut: text,
      });
    }
  };

  isValid = () => {
    let errorCount = 0;

    if (!this.state.rut) {
      this.setState({
        rutError: {
          message:
            "Por favor, introduzca el N° de documento de identificación.",
        },
      });
      errorCount++;
    } else if (!CommonService.isRutValid(this.state.rut)) {
      this.setState({
        rutError: {
          message: "Por favor ingrese RUT válido.",
        },
      });
      errorCount++;
    }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  submitCode = () => {
    // Keyboard.dismiss()
    if (!this.isValid()) {
      return;
    }
    let rut = CommonService.formatRut(this.state.rut);
    this.setState({
      rut: rut,
    });
    this.props.getRecipientInfo({
      callback: (result) => this.onResponse(result),
      rut: this.state.rut,
    });
  };

  onResponse = (result) => {
    console.log(result);
    if (result.success && result.data && result.data.error) {
      if (result.data.status == 404) {
        this.setState({
          rutError: {
            message: "El RUT ingresado no se encuentra registrado",
          },
        });
      } else {
        this.setState({
          rutError: {
            message: result.data.message,
          },
        });
      }
      return;
    } else if (result.data && result.data.recipient_id) {
      // CommonService.goToScreenHideTopBar(this.props.componentId,NEW_TRANSFER_RECHARGE_SCREEN,{recipient:result.data})
    } else if (result.data && result.data.message) {
      this.setState({
        rutError: {
          message: result.data.message,
        },
      });
    }
  };

  closeModal = () => {
    this.props.scanQR(true);
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      authFailed: false,
      button1Text: "",
      modalButtonTap: null,
      modalBackButtonPress: null,
    });
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="qr-main-container">
            <IonButton style={{ marginTop: 200 }} onClick={this.openScanner}>
              Scan barcode
            </IonButton>
            {/* {this.state.showCamera ? <CameraKitCameraScreen
                 showFrame={false}
                 //Show/hide scan frame
                 scanBarcode={true}
                 //Can restrict for the QR Code only
                 laserColor={'blue'}
                 //Color can be of your choice
                 frameColor={'yellow'}
                 //If frame is visible then frame color
                 colorForScannerFrame={'black'}
                 //Scanner Frame color
                 onReadCode={event => 
                   {
                      this.onQrCodeRead(event)
                    }
                 }
                /> : null } */}

            {this.state.isCameraAuthorized && this.state.showCamera ? (
              <div className="qr-scan-container">
                <div className="qr-recipient-icon">
                  <div className="qr-bg-transparent"></div>
                  <div className="display-flex">
                    <div className="qr-recipient-icon"></div>
                    <img
                      src="../../assets/carpool/img/scan-frame.png"
                      className="qr-scan-frame"
                    />
                    <div className="qr-recipient-icon"></div>
                  </div>
                  <div className="qr-full-flex-center qr-bg-transparent">
                    <div className="qr-scan-full-main"></div>
                    <PBText textAlign={"text-center"} fontColor={"white-font"}>
                      Alinea el código QR dentro del cuadro {"\n"} para
                      escanear.
                    </PBText>
                  </div>
                </div>

                <div className="qr-bottom-container">
                  <div style={{ marginTop: 10 }}>
                    <PBText
                      textAlign={"text-center"}
                      fontSize={"font14"}
                      fontStyle={"bold-font"}
                    >
                      ¡Tu viaje está por comenzar!
                    </PBText>
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <PBInput
                      label=""
                      placeholder="Ingresa tu código aquí"
                      value={this.state.rut}
                      onChange={(text) => this.onChange(text)}
                      onBlur={(text) => this.onBlur(text)}
                      error={this.state.rutError ? true : false}
                      errorMessage={
                        this.state.rutError ? this.state.rutError.message : ""
                      }
                      disable={this.state.recipient ? true : false}
                      style={commonStyles.carpoolInput}
                      textStyle={{ fontSize: 14 }}
                    ></PBInput>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <PButton
                      onPress={this.submitCode}
                      style={{ height: 50, borderRadius: 10 }}
                      noGradient={true}
                      title={"VALIDAR CÓDIGO"}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="qr-last-element">
              <CarpoolHeader
                backIcon={true}
                backIconColor={"#fff"}
                componentId={this.props.componentId}
                label="Escanear "
                labelBold="QR"
              />
            </div>

            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.button1Text}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              showButton2={this.state.showButton2}
              button2Text={this.state.button2Text}
              button2Press={this.state.button2Press}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.closeModal
              }
              icon={this.state.modalIcon}
            >
              {this.state.showSuccessBody && (
                <div style={styles.failureModalBody}>
                  <div style={styles.text1Container}>
                    <PBText style={{ textAlign: "center" }}>
                      Puede que el RUT que ingresaste esté erróneo o no esté
                      registrado en PasajeBus.com
                    </PBText>
                  </div>
                  <div style={styles.text2Container}>
                    <PBText style={{ textAlign: "center" }}>
                      Las transferencias de saldo solo son posibles entre
                      cuentas registradas.
                    </PBText>
                  </div>
                </div>
              )}

              {this.state.showConfirmAmountBody ? (
                <div style={styles.failureModalBody}>
                  <div style={{ flexDirection: "row", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <PBText>Monto a transferir: </PBText>
                    </div>
                    <div>
                      <PBText>CLP${this.state.amount}</PBText>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <PBText>Monto a transferir: </PBText>
                    </div>
                    <div>
                      <PBText>
                        CLP{this.props.data.loginData.wallet_moneys[0].amount}
                      </PBText>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.showTransferSuccessBody ? (
                <div style={styles.failureModalBody}>
                  <PBText style={{ fontSize: 18, textAlign: "center" }}>
                    ¡Listo! El saldo ha sido enviado al usuario seleccionado
                  </PBText>
                  <div style={{ width: "100%", height: 25 }}></div>
                  <PBText style={{ fontSize: 16, textAlign: "center" }}>
                    Te hemos enviado un mensaje de correo electrónico con los
                    detalles de tu transacción.
                  </PBText>
                </div>
              ) : null}

              {this.state.showTransferFailBody ? (
                <div style={styles.failureModalBody}>
                  <PBText style={{ fontSize: 18, textAlign: "center" }}>
                    Tu transacción no pudo ser realizada. Por favor intenta
                    nuevamente.
                  </PBText>
                  <div style={{ width: "100%", height: 25 }}></div>
                  <PBText style={{ textAlign: "center" }}>
                    ¿Necesitas ayuda?
                  </PBText>
                  <PBText style={{ textAlign: "center" }}>
                    ayuda@pasajebus.com
                  </PBText>
                  <PBText style={{ textAlign: "center" }}>
                    +56 9 4915 6683.
                  </PBText>
                </div>
              ) : null}
            </PBModal>

            {/* <Toast ref="toast"/> */}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 12,
  },
  buttonContainer: {
    marginTop: 10,
  },
  scanContainer: {
    position: "absolute",
    zIndex: 9,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  recipientIcon: {
    width: dimensions.vw * 100,
    flex: 1,
  },
  bgTransparent: {
    backgroundColor: "#000",
    opacity: 0.4,
  },
  bottomContainer: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: "#fff",
    paddingHorizontal: 30,
    paddingVertical: 15,
    width: "100%",
    marginTop: -30,
  },
};

export default connectData()(NewScanQRScreen);
