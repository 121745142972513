// passing the path into variables

// const banner1 = '/assets/tepual/home/banner1.jpg';
// const banner2 = '/assets/tepual/home/banner2.jpg';
// const banner3 = '/assets/tepual/home/banner3.jpg';

const destination1 =
  '/assets/trrass/destinations/destinations-1.jpg';
const destination2 =
  '/assets/trrass/destinations/destinations-2.jpg';
const destination3 =
  '/assets/trrass/destinations/destinations-3.jpg';
const destination4 =
  '/assets/trrass/destinations/destinations-4.jpg';
const destination5 =
  '/assets/trrass/destinations/destinations-5.jpg';
const destination6 =
  '/assets/trrass/destinations/destinations-6.jpg';
const destination7 =
  '/assets/trrass/destinations/destinations-7.jpg';
const destination8 =
  '/assets/trrass/destinations/destinations-8.jpg';
const destination9 =
  '/assets/trrass/destinations/destinations-9.jpg';
const destination10 =
  '/assets/trrass/destinations/destinations-10.jpg';
const destination11 =
  '/assets/trrass/destinations/destinations-11.jpg';
const destination12 =
  '/assets/trrass/destinations/destinations-12.jpg';
const destination13 =
  '/assets/trrass/destinations/destinations-13.jpg';
const destination14 =
  '/assets/trrass/destinations/destinations-14.jpg';


const gallery1 = '/assets/trrass/gallery/Gallery-Photo1.jpg';
const gallery2 = '/assets/trrass/gallery/Gallery-Photo2.jpg';
const gallery3 = '/assets/trrass/gallery/Gallery-Photo3.jpg';
const gallery4 = '/assets/trrass/gallery/Gallery-Photo4.jpg';
const gallery5 = '/assets/trrass/gallery/Gallery-Photo5.jpg';
const gallery6 = '/assets/trrass/gallery/Gallery-Photo6.jpg';
const gallery7 = '/assets/trrass/gallery/Gallery-Photo7.jpg';

// const gallery5 = '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-05.jpg';
// const gallery6 = '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-06.jpg';

// const banner = [banner1, banner2, banner3];

const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
  destination5,
  destination6,
  destination7,
  destination8,
  destination9,
  destination10,
  destination11,
  destination12,
  destination13,
  destination14,
];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
];

const trrassSlides = {
  // banner,
  destination,
  gallery,
};

export default trrassSlides;
