const LocationIcon = '/assets/fernandez/general/LocationIcon.svg';
const PhoneIcon = '/assets/fernandez/general/PhoneIcon.svg';
const HeaderLogo = '/assets/fernandez/general/Header-BusesfernandezLogo.svg';
const FooterLogo = '/assets/fernandez/general/Footer-BusesfernandezLogo.svg';
const ContactBg = '/assets/fernandez/home/home-background.jpg';
// const PopupIcon = '/assets/fernandez/gallery/gallery5.jpg';

//
const BookingActive =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-BuyTicketOn.svg';
const BookingInActive =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-BuyTicketOff.svg';
const CancelActive =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-CancelTicketOn.svg';
const CancelInActive =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-CancelTicketOff.svg';
const OriginIcon =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-Origin.svg';
const DestinationIcon =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-Destinations.svg';
const CalendarIcon =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-Calendar.svg';
const SearchIcon =
	'/assets/fernandez/home/BusesFernandez-Website-SearchBar-Search.svg';

const BG = '/assets/fernandez/home/BusesFernandez-Banner-MobileVersion.jpg';

//
const checkboxIcon = '/assets/tepual/checkbox.png';

const BottomFooterKuposLogo = '/assets/common/Footer-kuposLogo.svg';
// const animation = '/images/animations/Services-kupos-PasajeBus-Orange.gif';
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const pnr =
	'/assets/fernandez/general/BusesFernandez-Website-Home-WhereToFindMyPNR.svg';

const contactMail = '/assets/fernandez/contact/contact-email-icon.svg';
const contactHeadphone =
	'/assets/fernandez/contact/contact-phone-number-icon.svg';
const aboutUsImage = '/assets/fernandez/icons/single-bus-about-us.jpg';

// banners
const destinationBanner = '/assets/fernandez/destinations/destination-main.jpg';
const galleryBanner = '/assets/fernandez/gallery/gallery-main.jpg';
const contactUsBanner = '/assets/fernandez/contact/contact-us-banner.jpg';
const aboutUsBanner =
	'/assets/fernandez/about-us/Busesfernandez-Website-AboutUs-MainBanner.jpg';
const tncBanner =
	'/assets/fernandez/FT/BusesFernandez-Website-FrequentTraveler-TermsConditions-Banner.jpg';
const officeBanner = '/assets/fernandez/offices/offices-banner.jpg';

const OfficePhoneIcon = '/assets/fernandez/general/officePhone.svg';
const close = '/assets/fernandez/general/BusesFernandez-PopUp-CrossIcon.svg';
const menuDot = '/assets/fernandez/general/BusesFernandez-SideMenuIcon.svg';



// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'
const fernandezIcons = [

	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: BG, name: 'BG' },
	{ icon: menuDot, name: 'menuDot' },

	{ icon: PhoneIcon, name: 'phone' },
	{ icon: LocationIcon, name: 'location' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: ContactBg, name: 'ContactBg' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: SearchIcon, name: 'Search' },
	// { icon: PopupIcon, name: 'Popup' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },

	{ icon: contactMail, name: 'contactMail' },
	{ icon: close, name: 'close' },
	{ icon: contactHeadphone, name: 'contactHeadphone' },
	{ icon: destinationBanner, name: 'destinationBanner' },
	{ icon: galleryBanner, name: 'galleryBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: aboutUsImage, name: 'aboutUsImage' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: tncBanner, name: 'tncBanner' },
	{ icon: officeBanner, name: 'officeBanner' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
];

export default fernandezIcons;
