// passing the path into variables

// const banner1 = '/assets/tepual/home/banner1.jpg';
// const banner2 = '/assets/tepual/home/banner2.jpg';
// const banner3 = '/assets/tepual/home/banner3.jpg';

const destination1 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-1.jpg';
const destination2 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-2.jpg';
const destination3 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-3.jpg';
const destination4 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-4.jpg';
const destination5 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-5.jpg';
const destination6 =
  '/assets/tepual/destinations/BusesTepual-Website-Destinations-Gallery-6.jpg';

const gallery1 =
  '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-01.jpg';
const gallery2 =
  '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-02.jpg';
const gallery3 =
  '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-03.jpg';
const gallery4 =
  '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-04.jpg';
// const gallery5 = '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-05.jpg';
// const gallery6 = '/assets/tepual/gallery/BusesTepual-Website-Gallery-Fleet-06.jpg';

// const banner = [banner1, banner2, banner3];

const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
  destination5,
  destination6,
];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  //  gallery5, gallery6
];

const tepualSlides = {
  // banner,
  destination,
  gallery,
};

export default tepualSlides;
