import React, { useState } from 'react';

import getIcons from '../../functions/getIcons/getIcons';
import { menuController } from '@ionic/core';

const Header1 = () => {
	const [expandMenu, setExpandMenu] = useState(false);

	const operator = JSON.parse(localStorage.getItem('op'));

	const styles = {
		line: {
			width: 17,
			height: 2,
			background: '#FFF',
			margin: 3,
		},
		toggleButton: {
			width: 50,
			height: 35,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			border: '1.4px solid #fff',
			borderRadius: 5,
			//   paddingTop:5,
			//   paddingBottom:5
		},
		blackLine: {
			height: 1,
			background: '#000',
			width: '80%',
			margin: 'auto',
		},
	};

	return (
		operator && (
			<div>
				{operator && operator.show_top_header ? (
					<TopHeader operator={operator} />
				) : (
					''
				)}

				<div
					className='themes-header home-container'
					style={{
						background:
							operator && operator.theme_color2 ? operator.theme_color2 : '',
						borderBottomRightRadius: 10,
						borderBottomLeftRadius: 10,
						border: `1px solid ${operator.theme_color}`,
						opacity: 0.9,
						justifyContent: 'center',
					}}
				>
					{/* <div onClick={() => menuController.open()}>
                <img
                    className="qr-back-arrow"
                    src="../../assets/carpool/menu-dot.png"
                />
            </div> */}
					<div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								// justifyContent: 'center',
								marginLeft: 15,
								marginRight: 15,
							}}
						>
							<div
								onClick={() => setExpandMenu(!expandMenu)}
								style={styles.toggleButton}
							>
								{/* <img
              className="qr-back-arrow"
              src="../../assets/carpool/menu-dot.png"
            /> */}
								<div style={styles.line}></div>
								<div style={styles.line}></div>
								<div style={styles.line}></div>
							</div>
							<div style={{ marginLeft: 25 }}>
								<a href='/'>
									<img
										src={operator && operator.header_logo}
										alt=''
										id='logo'
										height={'50px'}
									/>
								</a>
							</div>
						</div>
						{expandMenu && <div style={styles.blackLine}></div>}
						{expandMenu && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginTop: 20,
								}}
							>
								<style>
									a.header_link {'{'}
									{'}'}
									a.header_link:hover {'{'}
									border-bottom: 2px solid{' '}
									{operator && operator.theme_color3
										? operator.theme_color3
										: ''}
									; font-weight: 600;
									{'}'}
									.nav-section.home-nav-section.common-top-strip.header-box + *{' '}
									{'{'}
									margin-top:270px
									{'}'}
									.nav-section.home-nav-section.common-top-strip.header-box.payments-page-header
									+ * {'{'}
									margin-top:110px
									{'}'}
								</style>

								{operator &&
									operator.show_header_links &&
									operator.header_links.map((val, i) => {
										return (
											<a
												key={i}
												className='header_link f-13'
												style={{
													color: operator.text_color,
													borderBottom:
														window.location.pathname == Object.values(val) &&
															operator &&
															operator.theme_color3
															? `2px solid ${operator.theme_color3}`
															: '',
													margin: '5px 15px',
													paddingBottom: 5,
													width: 'max-content',
													opacity: 0.9,
												}}
												href={Object.values(val)}
											>
												{Object.keys(val)}
											</a>
										);
									})}
							</div>
						)}
					</div>{' '}
					:<div>&nbsp;</div>
				</div>
			</div>
		)
	);
};

const TopHeader = ({ operator }) => {
	return (
		<div
			className=' p5'
			style={{
				background: operator.theme_color,
				color: '#ffffff',
				opacity: 0.9,
			}}
		>
			<div
				className='new-container  font12'
				style={{
					padding: '5px 0px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					color: '#ffffff',
					justifyContent: 'center',
				}}
			>
				{operator.phone_number && (
					<span
						className='d-flex-c '
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<img
							src={getIcons('Telephone')}
							style={{
								height: 15,
								width: 20,
								marginRight: 3,
							}}
						/>
						{operator.phone_number ? operator.phone_number : 'N/A'}
					</span>
				)}
				{operator.phone_number && operator.email && (
					<span style={{ padding: '0 10px' }}>|</span>
				)}

				{operator.email && (
					<span
						className='d-flex-c'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<img
							src={getIcons('Email')}
							style={{
								height: 15,
								width: 20,
								marginRight: 3,
							}}
						/>
						{operator.email ? operator.email : 'N/A'}
					</span>
				)}
			</div>
		</div>
	);
};

export default Header1;
