import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { connectData } from "../../../redux";
import { CommonService } from "../../../services";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import getIcons from "../../functions/getIcons/getIcons";
import ThemeThreeContactForm from "../common/ThemeThreeContactForm";
import ThemeThreeFooter from "../common/ThemeThreeFooter";
import ThemeThreeTitle from "../common/ThemeThreeTitle";
import IvergramaFooter from "../../Ivergrama/common/IvergramaFooter";

const ThemeThreeContactUsScreen = props => {
  const operator = CommonService.getLocal("op") || props.data.operator;
  return (
    <IonPage>
      <IonContent>
        <Header />

        <HeroSection
          heroImage={getIcons("contactUsBanner", operator)}
          title="Contacto"
          description="Home / Contacto"
          height={"45vh"}
          operator={operator}
        />

        <ThemeThreeTitle
          operator={operator}
          title="Contacto"
          className={
            operator && operator.operator_name === "busnorte"
              ? "new-bold-font"
              : ""
          }
        />

        <ThemeThreeContactForm contact />
  
        {operator && operator.operator_name === 'arzola' ? <IvergramaFooter /> : <ThemeThreeFooter />}
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeThreeContactUsScreen);
