import './ThemeSixSlides.css';
import 'react-multi-carousel/lib/styles.css';

import Carousel from 'react-multi-carousel';
import React from 'react';

// import CommonService from '../../../../services/commonService';

const ThemeSixSlider = props => {
	const operator = JSON.parse(localStorage.getItem('op'));

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: props.noOfIcons ? props.noOfIcons : 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: props.noOfIcons ? props.noOfIcons : 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: props.noOfIcons ? props.noOfIcons : 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: props.noOfIcons ? props.noOfIcons : 1,
		},
	};

	const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		// onMove means if dragging or swiping in progress.
		return (
			<button onClick={() => onClick()}>
				hlo
				<img
					style={{
						width: 30,
						height: 30,
					}}
					src="/assets/pullmanSanAndres/leftorangearrow.svg"
					alt=""
				/>
			</button>
		);
	};
	const CustomLeftArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		// onMove means if dragging or swiping in progress.
		return (
			<button onClick={() => onClick()}>
				hlo
				<img
					style={{
						width: 30,
						height: 30,
					}}
					src="/assets/pullmanSanAndres/leftorangearrow.svg"
					alt=""
				/>
			</button>
		);
	};

	return (
		<div className="themes-theme-five-slider">
			<style>
				.themes-theme-five-slider .react-multi-carousel-dot--active
				button {'{'}
				background:
				{operator &&
				operator.color_codes &&
				operator.color_codes.primary_color
					? operator.color_codes.primary_color
					: '#018137'}
				;{'}'}
				.react-multiple-carousel__arrow {'{'}
				background-color: transparent; min-width: 20px; min-height:
				26px
				{'}'}
				.react-multiple-carousel__arrow:before {'{'}
				color: #db7838; align-items: center; color: #db7838;
				display: flex; justify-content: center; border-radius: 100%;
				font-size: 22px
				{'}'}
				.react-multiple-carousel__arrow:hover {'{'}
				background-color: transparent;
				{'}'}
				.react-multiple-carousel__arrow--right{'{'}
				right: 3px;
				{'}'}
				.react-multiple-carousel__arrow--left {'{'}
				left: 3px;
				{'}'}
				{/* .react-multi-carousel-item {'{'} */}
				{/* padding:{' '} */}
				{/* {props.noOfIcons && props.noOfIcons === 2
					? '0 40px'
					: '0 130px'}
				;{'}'} */}
				.themes-theme-five-slider .react-multi-carousel-list {'{'}
				min-height: 180px
				{'}'}
				.react-multiple-carousel__arrow:before {'{'}
				display: none !important;
				{'}'}
				button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left
				{'{'}
				background-image:url(/assets/pullmanSanAndres/leftorangearrow.svg)
				!important; background-repeat: no-repeat;
				{'}'}
				button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right
				{'{'}
				background-image:url(/assets/pullmanSanAndres/rightorangearrow.svg)
				!important; background-repeat: no-repeat;
				background-position-x: right;
				{'}'}
				.slide-item {'{'}
				width: 95px; height: 95px;
				{'}'}
				li.react-multi-carousel-item {'{'}
				display: flex; justify-content: center;
				{'}'}
			</style>
			<Carousel
				responsive={responsive}
				swipeable={true}
				draggable={false}
				arrows={true}
				infinite={true}
				autoPlay={true}
				// customRightArrow={<CustomRightArrow />}
				// customLeftArrow={<CustomLeftArrow />}
				autoPlaySpeed={3000}
				customTransition="all .5"
				transitionDuration={2000}
				// removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
			>
				{props.slides &&
					(props.slides.length || []) > 0 &&
					props.slides.map((slide, index) => {
						return <SlideItem slide={slide} index={index} />;
					})}
			</Carousel>
		</div>
	);
};

const SlideItem = props => {
	return (
		<div className="slide-item" key={props.index}>
			<div className="image-container">
				<img
					className="image"
					src={props.slide.banner}
					alt="slide-item"
				/>
				{props.slide.text && <p>{props.slide.text}</p>}
			</div>
		</div>
	);
};

export default ThemeSixSlider;
