import React from 'react';
import HeroSection from '../../common/HeroSection/HeroSection';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import getSlides from '../../functions/getSlides';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import { IonContent, IonPage } from '@ionic/react';
import Header from '../../common/header/Header';
import Footer2 from '../../common/footer/Footer2';

const ThemeFiveDestinationScreen = ({ data }) => {
  const { operator } = data;

  return (
    <IonPage>
      <IonContent>
        <Header />

        <HeroSection
          heroImage={getIcons('destinationBanner')}
          // heroImage={destBanner}
          height={'30vh'}
          title={'Destinos'}
          description={'Home / Destinos'}
        />

        <MultiSlider
          // smallHeading="Conoce"
          boldHeading='DESTINOS'
          multiSlides={getSlides('Destination')}
          width={'92%'}
        />

        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeFiveDestinationScreen);
