import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import ThemeTwoGallery from "./common/ThemeTwoGallery";
import { withRouter } from "react-router-dom";
import { connectData } from "../../redux";
import KuposHeader from "../../components/KuposHeader";
const operator = { operator_name: "tarapaca" || "biaggini"};

const ThemeTwoGalleryScreen = ({ history }) => {
  return (
    <IonPage>
      <IonContent>
        <KuposHeader
          title={"       "}
          boldTitle={"Galería"}
          handleHomeSubmit={() => history.push("/")}
          handleBackSubmit={() => history.goBack()}
        />
        <div className="new-container">
          {/* <ThemeTwoGallery /> */}
          <ThemeTwoGallery operator={operator} />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default withRouter(connectData()(ThemeTwoGalleryScreen));