// -------------
const LocationIcon = '/assets/erbuc/General/Erbuc-Website-LocationIcon.svg';
const PhoneIcon = '/assets/erbuc/General/Erbuc-Website-PhoneIcon.svg';
const HeaderLogo = '/assets/erbuc/General/Erbuc-Website-Logo-Header.svg';
const FooterLogo = '/assets/erbuc/General/Erbuc-Website-Logo-Footer.svg';

const ContactBg =
	'/assets/erbuc/Home/Erbuc-Website-Home-Banner-ContactSection.jpg';
// const PopupIcon = '/assets/erbuc/gallery/gallery5.jpg';
const BookingActive =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-BuyTicketIcon-Active.svg';
const BookingInActive =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-BuyTicketIcon-Inactive.svg';
const CancelActive =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-CancelTicketIcon-Active.svg';
const CancelInActive =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-CancelTicketIcon-Inactive.svg';

const OriginIcon =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-OriginIcon.svg';
const DestinationIcon =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-DestinationIcon.svg';
const CalendarIcon =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-CalendarIcon.svg';
const SearchIcon =
	'/assets/erbuc/Home/Erbuc-Website-Home-SearchBar-SearchIcon.svg';

const checkboxIcon =
	'/assets/erbuc/ResultCheckOut/BusOperatorsWebsite-Checkbox.png';

const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';

const pnr = '/assets/erbuc/Home/Erbuc-Website-Home-WhereToFindMyPNR.svg';

const contactMail =
	'/assets/erbuc/Contact/Erbuc-Website-Contact-EmailAddressIcon.svg';
const contactHeadphone =
	'/assets/erbuc/Contact/Erbuc-Website-Contact-PhoneNumberIcon-03.svg';

const aboutUsImage = '/assets/erbuc/AboutUs/Erbuc-Website-AboutUs-Image2.jpg';

// banners
const destinationBanner =
	'/assets/erbuc/Destinations/Erbuc-Website-MainBanner-Destinations.jpg';
const galleryBanner =
	'/assets/erbuc/Gallery/Erbuc-Website-MainBanner-Gallery.jpg';
const contactUsBanner =
	'/assets/erbuc/Contact/Erbuc-Website-MainBanner-Contact.jpg';
const aboutUsBanner =
	'/assets/erbuc/AboutUs/Erbuc-Website-MainBanner-AboutUs.jpg';
const tncBanner =
	'/assets/erbuc/tnc/Erbuc-Website-MainBanner-TermsAndConditions.jpg';
const officeBanner =
	'/assets/erbuc/Offices/Erbuc-Website-MainBanner-Offices.jpg';

const OfficePhoneIcon =
	'/assets/erbuc/Offices/Erbuc-Website-Offices-PhoneNumberIcon.svg';

const BG = '/assets/erbuc/Home/Erbuc-Website-Home-Slide-Mobile-1.jpg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'

const erbucIcons = [
	{ icon: BG, name: 'BG' },
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: PhoneIcon, name: 'phone' },
	{ icon: LocationIcon, name: 'location' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: ContactBg, name: 'ContactBg' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: SearchIcon, name: 'Search' },
	// { icon: PopupIcon, name: 'Popup' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },

	{ icon: contactMail, name: 'contactMail' },
	{ icon: contactHeadphone, name: 'contactHeadphone' },
	{ icon: destinationBanner, name: 'destinationBanner' },
	{ icon: galleryBanner, name: 'galleryBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: aboutUsImage, name: 'aboutUsImage' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: tncBanner, name: 'tncBanner' },
	{ icon: officeBanner, name: 'officeBanner' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
];

export default erbucIcons;
