import FleetScreen from '../theme1/AndimarPages/FleetScreen';
import PullmanSanAndreasFleet from '../theme6/PullmanSanAndreasFleet';
import React from 'react';
import ThemeFiveGalleryScreen from '../theme5/ThemeFivePages/ThemeFiveGalleryScreen';
import ThemeSevenGalleryScreen from '../ThemeSeven/ThemeSevenPages/ThemeSevenGalleryScreen';
import ThemeThreeGallery from '../ThemeThree/ThemeThreePages/ThemeThreeGallery';
import ThemeTwoGalleryScreen from '../ThemeTwo/ThemeTwoGalleryScreen';
import { connectData } from '../../redux';

const CommonGalleryPage = (props) => {
	const getPage = () => {
		const themeType =
			props.data && props.data.operator && props.data.operator.theme_type;
		if (themeType === 1) {
			return <FleetScreen />;
		} else if (themeType === 5) {
			return <ThemeFiveGalleryScreen />;
		} else if (themeType === 6) {
			return <PullmanSanAndreasFleet />;
		} else if (themeType === 7) {
			return <ThemeSevenGalleryScreen />;
		} else if (themeType === 2) {
			return <ThemeTwoGalleryScreen props={props} />
		}
		else if (themeType === 3) {
			return <ThemeThreeGallery props={props} />
		}
	};

	return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonGalleryPage);
