// passing the path into variables

const banner1 = '/assets/ivergrama/Home/Ivergrama-Website-Home-MainBanner.jpg';
const banner2 = '/assets/ivergrama/Home/Ivergrama-Website-Home-MainBanner2.jpg';
const banner3 = '/assets/ivergrama/Home/Ivergrama-Website-Home-MainBanner3.jpg';
const banner4 = '/assets/ivergrama/Home/Ivergrama-Website-Home-MainBanner4.jpg';

const destination1 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Santiago-1.jpg';
const destination2 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Temuco.jpg';
const destination3 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-PuertoSaavedra.jpg';
const destination4 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Valdivia.jpg';
const destination5 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Villarrica.jpg';
const destination6 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Carahue.jpg';
const destination7 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-NuevaImperial.jpg';
const destination8 =
  '/assets/ivergrama/Home/Ivergrama-Website-Destinations-DesktopVersion-Santiago-2.jpg';

const banner = [banner1, banner2, banner3, banner4];

const destination = [
  {
    one: destination1,
    two: destination2,
    three: destination3,
    four: destination4,
  },

  {
    one: destination5,
    two: destination6,
    three: destination7,
    four: destination8,
  },
];

const ivergramaSlides = {
  banner,
  destination,
};

export default ivergramaSlides;
