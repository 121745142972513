import React, { useState } from 'react';
import { IonContent, IonPage, IonModal, IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons'; // Import the close icon
import Header from '../../common/header/Header';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeFourSlider from '../common/ThemeFourSlider';
import getSlides from '../../functions/getSlides';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourTitle from '../common/ThemeFourTitle';

const ThemeFourTrabajanScreen = (props) => {
    const [showModal, setShowModal] = useState(false);
    const operator = CommonService.getLocal('op') || props.data.operator;

    const imageURL = 'https://live.staticflickr.com/65535/53719804552_624bcaae7e_o.png';

    const handleTitleClick = () => {
        setShowModal(true);
    };

    return (
        <IonPage>
            <IonContent>
                <Header />
                <HeroSection
                    heroImage={getIcons('destinationBanner', operator)}
                    title={'Trabaja con nosotros'}
                    description={'Home / Trabaja con nosotros'}
                />
                <div onClick={handleTitleClick} style={{ cursor: 'pointer' }}>
                    <ThemeFourTitle title='Trabaja con nosotros' />
                </div>
                <ThemeFourSlider slides={getSlides('Destination', operator)} />
                <ThemeFourFooter />

                {/* Modal */}
                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} cssClass="modal-centered">
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                            <IonIcon icon={close} onClick={() => setShowModal(false)} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img src={imageURL} alt="Popup Image" />
                        </div>
                    </div>
                </IonModal>

            </IonContent>
        </IonPage>
    );
};

export default connectData()(ThemeFourTrabajanScreen);
