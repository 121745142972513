// passing the path into variables

// const banner1 = '/assets/expressonorte/home/banner1.jpg';
// const banner2 = '/assets/expressonorte/home/banner2.jpg';
// const banner3 = '/assets/expressonorte/home/banner3.jpg';

const destination1 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-1.jpg';
const destination2 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-2.jpg';
const destination3 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-3.jpg';
const destination4 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-4.jpg';
const destination5 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-5.jpg';
const destination6 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-6.jpg';
const destination7 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-7.jpg';
const destination8 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-8.jpg';
const destination9 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-9.jpg';

const destination10 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-10.jpg';
const destination11 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-11.jpg';
const destination12 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-12.jpg';
const destination13 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-13.jpg';
const destination14 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-14.jpg';
const destination15 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-15.jpg';
const destination16 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-16.jpg';
const destination17 =
  '/assets/expressonorte/destinations/ExpresoNorte-WebDesign-Destinations-17.jpg';

const gallery1 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-1.jpg';
const gallery2 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-2.jpg';
const gallery3 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-3.jpg';
const gallery4 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-4.jpg';
const gallery5 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-5.jpg';
const gallery6 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-6.jpg';
const gallery7 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-7.jpg';
const gallery8 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-8.jpg';
const gallery9 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-9.jpg';
const gallery10 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-10.jpg';
const gallery11 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-11.jpg';
const gallery12 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-12.jpg';
const gallery13 =
  '/assets/expressonorte/gallery/ExpresoNorte-WebDesign-Gallery-13.jpg';

// const banner = [banner1, banner2, banner3];

const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
  destination5,
  destination6,
  destination7,
  destination8,
  destination9,
  destination10,
  destination11,
  destination12,
  destination13,
  destination14,
  destination15,
  destination16,
  destination17,
];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
];

const expresonorteSlides = {
  // banner,
  destination,
  gallery,
};

export default expresonorteSlides;
