import React from 'react';
import { connectData } from '../../redux';
import ThemeTenAgencies from '../ThemeTen/ThemeTenPages/ThemeTenAgencies';

const CommonAgenciesPage = props => {
  const getPage = () => {
    const themeType = props.data && props.data.operator && props.data.operator.theme_type;
    if (themeType === 10) {
      return <ThemeTenAgencies />;
    } 
  };

  return <React.Fragment>{getPage()}</React.Fragment>;
};


export default connectData()(CommonAgenciesPage);
