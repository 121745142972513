import React, { PureComponent } from 'react';

import { DateSerivce } from '../services';
import { IonButton } from '@ionic/react';
import KuposCalendar from './KuposCalendar';
import PBCard from './PBCard';
import PBSafeView from './PBSafeView';
import PBText from './PBText';
import PBTouchable from './PBTouchable';
import PButton from './PButton';
import colors from '../theme/colors';

// import PropTypes from 'prop-types';
// import {
//     Platform
// } from 'react-native';
// import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
// import Modal from "react-native-modal";











// header: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     paddingLeft: 0,
//     paddingRight: 0,
//     marginTop: 0,
//     marginHorizontal:0,
//     alignItems: 'center',
//     backgroundColor: appStyle.headerBackgroundColor,
//     borderTopRightRadius: appStyle.borderRadius,
//     borderTopLeftRadius: appStyle.borderRadius
//   },

const calendarTheme = {
	backgroundColor: colors.primaryBG,
	calendarBackground: '#ffffff',
	textSectionTitleColor: colors.primaryBG,
	selectedDayBackgroundColor: '#00adf5',
	selectedDayTextColor: '#ffffff',
	todayTextColor: '#00adf5',
	headerBackgroundColor: colors.primaryBG,
	borderRadius: 5,
	// dayTextColor: '#2d4150',
	// textDisabledColor: '#d9e1e8',
	// dotColor: '#00adf5',
	// selectedDotColor: '#ffffff',
	// arrowColor: 'orange',
	monthTextColor: colors.primaryText,
	indicatorColor: colors.primaryBG,
	// textDayFontFamily: Platform.OS === 'android' ? 'Carnas Regular' : 'Carnas-Regular',
	// textMonthFontFamily:  Platform.OS === 'android' ? 'Carnas Regular' : 'Carnas-Regular',
	// textDayHeaderFontFamily:  Platform.OS === 'android' ? 'Carnas Regular' : 'Carnas-Regular',
	// textDayFontWeight: '300',
	// textMonthFontWeight: 'bold',
	// textDayHeaderFontWeight: '300',
	// textDayFontSize: 16,
	// textMonthFontSize: 16,
	// textDayHeaderFontSize: 16
};

// const Arrow = ({arrowName}) => {
//     return (
//         arrowName == 'left'
//         ?<div style={styles.calArrow}><IonIcon style={styles.showPasswordIcon} name={'ios-arrow-back'} size={18} color={colors.primaryText} /></div>
//         :<div style={styles.calArrow}><IonIcon style={styles.showPasswordIcon} name={'ios-arrow-forward'} size={18} color={colors.primaryText} /></div>
//     )
// }

class PBCalendar extends PureComponent {
	state = {
		showCalendar: true,
	};
	constructor(props) {
		super(props);
	}
	render() {
		let markDate = {};
		markDate[this.props.selectedDate] = {
			selected: true,
			selectedColor: 'orange',
		};
		return this.props.showCalendar ? (
			<div className="pb-modal">
				<div className="pb-modal-content">
					<KuposCalendar
						firstDayOfWeek="mo"
						sunHighlight={true}
						minDateSelection={this.props.minDate}
						deactivatePastDate={true}
						dateSelected={
							this.props.selectedDate ||
							DateSerivce.getTodayString('dd-mm-yyyy')
						}
						onDateSelect={date =>
							this.props.onDateChange(date)
						}
						onMonthChange={d => {
							console.log(d);
						}}
						showClearDate={this.props.showClearDate}
						returnDate={this.props.returnDate}
						operator={this.props.operator}
					/>

					{/* {this.props.showClearDate ? (
                <div className="clear-date">
                  <PBTouchable
                    onPress={() =>
                      this.props.onDateChange(-1)
                    }
                  >
                    <div className="clear-date-inner">
                      <PBText primary={true} center={true} bold={true}>
                        Eliminar fecha
                      </PBText>
                    </div>
                  </PBTouchable>
                </div>
              ) : null} */}
				</div>

				<div
					className="contact-modal-backdrop"
					onClick={this.props.onBackdropPress}
				></div>
			</div>
		) : null;

		// <Modal
		//     style={{ margin: 0 }}
		//     isVisible={this.props.showCalendar}
		//     onBackButtonPress={this.props.onBackButtonPress ? this.props.onBackButtonPress : () => {} }
		//     onBackdropPress={this.props.onBackdropPress ? this.props.onBackdropPress : () => {} }
		//     animationTime={500}
		//     >
		//     <PBSafeView>
		//         <div style={styles.calendarTopBar}>
		//             <div style={styles.calendarTopBarTextContainer}>
		//                 <PBText style={styles.calendarTopBarText}>{this.props.title}</PBText>
		//             </div>
		//             <PBTouchable
		//                 onPress={() => this.props.onDateChange(null)} >
		//                 <div style={styles.calendarTopBarIconContainer}>
		//                     <IonIcon style={styles.showPasswordIcon} name={'md-arrow-back'} size={22} color={colors.primaryText} />
		//                 </div>
		//             </PBTouchable>

		//         </div>
		//         <div style={styles.calendarContainer}>
		//             <PBCard padding={0}>
		//                 {/* <Calendar
		//                     // Handler which gets executed on day press. Default = undefined
		//                     onDayPress={(day) => { this.props.onDateChange(day) }}
		//                     // Handler which gets executed when press arrow icon left. It receive a callback can go back month
		//                     onPressArrowLeft={substractMonth => substractMonth()}
		//                     // Handler which gets executed when press arrow icon left. It receive a callback can go next month
		//                     onPressArrowRight={addMonth => addMonth()}
		//                     current = {this.props.selectedDate}
		//                     markedDates={markDate}
		//                     minDate={this.props.minDate}
		//                     style={{
		//                         borderRadius: 5
		//                     }}
		//                     theme={calendarTheme}
		//                     renderArrow={(direction) => (<Arrow arrowName={direction}/>)}
		//                 /> */}

		//             </PBCard>
		//         </div>
		//             { this.props.dateType == 2 ?
		//                 <PBTouchable
		//                     onPress={() => { this.props.onDateChange(-1) }} >
		//                         <div style={styles.clearDate}>
		//                     <PBText style={styles.clearText}>Borrar fecha</PBText>
		//                     </div>
		//                 </PBTouchable>
		//             : null}
		//         </PBSafeView>
		// </Modal>
	}
}

const styles = {
	calendarContainer: {
		flex: 1,
		backgroundColor: '$pageBG',
	},
	calendarTopBar: {
		flexDirection: 'row',
		backgroundColor: '$primaryBG',
		width: '100%',
		height: 54,
		alignItems: 'center',
	},
	calendarTopBarIconContainer: {
		padding: 15,
		marginLeft: 10,
		zIndex: 9,
	},
	calendarTopBarTextContainer: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		zIndex: 1,
	},
	calendarTopBarText: {
		color: '#fff',
	},
	clearDate: {
		position: 'absolute',
		right: 0,
		top: 0,
		height: 54,
		paddingHorizontal: 20,
		zIndex: 9,
		justifyContent: 'center',
	},
	clearText: {
		color: '#fff',
	},
	calArrow: {
		paddingHorizontal: 10,
	},
};

export default PBCalendar;
