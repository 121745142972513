import "react-multi-carousel/lib/styles.css";

import Carousel from "react-multi-carousel";
import MultiSlideType1 from "./MultiSlideType1";
import React from "react";

const EmailIcon = "/assets/andimar/contact-by/email.svg";
const TelephoneIcon = "/assets/andimar/contact-by/telephone.svg";
const DestinationIcon = "/assets/andimar/destination.svg";
const ClockIcon = "/assets/andimar/favoriteDestinations/andimar-clock.svg";
const BlueArrow = "/assets/andimar/favoriteDestinations/andimar-blue-arrow.svg";




const DestinationIconNorte = "/assets/busnorte/Home/destination.svg";
const MapIconNorte = "/assets/busnorte/Home/map.svg";
const PhoneIconNorte = "/assets/busnorte/Home/telephone.svg";
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
	},
};

const MultiSlider3 = (props) => {
	const operator = JSON.parse(localStorage.getItem("op"));
	return (
		<div className="multi-slider-container-outer">

			{props.andrimarArrowStyle && (
				<style>
					.react-multiple-carousel__arrow {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow:before {"{"}
					color: #161d4f;
					{"}"}
					.react-multiple-carousel__arrow:hover {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow--right{"{"}
					right: 10px;
					{"}"}
					.react-multiple-carousel__arrow--left {"{"}
					background-color: transparent; left: 10px;
					{"}"}
				</style>
			)}
			{props.norteArrowStyle && (
				<style>
					.react-multiple-carousel__arrow {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow:before {"{"}
					color: #d9d629;
					{"}"}
					.react-multiple-carousel__arrow:hover {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow--right{"{"}
					right: 10px;
					{"}"}
					.react-multiple-carousel__arrow--left {"{"}
					background-color: transparent; left: 10px;
					{"}"}
				</style>
			)}
			{props.sanantonioArrowStyle && (
				<style>
					.react-multiple-carousel__arrow {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow:before {"{"}
					color: {operator.theme_color};{"}"}
					.react-multiple-carousel__arrow:hover {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow--right{"{"}
					right: 10px;
					{"}"}
					.react-multiple-carousel__arrow--left {"{"}
					background-color: transparent; left: 10px;
					{"}"}
				</style>
			)}

			{props.aeroquintaArrowStyle && (
				<style>
					.react-multiple-carousel__arrow {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow:before {"{"}
					color: #1a213a;
					{"}"}
					.react-multiple-carousel__arrow:hover {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow--right{"{"}
					right: 0px;
					{"}"}
					.react-multiple-carousel__arrow--left {"{"}
					background-color: transparent; left: 0px;
					{"}"}
				</style>
			)}


			{props.norteArrowStyle && (
				<style>
					.react-multiple-carousel__arrow {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow:before {"{"}
					color: #02602e;
					{"}"}
					.react-multiple-carousel__arrow:hover {"{"}
					background-color: transparent;
					{"}"}
					.react-multiple-carousel__arrow--right{"{"}
					right: 10px;
					{"}"}
					.react-multiple-carousel__arrow--left {"{"}
					background-color: transparent; left: 10px;
					{"}"}
				</style>
			)}


			<div>

				{props.smallHeading && (
					<div className="font10 less-bold-text mb5 text-center">
						{props.smallHeading}
					</div>
				)}

				{props.boldHeading && (
					<h2
						className=""
						style={{
							color:
								operator && operator.theme_color ? operator.theme_color : "",
						}}
					>
						{props.boldHeading}
					</h2>
				)}
			</div>
			<div className="multi-slider-container">
				<Carousel
					responsive={responsive}
					swipeable={false}
					draggable={false}
					showDots={false}
					infinite={true}
					autoPlay={true}
					autoPlaySpeed={3000}
					customTransition="all .5"
					transitionDuration={3000}
					removeArrowOnDeviceType={["desktop", "tablet"]}
				>
					{props.multiSlides &&
						(props.multiSlides.length || []) > 0 &&
						props.multiSlides.map((slide, index) => {
							return props.slideType === 1 ? (
								<MultiSlideType1
									slide={slide}
									itemNo={index}
									width={props.width}
									height={props.height}
								/>
							) : (
								<div style={{ margin: "0 20px" }}>
									{props.textCard ? (
										<MultiSlideType1
											slide={slide.image}
											itemNo={index}
											width={props.width}
											height={props.height}

										/>
									) : (
										<MultiSlideType1
											slide={slide}
											itemNo={index}
											width={props.width}
											height={props.height}

										/>
									)}
									{props.textCard && (
										<div
											style={{
												background: operator && operator.operator_name === "busnorte" ? "#1a4b2e" : "#d9d629",
												padding: 20,
												borderBottomLeftRadius: 10,
												borderBottomRightRadius: 10,
												width: props.width ? props.width : "100%",
												margin: "auto",
											}}
										>
											<div style={{ minHeight: 140 }}>
												<div
													className="font13"
													style={{
														color: operator && operator.operator_name === "busnorte" ? "#fff" : "",
														display: "flex",
														alignItems: "center",
														marginBottom: 15,
														lineHeight: 1.3,
													}}
												>

													{operator && operator.operator_name === 'busnorte'

														? (<img
															src={DestinationIconNorte}
															style={{ width: 15, marginRight: 5 }}
														/>) : (<img
															src={DestinationIcon}
															style={{ width: 25, marginRight: 5 }}
														/>)
													}
													{slide.address}
												</div>

												<div
													className="font13"
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: 15,
														color: operator && operator.operator_name === "busnorte" ? "#fff" : "",
													}}
												>
													{operator && operator.operator_name === 'busnorte'

														? (<img
															src={PhoneIconNorte}
															style={{ width: 15, marginRight: 5 }}
														/>) : (<img
															src={TelephoneIcon}
															style={{ width: 15, marginRight: 5 }}
														/>)
													}

													{slide.phone}
												</div>

												{!props.isSanantonio && <div
													className="font13"
													style={{
														display: "flex",
														alignItems: "baseline",
														marginBottom: 15,
														lineHeight: 1.3,
														color: operator && operator.operator_name === "busnorte" ? "#fff" : "",
													}}
												>
													{operator && operator.operator_name === 'busnorte'

														? ('') : (<img
															src={ClockIcon}
															style={{ width: 15, marginRight: 5 }}
														/>)
													}

													{slide.timing}
												</div>}
											</div>
											<div
												className="font13"
												style={{ display: "flex", alignItems: "center" }}
											>

												{operator && operator.operator_name === 'busnorte'

													? (<img
														src={MapIconNorte}
														style={{ width: 10, marginRight: 5 }}
													/>) : (<img
														src={BlueArrow}
														style={{ width: 15, marginRight: 5 }}
													/>)
												}

												<a
													href={slide.mapLink}
													target="_blank"
													style={{
														textDecoration: "underline",
														color: operator && operator.operator_name === "busnorte" ? "#d9d629" : "#161D4F",
													}}
												>
													Ver mapa
												</a>
											</div>
										</div>
									)}
								</div>
							);
						})}
					{/* <div>Item 1</div>
        <div>Item 2</div>
        <div>Item 3</div>
        <div>Item 4</div> */}
				</Carousel>
			</div>
		</div>
	);


};

export default MultiSlider3;
