
const Banner = '/assets/serenamar/home/banner.png';
const OriginIcon = '/assets/serenamar/home/origin.svg';
const DestinationIcon = '/assets/serenamar/home/destination.svg';
const CalendarIcon = '/assets/serenamar/home/calendar.svg';
const BookingActive = '/assets/serenamar/home/ticket-blue.svg';
const BookingInActive = '/assets/serenamar/home/ticket-white.svg';
const CancelActive = '/assets/serenamar/home/cancel-blue.svg';
const CancelInActive = '/assets/serenamar/home/cancel-white.svg';

const HeaderLogo = '/assets/serenamar/home/serena-mar-logo.svg';
const FooterLogo = '/assets/serenamar/home/serenamar-logo-white.svg';
const BG = '/assets/serenamar/home/mobile-banner.jpg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const pnr = '/assets/serenamar/home/find-my-pnr.svg';
const PopupIcon = '/assets/serenamar/home/banner-popup.jpg';
const animation = '/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const Whatsapp = '/assets/serenamar/icons/whatsapp.svg';
const Email = '/assets/serenamar/icons/email.svg';
const HeadPhone = '/assets/serenamar/icons/headphone.svg';
const MenuDot = '/assets/serenamar/icons/serenamar-sidemenu.svg';
const BottomFooterKuposLogo = '/assets/serenamar/Footer-kuposLogo.svg';
const close = '/assets/serenamar/icons/close.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png'; 
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';


const operatorPay ='/assets/serenamar/home/Logo-SerenaPay-240x52.png';

const serenamarIcons = [
  { icon: operatorPay, name: 'operatorPay' },

  { icon: HeaderLogo, name: 'headerLogo' },
  { icon: FooterLogo, name: 'footerLogo' },
  { icon: Banner, name: 'Banner' },
  { icon: OriginIcon, name: 'Origin' },
  { icon: DestinationIcon, name: 'Destination' },
  { icon: CalendarIcon, name: 'Calendar' },
  { icon: BookingInActive, name: 'BIA' },
  { icon: BookingActive, name: 'BA' },
  { icon: CancelInActive, name: 'CIA' },
  { icon: CancelActive, name: 'CA' },
  { icon: BG, name: 'BG' },
  { icon: checkboxIcon, name: 'Checkbox' },
  { icon: animation, name: 'animation' },
  { icon: pnr, name: 'pnr' },
  { icon: PopupIcon, name: 'Popup' },
  { icon: Whatsapp, name: 'Whatsapp' },
  { icon: Email, name: 'Email' },
  { icon: HeadPhone, name: 'Headphone' },
  { icon: MenuDot, name: 'menuDot' },
  { icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
  { icon: close, name: 'close' },

    // accounts section
    { icon: pay, name: 'pay' },
    { icon: backimage, name: 'backimage' },
    { icon: tarjetas, name: 'tarjetas' },
    { icon: viajes, name: 'viajes' },
    { icon: movimientos, name: 'movimientos' },
    { icon: wallet, name: 'wallet' },
    { icon: shareMoney, name: 'shareMoney' },
    { icon: camera, name: 'camera' },
    { icon: checkmark, name: 'checkmark' },
    { icon: tick, name: 'tick' },
    { icon: downArrow, name: 'downArrow' },
    { icon: loginblue, name: 'loginblue' },
    { icon: recoverpswd, name: 'recoverpswd' },
    { icon: success, name: 'success' },
    { icon: failure, name: 'failure' },
    { icon: heartOn, name: 'heartOn' },
    { icon: heartOff, name: 'heartOff' },
    { icon: registrationSuccess, name: 'registrationSuccess' },
    { icon: userIcon, name: 'userIcon' },
    { icon: warning, name: 'warning' },
]

export default serenamarIcons