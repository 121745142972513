import React from 'react';
import { connectData } from '../../redux';
import AndimarDestinations from '../theme1/AndimarPages/AndimarDestinations';
import ThemeFiveDestinationScreen from '../theme5/ThemeFivePages/ThemeFiveDestinationScreen';
import ThemeFourDestinationScreen from '../ThemeFour/ThemeFourPages/ThemeFourDestinationScreen';
import ThemeTenDestinations from '../ThemeTen/ThemeTenPages/ThemeTenDestinations';

const CommonDestinationPage = (props) => {
  const getPage = () => {
    const themeType =
      props.data && props.data.operator && props.data.operator.theme_type;
    if (themeType === 1) {
      return <AndimarDestinations />;
    } else if (themeType === 5) {
      return <ThemeFiveDestinationScreen />;
    } else if (themeType === 4) {
      return <ThemeFourDestinationScreen />;
    } else if (themeType === 10) {
      return <ThemeTenDestinations />;
    }
  };
  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonDestinationPage);
