const destination1 =
  '/assets/santajuana/General/carrusel-1-nosotros.png';
const destination2 =
  '/assets/santajuana/General/carrusel-2-nosotros.png';
const destination3 =
  '/assets/santajuana/General/carrusel-3-nosotros.png';

// const banner = [banner1, banner2, banner3];

const destination = [destination1, destination2, destination3,];

// const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

const santajuanaSlides = {
  // banner,
  destination,
  // gallery
};

export default santajuanaSlides;
