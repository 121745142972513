import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import { connectData } from '../../../redux';
import { IonContent, IonPage } from '@ionic/react';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import Header from '../../common/header/Header';
import Footer2 from '../../common/footer/Footer2';
import getSlides from '../../functions/getSlides';
import getIcons from '../../functions/getIcons/getIcons';

function ThemeFiveGalleryScreen() {
  const operator = JSON.parse(localStorage.getItem('op'));

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('galleryBanner')}
          height={'30vh'}
          // height={'50vh'}
          title={'Galería'}
          description={'Home / Galería'}
        />

        <MultiSlider
          // smallHeading="Conoce"
          boldHeading='NUESTRA FLOTA'
          multiSlides={getSlides('Gallery')}
          width={'92%'}
        />
        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(ThemeFiveGalleryScreen);
