import 'react-multi-carousel/lib/styles.css';

import Carousel from 'react-multi-carousel';
import MultiSlideType1 from './MultiSlideType1';
import React from 'react';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const MultiSlider = (props) => {
  const operator = JSON.parse(localStorage.getItem('op'));
  return (
    <div className='multi-slider-container-outer'>
      <div>
        {props.smallHeading && (
          <div className='font10 less-bold-text mb5 text-center'>
            {props.smallHeading}
          </div>
        )}

        {props.boldHeading && (
          <h2
            className=''
            style={{
              color:
                operator &&
                operator.color_codes &&
                operator.color_codes.text_color
                  ? operator.color_codes.text_color
                  : '',
            }}
          >
            {props.boldHeading}
          </h2>
        )}
      </div>
      <div className='multi-slider-container'>
        <Carousel
          responsive={responsive}
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          customTransition='all .5'
          transitionDuration={3000}
          removeArrowOnDeviceType={['desktop', 'tablet']}
        >
          {props.multiSlides &&
            (props.multiSlides.length || []) > 0 &&
            props.multiSlides.map((slide, index) => {
              return props.slideType === 1 ? (
                <MultiSlideType1
                  slide={slide}
                  itemNo={index}
                  width={props.width}
                />
              ) : (
                <MultiSlideType1
                  slide={slide}
                  itemNo={index}
                  width={props.width}
                />
              );
            })}
          {/* <div>Item 1</div>
        <div>Item 2</div>
        <div>Item 3</div>
        <div>Item 4</div> */}
        </Carousel>
      </div>
    </div>
  );
};

export default MultiSlider;
