import AndimarOffices from '../theme1/AndimarPages/AndimarOffices';
import React from 'react';
import ThemeFiveOfficesScreen from '../theme5/ThemeFivePages/ThemeFiveOfficesScreen';
import ThemeFourOfficeScreen from '../ThemeFour/ThemeFourPages/ThemeFourOfficeScreen';
import ThemeTenOffices from '../ThemeTen/ThemeTenPages/ThemeTenOffices';
import ThemeThreeOffices from '../ThemeThree/ThemeThreePages/ThemeThreeOffices';
import ThemeTwoOfficesScreen from '../ThemeTwo/ThemeTwoOfficesScreen';
import { connectData } from '../../redux';

const CommonOfficesPage = (props) => {
	const getPage = () => {
		const themeType =
			props.data && props.data.operator && props.data.operator.theme_type;
		if (themeType === 1) {
			return <AndimarOffices />;
		} else if (themeType === 5) {
			return <ThemeFiveOfficesScreen />;
		} else if (themeType === 4) {
			return <ThemeFourOfficeScreen />;
		} else if (themeType === 10) {
			return <ThemeTenOffices />;
		} else if (themeType === 2) {
			return <ThemeTwoOfficesScreen props={props} />
		}
		else if (themeType === 3) {
			return <ThemeThreeOffices props={props} />
		}
	};

	return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonOfficesPage);
