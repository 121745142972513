// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  PBOTPInput,
  CarpoolSuccessFailureModal,
  View,
} from "../../components";
import { commonStyles, dimensions, colors } from "../../theme";
import { CommonService } from "../../services";
import { IonPage, IonContent, IonToast } from "@ionic/react";
import getIcons from "../../themes/functions/getIcons/getIcons";

class OtpChangePasswordScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      otpError: null,
      isOtpVerified: false,
      codeValue: null,
      otpKey: 1,
      showIndicator: true,
      firstTime: true,
    };
    console.log("PROPS DATA ==", this.props.data);

  }

  componentDidMount() {
    // this.refs.nameInput.focus();
    // document.getElementById("otp1").focus();
  }

  componentWillUnmount() {
    clearInterval(this.smsInterVal);
  }

  passwordChange = (text) => {
    this.setState({
      passwordError: null,
      password: text,
    });
  };

  confirmPasswordChange = (text) => {
    this.setState({
      confirmPasswordError: null,
      confirmPassword: text,
    });
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "password") {
        this.setState({
          passwordError: {
            message: "Ingresa tu contraseña",
          },
        });
      } else if (inp == "confirm_password") {
        this.setState({
          confirmPasswordError: {
            message: "Confirma tu contraseña",
          },
        });
      }
    } else if (inp == "password") {
      if (this.state.password.length < 4) {
        this.setState({
          passwordError: {
            message: "La contraseña debe contener mínimo 4 dígitos",
          },
        });
      }
    } else if (inp == "confirm_password") {
      if (this.state.password != this.state.confirmPassword) {
        this.setState({
          confirmPasswordError: {
            message: "Las contraseñas deben coincidir",
          },
        });
      }
    }
  };

  validateOtp = () => {
    console.log("PROPS DATA", this.props);
    let errorCount = 0;
    if (
      !this.state.otp1 ||
      !this.state.otp2 ||
      !this.state.otp3 ||
      !this.state.otp4
    ) {
      // if (!this.state.otp) {
      //   // this.setState({
      //   //     otpError:{
      //   //         message:'Introduzca su número de móvil'
      //   //     }
      //   // })
      //   errorCount++;
      // } else if (this.state.otp.toString().length != 4) {
      //   // this.setState({
      //   //     otpError:{
      //   //         message:'Introduzca su número de móvil de 9 dígitos'
      //   //     }
      //   // })
      //   errorCount++;
      // }
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }
    if (this.smsInterVal) clearInterval(this.smsInterVal);
    try {
      this.props.validateOtp({
        data: {
          country_code: this.props.data.registerUserPhoneDataForOtp.country,
          mobile_number: this.props.data.registerUserPhoneDataForOtp.mobile,
          cat_type: "change_pwd_valid",
          code:
            this.state.otp1 +
            "" +
            this.state.otp2 +
            "" +
            this.state.otp3 +
            "" +
            this.state.otp4,
										operator_id: this.props.data.operator.is_pay_enabled === true ? this.props.data.operator.operator_id : null
        },
        callback: (res) => {
          console.log(res);
          if (res.success && res.data && res.data.status === 200) {
            this.setState({
              isOtpVerified: true,
            });
            // this.refs.toast.show("Número de teléfono verificado con éxito");
          } else {
            this.setState({
              showSuccessFailureModal: true,
              showSuccessFailureModalStatus: false,
              showSuccessFailureModalBodyText: res.data.message,
              otpVerification: true,
            });
          }
        },
      });
    } catch (e) {
      console.log(e);
    }

    // this.props.forgotPassword({callback:(result) => this.onResponse(result),data:data})
  };

  generateOtp = () => {
    try {
      this.props.generateOtp({
        data: {
          country_code:
            "+" +
            this.props.data.registerUserPhoneDataForOtp.country.callingCode,
          mobile_number: this.props.data.registerUserPhoneDataForOtp.mobile,
          cat_type: "change_pwd_valid",
        },
        callback: (res) => {
          console.log(res);
          if (res.success && res.data && res.data.status === 200) {
            // this.setState({
            //   showSuccessFailureModal: true,
            //   showSuccessFailureModalStatus: true,
            //   showSuccessFailureModalBodyText: res.data.message,
            // });
            this.setState({
              otp1: null,
              otp2: null,
              otp3: null,
              otp4: null,
              otpKey: this.state.otpKey + 1,
              showToast: true,
              toastMessage: res.data.message,
            });
          } else {
            this.setState({
              showSuccessFailureModal: true,
              showSuccessFailureModalStatus: false,
              showSuccessFailureModalBodyText: res.data.message,
            });
          }
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  changePassword = () => {
    let errorCount = 0;
    if (!this.state.password) {
      this.setState({
        passwordError: {
          message: "Ingresa tu contraseña",
        },
      });
      errorCount++;
    } else if (this.state.password.length < 4) {
      this.setState({
        passwordError: {
          message: "La contraseña debe contener mínimo 4 dígitos",
        },
      });
      errorCount++;
    }
    if (this.state.password != this.state.confirmPassword) {
      this.setState({
        confirmPasswordError: {
          message: "Las contraseñas deben coincidir",
        },
      });
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }
    let data = {
      country_code:
        "+" + this.props.data.registerUserPhoneDataForOtp.country.callingCode,
      mobile_number: this.props.data.registerUserPhoneDataForOtp.mobile,
      cat_type: "change_pwd",
						operator_id: this.props.data.operator.is_pay_enabled === true ? this.props.data.operator.operator_id : null,
      user: {
        password: this.state.password,
        password_confirmation: this.state.confirmPassword,
      },
    };
    this.props.validateOtp({
      data: data,
      callback: (res) => {
        console.log(res);
        if (res.success && res.data && res.data.status === 200) {
          this.setState({
            showSuccessFailureModal: true,
            showSuccessFailureModalStatus: true,
            showSuccessFailureModalBodyText: res.data.message,
            otpVerification: false,
          });
          // this.refs.toast.show(res.data.message);
        } else {
          this.setState({
            showSuccessFailureModal: true,
            showSuccessFailureModalStatus: false,
            showSuccessFailureModalBodyText: res.data.message,
            otpVerification: false,
          });
        }
      },
    });
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        showRegisterLink: true,
      });
      return;
    } else if (result.data.message) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        modalBody: result.data.message,
        modalIcon: "/assets/icons/success.png",
        showRegisterLink: false,
      });
    }
  };

  goBack = () => {
    let modalTitle = this.state.modalTitle;
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    if (modalTitle != "¡Lo Sentimos!") this.props.history.goBack();
  };

  _onFulfill = (code) => {
    // TODO: call API to check code here
    // If code does not match, clear input with: this.refs.codeInputRef1.clear()

    // if (code == 'Q234E') {
    //   Alert.alert(
    //     'Confirmation Code',
    //     'Successful!',
    //     [{text: 'OK'}],
    //     { cancelable: false }
    //   );
    // } else {
    //   Alert.alert(
    //     'Confirmation Code',
    //     'Code not match!',
    //     [{text: 'OK'}],
    //     { cancelable: false }
    //   );

    //   this.refs.codeInputRef1.clear();
    // }
    this.setState({
      otp: code,
    });
    // this.refs.toast.show(code);
  };

  onOtpChange = (position, el) => {
    switch (position) {
      case 1:
        this.setState({
          otp1: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp2.focus();
        else this.refs.otp1.focus();
        break;
      case 2:
        this.setState({
          otp2: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp3.focus();
        // else this.refs.otp1.focus();
        break;
      case 3:
        this.setState({
          otp3: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp4.focus();
        // else this.refs.otp2.focus();
        break;
      case 4:
        this.setState({
          otp4: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp4.focus();
        // else this.refs.otp3.focus();
        break;
    }
    console.log("OTP Position", position);
    console.log(
      "OTPs",
      this.state.otp1,
      this.state.otp2,
      this.state.otp3,
      this.state.otp4
    );
  };

  goToHome = () => {
    this.setState({
      showSuccessFailureModal: false,
      showSuccessFailureModalBodyText: null,
    });
    this.props.history.push("/");
  };

  render() {
    const selectedTxn = this.props.selectedTxn;
    return (
      <IonPage>
        <IonContent forceOverscroll={false}>
          <View>
            <CarpoolHeader
              backIcon={true}
              componentId={this.props.componentId}
              label=" "
              labelDark={true}
              history={this.props.history}
            />
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    marginHorizontal: "32vw",
                    width: "28vw",
                  }}
                  // src={"/assets/carpool/forgot-circle.png"}
                  src={getIcons('recoverpswd')}
                  alt=''
                />
              </View>

              <View style={{ alignItems: "center" }}>
                <PBText
                  dark={true}
                  bold={true}
                  style={{ textAlign: "center", fontSize: 15 }}
                >
                  {this.state.isOtpVerified
                    ? "Cambia tu contraseña"
                    : "Verifica tu teléfono"}
                </PBText>
              </View>
              <View
                style={{
                  alignItems: "center",
                  paddingHorizontal: 50,
                  marginTop: 10,
                }}
              >
                <PBText
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    padding: "0px 20px",
                  }}
                >
                  {this.state.isOtpVerified
                    ? `Elige una contraseña de mínimo 4 carácteres\npara acceder a tu cuenta`
                    : "Ingresa el código de 4 dígitos que enviamos al número registrado."}
                </PBText>
              </View>
              <View className="kupos-card">
                {!this.state.isOtpVerified ? (
                  <View style={styles.inputContainer}>
                    <View style={{ alignItems: "center" }}>
                      <View className="flex-row otp-inps">
                        <input
                          id="otp1"
                          ref="otp1"
                          value={this.state.otp1}
                          autoCorrect={false}
                          type="password"
                          onKeyUp={(el) => {
                            let key = el.keyCode || el.charCode;
                            if (key == 39) this.refs.otp2.focus();
                          }}
                          onChange={(el) => this.onOtpChange(1, el)}
                        />
                        <input
                          id="otp2"
                          ref="otp2"
                          value={this.state.otp2}
                          autoCorrect={false}
                          type="password"
                          onKeyUp={(el) => {
                            let key = el.keyCode || el.charCode;
                            if (key == 8 || key == 46) this.refs.otp1.focus();
                            if (key == 37) this.refs.otp1.focus();
                            if (key == 39) this.refs.otp3.focus();
                          }}
                          onChange={(el) => this.onOtpChange(2, el)}
                        />
                        <input
                          id="otp3"
                          ref="otp3"
                          value={this.state.otp3}
                          autoCorrect={false}
                          type="password"
                          onKeyUp={(el) => {
                            let key = el.keyCode || el.charCode;
                            if (key == 8 || key == 46) this.refs.otp2.focus();
                            if (key == 37) this.refs.otp2.focus();
                            if (key == 39) this.refs.otp4.focus();
                          }}
                          onChange={(el) => this.onOtpChange(3, el)}
                          // onChange={(el) => this.setState({ otp3: CommonService.phoneNumberFormat(el.target.value,1) })}
                        />
                        <input
                          id="otp4"
                          ref="otp4"
                          value={this.state.otp4}
                          autoCorrect={false}
                          type="password"
                          onKeyUp={(el) => {
                            let key = el.keyCode || el.charCode;
                            if (key == 8 || key == 46) this.refs.otp3.focus();
                            if (key == 37) this.refs.otp3.focus();
                          }}
                          onChange={(el) => this.onOtpChange(4, el)}
                          // onChange={(el) => this.setState({ otp4: CommonService.phoneNumberFormat(el.target.value,1) })}
                        />
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={styles.passwordsContainer}>
                    <View style={styles.inputContainer}>
                      <PBInput
                        password={true}
                        placeholder="Ingresa tu nueva contraseña"
                        value={this.state.password}
                        onTextChange={(text) => this.passwordChange(text)}
                        onBlur={(text) => this.onBlur(text, "password")}
                        error={this.state.passwordError ? true : false}
                        errorMessage={
                          this.state.passwordError
                            ? this.state.passwordError.message
                            : ""
                        }
                        style={commonStyles.carpoolInput}
                        textStyle={styles.fontSize}
                      ></PBInput>
                    </View>
                    <View style={styles.inputContainer}>
                      <PBInput
                        password={true}
                        placeholder="Confirma tu nueva contraseña"
                        value={this.state.confirmPassword}
                        onTextChange={(text) =>
                          this.confirmPasswordChange(text)
                        }
                        onBlur={(text) => this.onBlur(text, "confirm_password")}
                        error={this.state.confirmPasswordError ? true : false}
                        errorMessage={
                          this.state.confirmPasswordError
                            ? this.state.confirmPasswordError.message
                            : ""
                        }
                        style={commonStyles.carpoolInput}
                        textStyle={styles.fontSize}
                      ></PBInput>
                    </View>
                  </View>
                )}

                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={
                      this.state.isOtpVerified
                        ? this.changePassword
                        : this.validateOtp
                    }
                    // style={commonStyles.carpoolButtom}
                    noGradient={true}
                    title={"CONTINUAR"}
                  />
                </View>
              </View>

              {!this.state.isOtpVerified ? (
                <View
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginHorizontal: 20,
                  }}
                >
                  <PBText style={{ marginRight: 5, fontSize: 13 }}>
                    ¿No recibiste el código?
                  </PBText>
                  <a
                    onClick={() => {
                      this.generateOtp();
                    }}
                  >
                    <PBText primary={true}>Reenvíalo aquí.</PBText>
                  </a>
                </View>
              ) : null}
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={
                !this.state.showRegisterLink ? this.goBack : this.register
              }
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <a onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </a>
                </View>} */}
            </PBModal>
            <CarpoolSuccessFailureModal
              showModal={this.state.showSuccessFailureModal}
              success={this.state.showSuccessFailureModalStatus}
              smallIcon={true}
              bodyText={this.state.showSuccessFailureModalBodyText}
              buttonText={"OK, CONTINUAR"}
              onButtonPress={() => {
                this.state.showSuccessFailureModalStatus
                  ? this.goToHome()
                  : this.setState({
                      showSuccessFailureModal: false,
                      showSuccessFailureModalBodyText: null,
                    });
              }}
            />
            {/* <Toast ref="toast" /> */}
          </View>
        </IonContent>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() => this.setState({ showToast: false })}
          message={this.state.toastMessage}
          duration={2000}
        />
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 12,
  },
  buttonContainer: {
    marginTop: 10,
  },
};

export default connectData()(OtpChangePasswordScreen);
