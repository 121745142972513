import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeroSection from '../../common/HeroSection/HeroSection';
import { connectData } from '../../../redux';
import Footer1 from '../../common/footer/Footer1';
import Header from '../../common/header/Header';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeTenTitle from './ThemeTenTitle';


const ThemeTenTermsAndConditions = (props) => {
  const createMarkup = (data) => {
    return { __html: data };
  };

  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('TermsBanner',operator)}
          height={'45vh'}
          title={'Términos y condiciones'}
          description={'Home / Términos y  condiciones'}
        />
        
        <ThemeTenTitle 
          title={'TÉRMINOS Y CONDICIONES'} 
          fontSize={'16px'}
        />

        <div style={{ width: '90%', marginLeft: '5%' }}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              operator && operator.terms ? operator.terms : ''
            )}
            className='lh-1-3'
            style={{ fontSize: '13px' }}
          />
        </div>

        <Footer1 operator={operator} isSanantonio />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTenTermsAndConditions);
