import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { dimensions, commonStyles } from "../../theme";
import { CommonService, DateSerivce } from "../../services";
import {
  BGCarpoolInnerBottom,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  View,
  PBModalSliding,
  NoData,
} from "../../components";
import {
  IonPage,
  IonContent,
  IonDatetime,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";

const month = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
const year = [2020, 2019, 2018, 2017];

class CarpoolVirtualMoneyScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      date: DateSerivce.getToday(),
      month: DateSerivce.getMonthNameFromDate(
        DateSerivce.getToday(),
        "yyyy-mm-dd",
        "full"
      ),
      year: DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      selectedData: [
        DateSerivce.getMonthNameFromDate(
          DateSerivce.getToday(),
          "yyyy-mm-dd",
          "full"
        ),
        DateSerivce.getYearFromDate(DateSerivce.getToday(), "yyyy-mm-dd"),
      ],
      showInfoModal: false,
    };
    const operator = JSON.parse(localStorage.getItem('op')) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator)
    }
    this.props.getCustomerProfile({
      callback: (result) => this.onProfileLoad(result),
      operator_id: query,
      hideLoader: true,
    });
    this.props.getVirtualMoney({
      callback: (result) => this.onHistoryLoad(result),
      path:
        "month_str=" +
        DateSerivce.getMonthYearFromDate(this.state.date, "yyyy-mm-dd"),
    });
    // CommonService.bindNav(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.onpopstate = (e) => {
      //your code...
      if (this._isMounted) {
        console.log(e);
        var link = document.getElementsByTagName("ion-backdrop");
        if (link && link[0]) {
          link[0].click();
          setTimeout(() => {
            // this.props.history.push('/transactions')
            // window.history.pushState(null, document.title, '/transactions');
          }, 250);
        }
      }
    };
  }

  refresh = (event) => {
    // return new Promise((resolve) => {
    this.props.getVirtualMoney({
      callback: (result) => {
        // resolve();
        event.detail.complete();
      },
      path:
        "month_str=" +
        (month.indexOf(this.state.selectedData[0]) + 1) +
        "-" +
        this.state.selectedData[1],
    });
    // });
  };

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }

  //   refresh = () => {
  //     return new Promise((resolve) => {
  //         this.props.getVirtualMoney({callback:(result) => {
  //             resolve();
  //         },path:'month_str='+(month.indexOf(this.state.selectedData[0])+1)+'-'+this.state.selectedData[1]});
  //     });
  //   }

  buttonReload = () => {
    this.props.getVirtualMoney({
      callback: (result) => {},
      path:
        "month_str=" +
        (month.indexOf(this.state.selectedData[0]) + 1) +
        "-" +
        this.state.selectedData[1],
    });
  };

  onProfileLoad = (result) => {
    if (result.statusCode == 200) {
      CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
    }
  };

  onHistoryLoad = (result) => {
    if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "../../../assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  editProfile = () => {
    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,LOGIN_SCREEN,'Ingresa a tu cuenta');
  };

  txnDetailsScreen = (item) => {
    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,TXN_DETAILS_SCREEN,'Detalle de la transacción',{selectedTxn:item});
  };

  historyScreen = () => {
    // Picker.hide();
    // CommonService.goToScreen(this.props.componentId,LOGIN_SCREEN,'Ingresa a tu cuenta');
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      authFailed: false,
      modalButtonText: "",
      modalButtonTap: null,
      modalBackButtonPress: null,
    });
  };

  goToHome = () => {
    // Picker.hide();
    this.closeModal();
    CommonService.goToHome(this.props.componentId);
  };

  goToLogin = () => {
    // Picker.hide();
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      this.props.history.push("/login-options");
      // setTimeout(
      //     () => {
      //         this.props.history.push('/login-options')
      // }, 150);
    }
  };

  //   showPicker = () => {
  //     this.setState({
  //         showPickerModal:true
  //     })
  //     let data = [];

  //     data = [month,year];

  //     Picker.init({
  //         pickerConfirmBtnColor:[255,0,20,1],
  //         pickerCancelBtnColor:[255,0,20,1],
  //         pickerData: data,
  //         selectedValue: [this.state.month,this.state.year],
  //         pickerTitleText:'Seleccione',
  //         pickerCancelBtnText:'Cancelar',
  //         pickerConfirmBtnText:'Confirmar',
  //         onPickerConfirm: data => {
  //             console.log(data);
  //             this.setState({
  //                 month:data[0],
  //                 year:data[1],
  //                 selectedData:data,
  //                 showPickerModal:false
  //             })
  //             this.props.getVirtualMoney({callback:(result) => this.onHistoryLoad(result),path:'month_str='+(month.indexOf(data[0])+1 <= 9 ? '0' + (month.indexOf(data[0])+1) : month.indexOf(data[0])+1 )+'-'+data[1]});
  //         },
  //         onPickerCancel: data => {
  //             console.log(data);
  //             this.setState({
  //                 showPickerModal:false
  //             })
  //         },
  //         onPickerSelect: data => {
  //             // Picker.hide();
  //             // this.setState({
  //             //     month:data[0],
  //             //     year:data[1],
  //             //     selectedData:data
  //             // })
  //             // this.props.getVirtualMoney({callback:(result) => this.onHistoryLoad(result),path:'month_str='+(month.indexOf(data[0])+1 <= 9 ? '0' + (month.indexOf(data[0])+1) : month.indexOf(data[0])+1 )+'-'+data[1]});
  //         }
  //     });
  //     setTimeout(() => {
  //         Picker.show();
  //     },100)
  //   }

  changeDate = (val) => {
    // console.log("Value Selected", val)

    this.setState({
      month: DateSerivce.getMonthNameFromDate(val, "yyyy-mm-dd", "full"),
      year: val.split("-")[0],
      selectedData: [
        DateSerivce.getMonthNameFromDate(
          val.split("-")[1] + 1,
          "yyyy-mm-dd",
          "full"
        ),
        val.split("-")[0],
      ],
    });

    this.props.getVirtualMoney({
      callback: (result) => this.onHistoryLoad(result),
      path: "month_str=" + val.split("-")[1] + "-" + val.split("-")[0],
    });
  };

  renderHeader = () => {
    return (
      <View>
        <View style={{ marginBottom: 15 }}>
          <View className="promotion-header-amount-container">
            <PBText
              fontStyle={"bold-text"}
              fontColor={"green-font"}
              fontSize={"font20"}
              textAlign={"text-center"}
            >
              {this.props.data.loginData && this.props.data.loginData.balances
                ? CommonService.currency(
                    this.props.data.loginData.balances.clp.credit
                  ).split(",")[0]
                : "$0.0"}
            </PBText>
          </View>
          <View>
            <PBText
              fontStyle={"bold-text"}
              fontColor={"dark-font"}
              textAlign={"text-center"}
              fontSiz={"font12"}
            >
              Balance de créditos
            </PBText>
          </View>
        </View>

        <View className="transactions-header-container">
          <PBTouchable
            className="full-flex"
            // onPress={this.showPicker}
          >
            <View className="transactions-header-inner">
              <View className="display-flex">
                <PBText
                  fontStyle={"bold-text"}
                  fontSize={"font14"}
                  fontColor={"white-font"}
                  style={{ marginRight: 10 }}
                >
                  {CommonService.capitalize(this.state.month)}
                </PBText>
                <PBText
                  fontStyle={"bold-text"}
                  fontSize={"font14"}
                  fontColor={"white-font"}
                  style={{ marginRight: 10 }}
                >
                  {this.state.year}
                </PBText>
              </View>
              <img width={16} src="../../assets/icons/arrow-down-white.png" />
            </View>
          </PBTouchable>

          <div className="transactions-deep_date-picker">
            <IonDatetime
              doneText="OK"
              cancelText="Cancelar"
              displayFormat="MMM YYYY"
              placeholder="Select Date"
              onIonChange={(e) => this.changeDate(e.detail.value)}
              monthShortNames={month}
              // onIonChange={e => console.log(e.detail.value)}
            ></IonDatetime>
          </div>
        </View>
      </View>
    );
  };

  shareMessage = () => {
    console.log(
      "Invite data",
      this.props.data.loginData.campaign_info.short_desc,
      this.props.data.loginData.campaign_info.invite_url
    );
    let message = this.props.data.loginData.campaign_info.short_desc;

    console.log("Message builded is", message);

    navigator
      .share({
        title: "Kupos invitaion!",
        // url: `${message}`,
        url: this.props.data.loginData.campaign_info.invite_url,
        text: `${message}`,
      })
      .then(() => console.log("Successful share! 🎉"))
      .catch((err) => console.error(err));
  };

  renderTransactions = () => {
    return (
      <View className="promotion-txns">
        {this.props.data.virtualMoney && this.props.data.virtualMoney.length ? (
          <View className="promotion-transaction-container position-relative">
            {this.props.data.virtualMoney.map((item, i) =>
              this.renderVirtualMoneyItem(item, i)
            )}
          </View>
        ) : (
          <View>
            <NoData
              icon={"/assets/carpool/gift-box-grey-circle.png"}
              title={"¡Aún no has recibido KuposCréditos!"}
              desc={"Comparte tu código y gana créditos para ti."}
              buttonText={"¡Comparte con tus amigos aquí!"}
              onPress={this.shareMessage}
            />
          </View>
        )}
      </View>
    );
  };

  renderVirtualMoneyItem = (item, i) => {
    return (
      <View className="promotion-txn-item">
        <View className="promotion-amount">
          <PBText
            fontSize={"font12"}
            fontColor={item.type == "credit" ? "green-font" : "secondary-font"}
            fontStyle={"bold-text"}
          >
            {item.amount}
          </PBText>
          {item.type == "credit" ? (
            <View className="my-account-flex-end">
              <img src="../../assets/carpool/arrow_green.png" size={14} />
            </View>
          ) : (
            <View className="my-account-flex-end">
              <img src="../../assets/carpool/arrow_red.png" size={14} />
            </View>
          )}
        </View>
        <View className="promotion-txn-name">
          <PBText fontStyle={"bold-font"} fontSize={"font12"}>
            {CommonService.capitalize(item.category_info.label)}
          </PBText>
          <PBText fontSize={"font12"}>
            {DateSerivce.getVirtualMoneyListDateFromDate(
              item.created_date,
              "yyyy-mm-dd"
            )}
          </PBText>
        </View>
        <View className="promotion-txn-exp">
          <PBText fontSize={"font12"}>
            {!item.expire_info.expired ? "Vence el" : "Vencido el"}
          </PBText>
          <PBText fontSize={"font12"}>
            {DateSerivce.changeDateFormat(
              item.expire_info.expires_at,
              "yyyy-mm-dd",
              "dd/mm/yyyy"
            )}
          </PBText>
        </View>
      </View>
    );
  };

  showInfo = () => {
    this.setState({
      showInfoModal: true,
    });
  };

  renderInfoModal = () => {
    return (
      <PBModalSliding
        showModal={this.state.showInfoModal}
        onBackButtonPress={() => this.setState({ showInfoModal: false })}
        onBackdropPress={() => this.setState({ showInfoModal: false })}
        onSwipeComplete={() => this.setState({ showInfoModal: false })}
        swipeDirection="down"
      >
        <View className="promotion-info-modal-content">
          <View className="promotion-modal-icon-container">
            <img src="../../assets/carpool/coins-circle.png" />
          </View>
          <View>
            <PBText
              fontColor={"dark-font"}
              fontStyle={"bold-text"}
              fontSize={"font16"}
              textAlign={"text-center"}
              style={{ marginBottom: 20 }}
            >
              {"¿Qué son tus KuposCréditos?"}
            </PBText>
            <PBText
              fontColor={"light-grey-font"}
              textAlign={"text-center"}
              style={{ marginBottom: 20 }}
            >
              {"Comienza a acumularlos y ahorra en tus viajes."}
            </PBText>
            <PBText
              fontColor={"light-grey-font"}
              textAlign={"text-center"}
              style={{ marginBottom: 20 }}
            >
              {
                "Tus KuposCréditos son la moneda de kupos.cl, y puedes utilizarlos para compras dentro de nuestra app. Estos créditos vencen así que, ¡apúrate utilizándolos!"
              }
            </PBText>
          </View>
        </View>
      </PBModalSliding>
    );
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <View className="full-flex promotion-full-screen">
            <BGCarpoolInnerBottom />

            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                />
              </div>
              <div className="my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}></PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
                  fontStyle={"bold-font"}
                >
                  Promociones
                </PBText>
              </div>
              <div onClick={this.showInfo} className="my-account-flex-end">
                <img src="../../assets/carpool/icon_info.png"></img>
              </div>
            </div>

            <div
            // onRefresh={this.refresh}
            >
              {this.renderHeader()}
              {this.renderTransactions()}

              <PBModal
                showModal={this.state.showModal}
                titleText={this.state.modalTitle}
                bodyText={this.state.modalBody}
                type="alert"
                showButton1={true}
                button1Text={this.state.modalButtonText}
                button1Press={
                  this.state.modalButtonTap
                    ? this.state.modalButtonTap
                    : this.closeModal
                }
                icon={this.state.modalIcon}
                onBackButtonPress={
                  this.state.modalBackButtonPress
                    ? this.state.modalBackButtonPress
                    : this.goBack
                }
              />
            </div>

            {/* <Modal
                backdropOpacity={.4}
                style={{ margin: 0,marginBottom:200 }}
                isVisible={this.state.showPickerModal} 
                animationInTiming={50}
                onBackButtonPress={() => {Picker.hide();this.setState({showPickerModal:false})}}
                onBackdropPress={() => {Picker.hide();this.setState({showPickerModal:false})}}
            >
                </Modal> */}
            {this.renderInfoModal()}
          </View>
          <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  hLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginVertical: 10,
    width: "100%",
  },

  //renderTransactions
  txns: {
    width: "100%",
    marginBottom: 20,
    marginTop: 0,
  },
  txnHead: {
    width: dimensions.vw * 92,
    marginHorizontal: dimensions.vw * 4,
    flexDirection: "row",
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    marginTop: 25,
    justifyContent: "space-between",
    alignItems: "flex-end",
  },

  txnItem: {
    paddingHorizontal: 5,
    paddingVertical: 18,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    flexDirection: "row",
  },
  amount: {
    flexDirection: "row",
    alignItems: "center",
    width: 90,
  },
  txnName: {
    flex: 1,
    paddingHorizontal: 10,
    justifyContent: "center",
  },
  txnExp: {
    justifyContent: "center",
  },
  listFontSize: {
    fontSize: 11,
  },
  infoModalContent: {
    padding: 20,
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  modalIconContainer: {
    alignItems: "center",
    marginBottom: 20,
    marginTop: 10,
  },
  textCenter: {
    textAlign: "center",
  },
};

export default connectData()(CarpoolVirtualMoneyScreen);
