import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import ThemeTwoOffices from "./common/ThemeTwoOffices";
import KuposHeader from "../../components/KuposHeader";
import { connectData } from "../../redux";

const ThemeTwoOfficesScreen = ({ props }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getOfficeDetails({ callback: res => onResponse(res) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResponse = res => {
    if (
      res &&
      res.data.data &&
      res.data.data.offices &&
      res.data.data.offices.length > 0
    ) {
      setData(res.data.data.offices);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <KuposHeader
          title={"   "}
          boldTitle={"Oficinas"}
          handleHomeSubmit={() => props.history.push("/")}
          handleBackSubmit={() => props.history.goBack()}
        />
        <div className="new-container">
          <ThemeTwoOffices offices={data} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoOfficesScreen);
