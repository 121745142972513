/* eslint-disable jsx-a11y/iframe-has-title */
import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenCargoScreen.css';

const ThemeSevenCargoScreen = (props) => {
  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-cargo-screen'>
          <div id='cargo'>
            <div className='new-container'>
              <div className='heading'>
                <h1>
                  <span>Envia tu carga</span> <br />{' '}
                  <span className='bold'>sin problemas</span>
                </h1>
              </div>
            </div>
          </div>
          <div className='new-container'>
            <div className='col-md-12'>
              <div className='cargo'>
                <h3>
                  <span>Servicios de carga</span>
                </h3>
                <div className='cargo-details'>
                  <h5>
                    Buses Fernández cuenta con{' '}
                    <span>Servicio de traslado terrestre de carga</span> desde{' '}
                    <span>Punta Arenas</span> a <span>Puerto Natales</span> y
                    viceversa. <br /> Nuestras oficinas reciben carga de{' '}
                    <span>
                      Lunes a Sábado desde las 07:00 a las 21:00 horas
                    </span>{' '}
                    y despachamos la carga a sus destinos varias veces en el
                    día, por lo que si necesitas un despacho urgente o de alta
                    prioridad coordinamos su despacho para que se reciba en el
                    mismo día.
                  </h5>
                  <h5>
                    <span>
                      Además tenemos servicio de retiro y entrega de la carga a
                      domicilio tanto en Punta Arenas como en Puerto Natales.
                    </span>{' '}
                    <br /> Contamos con servicios de carga para particulares y
                    empresas.
                  </h5>
                  <div className='iframe1'>
                    <div className='col-md-4'>
                      <img
                        src='/assets/fernandez/services/BusesFernandez-Website-Services-PuntaArenas.png'
                        alt='punta-/arenas'
                      />
                    </div>
                    <div className='col-md-8'>
                      <div className='support'>
                        <div className='d-flex'>
                          <div className='item'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
                              alt='location'
                            />
                            <span>Dirección: Armando Sanhueza #745</span>
                          </div>
                          <div className='item'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
                              alt='support'
                            />
                            <span>
                              Teléfonos: +56 61 2221 812/+56 61 2242 313/+56 9
                              9438 5125
                            </span>
                          </div>
                        </div>
                        <div className='item'>
                          <span>
                            Horario de atención al público: 08:00 a 21:00 hrs.
                          </span>
                        </div>
                      </div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2392.32299604803!2d-70.91057488424771!3d-53.15824367993995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdb2637c2f63d307%3A0x890b66db8c956357!2sArmando%20Sanhueza%20745%2C%20Punta%20Arenas%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena%2C%20Chile!5e0!3m2!1sen!2sin!4v1618231042334!5m2!1sen!2sin'
                        width='100%'
                        height='250'
                        // style="border:0;"
                        allowfullscreen=''
                        loading='lazy'
                        // title=".."
                      ></iframe>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                  <div className='iframe2'>
                    <div className='col-md-8'>
                      <div className='support'>
                        <div className='d-flex'>
                          <div className='item'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
                              alt='location'
                            />
                            <span>Dirección: Eleuterio Ramirez #399</span>
                          </div>
                          <div className='item'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
                              alt='support'
                            />
                            <span>
                              Teléfonos: +56 61 2414 786/+56 61 2242 313/+56 9
                              9225 8211
                            </span>
                          </div>
                        </div>
                        <div className='item'>
                          <span>
                            Horario de atención al público: 08:00 a 21:00 hrs.
                          </span>
                        </div>
                      </div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.2801391669655!2d-72.50164318430352!3d-51.72791127967205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdafe81cadbcf9cd%3A0x67831c713b1b200!2sEleuterio%20Ram%C3%ADrez%20399%2C%20Puerto%20Natales%2C%20Natales%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena%2C%20Chile!5e0!3m2!1sen!2sin!4v1618231159478!5m2!1sen!2sin'
                        width='100%'
                        height='250'
                        // style="border:0;"
                        allowfullscreen=''
                        loading='lazy'
                        title='..'
                      ></iframe>
                    </div>
                    <div className='col-md-4'>
                      <img
                        src='/assets/fernandez/services/BusesFernandez-Website-Services-Mobile-PuertoNatales-2.png'
                        alt='punta-natales'
                      />
                    </div>
                    <div className='clearfix'></div>
                  </div>
                </div>
                <div className='more'>
                  <span>
                    Si necesitas más información o tienes alguna consulta en
                    particular, contáctenos
                  </span>
                  <span onClick={() => props.history.push('/contact')}>
                    aquí
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenCargoScreen);
