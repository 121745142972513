import './ThemeSevenContactScreen.css';

import {
	CarpoolSuccessFailureModal,
	PBInput,
	PButton,
} from "../../../components";
import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from "react";

import { CommonService } from "../../../services";
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const ThemeSevenContactScreen = (props) => {


	const initialState = {
		name: null,
		phone: null,
		email: null,
		comment: null,
		nameError: false,
		phoneError: false,
		emailError: false,
		commentError: false,
	};
	const [state, setState] = useState(initialState);

	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	const inpChange = (value, type) => {
		if (type == "phone") {
			value = CommonService.phoneNumberFormat(value);
			setState({
				...state,
				[type]: value,
				[type + "Error"]: false,
			});
		} else {
			setState({ ...state, [type]: value, [type + "Error"]: false });
		}
	};

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (!state.comment) {
			setState({
				...state,
				commentError: true,
				commentErrorMessage: "Inserta tu comentario",
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};

	const onSubmit = () => {
		if (!validate()) {
			return;
		}

		let data = {
			name: state.name,
			email: state.email,
			phone_number: state.phone,
			comments: state.comment,
			operator_id: operator.operator_id,
			operator_name: operator.operator_name,
		};

		const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

		if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
			data.last_name = state.last_name;
		}
		if (operator && (operator.operator_name === "fernandez")) {

			// if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "biaggini")) {

			props.opsiteContactInfo({
				callback: (result) => onContactInfoResponse(result),
				data: data,
			});
		}
	};


	const onContactInfoResponse = (res) => {
		if (res && res.data && res.data.message) {
			setState({
				...state,
				showModal: true,
				responseMessage: res.data.message,
				status: res.data.status,
			});
		}
	};


	return (
		<IonPage>
			<IonContent>
				<Header />
				<div className='themes-theme-seven-contact-screen'>
					<div id='contact'>
						<div className='new-container'>
							<div className='heading'>
								<h1>Contacto</h1>
							</div>
						</div>
					</div>

					{/* --------------------- */}
					<div className='new-container'>
						<div className='contact'>
							<h3>
								<span>Contáctanos</span>
							</h3>
							<h5>
								Te ayudamos a <span>solucionar</span> cualquier{' '}
								<span>duda o inconveniente</span> durante el proceso de compra
								en línea, anulación de pasajes y devoluciones.
							</h5>
							<div className='contact-section'>
								<div className='col-md-12'>
									<div className='info'>
										<div className='w50 bdr-rt'>
											<div className='address'>
												<span>Punta Arenas</span>
												<span>Horarios de Atención: 07:45 - 22:00 hrs.</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
													alt='support'
												/>
												<span>+56 61 2242 313 / +56 9 9326 0974</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
													alt='location'
												/>
												<span>Armando Sanhueza #745</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Email.svg'
													alt='email'
												/>
												<span
												// style="word-break: break-all;"
												>
													pasajespuntaarenas@busesfernandez.com
												</span>
											</div>
										</div>
										<div className=''>
											<div className='address'>
												<span>Puerto Natales</span>
												<span>Horarios de Atención: 07:00 - 22:00 hrs.</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
													alt='support'
												/>
												<span>
													+56 61 2414 786 / +56 61 2411 111
												</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
													alt='location'
												/>
												<span>Eleuterio Ramirez #399 / Av. España #1455</span>
											</div>
											<div className='c-flex-row'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Email.svg'
													alt='email'
												/>
												<span
												// style="word-break: break-all;"
												>
													operadornatales@busesfernandez.com
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='form-section'>
								<div className='col-md-12'>
									<div className='form'>
										{/* <form
                      accept-charset='UTF-8'
                      // action='/feedbacks/create_feedback'
                      // className='form-contact'
                      // data-remote='true'
                      // id='contact_form'
                      method='post'
                    > */}
										<div className='col-md-4'>
											<div className='form-group'>
												<label for='username'>Nombre completo</label>
												<PBInput
													// label='Nombre'
													placeholder="Nombre"
													value={state.name}
													onChange={(text) => inpChange(text, "name")}
													// onBlur={(text) => onBlur(text, "name")}
													error={state.nameError ? true : false}
													errorMessage={state.nameError ? state.nameErrorMessage : ""}
													containerStyle={{
														padding: "0",
														border: 0,
													}}
													inputClass={{
														padding: "5px",
													}}
												/>
												{/* <input
                            placeholder='Ej. Maria'
                            className='form-control'
                            maxlength='20'
                            required='required'
                            size='20'
                            type='text'
                            name='feedback[username]'
                            id='feedback_username'
                          /> */}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label for='email'>Email</label>
												<PBInput
													// label='Nombre'
													placeholder="abc@gmail.com"
													value={state.email}
													onChange={(text) => inpChange(text, "email")}
													// onBlur={(text) => onBlur(text, "name")}
													error={state.emailError ? true : false}
													errorMessage={state.emailError ? state.emailErrorMessage : ""}
													containerStyle={{
														padding: "0",

													}}
													inputClass={{
														padding: "5px",
													}}
												/>
												{/* <input
                            placeholder='ejemplo@gmail.com'
                            className='form-control'
                            maxlength='30'
                            required='required'
                            size='30'
                            type='text'
                            name='feedback[email]'
                            id='feedback_email'
                          /> */}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label for='contact_number'>Nº de contacto</label>
												<PBInput
													// label='Nombre'
													placeholder="9 XXXX XXXX"
													value={state.phone}
													onChange={(text) => inpChange(text, "phone")}
													// onBlur={(text) => onBlur(text, "name")}
													error={state.nameError ? true : false}
													errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
													containerStyle={{
														padding: "0",

													}}
													inputClass={{
														padding: "5px",
													}}
												/>
												{/* <input
                            placeholder='+56 9 0000 0000'
                            className='form-control'
                            maxlength='30'
                            required='required'
                            size='30'
                            type='text'
                            name='feedback[contact_number]'
                            id='feedback_contact_number'
                          /> */}
											</div>
										</div>

										<div className='col-md-12'>
											<div className='form-group'>
												<label for='comments'>Mensaje</label>
												{/* <textarea
                            placeholder=''
                            className='form-control'
                            maxlength='2000'
                            required='required'
                            name='feedback[comments]'
                            id='feedback_comments'
                          ></textarea> */}
												<PBInput
													// label='Nombre'
													placeholder="Comentario"
													value={state.comment}
													onChange={(text) => inpChange(text, "comment")}
													// onBlur={(text) => onBlur(text, "name")}
													error={state.commentError ? true : false}
													errorMessage={state.commentError ? state.commentErrorMessage : ""}
													containerStyle={{
														padding: "0",

													}}
													inputClass={{
														padding: "5px",
													}}
												/>
											</div>
											<div className=' text-center'>
												<button onClick={onSubmit}
													className='btn btn-success hvr-back-pulse'
													id='submit_btn'
												>
													ENVIAR
												</button>
												<CarpoolSuccessFailureModal
													showModal={state.showModal}
													success={state.status ? true : false}
													smallIcon={true}
													bodyText={state.responseMessage}
													buttonText={"OK"}
													onButtonPress={() => {
														setState({
															showModal: false,
														});
														props.history.push("/");
													}}
												/>
											</div>
										</div>
										{/* </form> */}
										<div className='clearfix'></div>
									</div>
								</div>
							</div>
							<div className='support-section'>
								<div className='col-md-12'>
									<div className='support'>
										<h5 style={{ display: 'flex', justifyContent: 'center' }}>
											<span>Soporte para compras en línea</span>
										</h5>
										<div className='d-flex'>
											<div className='col-sm-4 text-center item'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
													alt='support'
												/>
												<span>+56 9 4915 6683</span>
											</div>
											<div className='col-sm-4 text-center item'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-2-WhatsApp.svg'
													alt='support'
												/>
												<span>+56 9 4915 6683</span>
											</div>
											<div className='col-sm-4 text-center item'>
												<img
													src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Email.svg'
													alt='support'
												/>
												<span>ayuda@pasajebus.com</span>
											</div>
										</div>

										<div className='clearfix'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ThemeSevenFooter />
			</IonContent>
		</IonPage>
	);
};
export default connectData()(ThemeSevenContactScreen);
