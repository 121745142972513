const classes = [
    // kennybus 
    { name: 'kennybuslight', className: 'cera_Pro_light' },
    { name: 'kennybusmedium', className: 'cera_Pro_medium' },
    { name: 'kennybusbold', className: 'cera_Pro_Bold' }
]

const getFonts = (name, type) => {
    // Change andimar with tepual later we because for now we are getting the domain_url as andimar

    const fontName = `${name.split('.')[0]}${type}`
    const showFont = classes.find(val => val.name === fontName);
    return showFont && showFont.className ? showFont.className : ''
}

export default getFonts