// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  CarpoolSuccessFailureModal,
  View,
} from "../../components";
import { CommonService } from "../../services";
import { IonPage, IonContent, IonToast } from "@ionic/react";
import getIcons from "../../themes/functions/getIcons/getIcons";

class OtpScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      otpError: null,
      codeValue: null,
      otpKey: 1,
      showIndicator: false,
    };
  }

  // _onSmsListenerPressed = async () => {
  //   try {
  //     const registered = await SmsRetriever.startSmsRetriever();
  //     console.log(registered);
  //     if (registered) {
  //       SmsRetriever.addSmsListener(event => {
  //         console.log(event);
  //         SmsRetriever.removeSmsListener();
  //       });
  //     }
  //   } catch (error) {
  //     console.log(JSON.stringify(error));
  //   }
  // };

  componentDidMount() {
    // this._onSmsListenerPressed()
  }
  componentWillUnmount() {
    // SmsRetriever.removeSmsListener();
    // this.subscription.remove()
  }

  generateOtp = () => {
    try {
      this.props.generateOtp({
        data: {
          country_code:
            "+" +
            this.props.data.registerUserPhoneDataForOtp.country.callingCode,
          mobile_number: this.props.data.registerUserPhoneDataForOtp.mobile,
          cat_type: "sign_up",
        },
        callback: (res) => {
          console.log(res);
          if (res.success && res.data && res.data.status === 200) {
            // this.setState({
            //   showSuccessFailureModal: true,
            //   showSuccessFailureModalStatus: true,
            //   showSuccessFailureModalBodyText: res.data.message,
            // });
            this.setState({
              otp1: null,
              otp2: null,
              otp3: null,
              otp4: null,
              otpKey: this.state.otpKey + 1,
              showToast: true,
              toastMessage: res.data.message,
            });
          } else {
            this.setState({
              showSuccessFailureModal: true,
              showSuccessFailureModalStatus: false,
              showSuccessFailureModalBodyText: res.data.message,
            });
          }
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  onSubmit = (otp) => {
    let errorCount = 0;
    if (
      !this.state.otp1 ||
      !this.state.otp2 ||
      !this.state.otp3 ||
      !this.state.otp4
    ) {
      // if (!this.state.otp) {
      //   // this.setState({
      //   //     otpError:{
      //   //         message:'Introduzca su número de móvil'
      //   //     }
      //   // })
      //   errorCount++;
      // } else if (this.state.otp.toString().length != 4) {
      //   // this.setState({
      //   //     otpError:{
      //   //         message:'Introduzca su número de móvil de 9 dígitos'
      //   //     }
      //   // })
      //   errorCount++;
      // }
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }
    if (this.smsInterVal) clearInterval(this.smsInterVal);
    try {
      this.props.validateOtp({
        data: {
          country_code:
            "+" +
            this.props.data.registerUserPhoneDataForOtp.country.callingCode,
          mobile_number: this.props.data.registerUserPhoneDataForOtp.mobile,
          cat_type: "sign_up",
          code:
            this.state.otp1 +
            "" +
            this.state.otp2 +
            "" +
            this.state.otp3 +
            "" +
            this.state.otp4,
        },
        callback: (res) => {
          console.log(res);
          if (res.success && res.data && res.data.status === 200) {
            this.props.history.push("/register");
            // this.refs.toast.show("Número de teléfono verificado con éxito");
            // this.setState({
            //   showSuccessFailureModal: true,
            //   showSuccessFailureModalStatus: true,
            //   showSuccessFailureModalBodyText: "Número de teléfono verificado con éxito",
            // });
          } else {
            this.setState({
              showSuccessFailureModal: true,
              showSuccessFailureModalStatus: false,
              showSuccessFailureModalBodyText: res.data.message,
            });
            if (otp) {
              this.setState({
                codeValue: null,
                otpKey: this.state.otpKey + 1,
              });
            }
          }
        },
      });
    } catch (e) {
      console.log(e);
    }

    // this.props.forgotPassword({callback:(result) => this.onResponse(result),data:data})
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        showRegisterLink: true,
      });
      return;
    } else if (result.data.message) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        modalBody: result.data.message,
        modalIcon: "/assets/icons/success.png",
        showRegisterLink: false,
      });
    }
  };

  goBack = () => {
    let modalTitle = this.state.modalTitle;
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    if (modalTitle != "¡Lo Sentimos!") this.props.history.goBack();
  };

  _onFulfill = (code) => {
    // TODO: call API to check code here
    // If code does not match, clear input with: this.refs.codeInputRef1.clear()

    // if (code == 'Q234E') {
    //   Alert.alert(
    //     'Confirmation Code',
    //     'Successful!',
    //     [{text: 'OK'}],
    //     { cancelable: false }
    //   );
    // } else {
    //   Alert.alert(
    //     'Confirmation Code',
    //     'Code not match!',
    //     [{text: 'OK'}],
    //     { cancelable: false }
    //   );

    //   this.refs.codeInputRef1.clear();
    // }
    this.setState({
      otp: code,
    });
    // this.refs.toast.show(code);
  };

  onOtpChange = (position, el) => {
    switch (position) {
      case 1:
        this.setState({
          otp1: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp2.focus();
        else this.refs.otp1.focus();
        break;
      case 2:
        this.setState({
          otp2: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp3.focus();
        // else this.refs.otp1.focus();
        break;
      case 3:
        this.setState({
          otp3: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp4.focus();
        // else this.refs.otp2.focus();
        break;
      case 4:
        this.setState({
          otp4: CommonService.phoneNumberFormat(el.target.value, 1),
        });
        if (el.target.value) this.refs.otp4.focus();
        // else this.refs.otp3.focus();
        break;
    }

    console.log("OTP Position", position);
    console.log(
      "OTPs",
      this.state.otp1,
      this.state.otp2,
      this.state.otp3,
      this.state.otp4
    );
  };

  render() {
    const selectedTxn = this.props.selectedTxn;
    return (
      <IonPage>
        <IonContent forceOverscroll={false}>
          <View>
            <CarpoolHeader
              backIcon={true}
              componentId={this.props.componentId}
              label=" "
              labelDark={true}
              history={this.props.history}
            />
            <View>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <img
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    marginLeft: "36vw",
                    width: "28vw",
                  }}
                  // src={"/assets/carpool/forgot-circle.png"}
                  src={getIcons('recoverpswd')}
                  alt=''
                />
              </View>

              <View
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PBText
                  dark={true}
                  bold={true}
                  style={{ textAlign: "center", fontSize: 15 }}
                >
                  Verifica tu teléfono
                </PBText>
              </View>
              <View
                style={{
                  alignItems: "center",
                  paddingHorizontal: 50,
                  marginTop: 5,
                }}
              >
                <PBText style={{ textAlign: "center", fontSize: 14 }}>
                  Ingresa el código de 4 dígitos que enviamos al número
                  registrado.
                </PBText>
              </View>
              <View
                className="kupos-card"
                style={{ paddingVertical: 30, marginTop: 20, marginBottom: 20 }}
              >
                <View style={styles.inputContainer}>
                  <View style={{ alignItems: "center" }}>
                    <View className="flex-row otp-inps">
                      <input
                        id="otp1"
                        ref="otp1"
                        value={this.state.otp1}
                        autoCorrect={false}
                        type="password"
                        onKeyUp={(el) => {
                          let key = el.keyCode || el.charCode;
                          if (key == 39) this.refs.otp2.focus();
                        }}
                        onChange={(el) => this.onOtpChange(1, el)}
                      />
                      <input
                        id="otp2"
                        ref="otp2"
                        value={this.state.otp2}
                        autoCorrect={false}
                        type="password"
                        onKeyUp={(el) => {
                          let key = el.keyCode || el.charCode;
                          if (key == 8 || key == 46) this.refs.otp1.focus();
                          if (key == 37) this.refs.otp1.focus();
                          if (key == 39) this.refs.otp3.focus();
                        }}
                        onChange={(el) => this.onOtpChange(2, el)}
                      />
                      <input
                        id="otp3"
                        ref="otp3"
                        value={this.state.otp3}
                        autoCorrect={false}
                        type="password"
                        onKeyUp={(el) => {
                          let key = el.keyCode || el.charCode;
                          if (key == 8 || key == 46) this.refs.otp2.focus();
                          if (key == 37) this.refs.otp2.focus();
                          if (key == 39) this.refs.otp4.focus();
                        }}
                        onChange={(el) => this.onOtpChange(3, el)}
                        // onChange={(el) => this.setState({ otp3: CommonService.phoneNumberFormat(el.target.value,1) })}
                      />
                      <input
                        id="otp4"
                        ref="otp4"
                        value={this.state.otp4}
                        autoCorrect={false}
                        type="password"
                        onKeyUp={(el) => {
                          let key = el.keyCode || el.charCode;
                          if (key == 8 || key == 46) this.refs.otp3.focus();
                          if (key == 37) this.refs.otp3.focus();
                        }}
                        onChange={(el) => this.onOtpChange(4, el)}
                        // onChange={(el) => this.setState({ otp4: CommonService.phoneNumberFormat(el.target.value,1) })}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={this.onSubmit}
                    noGradient={true}
                    title={"CONTINUAR"}
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PBText>¿No recibiste el código? &nbsp;</PBText>
                <a onClick={this.generateOtp}>
                  <PBText primary={true}>Haz clic aquí para reenviar.</PBText>
                </a>
              </View>
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={
                !this.state.showRegisterLink ? this.goBack : this.register
              }
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </View>} */}
            </PBModal>
            <CarpoolSuccessFailureModal
              showModal={this.state.showSuccessFailureModal}
              success={this.state.showSuccessFailureModalStatus}
              smallIcon={true}
              bodyText={this.state.showSuccessFailureModalBodyText}
              buttonText={"OK, CONTINUAR"}
              onButtonPress={() => {
                this.setState({
                  showSuccessFailureModal: false,
                  showSuccessFailureModalBodyText: null,
                });
              }}
            />
            {/* <Toast ref="toast" /> */}
          </View>
        </IonContent>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() => this.setState({ showToast: false })}
          message={this.state.toastMessage}
          duration={2000}
        />
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 12,
  },
  buttonContainer: {
    marginTop: 10,
  },
};

export default connectData()(OtpScreen);
