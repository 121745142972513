import React from "react";
import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";

const IonicDropdown = (props) => {
  let onChange = () => {
    var link = document.getElementsByTagName("ion-backdrop");
    if (link && link[0]) {
      link[0].click();
    }
  };
  return (
    <IonItem className={props.dropdownClass + " font14"}>
      {/* <IonLabel>Country Code</IonLabel> */}

      <IonSelect
        className={props.selectClass}
        value={props.value}
        okText="select"
        cancelText="cancel"
        disabled={props.disabled || false}
        placeholder={props.value ? props.value.name : props.placeholder}
        interface="action-sheet"
        onIonChange={(ev) => props.onSelectChange(ev.detail.value)}
        // onIonChange={ev => console.log(ev)}
      >
        {/* {console.log("Props data",props.data)} */}
        {props.data &&
          props.data.map((val) => {
            return (
              <IonSelectOption key={val.id} value={val}>
                {val.name}
              </IonSelectOption>
            );
          })}
      </IonSelect>
    </IonItem>
  );
};

export default IonicDropdown;
