import './Footer2.css';

import React from 'react';
import getIcons from '../../functions/getIcons/getIcons';

const Footer2 = ({ operator }) => {
	return (
		operator && (
			<div className='themes-footer-2 ' style={{ marginTop: 20 }}>
				<div
					style={{
						background:
							operator &&
								operator.color_codes &&
								operator.color_codes.footer_color
								? operator.color_codes.footer_color
								: '',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							paddingTop: 20,
							paddingBottom: 10,
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<div className='footer-logo newfooterlogo'>
							<a href="/">
								<img
									style={{
										width: '235px',
										// height: 50,
										height: 'auto',
										marginBottom:'10px'
									}}
									src={
										operator && operator.header_logo
										// getIcons('headerLogo')
									}
									alt=""
									id="logo"
								/></a>
						</div>
						<div>
							<div
								className='mb-1'
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									color:
										operator && operator.operator_name === 'expressonorte'
											? '#808080'
											: '#fff',
									padding: "0px 20px",
									fontSize: 12,
									textAlign: 'center',
									gap: 10,
								}}
							>
								{operator?.operator_name === "jeldres" &&
								<img
                               src={getIcons('footerPhone')}
                               alt='DIS'
                               className='top_icon'
				               style={{height:20,width:20}}
                               />}
								{operator && operator.phone_number ? operator.phone_number : ''}
							</div>

							{operator?.operator_name === "jeldres" &&	<div
								className='mb-1'
								style={{
									display: 'flex',
									justifyContent: 'center',
                                   alignItems: 'center',
									color:'#fff',
									padding: "0px 20px",
									fontSize: 12,
									textAlign: 'center',
									gap:10,
									// marginTop:10,
								}}
							>
								
								<img
                            src={getIcons('footerMail')}
                            alt='DIS'
                            className='top_icon'
				            style={{height:20,width:20}}
                              />
								{operator && operator.email ? operator.email : ''}

							</div>}

							<div
								className='d-flex-c '
								style={{
									display: 'flex',
									justifyContent: 'center',
									textAlign: 'center',
									color:
										operator && operator.operator_name === 'expressonorte'
											? '#808080'
											: '#fff',
									padding: 5,
								}}
							>
								{/* <img
                  src={getIcons(operator.domain_url, 'location')}
                  alt="DIS"
                  className="top_icon"
                // id="main-logo"
                // alt={this.props.t('TRANSLATIONS.MAIN_LOGO')}
                /> */}
								{operator.address}
							</div>
						</div>
					</div>
				</div>

				{operator && operator.show_bottom_footer ? (
					<ShowBottomFooter operator={operator} />
				) : null}
			</div>
		)
	);
};

const ShowBottomFooter = ({ operator }) => {
	return (
		<div
			className='d-flex-c f-13 p-2'
			style={{
				// padding: 10,
				textAlign: 'center',
				background:
					operator.color_codes && operator.color_codes.sub_footer_color
						? operator.color_codes.sub_footer_color
						: '',
			}}
		>
			<div
				style={{
					color:
						operator.color_codes && operator.color_codes.secondary_text_color
							? operator.color_codes.secondary_text_color
							: '',
					padding: 5,
				}}
			>
				<span className='avenir-semi font12'>{operator.name} {new Date().getFullYear()} ©</span>
				<span className='poppins-ligh font12'>
					- Todos los derechos reservados © | <br></br> Mapa del sitio | Powered by
					<a href='https://kupos.cl' target='_blank' style={{ color: '#fff', fontWeight: 'bold' }}>
						{/* <img
          src={getIcons('BottomFooterKuposLogo', 'kuposLogo')}
          className='footer-kupos-logo'
          alt=''
          style={{ height: '15px', width: 'auto', paddingTop:5 }}
        /> */}
						{' '} kupos.cl
					</a>
				</span>

			</div>

		</div>
	);
};

export default Footer2;
