import React from 'react';

const MultiSlideType1 = props => {
  return (
    <div className="multi-slide-type-1">
      {/* <div className="top-strip-1" style={{ backgroundColor: props.themeColor3 }}></div>
      <div
        className="top-strip-2"
        style={
          props.itemNo % 2 === 0
            ? { backgroundColor: props.themeColor1 }
            : { backgroundColor: props.themeColor2 }
        }
      ></div> */}
      <div className="image-container">
        <img className="image" src={props.slide} alt="slide-item" style={{width: props.width ? props.width : "100%", height: props.height ? props.height : "100%"}} />
      </div>
    </div>
  );
};

export default MultiSlideType1;
