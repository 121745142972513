import "react-multi-carousel/lib/styles.css";

import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";

import Carousel from "react-multi-carousel";
import { CommonService } from "../../../services";
import Footer1 from "../../common/footer/Footer1";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import KuposHelmet from "../../common/KuposHelmet";
import MultiSlideType1 from "../../common/MultiSlider/MultiSlideType1";
import ThemeTenTitle from "./ThemeTenTitle";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";
import getSlides from "../../functions/getSlides";

const initalState = {
	selectedTab: 0,
};

const title = {
	liquine: 'OFICINAS',
	tacoha: 'AGENCIAS'
}

const ThemeTenAgencies = (props) => {
	const operator = CommonService.getLocal("op") || props.data.operator;
	const [state, setState] = useState(initalState);

	const onTabChange = (tab) => {
		setState({ ...state, selectedTab: tab });
	};


	return (
		<IonPage>
			{/* <KuposHelmet /> */}

			<IonContent>
				<Header />
				<HeroSection
					heroImage={getIcons("agenciesBanner")}
					height={"45vh"}
					title={"Oficinas"}
					description={"Home / Oficinas"}
				/>

				<ThemeTenTitle title={title[operator && operator.operator_name]} />
				<div className="new-container">
					<Tabs
						tabs={getSlides("AgencyTabs").tabs || []}
						onTabChange={onTabChange}
						selectedTab={state.selectedTab}
						operator={operator}
						active={
							operator &&
							operator.color_codes &&
							operator.color_codes.primary_color
						}
						inActive={
							operator &&
							operator.color_codes &&
							operator.color_codes.secondary_color
						}
					/>
					<div>
						<Slider
							multiSlides={getSlides("AgencyTabs")[state.selectedTab] || []}
							textCard={true}
							removeArrowOnDeviceType={[]}
							andrimarArrowStyle
							operator={operator}
						/>
					</div>
				</div>
				<Footer1 operator={operator} isSanantonio />
			</IonContent>
		</IonPage>
	);
};

const Tabs = ({
	tabs,
	onTabChange,
	selectedTab,
	operator,
	width,
	active,
	inActive,
}) => {
	const getStyles = (bool) => {
		return {
			paddingTop: 15,
			paddingBottom: 15,
			width: width,
			paddingLeft: 20,
			paddingRight: 20,
			backgroundColor: bool ? active : inActive,
			borderRadius: "75px",
			textAlign: "center",
			color: '#fff'
		};
	};

	return (
		<div className="recharge-tabs-container less-bold-text">
			<div
				className="map-container-selector "
				style={{
					marginTop: 0,
					background: operator.theme_color,
					width: width ? 400 : " ",
					fontSize: "13px",
				}}
			>
				{tabs.map((tab) => {
					return (
						<div
							key={tab.id}
							className={"selector-item "}
							onClick={() => onTabChange(tab.id)}
							style={getStyles(selectedTab === tab.id ? true : false)}
						>
							{tab.label}
						</div>
					);
				})}
			</div>
		</div>
	);
};

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const removeArrowOnDeviceType = ["tablet", "mobile", "desktop"];

const Slider = (props) => {
	return (
		<div
			className="multi-slider-container"
			style={{
				marginTop: 30,
			}}
		>


			<Carousel
				responsive={responsive}
				swipeable={true}
				draggable={false}
				showDots={false}
				infinite={true}
				autoPlay={true}
				autoPlaySpeed={3000}
				customTransition="1s"
				transitionDuration={3000}
				removeArrowOnDeviceType={
					props.removeArrowOnDeviceType
						? props.removeArrowOnDeviceType
						: removeArrowOnDeviceType
				}
			>
				{props.multiSlides &&
					(props.multiSlides.length || []) > 0 &&
					props.multiSlides.map((slide, index) => {
						console.log("Slide", slide);
						return (
							<div key={slide.id} style={{ margin: "0 20px" }}>
								<MultiSlideType1 slide={slide.image} itemNo={index} />
								{props.textCard && (
									<div
										style={{
											background: "#eff3ff",
											padding: 15,
											borderBottomLeftRadius: 10,
											borderBottomRightRadius: 10,
											display: "flex",
											flexDirection: "column",
											alignItems: "space-between",
											justifyContent: "space-between",
											marginTop: "-6px",
										}}
									>

										{slide.address ? (
											<div className="font10" style={styles.item}>
												<img
													src={getIcons('agencyAddress', props.operator)}
													alt=""
													style={{ width: 10, marginRight: 5, marginTop: -12 }}
												/>
												<div>{slide.address}</div>
											</div>
										) : null}
										{slide.mapLink ?
											<div
												className="font11"
												style={{ display: "flex", alignItems: "baseline" }}
											>
												<img
													src={getIcons("BlueArrow", props.operator)}
													alt=""
													style={{ height: 10, width: 10, marginRight: 5 }}
												/>
												<a
													href={slide.mapLink}
													target="_blank"
													style={{
														textDecoration: "underline",
														color: "#2E3191",
													}}
												>
													Ver mapa
												</a>
											</div> : null
										}

									</div>
								)}
							</div>
						);
					})}
			</Carousel>
		</div>
	);
};

export default connectData()(ThemeTenAgencies);

const styles = {
	textCardContainer: {
		background: '#eff3ff',
		padding: 15,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'space-between',
		justifyContent: 'space-between',
		minHeight: '150px',
	},
	item: {
		display: 'flex',
		alignItems: 'baseline',
		marginBottom: 15,
	},
	vermapa: { display: 'flex', alignItems: 'baseline', marginTop: 20 },
	vermapaTransform: {
		height: 10, width: 10, marginRight: 5,
		transform: 'rotate(-90deg)'
	},
	vermapaImg: {
		height: 10, width: 10, marginRight: 5,
	}
};
