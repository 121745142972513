const LocationIcon = "/assets/tepual/general/LocationIcon.svg";
const PhoneIcon = "/assets/tepual/general/PhoneIcon.svg";
const HeaderLogo = "/assets/tepual/general/Header-BusesTepualLogo.svg";
const FooterLogo = "/assets/tepual/general/Footer-BusesTepualLogo.svg";
const ContactBg = "/assets/tepual/home/home-background.jpg";
// const PopupIcon = '/assets/tepual/gallery/gallery5.jpg';
const BookingActive = "/assets/tepual/home/BuyTicketIcon-Active.svg";
const BookingInActive = "/assets/tepual/home/BuyTicketIcon-Inactive.svg";
const CancelActive = "/assets/tepual/home/CancelTicketIcon-Active.svg";
const CancelInActive = "/assets/tepual/home/CancelTicketIcon-Inactive.svg";
const OriginIcon = "/assets/tepual/home/SearchBar-OriginIcon.svg";
const DestinationIcon = "/assets/tepual/home/SearchBar-DestinationIcon.svg";
const CalendarIcon = "/assets/tepual/home/SearchBar-CalendarIcon.svg";
const SearchIcon = "/assets/tepual/home/SearchBar-SearchIcon.svg";
const checkboxIcon = "/assets/tepual/checkbox.png";
const BottomFooterKuposLogo = "/assets/tepual/Footer-kuposLogo.svg";
const animation =
	"/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif";

const BG = "/assets/tepual/home-banners/BusesTepual-Banner-MobileVersion-1.jpg";
const pnr = "/assets/tepual/find-my-pnr.svg";

const contactMail = "/assets/tepual/contact/contact-email-icon.svg";
const contactHeadphone = "/assets/tepual/contact/contact-phone-number-icon.svg";
const aboutUsImage = "/assets/tepual/icons/single-bus-about-us.jpg";

// banners
const destinationBanner = "/assets/tepual/destinations/destination-main.jpg";
const galleryBanner = "/assets/tepual/gallery/gallery-main.jpg";
const contactUsBanner = "/assets/tepual/contact/contact-us-banner.jpg";
const aboutUsBanner =
	"/assets/tepual/about-us/BusesTepual-Website-AboutUs-MainBanner.jpg";
const tncBanner = "/assets/tepual/tnc/banner-tnc.jpg";
const officeBanner = "/assets/tepual/offices/offices-banner.jpg";
const operatorPay = "/assets/santamaria/SMPaypng.png"
const OfficePhoneIcon = "/assets/tepual/general/officePhone.svg";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

const tarjetas = "/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
const viajes = "/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const movimientos = "../../../assets/carpool/pigi-bank-circle.png";
const wallet =
	"/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg";
const shareMoney =
	"/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
// const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const success =
	"/assets/expressonorte/Green/MyAccount-IllustratedIcons-CheckMark-Green.svg";
const failure =
	"/assets/expressonorte/Green/MyAccount-IllustratedIcons-Information-Green.svg";
const warning =
	"/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "../assets/carpool/user-circle.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";

const tepualIcons = [
	{ icon: PhoneIcon, name: "phone" },
	{ icon: LocationIcon, name: "location" },
	{ icon: HeaderLogo, name: "headerLogo" },
	{ icon: FooterLogo, name: "footerLogo" },
	{ icon: ContactBg, name: "ContactBg" },
	{ icon: BookingInActive, name: "BIA" },
	{ icon: BookingActive, name: "BA" },
	{ icon: CancelInActive, name: "CIA" },
	{ icon: CancelActive, name: "CA" },
	{ icon: OriginIcon, name: "Origin" },
	{ icon: DestinationIcon, name: "Destination" },
	{ icon: CalendarIcon, name: "Calendar" },
	{ icon: SearchIcon, name: "Search" },
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: checkboxIcon, name: "Checkbox" },
	{ icon: BG, name: "BG" },
	{ icon: animation, name: "animation" },
	{ icon: pnr, name: "pnr" },
	{ icon: BottomFooterKuposLogo, name: "BottomFooterKuposLogo" },

	{ icon: OfficePhoneIcon, name: "OfficePhoneIcon" },

	{ icon: contactMail, name: "contactMail" },
	{ icon: contactHeadphone, name: "contactHeadphone" },
	{ icon: destinationBanner, name: "destinationBanner" },
	{ icon: galleryBanner, name: "galleryBanner" },
	{ icon: contactUsBanner, name: "contactUsBanner" },
	{ icon: aboutUsImage, name: "aboutUsImage" },
	{ icon: aboutUsBanner, name: "aboutUsBanner" },
	{ icon: tncBanner, name: "tncBanner" },
	{ icon: officeBanner, name: "officeBanner" },

	// accounts section
	{ icon: pay, name: "pay" },
	{ icon: backimage, name: "backimage" },
	{ icon: tarjetas, name: "tarjetas" },
	{ icon: viajes, name: "viajes" },
	{ icon: movimientos, name: "movimientos" },
	{ icon: wallet, name: "wallet" },
	{ icon: shareMoney, name: "shareMoney" },
	{ icon: camera, name: "camera" },
	{ icon: checkmark, name: "checkmark" },
	{ icon: tick, name: "tick" },
	{ icon: downArrow, name: "downArrow" },
	{ icon: loginblue, name: "loginblue" },
	{ icon: recoverpswd, name: "recoverpswd" },
	{ icon: success, name: "success" },
	{ icon: failure, name: "failure" },
	{ icon: heartOn, name: "heartOn" },
	{ icon: heartOff, name: "heartOff" },
	{ icon: registrationSuccess, name: "registrationSuccess" },
	{ icon: userIcon, name: "userIcon" },
	{ icon: warning, name: "warning" },
];

export default tepualIcons;
