const Banner = '/assets/kennybus/general/kenny_banner.png';
const HeaderLogo = '/assets/kennybus/general/kennybus-header-logo.png';
const FooterLogo = '/assets/kennybus/general/kennybus-footer-logo-white.png';
const BookingInActive = '/assets/kennybus/general/booking_white.svg';
const BookingActive = '/assets/kennybus/general/booking.svg';
const CancelInActive = '/assets/kennybus/general/cancel_white.svg';
const CancelActive = '/assets/kennybus/general/cancel.svg';

const Whatsapp = '/assets/kennybus/icons/whatsapp.svg';
const Email = '/assets/kennybus/icons/email.svg';
const HeadPhone = '/assets/kennybus/icons/headphone.svg';
const YellowLine = '/assets/kennybus/icons/yellow-line.svg';

const OriginIcon = '/assets/kennybus/icons/origin.svg';
const DestinationIcon = '/assets/kennybus/icons/destination.svg';
const CalendarIcon = '/assets/kennybus/icons/calendar.svg';

const pnr = '/assets/kennybus/general/find-my-pnr.svg';
const PopupIcon = '/assets/kennybus/general/Pop-up.jpg';

//  Common Assets as we have in tepual
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const BG = '/assets/kennybus/general/KennyBus-BannersWeb-Mobile.jpg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const close = '/assets/kennybus/icons/close.svg';

// const BG = '/assets/tepual/home-banners/BusesTepual-Banner-MobileVersion-1.jpg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'

const kennybusIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: Banner, name: 'Banner' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: Whatsapp, name: 'Whatsapp' },
	{ icon: Email, name: 'Email' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: YellowLine, name: 'Line' },
	{ icon: BG, name: 'BG' },

	//   common assets
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
];

export default kennybusIcons;
