import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { commonStyles, colors, dimensions } from "../../theme";
import {
  PBCard,
  PBText,
  PBInput,
  PButton,
  Loader,
  PBModal,
  PBTabs,
  AccountInfoCard,
  PBCarpoolBG,
  BGCarpoolInnerBottom,
} from "../../components";
// import { REGISTER_SCREEN, LOGIN_SCREEN,  RECHARGE_MAP_SCREEN, RECHARGE_STEPS_SCREEN } from '../../navigation/Screens';
import { CommonService } from "../../services";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";

class RechargeStepsScreen extends PureComponent {
  constructor(props) {
    super(props);
    // CommonService.bindNav(this);
  }
  gotoMaps = () => {
    this.props.history.push("/recharge-wallet/map");
  };
  render() {
    return (
      <IonPage>
        <IonContent>
          <BGCarpoolInnerBottom zIndex={"zIndex:1"} />
          <div style={commonStyles.screenContainer}>
            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="/assets/carpool/icon_back_arrow.png"
                />
              </div>

              <div className="font19 flex-center display-flex dark-font">
                <span>¿Cómo</span>&nbsp;
                <span className="bold-text ">recargar?</span>
              </div>
              <div>&nbsp;</div>
            </div>

            <div className="recharge-steps-container">
              <div className="wallet-recharge-extra-items">
                <div>
                  <div className="kupos-card">
                    <div className="wallet-recharge-extra-item">
                      <span className="bold-text font15">Paso 1</span>
                      <img
                        src="/assets/icons/circular/icon_kupos_wallet.png"
                        alt=""
                      />
                    </div>
                    <span className="font14">
                      Ingresa el monto que deseas recargar.
                    </span>
                  </div>

                  <div className="kupos-card">
                    <div className="wallet-recharge-extra-item">
                      <span className="bold-text font15">Paso 3</span>
                      <img src="/assets/icons/circular/rut.png" alt="" />
                    </div>
                    <span className="font14">
                      Comparte tu RUT con el agente y recarga.{" "}
                      {/* <a  className="primary-font">
                                                Ver codigos
                                            </a> */}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="kupos-card">
                    <div className="wallet-recharge-extra-item">
                      <span className="bold-text font15">Paso 2</span>
                      <img src="/assets/icons/circular/house.png" alt="" />
                    </div>
                    <span className="font14">
                      Encuentra en el mapa tu punto de recarga.{" "}
                      <a onClick={this.gotoMaps} className="primary-font">
                        Ver mapa
                      </a>
                    </span>
                  </div>

                  <div className="kupos-card">
                    <div className="wallet-recharge-extra-item">
                      <span className="bold-text font15">Paso 4</span>
                      <img src="/assets/icons/circular/ticket.png" alt="" />
                    </div>
                    <span className="font14">
                      En 5 minutos tendrás el dinero en tu cuenta.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="steps-button-container">
            <PButton
              onPress={() => this.props.history.goBack()}
              style={{
                borderRadius: 10,
                color: "#FC8332",
                backgroundColor: "#fff",
              }}
              className="white-button"
              textColor={"#FC8332"}
              title={"CONTINUAR"}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default connectData()(RechargeStepsScreen);
