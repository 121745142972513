import React from 'react';
import { connectData } from '../../../redux';
import "./ThemeTwoAboutUs.css";


export const ThemeTwoAboutUs = (props) => {
  const createMarkup = () => {
    return { __html: props.aboutUsBody.replaceAll("///"," ") };
  };

  return (
    <div className='themes-about-us-1 mt10'>
      <div className='about-us-main-container'>
        <div className='left-side' style={{ padding: 20 }}>
          <div className='font12 less-bold-text mb5'>{props.smallHeading}</div>
          <div className='font15 bold-text mb15 black-text-color capitalize'>
            {props.boldHeading}
          </div>
          <div
            className='pr10 lh-1-3 font12 justify-text'
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
      </div>
    </div>
  );
};

export default connectData()(ThemeTwoAboutUs);
