import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";

import { AndimarTitle } from "../../common/Title/AndimarTitle";
import Footer1 from "../../common/footer/Footer1";
import HeroSection from "../../common/HeroSection/HeroSection";
import KuposHelmet from "../../common/KuposHelmet";
import { connectData } from "../../../redux";
import Header from "../../common/header/Header";

const AboutBanner = "/assets/traveltur/About/banner.png";
const BusImage = "/assets/traveltur/About/bus.png";

const firstFloor = [
  "Sensor fatiga y somnolencia",
  "Sensor cambio de carril",
  "Sensor exceso de velocidad",
];
const secondFloor = [
  " Velocímetro visible",
  "Monitoreo por GPS",
  "Cámaras de seguridad",
];

const ThemeTenFleetScreen = (props) => {
  const operator =
    JSON.parse(localStorage.getItem("op")) || props.data.operator;

  const styles = {
    singleTab: {
      marginTop: 20,
      background:
        operator && operator.theme_color2 ? operator.theme_color2 : "",
      padding: 15,
      justifyContent: "space-around",
      color: "#fff",
      borderRadius: 50,
      display: "flex",
      fontWeight: "bold",
    },
    interCityContainer: {
      background: "#F3F7FF",
      // height: 300,
      marginTop: -40,
      borderRadius: 10,
      paddingTop: 50,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
    whiteColor: {
      color: "#fff",
    },
  };

  return (
    <IonPage>
      {/* <KuposHelmet /> */}

      <IonContent>
        <Header />
        <HeroSection
          heroImage={AboutBanner}
          // height={'60vh'}
          height={"45vh"}
          title={"La empresa"}
          description={"Home / La empresa"}
        />

        {/* Amenities section  */}
        <AndimarTitle
          title={"TRANSPORTE Y LOGÍSTICA A TU MEDIDA"}
          isSanantonio={false}
          isTraveltur={true}
        />
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <div>
            Traveltur desde 2007 viene cosechando reconocimiento en el mercado
            por su seriedad y compromiso con la seguridad de nuestros clientes y
            colaboradores. Somos fuerza en movimiento, contamos con una moderna
            flota y un equipo de trabajadores calificados, lo que nos permite
            entregar un servicio de calidad y hecho a la medida. Y en cuanto a
            visión, nos proyectamos al futuro basándonos en la preocupación por
            cada detalle del servicio, desarrollo de nuestro equipo humano,
            constante preocupación por la mejora de la seguridad, procesos y
            apoyo a las comunidades.
          </div>

          <AndimarTitle
            title={`NUESTRAS  COMODIDADES`}
            isSanantonio={false}
            isTraveltur={true}
          />
          <div>
            En Traveltur tomamos en serio lo que es sagrado: tu integridad y
            bienestar con nosotros, y para elo constantemente revisamos y
            actualizamos buses y tecnología en gral. Y velamos in situ a traves
            de una moderna central de monitoreo para cumplir y así tambien las
            normas de seguridad. Y nuestros colaboradores reciben la
            capacitación frecuente en las áreas de gestión de rutas, prevención
            de riesgos, administración y atención al cliente, entre otras.
          </div>
          <div className="take-care-item d-flex flex-column align-items-center text-center mt-10">
            <img
              src={BusImage}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="font14 montserrat-bold" style={styles.singleTab}>
            <div style={styles.whiteColor}>ESTÁNDAR DE SEGURIDAD</div>
          </div>

          <div
            className="font13 montserrat"
            style={{
              padding: 15,
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div>
              {firstFloor.map((item) => {
                return (
                  <div style={{ marginBottom: 8 }}>
                    <span style={{ color: "red" }}>-</span> {item}
                  </div>
                );
              })}
            </div>
            <div>
              {secondFloor.map((item) => {
                return (
                  <div style={{ marginBottom: 8 }}>
                    <span style={{ color: "red" }}>-</span> {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Footer1 operator={operator} isSanantonio />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTenFleetScreen);
