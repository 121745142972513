import React, { Component } from "react";

import { connectData } from "../../../redux";
import getFonts from "../../functions/getFonts";
import titleLeftBird from "../../assets/saopaulo/general/left-bird.svg";
import titleRightBird from "../../assets/saopaulo/general/right-bird.svg";

export const AndimarTitle = ({ title, isSanantonio, isTraveltur }) => {
	const operator = JSON.parse(localStorage.getItem("op"));

	return (
		operator && operator.operator_name === "busnorte" ? (
			<div className="favorite-destinations-heading d-flex align-center justify-center my-4 montserrat-bold mt-30 avenir-semi">
				<span className="d-inline-block title-dash-line"></span>
				<div
					className="px-2 text-uppercase m-0  text-align-center"
					style={{
						color: isSanantonio
							? "#585857"
							: isTraveltur
								? "#2767E6"
								: operator && operator.theme_color2 && operator.theme_color2,
						fontSize: isTraveltur ? 18 : 22,
					}}
				>
					{title ? title : "title here"}
				</div>
				<span className="d-inline-block title-dash-line"></span>
			</div>
		) : (<div className="favorite-destinations-heading d-flex align-center justify-center my-4 montserrat-bold mtb-30 avenir-semi">
			<span className="d-inline-block title-dash-line"></span>
			<div
				className="px-2 text-uppercase m-0  text-align-center"
				style={{
					color: isSanantonio
						? "#585857"
						: isTraveltur
							? "#2767E6"
							: operator && operator.theme_color2 && operator.theme_color2,
					fontSize: isTraveltur ? 18 : 22,
				}}
			>
				{title ? title : "title here"}
			</div>
			<span className="d-inline-block title-dash-line"></span>
		</div>)
	);
};

const styles = {
	mainContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// paddingTop: 20,
		// paddingBottom: 20,
		padding: "20px",
	},
	leftIcon: {
		marginRight: 10,
	},
	rightIcon: {
		marginLeft: 10,
	},
};

export default connectData(AndimarTitle);
