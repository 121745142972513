import "./Footer2.css";

import React from "react";
import getIcons from "../../functions/getIcons/getIcons";

const Footer1 = ({ operator, isSanantonio }) => {
	let background =
		operator && operator.operator_name !== 'andimar' ?
			operator.color_codes &&
			operator.color_codes.footer_color &&
			operator.color_codes.footer_color :
			operator?.theme_color2

	return (
		operator && (
			<div className="themes-footer-2 " style={{ marginTop: 20 }}>
				<div
					style={{
						background
					}}
					className="avenir-semi"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							paddingTop: 20,
							paddingBottom: 10,
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div className="footer-logo" style={{ margin: 0 }}>
							<a href="/">
								<img
									src={operator && operator.footer_logo}
									style={{
										marginTop: "0",
										width: isSanantonio ? 140 : "",
										height: "auto",
									}}
									alt=""
								/>
							</a>
						</div>

						{operator && operator.operator_name === "busnorte" ? (<div className="bottom-links-list none">
							{operator &&
								operator.show_header_links &&
								operator.header_links.map((val, index, arr) => {
									return (
										<a
											className="montserrat-bold "
											key={index}
											style={{
												color: operator.text_color,
												// marginRight: 15,
												// marginLeft: 15,
											}}
											href={Object.values(val)}
										>
											{(index !== 0 ||
												(index !== arr.length - 1 && index !== 0)) && (
													<span className="px-4 mh-5">|</span>
												)}
											{Object.keys(val)}
										</a>
									);
								})}
						</div>) : (<div className="bottom-links-list">
							{operator &&
								operator.show_header_links &&
								operator.header_links.map((val, index, arr) => {
									return (
										<a
											className="montserrat-bold "
											key={index}
											style={{
												color: operator.text_color,
												// marginRight: 15,
												// marginLeft: 15,
											}}
											href={Object.values(val)}
										>
											{(index !== 0 ||
												(index !== arr.length - 1 && index !== 0)) && (
													<span className="px-4 mh-5">|</span>
												)}
											{Object.keys(val)}
										</a>
									);
								})}
						</div>)}



						<div
							className="contact-details mt-2"
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
							}}
						>
							{operator.phone_number ? (
								<div
									className="contact-details-item"
									style={{
										display: "flex",
										alignItems: "center",
										color: isSanantonio ? operator.text_color : "white",
									}}
								>
									<img
										src={getIcons("TelephoneWhite")}
										alt=""
										style={{ width: 20, marginRight: 5 }}
									/>
									<span>{operator.phone_number}</span>
								</div>
							) : null}
							{operator.phone_number && operator.email && (
								<span
									className="px-2"
									style={{
										margin: "0 5px",
										color: isSanantonio ? operator.text_color : "white",
									}}
								>
									|
								</span>
							)}

							{operator.email && (
								<div
									className="contact-details-item"
									style={{
										display: "flex",
										alignItems: "center",
										color: isSanantonio ? operator.text_color : "white",
									}}
								>
									{getIcons("EmailWhite") && (
										<img
											src={getIcons("EmailWhite")}
											alt=""
											style={{ width: 20, marginRight: 5 }}
										/>
									)}
									<span>{operator.email}</span>
								</div>
							)}
						</div>
					</div>
				</div>

				{operator && operator.show_bottom_footer ? (
					<ShowBottomFooter operator={operator} isSanantonio={isSanantonio} />
				) : null}
			</div>
		)
	);
};

const ShowBottomFooter = ({ operator, isSanantonio }) => {
	return (
		<div
			className="d-flex-c f-13 p-2"
			style={{
				background: isSanantonio
					? operator.color_codes.sub_footer_color
					: operator.theme_color,
				color: operator.text_color,
				padding: 10,
				textAlign: "center",
			}}
		>
			<div>
				<span className="poppins-medium font12" style={{ color: "#fff" }}>
					{operator.name} {new Date().getFullYear()}{" "}
				</span>
				<span className="poppins-ligh font12" style={{ color: "#fff" }}>
					- Todos los derechos reservados © | Powered by
				</span>
				<a
					href="https://kupos.cl"
					target="_blank"
					className="kupos_link poppins-bold"
				>
					kupos.cl
				</a>
			</div>
		</div>
	);
};

export default Footer1;
