import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeThreeFooter from '../common/ThemeThreeFooter';
import ThemeThreeTitle from '../common/ThemeThreeTitle';

const romanToNumericMap = {
  'I': 1,
  'II': 2,
  'III': 3,
  'IV': 4,
  'V': 5,
  'VI': 6,
};

const replaceRomanNumerals = (text) => {
  return text.replace(/\b(I{1,3}|IV|V|VI{0,3}|IX|X|XI{0,3}|XIV|XV|XVI{0,3}|XIX|XX)\. /g, (match) => {
    const romanNumeral = match.trim().slice(0, -1); 
    const numericValue = romanToNumericMap[romanNumeral];
    return numericValue !== undefined ? numericValue + '. ' : match; 
  }).replace(/\{C\}/g, ''); 
};

const ThemeThreeTnCScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;

  const createMarkup = (data) => {
    if (operator.operator_name === 'aeroquinta') {
      data = data.replace(/^,/, '').replace(/\{C\}/g, '');
  
      let paragraphs = data.split('<p>');
  
      if (paragraphs.length > 1) {
        paragraphs.splice(1, 1);
        data = paragraphs.join('<p>');
      }
  
      data = replaceRomanNumerals(data);
      data = applyDMSansBoldToStrongTags(data);
    }
    return { __html: data };
  };

  const applyDMSansBoldToStrongTags = (html) => {
    const regex = /<strong\b[^>]*>(.*?)<\/strong>/g;
    html = html.replace(regex, '<strong style="font-family: DMSans-Bold;">$1</strong>');
    return html;
  };

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          title='Términos y condiciones'
          height={'45vh'}
          description='Home / Términos y condiciones        '
          heroImage={getIcons('tncBanner', operator)}
        />

        <ThemeThreeTitle operator={operator} title='Términos y condiciones' />

        <div className='new-container mb10 mt10'>
          <div
            dangerouslySetInnerHTML={createMarkup(
              operator.terms ? operator.terms : ''
            )}
            className='lh-1-3 justify-text'
          />
        </div>
        <ThemeThreeFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeThreeTnCScreen);
