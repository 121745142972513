const HeaderLogo = "/assets/andimar/andimar-logo.svg";
const DestinationBanner = "/assets/andimar/destinations/destino_banner.jpg";
const FooterLogo = "/assets/tepual/general/Footer-BusesTepualLogo.svg";
const ContactBg = "/assets/tepual/home/home-background.jpg";
const PopupIcon = "/assets/andimar/Andimar-PopUp.jpg";
const HeaderLogoYellow = "/assets/centropuerto/centropuerto-logo-menu.png";
const HeaderLogowhite = "/assets/centropuerto/centropuertologocolor.png";
const FooterLogoYellow = "/assets/centropuerto/centropuerto-flogo-menu.png";
const BookingActive = "/assets/tepual/home/BuyTicketIcon-Active.svg";
const BookingInActive = "/assets/tepual/home/BuyTicketIcon-Inactive.svg";
const CancelActive = "/assets/tepual/home/CancelTicketIcon-Active.svg";
const CancelInActive = "/assets/tepual/home/CancelTicketIcon-Inactive.svg";
const OriginIcon = "/assets/centropuerto/origin.svg";
const ContactIcon = "/assets/centropuerto/house.svg";
const BricksIcon = "/assets/centropuerto/empressa/nuestra-empresa-consolidacion.svg";
const HandsIcon = "/assets/centropuerto/empressa/nuestra-empresa-confiabilidad.svg";
const AccordOpenIcon = "/assets/centropuerto/faq/on.svg";
const AccordCloseIcon = "/assets/centropuerto/faq/off.svg";
const menuDot = '/assets/centropuerto/dots.svg';

const EmailIcon = "/assets/andimar/contact-by/email.svg";
const TelephoneIcon = "/assets/centropuerto/telephone.svg";
const DestinationIcon = "/assets/centropuerto/destination.svg";
const Calendar = "/assets/centropuerto/navbar-dateIcon.svg";
const PhoneYellowIcon = "/assets/centropuerto/phone-yellow.svg";
const EmailYellowIcon = "/assets/centropuerto/mail-yellow.svg";

const SearchIcon = "/assets/andimar/navbar-searchIcon.svg";
const BlueArrow = "/assets/andimar/favoriteDestinations/andimar-blue-arrow.svg";
const ClockIcon = "/assets/andimar/favoriteDestinations/andimar-clock.svg";
const FleetBanner = "/assets/andimar/fleet/fleet-banner.jpg";
const FleetTv = "/assets/andimar/fleet/18.svg";
const FleetToilet = "/assets/andimar/fleet/19.svg";
const FleetAc = "/assets/andimar/fleet/20.svg";
const FleetSnacks = "/assets/andimar/fleet/21.svg";
const FleetBed = "/assets/andimar/fleet/22.svg";
const AmenitiesLeftImage = "/assets/andimar/fleet/24.jpg";
const BuyIconWhite = "/assets/andimar/buy-ticket.svg";
const BuyIconBlue = "/assets/andimar/buy-ticket-blue.svg";
const CancelIconWhite = "/assets/andimar/cancel-ticket.svg";
const CancelIconBlue = "/assets/andimar/cancel-ticket-blue.svg";
const TelephoneWhite = "/assets/andimar/contact-by/telephone-white.svg";
const EmailWhite = "/assets/andimar/contact-by/email-white.svg";
const BG = "/assets/centropuerto/Home/homebanner.png";
const MyRoutes = "/assets/centropuerto/Menu-Rutas.svg";
const MyRoutescent = "/assets/centropuerto/headphone.svg";
const checkboxIcon = "/assets/centropuerto/checkbox.png";

const animation = "/assets/animation-screen/Bus-LoadingAnimation-Andimar.gif";
const pnr = "/assets/andimar/Andimar-Website-Home-WhereToFindMyPNR.svg";
const close = "/assets/viatur/icons/close.svg";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

const tarjetas = "/assets/blue/IllustratedIcons-MyCards-Blue.svg";
const viajes = "/assets/blue/IllustratedIcons-MyTrips-Blue.svg";
const movimientos = "/assets/blue//IllustratedIcons-MyMovements-Blue.png";
const wallet = "/assets/blue/IllustratedIcons-RechargeWallet-Blue.svg";
const shareMoney = "/assets/blue/IllustratedIcons-ShareMoney-Blue.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/blue/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/blue/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/blue/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
const success = "/assets/centropuerto/success-tick.svg";
const failure = "/assets/centropuerto/fail-cross.svg";
const warning =
	"/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "/assets/blue/IllustratedIcons-user.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";

const operatorPay = "/assets/andimar/Andimarpay.svg";


const andimarIcons = [
	{ icon: HeaderLogowhite, name: "HeaderLogowhite" },
	{ icon: HandsIcon, name: "HandsIcon" },
	{ icon: BricksIcon, name: "BricksIcon" },
	{ icon: HeaderLogoYellow, name: "HeaderLogoYellow" },
	{ icon: HeaderLogo, name: "headerLogo" },
	{ icon: MyRoutes, name: "MyRoutes" },
	{ icon: MyRoutescent, name: "MyRoutescent" },
	{ icon: FooterLogo, name: "footerLogo" },
	{ icon: ContactBg, name: "ContactBg" },
	{ icon: BookingInActive, name: "BIA" },
	{ icon: BookingActive, name: "BA" },
	{ icon: CancelInActive, name: "CIA" },
	{ icon: CancelActive, name: "CA" },
	{ icon: OriginIcon, name: "Origin" },
	{ icon: DestinationIcon, name: "Destination" },
	{ icon: Calendar, name: "Calendar" },
	{ icon: SearchIcon, name: "Search" },
	{ icon: PopupIcon, name: "Popup" },
	{ icon: DestinationBanner, name: "DestinationBanner" },
	{ icon: ContactIcon, name: "ContactIcon" },
	{ icon: PhoneYellowIcon, name: "PhoneYellowIcon" },
	{ icon: EmailYellowIcon, name: "EmailYellowIcon" },

	{ icon: menuDot, name: "menuDot" },

	{ icon: TelephoneIcon, name: "TelephoneIcon" },
	{ icon: TelephoneIcon, name: "TelephoneIcon" },
	{ icon: BlueArrow, name: "BlueArrow" },
	{ icon: ClockIcon, name: "ClockIcon" },
	{ icon: FleetBanner, name: "FleetBanner" },
	{ icon: FleetTv, name: "FleetTv" },
	{ icon: FleetToilet, name: "FleetToilet" },
	{ icon: FleetAc, name: "FleetAc" },
	{ icon: FleetSnacks, name: "FleetSnacks" },
	{ icon: FleetBed, name: "FleetBed" },
	{ icon: AmenitiesLeftImage, name: "AmenitiesLeftImage" },
	{ icon: EmailIcon, name: "EmailIcon" },
	{ icon: BuyIconWhite, name: "BuyIconWhite" },
	{ icon: BuyIconBlue, name: "BuyIconBlue" },
	{ icon: CancelIconWhite, name: "CancelIconWhite" },
	{ icon: CancelIconBlue, name: "CancelIconBlue" },
	{ icon: TelephoneWhite, name: "TelephoneWhite" },
	{ icon: EmailWhite, name: "EmailWhite" },
	{ icon: checkboxIcon, name: "Checkbox" },
	{ icon: BG, name: "BG" },
	{ icon: animation, name: "animation" },
	{ icon: pnr, name: "pnr" },
	{ icon: close, name: "close" },


	// accounts section
	{ icon: pay, name: "pay" },
	{ icon: backimage, name: "backimage" },
	{ icon: tarjetas, name: "tarjetas" },
	{ icon: viajes, name: "viajes" },
	{ icon: movimientos, name: "movimientos" },
	{ icon: wallet, name: "wallet" },
	{ icon: shareMoney, name: "shareMoney" },
	{ icon: camera, name: "camera" },
	{ icon: checkmark, name: "checkmark" },
	{ icon: tick, name: "tick" },
	{ icon: downArrow, name: "downArrow" },
	{ icon: loginblue, name: "loginblue" },
	{ icon: recoverpswd, name: "recoverpswd" },
	{ icon: success, name: "success" },
	{ icon: failure, name: "failure" },
	{ icon: heartOn, name: "heartOn" },
	{ icon: heartOff, name: "heartOff" },
	{ icon: registrationSuccess, name: "registrationSuccess" },
	{ icon: userIcon, name: "userIcon" },
	{ icon: warning, name: "warning" },
	{ icon: AccordOpenIcon, name: "AccordOpenIcon" },
	{ icon: AccordCloseIcon, name: "AccordCloseIcon" },
	{ icon: operatorPay, name: "operatorPay" },
];

export default andimarIcons;
