import React, { PureComponent } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { AppData, CommonService } from "../services";

class PBInputPlaces extends PureComponent {
  // const {disable,placeholder,error,errorMessage,value,onTextChange,onBlur,password,type,iconLeft,showPasswordIcon} = props;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.renderAutoComplete();

    setTimeout(() => {
      if(this.props.passReference)
        this.props.passReference(this.autocomplete);
    }, 500);
  }

  renderAutoComplete = () => {
    const { google } = this.props;

    if (!google) return;
    var options = {
      componentRestrictions: { country: "cl" },
    };

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      options
    );
    // autocomplete.bindTo('bounds', map);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      console.log("place.geometry.location.lat()", place);
      if (!place.geometry) return;
      this.props.onChange({
        location: {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng(),
        },
        address:
          (place.name ? place.name + " - " : "") +
          CommonService.normalizeAddress(place.formatted_address),
      });
      if (this.props.onTextChange) {
        this.props.onTextChange(
          (place.name ? place.name + " - " : "") +
            CommonService.normalizeAddress(place.formatted_address)
        );
      }
    });
  };

  render() {
    const {
      disable,
      placeholder,
      value,
      onChange,
      onFocus,
      onBlur,
      password,
      type,
      centerText,
      inputClass = "",
      onTextChange,
    } = this.props;
    return (
      <input
        ref={(ref) => (this.autocomplete = ref)}
        // className={centerText ? inputClass : "PB-text-input"}
								className={`${inputClass} PB-text-input`}
        style={this.props.textStyle}
        onChange={
          onTextChange ? (event) => onTextChange(event.target.value) : null
        }
        onBlur={(el) => (onBlur ? onBlur(el.target.value) : null)}
        onFocus={onFocus ? onFocus : null}
        value={value}
        placeholder={placeholder}
        // placeholderTextColor="#bbb"
        disabled={disable ? true : false}
        type="text"
        // keyboardType={type}
        // secureTextEntry={this.state.isPassowrd}
        // autoCorrect={false}
      />
    );
  }
}

const styles = {
  inputContainer: {
    paddingVertical: 5,
  },
  inputWrapper: {
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: "row",
    height: 36,
    alignItems: "center",
  },
  iconContainer: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainerRight: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    height: "100%",
    paddingHorizontal: 10,
    color: "#464647",
    fontSize: 14,
    flex: 1,
    paddingVertical: 0,
    margin: 0,
    marginVertical: 0,
  },
  showPasswordContainer: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorTextContainer: {
    paddingHorizontal: 2,
  },
  errorText: {
    color: "#f00",
    fontSize: 11,
  },
  labelTextContainer: {
    paddingHorizontal: 2,
  },
  labelText: {
    color: "#464647",
    fontSize: 14,
  },
};

export default GoogleApiWrapper({
  apiKey: AppData.MAP_API_KEY,
})(PBInputPlaces);
