import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeFourContactForm from '../common/ThemeFourContactForm';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourOfficeSection from '../common/ThemeFourOfficeSection';
import ThemeFourTitle from '../common/ThemeFourTitle';

const ThemeFourContactUsScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('contactUsBanner', operator)}
          title={'Contacto'}
          description={'Home / Contacto'}
        />
        <ThemeFourTitle title='¡Comunícate con nosotros!' />

        <ThemeFourContactForm contactForm />

        <ThemeFourOfficeSection  />

        <ThemeFourFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeFourContactUsScreen);
