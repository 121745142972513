import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import getIcons from "../functions/getIcons/getIcons";
import { connectData } from "../../redux";
import KuposHeader from "../../components/KuposHeader";
import ThemeTwoAboutUs from "./common/ThemeTwoAboutUs";

const ThemeTwoAboutUsScreen = ({props}) => {
  const operator =
    JSON.parse(localStorage.getItem("op")) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <div className="">
          <KuposHeader
            title={"Nuestra"}
            boldTitle={"Empresa"}
            handleHomeSubmit={() => props.history.push("/")}
            handleBackSubmit={() => props.history.goBack()}
          />
        </div>

        <div className="new-container">
          <ThemeTwoAboutUs
            aboutUsBody={operator && operator.about_us ? operator.about_us : ""}
            imageSource={getIcons("aboutUsImage")}
												props={props}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoAboutUsScreen);
