import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenSpecialServicesScreen.css';

const ThemeSevenSpecialServicesScreen = (props) => {
  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-special-services-screen'>
          <div id='special-service'>
            <div className='new-container'>
              <div className='heading'>
                <h1>Servicios</h1>
                {/* <ul className='service-list hidden-sm hidden-xs'>
                  <li>
                    <a href='/'>Venta de pasajes online</a>
                  </li>
                  <li>
                    <a href='/ticket-sales'>Venta de pasajes presencial</a>
                  </li>
                  <li className='active-color'>
                    <a href='/special-service'>Servicios especiales</a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>

          <div class='new-container'>
            <div class='col-md-12'>
              <div class='special-service'>
                <h3>
                  <span>Servicios especiales</span>
                </h3>
                <h5>
                  Buses Fernández te invita a conocer la{' '}
                  <span>Patagonia Chilena</span> y para esto contamos con
                  servicios exclusivos y especiales para atender tus necesidades
                  en la{' '}
                  <span>Región de Magallanes y la Antártica Chilena.</span>
                </h5>
                <h5>
                  Contamos con una{' '}
                  <span>
                    flota de buses equipado para entregar confort y seguridad
                  </span>{' '}
                  de nuestros pasajeros durante el viaje y que nos permite
                  atender requerimientos de traslado de pasajeros para turismo,
                  con visita a las típicas alternativas de nuestra región: City
                  tour Punta Arenas o Puerto Natales, Fuerte Bulnes, Conexión
                  con expediciones a pingüineras, Parque Nacional Torres del
                  Paine y mucho más. También trabajamos con{' '}
                  <span>
                    traslados para empresas y requerimientos de particulares.
                  </span>
                </h5>
                <p>
                  Contáctanos a nuestros teléfonos o envíanos tus requerimientos
                  por esta vía:
                </p>
                <div class='form-section'>
                  <div class='form'>
                    <form
                      accept-charset='UTF-8'
                      action='/feedbacks/create_feedback'
                      class='form-contact'
                      data-remote='true'
                      id='contact_form'
                      method='post'
                    >
                      <div className='d-flex'>
                        <div class='col-md-4'>
                          <div class='form-group'>
                            <label for='username'>Nombre completo</label>
                            <input
                              placeholder='Ej. Maria'
                              class='form-control'
                              maxlength='20'
                              required='required'
                              size='20'
                              type='text'
                              name='feedback[username]'
                              id='feedback_username'
                            />
                          </div>
                        </div>
                        <div class='col-md-4'>
                          <div class='form-group'>
                            <label for='email'>Email</label>
                            <input
                              placeholder='ejemplo@gmail.com'
                              class='form-control'
                              maxlength='30'
                              required='required'
                              size='30'
                              type='text'
                              name='feedback[email]'
                              id='feedback_email'
                            />
                          </div>
                        </div>
                        <div class='col-md-4'>
                          <div class='form-group'>
                            <label for='contact_number'>Nº de contacto</label>
                            <input
                              placeholder='+56 9 0000 0000'
                              class='form-control'
                              maxlength='30'
                              required='required'
                              size='30'
                              type='text'
                              name='feedback[contact_number]'
                              id='feedback_contact_number'
                            />
                          </div>
                        </div>
                      </div>

                      <div class='col-md-12'>
                        <div class='form-group'>
                          <label for='comments'>Mensaje</label>
                          <textarea
                            style={{ height: 59 }}
                            placeholder=''
                            class='form-control'
                            maxlength='2000'
                            required='required'
                            name='feedback[comments]'
                            id='feedback_comments'
                          ></textarea>
                        </div>
                        <div class=' text-center'>
                          <button
                            class='btn btn-success hvr-back-pulse'
                            id='submit_btn'
                          >
                            ENVIAR
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class='clearfix'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenSpecialServicesScreen);
