import './Footer6.css';

import { IonApp, IonContent } from '@ionic/react';

import React from 'react';
import { connectData } from '../../../redux';

const Footer6 = (props) => {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;
  console.log('first=============', operator);
  return (
    <div
      className='footer6'
      style={{
        background:
          operator &&
          operator.color_codes &&
          operator.color_codes.sub_footer_color
            ? operator.color_codes.sub_footer_color
            : '',
        marginTop: '30px',
      }}
    >
      <style>
        .footer-input::placeholder, textarea::placeholder {'{'}
        font-size: 16px; font-weight: bold
        {'}'}
        .footer-input {'{'}
        font-size: 14px
        {'}'}
      </style>
      <div className='text-white'>
        <div
          className='main-footer-container'
          style={{
            background:
              operator &&
              operator.color_codes &&
              operator.color_codes.footer_color
                ? operator.color_codes.footer_color
                : '',
          }}
        >
          <a href='/'>
            <img
              src={operator.header_logo}
              alt='DIS'
              className='logo2 main-footer-logo'
              id='main-logo'
            />
          </a>
        </div>
        <div className='footer-6-section2'>
          <div className='boxes px-3 col border-right'>
            <div
              className='color-white footer-text text-justify font-14px pullman-bold-text'
              style={{ lineHeight: 1.3 }}
            >
              San Andrés es una empresa de viajes dedicada a darte una
              experiencia cómoda en todo momento. Queremos entregarte un
              servicio de calidad a través de nuestra flota y personal
              completamente preparados para tu satisfacción.
              <br />
              <br />A través de nuestros viajes deseamos ganar la verdadera
              confianza y confort de nuestros clientes.
            </div>
            <div className='d-flex justify-between text-justify font-14px'>
              <div className='margin-top-10 margin-bottom-10 '>
                <a
                  // className=' margin-right-10'
                  href='https://www.facebook.com/pullmansanandres.cl'
                  target='_blank'
                >
                  <span className='san-andreas-facebook1 mx-1'></span>
                </a>
                <a
                  href='https://www.instagram.com/pullmansanandres.cl/'
                  target='_blank'
                >
                  <span className='san-andreas-insta1 mx-1'></span>
                </a>
              </div>
              <div
                className=' '
                style={{
                  lineHeight: 1.2,
                }}
              >
                <div
                  className='font-14px  color-white margin-top-13'
                  style={{
                    lineHeight: 1.2,
                  }}
                >
                  <span
                    className='bold-text'
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Representante legal:
                  </span>
                  Rodrigo Challapa Gómez
                </div>

                <div
                  className='font-14px  color-white margin-top-10'
                  style={{
                    lineHeight: 1.2,
                  }}
                >
                  <span
                    className='bold-text'
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Direccion:
                  </span>
                  Patricio Lynch s/n, Terminal de Buses Of. 16, Iquique. Región
                  de Tarapacá.
                </div>

                <div
                  className='font-14px  color-white margin-top-10'
                  style={{
                    lineHeight: 1.2,
                  }}
                >
                  <span
                    className='bold-text'
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Correo:
                  </span>
                  contacto@transportestransmat.cl
                </div>

                <div
                  className='font-14px  color-white margin-top-10'
                  style={{
                    lineHeight: 1.2,
                  }}
                >
                  <span
                    className='bold-text'
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Teléfono:
                  </span>{' '}
                  +56933798168
                </div>
              </div>
            </div>
          </div>
          <div className='boxes px-3  col border-right margin-top-20'>
            <div className=' bold-text mb-2 color-white footer-sub-heading font-24px'>
              Sucursales:
            </div>
            <ul
              className='font-italic bold-text color-white font-16px'
              style={{ lineHeight: 1.2 }}
            >
              <li className='mb-1'>Arica - Terminal Rodoviario.</li>
              <li className='mb-1'>Iquique - Terminal Rodoviario.</li>
              <li className='mb-1'>
                Antofagasta - Terminal Card. Carlos Oviedo.
              </li>
              <li className='mb-1'>
                Calama - Terminal Rodoviario. Santiago - Terminal San Borja.
              </li>
              <li className='mb-4'>Santiago - Terminal San Borja.</li>
            </ul>
            <div className='font-24px bold-text color-white  mb-2 footer-sub-heading font-24'>
              Horario de Atención:
            </div>
            <div className='font16 color-white font-16px'>
              Lunes a Viernes - 09:00 a 18:00 hrs.
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className='boxes px-3 col'>
            <div className=' bold-text mb-2 d-flex align-items-center color-white footer-sub-heading font-24px'>
              ¿Necesitas Ayuda?{' '}
              <img
                src='/assets/pullmanSanAndres/footer-whatsapp.svg'
                style={{
                  width: '25px',
                  marginLeft: '10px',
                }}
                alt=''
              />
            </div>
            <div
              className='font16 font-italic mb-2 color-white font-16px'
              style={{
                marginBottom: 20,
              }}
            >
              Contáctanos al: {operator && operator.phone_number}
            </div>

            <form action='' className='w-100'>
              <input
                type='text'
                placeholder='Nombre'
                className=' footer-input w-100 py-1 footer-input px-1  mb-1'
              />
              <input
                type='text'
                placeholder='E-mail'
                className=' footer-input w-100 py-1 footer-input px-1  mb-1'
              />
              <input
                type='text'
                placeholder='Numero de contacto'
                className=' footer-input w-100 py-1 footer-input px-1  mb-1'
              />
              <textarea
                name=''
                placeholder='Mensaje'
                className='footer-input'
                style={{ resize: 'none' }}
                id=''
                rows='3'
                className='w-100 px-1 py-1 mb-1'
              />
              <div className='d-flex justify-content-end footer-button-container'>
                <button
                  className='  px-4 py-2 text-white footer-button'
                  style={{
                    backgroundColor:
                      operator &&
                      operator.color_codes &&
                      operator.color_codes.primary_color
                        ? operator.color_codes.primary_color
                        : '',
                    border: 'none',
                  }}
                >
                  ENVIAR
                </button>
              </div>
            </form>
          </div>

          {/* <div>PULLMAN SAN ANDRÉS 2022 © TODOS LOS DERECHOS RESERVADOS - POWERED BY KUPOS.COM</div> */}
        </div>
        <div className='poweredBy'>
          <p className='color-white font-12px'>
            Pullman San Andrés 2022 © Todos los derechos reservados - Powered by{' '}
            <a
              href='https://kupos.cl'
              target='_blank'
              style={{ color: 'white' }}
            >
              kupos.cl
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default connectData()(Footer6);
