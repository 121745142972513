import React, { Component, useRef, useState } from "react";

import { connectData } from "../../../redux";
import {
  CarpoolSuccessFailureModal,
  PBCalendar,
  PBInput,
  PBSelect,
  PBText,
  PBTouchable,
} from "../../../components";
import ReactDropdown from "../../../components/KuposDropdown";
import { AppData, CommonService, DateSerivce } from "../../../services";
import getIcons from "../../functions/getIcons/getIcons";
import Select from "react-select";
import PBInputPlaces from "../../../components/PBInputPlaces";

const ContactFormService = props => {
  const generateReactSelectOptions = (arr = []) => {
    try {
      return arr.map(([label, value]) => ({ label, value }));
    } catch (e) {
      console.log("🚀 ~ e:", e);
    }
  };
  const initialState = {
    name: "",
    nameError: "",
    lastNameError: "",
    lastName: "",
    email: "",
    emailError: "",
    origin: "",
    originError: "",
    destination: "",
    destinationError: "",
    date: DateSerivce.getTodayString("dd-mm-yyyy"),
    dateError: "",
    noOfPassengers: "",
    noOfPassengersError: "",
    comments: "",
    commentsError: "",
    showCalendar: false,
    showModal: false,
  };

  const errorInitialState = {
    source: "",
    destination: "",
    date: "",
  };

  const [state, setState] = useState(initialState);
  console.log("🚀 ~ ContactFormService ~ state:", state);
  const [error, setError] = useState(errorInitialState);
  const destinationRef = useRef({});

  const opname = props.operator && props.operator.operator_name;

  const onSourceChange = value => {
    setError({ ...error, source: null });
    setState(prevData => ({
      ...prevData,
      origin: value,
      destination:
        state.destination.value === value.value ? null : prevData.destination,
    }));
  };

  const onDestinationChange = value => {
    setError({ ...error, destination: null });
    setState(prevData => ({ ...prevData, destination: value }));
    // props.actions.pbSetDestinationCity(value);
  };

  const getOriginSelectedFilteredDestinations = (rawArray, itemToRemove) => {
    if (rawArray && itemToRemove) {
      return rawArray
        .map(function (obj) {
          if (obj.label == itemToRemove.label) {
          } else {
            return obj;
          }
        })
        .filter(function (obj) {
          return typeof obj !== "undefined";
        });
    } else if (!itemToRemove) {
      // return rawArray;
    }
  };
  const getColor = () => {
    if (opname === "sanantonio" || opname === "traveltur") {
      return {
        contactBG: props.operator && props.operator.theme_color2,
        dashline: "#fff",
      };
    }
    if (opname === "tacoha") {
      return {
        contactBG: props.operator && props.operator.color_codes.secondary_color,
        dashline:
          props.operator &&
          props.operator.color_codes &&
          props.operator.color_codes.button_color,
      };
    }
    if (opname === "liquine") {
      return {
        contactBG: props.operator && props.operator.color_codes.header_color,
        dashline:
          props.operator &&
          props.operator.color_codes &&
          props.operator.color_codes.secondary_color,
      };
    }
    return {
      contactBG: "#161d4f",
      dashline: "#fff",
    };
  };

  const onInputChange = (value, field) => {
    setState({
      ...state,
      [field]: value,
      [`${field}Error`]: "",
      showCalendar: false,
    });
  };

  const isFormValid = () => {
    let error = 0;
    if (!state.name) {
      setState(prevData => ({
        ...prevData,
        nameError: "Ingresa tu nombre y apellido",
      }));
      error++;
    }
    // if (!state.lastName) {
    // 		setState(prevData => ({
    // 				...prevData,
    // 				lastNameError: 'Selecciona tu Tipo de solicitud',
    // 		}));
    // 		error++;
    // }

    if (!state.date) {
      setState(prevData => ({
        ...prevData,
        dateError: "Ingresa tu fecha de viaje",
      }));
      error++;
    }

    if (!state.email) {
      setState(prevData => ({
        ...prevData,
        emailError: "Ingresa tu correo electrónico",
      }));
      error++;
    } else if (!CommonService.isEMailValid(state.email)) {
      setState(prevData => ({
        ...prevData,
        emailError: "Ingrese un email valido",
      }));
      error++;
    }

    if (!state.noOfPassengers) {
      setState(prevData => ({
        ...prevData,
        noOfPassengersError: "Ingresa el n° de pasajeros",
      }));
      error++;
    }

    if (!state.comments) {
      setState(prevData => ({
        ...prevData,
        commentsError: "Ingresa los detalles de tu solicitud",
      }));
      error++;
    }

    if (!state.date) {
      setState(prevData => ({
        ...prevData,
        dateError: "Ingresa tu comentario",
      }));
      error++;
    }
    if (!state.origin) {
      setState(prevData => ({
        ...prevData,
        originError: "Ingresa tu ciudad de origen",
      }));
      error++;
    }

    if (!state.destination) {
      setState(prevData => ({
        ...prevData,
        destinationError: "Ingresa tu ciudad de destino",
      }));
      error++;
    }

    return error;
  };

  const update = e => {
    // e.preventDefault();
    if (isFormValid()) {
      return;
    }

    let data = {
      first_name: state.name,
      last_name: state.lastName,
      rut_number: "",
      phone_number: "",
      email: state.email,
      origin: state.origin,
      destination: state.destination,
      travel_date: state.date,
      no_of_passengers: state.noOfPassengers,
      comments: state.comments,
						api_operator_id: props.operator.operator_id,
    };

    props.getSpecialService({
      callback: res => onApiResponse(res),
      data: {
        special_service_request: data,
      },
    });
  };

  const onApiResponse = res => {
    if (res && res.data && res.data.data && res.data.data.message) {
      setState({
        showModal: true,
        responseMessage: res.data.data.message,
        status: res.data.status,
      });
    }
  };

  const submit = () => {
    if (this.props.andimar) {
      window.dataLayer.push({
        event: "formContacto",
      });
    }
  };

  const getReference = ref => {
    if (ref) {
      ref.focus();
    }
  };

  return (
    <div className="contact-form w-100 montserrat-bold ">
      <style>
        {`
						.PB-input-container .input-wrapper {
							padding: 0;
							backgroundColor: white

						}
						// .form-group input, .form-group textarea {
						// 	padding-left: 10px !important;
						// }
					
						`}
      </style>
      <div
        className="contact-form__head text-uppercase px-5 py-3 montserrat-bold font11 avenir-semi"
        style={{
          background: getColor().contactBG,
        }}
      >
        Cotiza tu viaje especial
        <span class="d-inline-block title-dash-line"></span>
      </div>

      <div
        className="contact-form__container py-4"
        style={{ background: props.isSanantonio ? "#F4F4F4" : "" }}
      >
        <form className="form-4">
          <div className="form-group w-100 px-5 font13 avenir-semi">
            <PBText
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              Nombre y apellido
            </PBText>
            <div>
              <PBInput
                type="text"
                className="w-100 font13"
                value={state.name}
                onChange={value => onInputChange(value, "name")}
                error={state.nameError}
                errorMessage={state.nameError}
              />
            </div>
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <PBText
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              Correo electrónico
            </PBText>
            <PBInput
              type="email"
              className="w-100 font13"
              value={state.email}
              onChange={value => onInputChange(value, "email")}
              error={state.emailError}
              errorMessage={state.emailError}
            />
          </div>

          <div className="form-group w-100 px-5 avenir-semi relative">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              Origen
            </label>
            <div >
              <PBInputPlaces
                value={state.origin && state.origin.value}
                onTextChange={value => onInputChange(value, "origin")}
                passReference={getReference}
																placeholder={"Origen"}
                onChange={() => null}
                inputClass="border pl-10"
              />

              <img
                src={getIcons("originoIcon")}
                className="special-service-origin"
              />
            </div>
            {state.originError && (
              <span style={{ color: "rgb(255, 0, 0)", fontSize: "11px" }}>
                {state.originError}
              </span>
            )}
          </div>

          <div className="form-group w-100 px-5 avenir-semi relative">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
                marginBottom: 3,
              }}
            >
              Destino
            </label>
            <div>
              <PBInputPlaces
                value={state.destination && state.destination.value}
                onTextChange={value => onInputChange(value, "destination")}
																placeholder={"Destino"}
                passReference={getReference}
                onChange={() => null}
                error={state.destinationError}
                errorMessage={state.destinationError}
																inputClass="border pl-10"
              />

              <img
                className="special-service-dest"
                src={getIcons("destinoIcon")}
              />
            </div>
            {state.destinationError && (
              <span style={{ color: "rgb(255, 0, 0)", fontSize: "11px" }}>
                {state.destinationError}
              </span>
            )}
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <PBText
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              Fecha de viaje
            </PBText>
            <PBCalendar
              showCalendar={state.showCalendar}
              onDateChange={value => onInputChange(value, "date")}
              selectedDate={state.date}
              minDate={DateSerivce.getTodayString("dd-mm-yyyy")}
            />

            <SelectDateItem
              icon={getIcons("Calendar")}
              onSelectDate={() => setState({ ...state, showCalendar: true })}
              operator={props.operator}
              selectedDate={state.date}
            />
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <PBText
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              N° de pasajeros
            </PBText>
            <PBInput
              type=""
              className="w-100 font13"
              value={state.noOfPassengers}
              onChange={value => onInputChange(value, "noOfPassengers")}
              error={state.noOfPassengersError}
              errorMessage={state.noOfPassengersError}
            />
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <PBText
              style={{
                color:
                  props.isSanantonio &&
                  props.operator.color_codes &&
                  props.operator.color_codes.sub_footer_color &&
                  props.operator.color_codes.sub_footer_color,
              }}
            >
              Comentarios / Observaciones
            </PBText>
            <PBInput
              type=""
              className="w-100 font13"
              value={state.comments}
              onChange={value => onInputChange(value, "comments")}
              error={state.commentsError}
              errorMessage={state.commentsError}
            />
          </div>

          <div className="form-group w-100 px-5 ">
            <button
              className="w-100 font14"
              type="reset"
              style={{
                background:
                  props.operator &&
                  props.operator.color_codes &&
                  props.operator.color_codes.button_color,
              }}
              onClick={update}
            >
              ENVIAR
            </button>
          </div>
        </form>
      </div>
      <CarpoolSuccessFailureModal
        showModal={state.showModal}
        success={true}
        smallIcon={true}
        bodyText={state.responseMessage}
        buttonText={"OK"}
        onButtonPress={() => {
          // window.location.reload();

          setState({
            showModal: false,
          });
        }}
      />
    </div>
  );
};

const SelectDateItem = props => {
  return (
    <PBTouchable onPress={props.onSelectDate}>
      <div className="date-item">
        <div className="date-label-contaner ">
          <PBText
            fontSize={"font14"}
            fontColor={"dark-font"}
            fontStyle={"bold-font"}
          >
            {props.label}
          </PBText>
        </div>
        <div className="new-home-date-box-inner font14">
          <img style={{ marginRight: 3 }} src={props.icon} />
          <PBText
            style={{ marginTop: 3 }}
            fontSize={"font14"}
            fontColor={"dark-font"}
          >
            {props.selectedDate
              ? props.selectedDate
              : DateSerivce.getTodayString()}
          </PBText>
        </div>
      </div>
    </PBTouchable>
  );
};

export default connectData()(ContactFormService);
