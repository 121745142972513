import './TarapacaFooter.css';

import React from 'react';

const TarapacaFooter = ({ operator }) => {
	return (
		operator && (
			<div className='ThemeTwoFooter '>
				<div
					style={{
						background:
							operator &&
								operator.color_codes &&
								operator.color_codes.footer_color
								? operator.color_codes.footer_color
								: '',
					}}
					className='footer'
				>
					<div className=''>
						<a href='/'>
							<img
								src={
									operator && operator.footer_logo ? operator.footer_logo : ''
								}
								// alt={this.props.t('TRANSLATIONS.VIATUR_LOGO')}
								style={{
									// marginTop: '0',
									width:
										operator && operator.footer_logo_width
											? operator.footer_logo_width
											: '250px',
									height: 'auto',
								}}
								alt=''
							/>
						</a>
					</div>

					<div
						className='info'
						style={{
							color:
								operator &&
									operator.color_codes &&
									operator.color_codes.footer_text_color
									? operator.color_codes.footer_text_color
									: '#fff',
						}}
					>
						<span>
							{operator.title ? operator.title.split('|')[0] : ''}{' '}
							{new Date().getFullYear()}.
						</span>
						<span className=''>Todos los derechos reservados ©</span>
						<span>
							<span>Powered by</span>
						</span>
					</div>
					<a
						href='https://kupos.cl'
						target='_blank'
						style={{
							color:
								operator &&
									operator.color_codes &&
									operator.color_codes.footer_text_color
									? operator.color_codes.footer_text_color
									: '#fff',
						}}
					>
						kupos.cl
						{/* <img
              src={getIcons('BottomFooterKuposLogo', 'kuposLogo')}
              // alt={this.props.t('TRANSLATIONS.VIATUR_LOGO')}
              className='footer-kupos-logo'
              alt=''
            /> */}
					</a>
				</div>
			</div>
		)
	);
};

export default TarapacaFooter;
