import IvergramaAboutUsScreen from "../Ivergrama/IvergramaPages/IvergramaAboutUsScreen";
import React from "react";
import TarapacaAboutUsScreen from "../Tarapaca/TarapacaAboutUsScreen";
import TepualAboutUs from "../theme5/TepualPages/TepualAboutUs";
import ThemeFiveAboutUsScreen from "../theme5/ThemeFivePages/ThemeFiveAboutUsScreen";
import ThemeFourAboutUsScreen from "../ThemeFour/ThemeFourPages/ThemeFourAboutUsScreen";
import ThemeSevenAboutUsScreen from "../ThemeSeven/ThemeSevenPages/ThemeSevenAboutUsScreen";
import ThemeTenAboutScreen from "../ThemeTen/ThemeTenPages/ThemeTenAboutScreen";
import ThemeThreeAboutUsScreen from "../ThemeThree/ThemeThreePages/ThemeThreeAboutUsScreen";
import ThemeTwoAboutUsScreen from "../ThemeTwo/ThemeTwoAboutUsScreen";
import { connectData } from "../../redux";

const CommonAboutUsPage = (props) => {
	const getPage = () => {
		const themeType =
			props.data && props.data.operator && props.data.operator.theme_type;
		// Sajad used TepualAboutUs page in  andimar in get routes so i copied same
		if (themeType === 1) {
			return <TepualAboutUs />;
		} else if (themeType === 5) {
			return <ThemeFiveAboutUsScreen />;
		} else if (themeType === 4) {
			return <ThemeFourAboutUsScreen />;
		} else if (themeType === 3) {
			return <ThemeThreeAboutUsScreen />;
		} else if (themeType === 7) {
			return <ThemeSevenAboutUsScreen />;
		} else if (themeType === 10) {
			return <ThemeTenAboutScreen />;
		} else if (themeType === 2) {
			return <ThemeTwoAboutUsScreen props={props} />
		}
		else if (props.data.operator && props.data.operator.operator_name === "tarapaca") {
			return <TarapacaAboutUsScreen props={props} />
		}
		else if (props.data.operator && props.data.operator.operator_name === "ivergrama" ) {
			return <IvergramaAboutUsScreen props={props} />
		}

		else if (themeType === 3) {
			return <ThemeThreeAboutUsScreen props={props} />
		}
	};

	return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonAboutUsPage);
