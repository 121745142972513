import "react-dropdown/style.css";

import Dropdown from "react-dropdown";
import React from "react";

const ReactDropdown = props => {
  let arr = [];
  props.data &&
    props.data.map(val => {
      arr.push({ ...val, value: val.id, label: val.name });
    });

  const onSelect = e => {
    const findItem = arr.find(val => val.value === e.value);
    props.onSelectChange(findItem);
  };

  return (
    <div className="react-dropdown-container">
      <Dropdown
						
        options={arr}
        onChange={onSelect}
        value={props.value}
        placeholder={props.placeholder}
        className={props.noBorder ? "border-less font14" : "font14"}
        disabled={props.disabled}
								
      />
    </div>
  );
};

export default ReactDropdown;
