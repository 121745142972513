import React, { PureComponent } from "react";

import { CommonService } from "../services";
import PBCalendar from "./PBCalendar";
import PBInputPlaces from "./PBInputPlaces";
import { PBText } from ".";
import PBTouchable from "./PBTouchable";

class PBInput extends PureComponent {
  // const {disable,placeholder,error,errorMessage,value,onTextChange,onBlur,password,type,iconLeft,showPasswordIcon} = props;
  constructor(props) {
    super(props);
    this.state = {
      isPassowrd: this.props.password,
      showCalendar: false,
    };
  }

  componentDidMount() {}

  onDateChange = day => {
    this.props.onDateChange(day);
    this.setState({ showCalendar: false });
  };
  render() {
    const {
      label,
      disable,
      placeholder,
      error,
      errorMessage,
      value,
      onChange,
      onFocus,
      onBlur,
      password,
      type,
      iconLeft,
      iconRight,
      rightIconHeight,
      showPasswordIcon,
      centerText,
      inputClass,
      onDateChange,
      onTextChange,
      selectedDate,
      calendarTitle,
      codeandphone,
      countryCode,
      onCountryCodeChange,
      style,
      onRightIconClick,
      operator,
    } = this.props;
    return (
      <div className="PB-input-container" style={this.props.style}>
        {label ? (
          <div style={styles.labelTextContainer}>
            <PBText
              fontSize={"font14"}
              fontStyle={"bold-text"}
              fontColor={"dark-font"}
            >
              {label}
            </PBText>
            {/* <span style={styles.labelText}>{label}</span> */}
          </div>
        ) : null}
        <div
          className="input-wrapper"
          style={{
            ...this.props.containerStyle,
            ...(disable ? { pointerEvents: "none", opacity: 0.6 } : {}),
          }}
        >
          {iconLeft ? (
            <div
              className="login-left-icon-container"
              style={{ marginLeft: "5px" }}
            >
              <img style={styles.cityBoxIcon} height={20} src={iconLeft} />
            </div>
          ) : null}
          {codeandphone ? (
            <div className="country-code-container code-and-phone-style">
              <input
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                value={countryCode}
                disabled={true}
                keyboardType={type === "number" ? "numeric" : type}
                autoCorrect={false}
                onChange={el => onCountryCodeChange(el.target.value)}
              />
            </div>
          ) : null}
          {type != "date" ? (
            // type == "phone-pad" ? (
            //     <input
            //   <div className="display-flex align-center">
            //     disabled= {true}
            //     style={{width:35}}
            //     className="dark-font"
            //     placeholder="+51"
            //     // value="+51"
            //     />
            //     <PBText fontColor={"dark-font"} style={{ marginRight: 10}}> | </PBText>
            //     <input
            //       className={centerText ? inputClass : "PB-text-input"}
            //       style={(styles.textInput, this.props.textStyle)}
            //       onChange={el =>
            //         onTextChange
            //           ? onTextChange(el.target.value)
            //           : onChange(el.target.value)
            //       }
            //       onBlur={el => onBlur(el.target.value)}
            //       value={value ? value : ""}
            //       placeholder={placeholder}
            //       // placeholderTextColor="#bbb"
            //       disabled={disable ? true : false}
            //       type={this.state.isPassowrd ? "password" : "text"}
            //       // keyboardType={type}
            //       // secureTextEntry={this.state.isPassowrd}
            //       // autoCorrect={false}
            //     />
            //   </div>
            // ) :
            type != "places" ? (
              <input
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                className={centerText ? inputClass : "PB-text-input"}
                style={(styles.textInput, this.props.textStyle)}
                onChange={el =>
                  type != "places"
                    ? onTextChange
                      ? onTextChange(el.target.value)
                      : onChange(el.target.value)
                    : null
                }
                onBlur={el => (onBlur ? onBlur(el.target.value) : null)}
                onFocus={onFocus ? onFocus : null}
                value={
                  type == "email-address" || type == "email"
                    ? CommonService.toLowerCase(value)
                    : value
                }
                placeholder={placeholder}
                placeholderTextColor={"#464647"}
                disabled={disable ? true : false}
                type={
                  (!type && !password) || type == "places" || type == "text"
                    ? "text"
                    : this.state.isPassowrd
                    ? "password"
                    : type == "email-address"
                    ? "email"
                    : type
                }
                // keyboardType={type}
                // secureTextEntry={this.state.isPassowrd}
                // autoCorrect={false}
                readOnly={this.props.readOnly}
                style={{ padding: "5px 10px" }}
              />
            ) : (
              <div className="flex1">
                <PBInputPlaces
                  placeholder={placeholder}
                  onFocus={onFocus}
                  value={value}
                  textStyle={this.props.textStyle}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={this.props.disabled}
                  onTextChange={this.props.onTextChange}
                  readOnly={this.props.readOnly}
                />
              </div>
            )
          ) : (
            <PBTouchable onPress={() => this.setState({ showCalendar: true })}>
              <div
                style={{
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "5px 10px",
                }}
              >
                {selectedDate ? (
                  <PBText fontSize={"font14"}>{selectedDate}</PBText>
                ) : (
                  <PBText fontSize={"font14"} fontColor={"bbb-font"}>
                    {placeholder}
                  </PBText>
                )}
              </div>
            </PBTouchable>
          )}
          {iconRight ? (
            <div onClick={onRightIconClick} style={styles.iconContainerRight}>
              <img
                style={styles.cityBoxIconRight}
                height={rightIconHeight ? rightIconHeight : 20}
                src={iconRight}
              />
            </div>
          ) : null}
          {showPasswordIcon && password ? (
            <div>
              <PBTouchable
                onPress={() =>
                  this.setState({ isPassowrd: !this.state.isPassowrd })
                }
              >
                <div className="show-password-container">
                  {this.state.isPassowrd ? (
                    <img
                      className={"password-show"}
                      src="../assets/carpool/password_off.svg"
                      size={20}
                    />
                  ) : (
                    <img
                      className={"password-hide"}
                      src="../assets/carpool/password_on.svg"
                      size={20}
                    />
                  )}
                </div>
              </PBTouchable>
            </div>
          ) : null}
        </div>
        {error ? (
          <div style={styles.errorTextContainer}>
            <span style={styles.errorText}>{errorMessage}</span>
          </div>
        ) : null}
        <PBCalendar
          onBackButtonPress={() => this.setState({ showCalendar: false })}
          showCalendar={this.state.showCalendar}
          onDateChange={day => this.onDateChange(day)}
          selectedDate={selectedDate}
          title={calendarTitle}
          operator={operator}
        />
      </div>
    );
  }
}

const styles = {
  inputContainer: {
    paddingVertical: 5,
  },
  inputWrapper: {
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: "row",
    height: 36,
    alignItems: "center",
  },
  iconContainer: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainerRight: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    height: "100%",
    paddingHorizontal: 10,
    color: "#464647",
    fontSize: 13,
    flex: 1,
    paddingVertical: 0,
    margin: 0,
    marginVertical: 0,
  },
  showPasswordContainer: {
    height: "100%",
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorTextContainer: {
    paddingHorizontal: 2,
  },
  errorText: {
    color: "#f00",
    fontSize: 11,
  },
  labelTextContainer: {
    paddingHorizontal: 2,
  },
  labelText: {
    color: "#464647",
    fontSize: 14,
  },
};

export default PBInput;
