/* Core CSS required for Ionic components to work properly */

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./css/global.css";
import "./theme/variables.css";

import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { Loader, PBContactModal } from "./components";
import React, { Suspense } from "react";

import AeroquintaFleet from "./themes/ThemeThree/ThemeThreePages/AeroquintaFleet";
import AndimarTermsAndConditions from "./themes/theme1/AndimarPages/AndimarTermsAndConditions";
import { AppPage } from "./declarations";
import ArzolaAboutus from "./themes/ThemeThree/ThemeThreePages/ArzolaAboutus";
import CarpoolMyAccountScreen from "./screens/CarpoolMyAccountScreen/CarpoolMyAccountScreen";
import CarpoolMyTransactionsScreen from "./screens/CarpoolMyTransactionsScreen/CarpoolMyTransactionsScreen";
import CarpoolMyTripsScreen from "./screens/CarpoolMyTripsScreen/CarpoolMyTripsScreen";
import CarpoolTransactionDetailsScreen from "./screens/CarpoolTransactionDetailsScreen/CarpoolTransactionDetailsScreen";
import CarpoolTripDetailsScreen from "./screens/CarpoolTripDetailsScreen/CarpoolTripDetailsScreen";
import CarpoolVirtualMoneyScreen from "./screens/CarpoolVirtualMoneyScreen/CarpoolVirtualMoneyScreen";
import CommonAboutUsPage from "./themes/common-pages/CommonAboutUsPage";
import CommonAgenciesPage from "./themes/common-pages/CommonAgenciesPage";
import CommonContactUsPage from "./themes/common-pages/CommonContactUsPage";
import CommonDestinationPage from "./themes/common-pages/CommonDestinationPage";
import CommonGalleryPage from "./themes/common-pages/CommonGalleryPage";
import CommonOfficesPage from "./themes/common-pages/CommonOfficesPage";
import CommonPrivacyPolicyPage from "./themes/common-pages/CommonPrivacyPolicyPage";
import { CommonService } from "./services";
import CommonServicesPage from "./themes/common-pages/CommonServicesPage";
import CommonTnCPage from "./themes/common-pages/CommonTnCPage";
import EditAccountScreen from "./screens/EditAccountScreen/EditAccountScreen";
import HomeMyCards from "./screens/HomeMyCards/HomeMyCards";
import { IonReactRouter } from "@ionic/react-router";
import IvergramaAboutUsScreen from "./themes/Ivergrama/IvergramaPages/IvergramaAboutUsScreen";
import IvergramaContactUsScreen from "./themes/Ivergrama/IvergramaPages/IvergramaContactUsScreen";
import IvergramaFaqScreen from "./themes/Ivergrama/IvergramaPages/IvergramaFaqScreen";
import IvergramaServiceScreen from "./themes/Ivergrama/IvergramaPages/IvergramaServiceScreen";
import IvergramaTnCScreen from "./themes/Ivergrama/IvergramaPages/IvergramaTnCScreen";
import KuposHelmet from "./themes/common/KuposHelmet";
import Menu from "./components/Menu";
import MobileRegisterScreen from "./screens/MobileRegisterScreen/MobileRegisterScreen";
import ModifyProfileScreen from "./screens/ModifyProfileScreen/ModifyProfileScreen";
import NewForgotPasswordScreen from "./screens/NewForgotPasswordScreen/NewForgotPasswordScreen";
import NewMyQRScreen from "./screens/NewMyQRScreen/NewMyQRScreen";
import NewScanQRScreen from "./screens/NewScanQRScreen/NewScanQRScreen";
import NewTransferRechargeScreen from "./screens/NewTransferRechargeScreen/NewTransferRechargeScreen";
import OtpChangePasswordScreen from "./screens/OtpChangePasswordScreen/OtpChangePasswordScreen";
import OtpScreen from "./screens/OtpScreen/OtpScreen";
import PreferencesScreen from "./screens/PreferencesScreen/PreferencesScreen";
import { Provider } from "./redux";
import PullmanSanAndreasAbout from "./themes/theme6/PullmanSanAndreasAbout";
import PullmanSanAndreasContact from "./themes/theme6/PullmanSanAndreasContact";
import PullmanSanAndreasSafety from "./themes/theme6/PullmanSanAndreasSafety";
import PullmanSanAndreasServices from "./themes/theme6/PullmanSanAndreasServices";
import RechargeMapScreen from "./screens/RechargeMapScreen/RechargeMapScreen";
import RechargeStepsScreen from "./screens/RechargeStepsScreen/RechargeStepsScreen";
import RechargeWalletScreen from "./screens/RechargeWalletScreen/RechargeWalletScreen";
import RegisterSecreen from "./screens/RegisterSecreen/RegisterSecreen";
import RegistrationSuccessScreen from "./screens/RegistrationSuccessScreen/RegistrationSuccessScreen";
import { Route } from "react-router-dom";
import ServiceDetailsReturnScreenV2 from "./screens/ServiceDetailsReturnScreen/ServiceDetailsReturnScreenV2";
import ServiceDetailsScreenV2 from "./screens/ServiceDetailsScreen/ServiceDetailsScreenV2";
import ServiceListReturnScreenV2 from "./screens/ServiceListReturnScreen/ServiceListReturnScreenV2";
import ServiceListScreenV2 from "./screens/ServiceListScreen/ServiceListScreenV2";
import TarapacaAboutUsScreen from "./themes/Tarapaca/TarapacaAboutUsScreen";
import TarapacaContactUsScreen from "./themes/Tarapaca/TarapacaContactUsScreen";
import TarapacaGallery from "./themes/Tarapaca/common/TarapacaGallery";
import TarapacaGalleryScreen from "./themes/Tarapaca/TarapacaGalleryScreen";
import TarapacaOfficesScreen from "./themes/Tarapaca/TarapacaOfficesScreen";
import TarapacaServicesScreen from "./themes/Tarapaca/TarapacaServicesScreen";
import ThemeElevenCompany from "./themes/theme11/Theme11Company";
import ThemeElevenFaq from "./themes/theme11/Theme11Faq";
import ThemeElevenLosHeros from "./themes/theme11/Theme11LosHeros";
import ThemeElevenRutaPajaritos from "./themes/theme11/Theme11RutaPajaritos";
import ThemeElevenRutasMaipu from "./themes/theme11/Theme11RutasMaipu";
import ThemeElevenTariff from "./themes/theme11/Theme11Tariff";
import ThemeElevenTerminalSur from "./themes/theme11/Theme11TerminalSur";
import ThemeFiveTnCScreen from "./themes/theme5/ThemeFivePages/ThemeFiveTnCScreen";
import ThemeFourTrabajanScreen from "./themes/ThemeFour/ThemeFourPages/ThemeFourTrabajanScreen";
import ThemeSevenCargoScreen from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenCargoScreen";
import ThemeSevenCompanyScreen from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenCompanyScreen";
import ThemeSevenFrequentCard from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenFrequentCard";
import ThemeSevenPriceAndServicesScreen from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenPriceAndServicesScreen";
import ThemeSevenSpecialServicesScreen from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenSpecialServicesScreen";
import ThemeSevenTicketSaleScreen from "./themes/ThemeSeven/ThemeSevenPages/ThemeSevenTicketSaleScreen";
import ThemeTenServiciosEspeciales from "./themes/ThemeTen/ThemeTenPages/ThemeTenServiciosEspeciales";
import ThemeTenTravelNorteScreen from "./themes/ThemeTen/ThemeTenPages/ThemeTenTravelNorteScreen";
import ThemeThreeAboutUsScreen from "./themes/ThemeThree/ThemeThreePages/ThemeThreeAboutUsScreen";
import ThemeTwoGalleryScreen from "./themes/ThemeTwo/ThemeTwoGalleryScreen";
import ThemeTwoQuienesSomos from "./themes/ThemeTwo/ThemeTwoQuienesSomos";
import TransferSuccess from "./screens/TransferSuccess/TransferSuccess";
import TxnDetailsScreen from "./screens/TxnDetailsScreen/TxnDetailsScreen";
import UploadPicScreen from "./screens/UploadPicScreen/UploadPicScreen";
import getRoutes from "./themes/routes/getRoutes";
import getSideMenuLinks from "./themes/functions/getSideMenuLinks";

/* Basic CSS for apps built with Ionic */

/* Optional CSS utils that can be commented out */

// import 'react-image-crop/dist/ReactCrop.css';

/* Theme variables */

const CarpoolTermsOfUseScreen = React.lazy(
  () => import("./screens/CarpoolTermsOfUseScreen/CarpoolTermsOfUseScreen")
);
const HomeScreen = React.lazy(() => import("./screens/HomeScreen/HomeScreen"));
// const ServiceListScreen = React.lazy(() => import("./screens/ServiceListScreen/ServiceListScreen"));
// const ServiceDetailsScreen = React.lazy(() => import("./screens/ServiceDetailsScreen/ServiceDetailsScreen"));
const PassengerDetailsScreen = React.lazy(
  () => import("./screens/PassengerDetailsScreen/PassengerDetailsScreen")
);
const PassengerDetailsTerminalScreen = React.lazy(
  () =>
    import("./screens/PassengerDetailsTerminalScreen/PassengerDetailsScreen")
);
const PaymentDetailsScreen = React.lazy(
  () => import("./screens/PaymentDetailsScreen/PaymentDetailsScreen")
);
const PaymentDetailsTerminalScreen = React.lazy(
  () => import("./screens/PaymentDetailsTerminalScreen/PaymentDetailsScreen")
);
const ThankyouTerminalScreen = React.lazy(
  () => import("./screens/ThankyouTerminalScreen/ThankyouScreen")
);
const ThankyouScreen = React.lazy(
  () => import("./screens/ThankyouScreen/ThankyouScreen")
);
// const ServiceListReturnScreen = React.lazy(() => import("./screens/ServiceListReturnScreen/ServiceListReturnScreen"));
const CancellationScreen = React.lazy(
  () => import("./screens/CancellationScreen/CancellationScreen")
);
const CancellationDetailsScreen = React.lazy(
  () => import("./screens/CancellationDetailsScreen/CancellationDetailsScreen")
);
const CancelSuccessScreen = React.lazy(
  () => import("./screens/CancelSuccessScreen/CancelSuccessScreen")
);
// const ServiceDetailsReturnScreen = React.lazy(() => import("./screens/ServiceDetailsReturnScreen/ServiceDetailsReturnScreen"));
const RefundFormScreen = React.lazy(
  () => import("./screens/RefundFormScreen/RefundFormScreen")
);
const LoginScreen = React.lazy(
  () => import("./screens/LoginScreen/LoginScreen")
);
const LoginOptionsScreen = React.lazy(
  () => import("./screens/LoginOptionsScreen/LoginOptionsScreen")
);

//cuponera screen
const CuponeraHome = React.lazy(
  () => import("./screens/Cuponera/HomeScreen/HomeScreen")
);
const ConfirmScreen = React.lazy(
  () => import("./screens/Cuponera/HomeScreen/ConfirmScreen")
);

const CuponeraSeatsSelection = React.lazy(
  () => import("./screens/Cuponera/SeatsSelectionScreen/index")
);
const CuponeraPassengerDetails = React.lazy(
  () => import("./screens/Cuponera/PassengerScreen/index")
);
const CuponeraPaymentDetails = React.lazy(
  () => import("./screens/Cuponera/PaymentScreen/index")
);
const ThankyouScreenCuponera = React.lazy(
  () => import("./screens/Cuponera/ThankyouScreen/index")
);
const CuponeraMyAccountScreen = React.lazy(
  () => import("./screens/Cuponera/Account/MyCuponsScreen/MyCuponsScreen")
);

//cuponera screens ended

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/sw.js') //
//     .then(function (reg) {
//       console.log('service worker registered');
//     })
//     .catch(function (err) {
//       console.log(err);
//     });
// }

const appPages2: AppPage[] = [
  {
    id: "4",
    name: "Términos de uso",
    src: "../assets/icons/menu/icon_info.png",
    icon: "icon_info",
    // "screen":TXN_HISTORY_SCREEN,
    title: "Términos de uso",
    url: "tnc",
  },

  // {
  //   id: "9",
  //   name: "Contáctanos",
  //   src: "../assets/icons/menu/icon_contact.png",
  //   icon: "icon_contact",
  //   // "screen":MY_TICKETS_SCREEN,
  //   title: "Contáctanos",
  //   url: "pasajes",
  // },
];

class App extends React.Component {
  state: any;
  getSideMenuLinks: any;
  constructor(props: any) {
    super(props);
    this.state = {};
    console.log(this.props, "app page");
  }

  componentDidMount() {
    const customEvent = new Event("addToHomeButtonShowEvent");
    window.addEventListener("beforeinstallprompt", (event: any) => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      CommonService.addToHomeButtonInstallPrompt = event;
      window.dispatchEvent(customEvent);
      // Update the install UI to notify the user app can be installed
      // document.querySelector('#install-button').disabled = false;
      // setAddToHomeState(true)
    });
  }

  render() {
    return (
      <Provider>
        <IonApp>
          <KuposHelmet />
          <PBContactModal />
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Suspense fallback={<Loader />}>
                <Menu />
                <IonRouterOutlet id="main">
                  <Route exact path="/" component={HomeScreen} />

                  {/* -------------------------------- es routes for bus started -------------------------------- */}
                  <Route exact path="/es/pasajes-bus" component={HomeScreen} />

                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/1"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/2"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/servicedetails"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsReturnScreenV2}
                  />

                  <Route
                    exact
                    path="/es/pasajes-bus/passenger-details"
                    component={PassengerDetailsScreen}
                  />
                  <Route
                    exact
                    path="/en/passenger-details-terminal"
                    component={PassengerDetailsTerminalScreen}
                  />
                  <Route
                    exact
                    path="/es/passenger-details-terminal"
                    component={PassengerDetailsTerminalScreen}
                  />
                  <Route
                    exact
                    path="/en/terminal-booking"
                    component={PaymentDetailsTerminalScreen}
                  />
                  <Route
                    exact
                    path="/es/terminal-booking"
                    component={PaymentDetailsTerminalScreen}
                  />
                  <Route
                    path="/en/terminal-booking/:status"
                    component={ThankyouTerminalScreen}
                  />
                  <Route
                    exact
                    path="/es/terminal-booking/:status"
                    component={ThankyouTerminalScreen}
                  />

                  <Route
                    exact
                    path="/es/pasajes-bus/servicedetails/passenger-list-return-screen"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/es/pasajes-bus/payment-details"
                    component={PaymentDetailsScreen}
                  />

                  <Route
                    exact
                    path="/es/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  <Route
                    exact
                    path="/add-card/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  {/* -------------------------------- es routes for bus ended -------------------------------- */}

                  {/* -------------------------------- en routes for bus started -------------------------------- */}

                  <Route exact path="/en/bus-tickets" component={HomeScreen} />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/1"
                    component={ServiceListScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/2"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/servicedetails"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
                    component={ServiceDetailsReturnScreenV2}
                  />

                  <Route
                    exact
                    path="/en/bus-tickets/passenger-details"
                    component={PassengerDetailsScreen}
                  />

                  <Route
                    exact
                    path="/en/bus-tickets/servicedetails/passenger-list-return-screen"
                    component={ServiceListReturnScreenV2}
                  />
                  <Route
                    exact
                    path="/en/bus-tickets/payment-details"
                    component={PaymentDetailsScreen}
                  />

                  <Route
                    exact
                    path="/en/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  <Route
                    exact
                    path="/booking/:status/:pnrNumber"
                    component={ThankyouScreen}
                  />

                  {/* -------------------------------- en routes for bus ended -------------------------------- */}

                  <Route
                    exact
                    path="/bus-cancel-trip"
                    component={CancellationScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip/:pnrNumber"
                    component={CancellationDetailsScreen}
                  />
                  <Route
                    exact
                    path="/bus-cancel-trip/refund/:refunType"
                    component={RefundFormScreen}
                  />

                  <Route
                    exact
                    path="/bus-cancel-trip/refund-status/:type"
                    component={CancelSuccessScreen}
                  />
                  {/* <Route exact path="/bus-cancel-trip/:pnrNumber/:refundDetails" component={CancelSuccessScreen} /> */}

                  {/* <Route exact path="/tnc" component={CarpoolTermsOfUseScreen} /> */}

                  {/* Themes Routes */}
                  {/* Theme Five */}
                  <Route
                    exact
                    path="/destinos"
                    component={CommonDestinationPage}
                  />
                  <Route exact path="/about-us" component={CommonAboutUsPage} />
                  <Route exact path="/terms" component={CommonTnCPage} />
                  <Route exact path="/office" component={CommonOfficesPage} />
                  <Route exact path="/gallery" component={CommonGalleryPage} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={CommonPrivacyPolicyPage}
                  />
                  <Route
                    exact
                    path="/travel-norte"
                    component={ThemeTenTravelNorteScreen}
                  />
                  <Route
                    exact
                    path="/Servicios-especiales"
                    component={ThemeTenServiciosEspeciales}
                  />
                  <Route
                    exact
                    path="/contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/Agencies"
                    component={CommonAgenciesPage}
                  />
                  {/* Themes Routes */}
                  {/* Theme Five */}
                  <Route
                    exact
                    path="/destinos"
                    component={CommonDestinationPage}
                  />
                  <Route exact path="/about-us" component={CommonAboutUsPage} />
                  <Route exact path="/terms" component={CommonTnCPage} />
                  <Route
                    exact
                    path="/terms-conditions"
                    component={CommonTnCPage}
                  />
                  <Route exact path="/gallery" component={CommonGalleryPage} />
                  <Route
                    exact
                    path="/contact"
                    component={CommonContactUsPage}
                  />

                  {/*Theme One Andimar  */}
                  <Route exact path="/fleet" component={CommonGalleryPage} />

                  <Route
                    exact
                    path="/sanandres-about"
                    component={PullmanSanAndreasAbout}
                  />

                  <Route
                    exact
                    path="/safety"
                    component={PullmanSanAndreasSafety}
                  />

                  <Route
                    exact
                    path="/saopaulo-destinos"
                    component={CommonDestinationPage}
                  />
                  <Route
                    exact
                    path="/saopaulo-contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/saopaulo-office"
                    component={CommonOfficesPage}
                  />
                  <Route
                    exact
                    path="/saopaulo-terms"
                    component={CommonTnCPage}
                  />
                  <Route
                    exact
                    path="/about-saopaulo"
                    component={CommonAboutUsPage}
                  />
                  <Route
                    exact
                    path="/chilebus-destinos"
                    component={CommonDestinationPage}
                  />
                  <Route
                    exact
                    path="/chilebus-contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/chilebus-office"
                    component={CommonOfficesPage}
                  />
                  <Route
                    exact
                    path="/chilebus-terms"
                    component={CommonTnCPage}
                  />
                  <Route
                    exact
                    path="/about-chilebus"
                    component={CommonAboutUsPage}
                  />
                  <Route
                    exact
                    path="/pullmandelsur-destinos"
                    component={CommonDestinationPage}
                  />
                  <Route
                    exact
                    path="/pullmandelsur-contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/pullmandelsur-office"
                    component={CommonOfficesPage}
                  />
                  <Route
                    exact
                    path="/pullmandelsur-terms"
                    component={CommonTnCPage}
                  />
                  <Route
                    exact
                    path="/about-pullmandelsur"
                    component={CommonAboutUsPage}
                  />

                  <Route
                    exact
                    path="/inter-regional-travel"
                    component={PullmanSanAndreasServices}
                  />

                  {/* <Route
          exact
          path="/sanandres-contact"
          component={
           PullmanSanAndreasContact
          }
         /> */}

                  <Route
                    exact
                    path="/tarapaca-services"
                    component={TarapacaServicesScreen}
                  />
                  <Route
                    exact
                    path="/tarapaca-office"
                    component={TarapacaOfficesScreen}
                  />
                  <Route
                    exact
                    path="/tarapaca-about"
                    component={TarapacaAboutUsScreen}
                  />
                  <Route
                    exact
                    path="/tarapaca-gallery"
                    component={TarapacaGalleryScreen}
                  />
                  <Route
                    exact
                    path="/tarapaca-contact"
                    component={TarapacaContactUsScreen}
                  />
                  {/*Theme Three ivergrama  */}
                  <Route
                    exact
                    path="/about-ivergrama"
                    component={IvergramaAboutUsScreen}
                  />

                  <Route
                    exact
                    path="/ivergrama-contact"
                    component={IvergramaContactUsScreen}
                  />
                  <Route
                    exact
                    path="/ivergrama-contact"
                    component={IvergramaContactUsScreen}
                  />
                  <Route
                    exact
                    path="/ivergrama-services"
                    component={IvergramaServiceScreen}
                  />
                  <Route
                    exact
                    path="/ivergrama-terms"
                    component={IvergramaTnCScreen}
                  />
                  <Route
                    exact
                    path="/ivergrama-faq"
                    component={IvergramaFaqScreen}
                  />
                  {/* Arzola */}
                  <Route
                    exact
                    path="/arzola-contact"
                    component={IvergramaContactUsScreen}
                  />
      
                  <Route
                    exact
                    path="/arzola-terms"
                    component={IvergramaTnCScreen}
                  />

                  <Route exact path="/la-empresa" component={ArzolaAboutus} />

                  {/* // Bus-Norte */}

                  <Route
                    exact
                    path="/norte-flota"
                    component={CommonGalleryPage}
                  />
                  <Route
                    exact
                    path="/norte-office"
                    component={CommonOfficesPage}
                  />

                  {/* // aeroquinta */}

                  <Route
                    exact
                    path="/aeroquinta-fleet"
                    component={AeroquintaFleet}
                  />
                  <Route
                    exact
                    path="/norte-office"
                    component={CommonOfficesPage}
                  />
                  <Route
                    exact
                    path="/aeroquinta-contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/about-aeroquinta"
                    component={CommonAboutUsPage}
                  />
                  <Route
                    exact
                    path="/aeroquinta-terms"
                    component={CommonTnCPage}
                  />
                  {/* Bus-Norte */}

                  <Route
                    exact
                    path="/company"
                    component={ThemeSevenCompanyScreen}
                  />
                  <Route
                    exact
                    path="/frequent-card"
                    component={ThemeSevenFrequentCard}
                  />

                  <Route
                    exact
                    path="/special-service"
                    component={ThemeSevenSpecialServicesScreen}
                  />
                  <Route
                    exact
                    path="/ticket-sales"
                    component={ThemeSevenTicketSaleScreen}
                  />

                  <Route
                    exact
                    path="/cargo"
                    component={ThemeSevenCargoScreen}
                  />

                  <Route
                    exact
                    path="/prices-and-services"
                    component={ThemeSevenPriceAndServicesScreen}
                  />
                  <Route
                    exact
                    path="/login-options"
                    component={LoginOptionsScreen}
                  />
                  <Route exact path="/login" component={LoginScreen} />

                  <Route exact path="/otp" component={OtpScreen} />
                  <Route
                    exact
                    path="/phone-register"
                    component={MobileRegisterScreen}
                  />
                  <Route exact path="/register" component={RegisterSecreen} />
                  <Route exact path="/upload-pic" component={UploadPicScreen} />
                  <Route
                    exact
                    path="/preferences"
                    component={PreferencesScreen}
                  />
                  <Route
                    exact
                    path="/register/status/:type"
                    component={RegistrationSuccessScreen}
                  />
                  <Route
                    exact
                    path="/register/status/:type"
                    component={RegistrationSuccessScreen}
                  />
                  <Route
                    exact
                    path="/forget-password"
                    component={NewForgotPasswordScreen}
                  />
                  <Route
                    exact
                    path="/reset-password-otp"
                    component={OtpChangePasswordScreen}
                  />
                  <Route
                    exact
                    path="/my-account"
                    component={CarpoolMyAccountScreen}
                  />
                  <Route
                    exact
                    path="/profile/edit-profile"
                    component={EditAccountScreen}
                  />
                  <Route
                    exact
                    path="/my-account/txn-details/:item"
                    component={TxnDetailsScreen}
                  />
                  <Route
                    exact
                    path="/my-account/qr"
                    component={NewMyQRScreen}
                  />
                  <Route
                    exact
                    path="/my-account/qr/scan"
                    component={NewScanQRScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-trips"
                    component={CarpoolMyTripsScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-trips/:pnr"
                    component={CarpoolTripDetailsScreen}
                  />

                  <Route
                    exact
                    path="/recharge-wallet"
                    component={RechargeWalletScreen}
                  />
                  <Route
                    exact
                    path="/recharge-wallet/steps"
                    component={RechargeStepsScreen}
                  />
                  <Route
                    exact
                    path="/my-account/my-cards"
                    component={HomeMyCards}
                  />
                  <Route
                    exact
                    path="/recharge-wallet/map"
                    component={RechargeMapScreen}
                  />
                  <Route
                    exact
                    path="/recharge-transfer"
                    component={NewTransferRechargeScreen}
                  />
                  {/* <Route
                  exact
                  path="/recharge-transfer"
                  component={RechargeTransferScreen}
                /> */}
                  <Route
                    exact
                    path="/recharge-transfer/status/:val"
                    component={TransferSuccess}
                  />
                  <Route
                    exact
                    path="/transactions"
                    component={CarpoolMyTransactionsScreen}
                  />
                  <Route
                    exact
                    path="/transactions/details/:tripId"
                    component={CarpoolTransactionDetailsScreen}
                  />
                  <Route
                    exact
                    path="/promotions"
                    component={CarpoolVirtualMoneyScreen}
                  />
                  <Route
                    exact
                    path="/modify-profile"
                    component={ModifyProfileScreen}
                  />

                  {/* CUPONERA ROUTES */}
                  <Route
                    exact
                    path="/confirm-cuponera"
                    component={ConfirmScreen}
                  />
                  <Route exact path="/cuponera/home" component={CuponeraHome} />
                  <Route
                    exact
                    path="/cuponera/payment/:status/:pnr"
                    component={ThankyouScreenCuponera}
                  />

                  <Route
                    exact
                    path="/cuponera/payment/:status"
                    component={ThankyouScreenCuponera}
                  />
                  <Route
                    exact
                    path="/cuponera/seats/:origin/:destination"
                    component={CuponeraSeatsSelection}
                  />
                  <Route
                    exact
                    path="/cuponera/passenger-details"
                    component={CuponeraPassengerDetails}
                  />
                  <Route
                    exact
                    path="/cuponera/payment-details"
                    component={CuponeraPaymentDetails}
                  />
                  <Route
                    exact
                    path="/my-account/my-coupons"
                    component={CuponeraMyAccountScreen}
                  />
                  <Route exact path="/tariff" component={ThemeElevenTariff} />
                  <Route exact path="/company" component={ThemeElevenCompany} />
                  <Route exact path="/faq" component={ThemeElevenFaq} />

                  <Route
                    exact
                    path="/losheros"
                    component={ThemeElevenLosHeros}
                  />
                  <Route
                    exact
                    path="/rutapajaritos"
                    component={ThemeElevenRutaPajaritos}
                  />
                  <Route
                    exact
                    path="/rutasmaipu"
                    component={ThemeElevenRutasMaipu}
                  />
                  <Route
                    exact
                    path="/terminalsur"
                    component={ThemeElevenTerminalSur}
                  />

                  <Route exact path="/#terms" component={CommonTnCPage} />

                  <Route
                    exact
                    path="/juana-work"
                    component={ThemeFourTrabajanScreen}
                  />
                  <Route
                    exact
                    path="/juana-contact"
                    component={CommonContactUsPage}
                  />
                  <Route
                    exact
                    path="/juana-office"
                    component={CommonOfficesPage}
                  />
                  <Route exact path="/juana-terms" component={CommonTnCPage} />
                  <Route
                    exact
                    path="/about-juana"
                    component={CommonAboutUsPage}
                  />

                  {/* {getRoutes()} */}

                  <Route
                    exact
                    path="/gallery"
                    component={ThemeTwoGalleryScreen}
                  />

                  <Route
                    exact
                    path="/About us"
                    component={ThemeTwoQuienesSomos}
                  />
                </IonRouterOutlet>
              </Suspense>
            </IonSplitPane>
          </IonReactRouter>
          <Loader />
        </IonApp>
      </Provider>
    );
  }
}
export default App;
