import './ThemeEightContactUsScreen.css';

import { CarpoolSuccessFailureModal, PBInput } from '../../../components';
import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import ThemeEightFooter from '../common/ThemeEightFooter';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeEightContactUsScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;
  const initialState = {
			name: null,
			phone: null,
			email: null,
			comment: null,
			nameError: false,
			phoneError: false,
			emailError: false,
			commentError: false,
	};
	const [state, setState] = useState(initialState);



const inpChange = (value, type) => {
			if (type == "phone") {
					value = CommonService.phoneNumberFormat(value);
					setState({
							...state,
							[type]: value,
							[type + "Error"]: false,
					});
			} else {
					setState({ ...state, [type]: value, [type + "Error"]: false });
			}
	};

	const validate = () => {
			let errorCount = 0;
			if (!state.name) {
					setState({
							...state,
							nameError: true,
							nameErrorMessage: "Ingresa tu nombre",
					});
					errorCount++;
			} else if (!state.phone) {
					setState({
							...state,
							phoneError: true,
							phoneErrorMessage: "Ingresa tu número de teléfono",
					});
					errorCount++;
			} else if (!state.email) {
					setState({
							...state,
							emailError: true,
							emailErrorMessage: "Ingrese un email valido",
					});
					errorCount++;
			} else if (state.email && !CommonService.isEMailValid(state.email)) {
					setState({
							...state,
							emailError: true,
							emailErrorMessage: "Ingrese un email valido",
					});
					errorCount++;
			} else if (!state.comment) {
					setState({
							...state,
							commentError: true,
							commentErrorMessage: "Inserta tu comentario",
					});
					errorCount++;
			}

			if (errorCount > 0) {
					return false;
			} else {
					return true;
			}
	};

	const onSubmit = () => {
			if (!validate()) {
					return;
			}

			let data = {
					name: state.name,
					email: state.email,
					phone_number: state.phone,
					comments: state.comment,
					operator_id: operator.operator_id,
					operator_name: operator.operator_name,
			};

			const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

			if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
					data.last_name = state.last_name;
			}
			if (operator && (operator.operator_name === "busesjm")) {

			// if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "biaggini")) {
				
					props.opsiteContactInfo({
							callback: (result) => onContactInfoResponse(result),
							data: data,
					});
			}
	};

	const onContactInfoResponse = (res) => {
			if (res && res.data && res.data.message) {
					setState({
							...state,
							showModal: true,
							responseMessage: res.data.message,
							status: res.data.status,
					});
			}
	};
  return (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-eight-contact-us-screen'>
          <HeroSection
            heroImage={getIcons('contactUsBanner', operator)}
            title='Contacto'
            description='Home / Contacto'
          />

          <div className='form-section'>
            <div className='contact-input-item'>
              <div className='contact-input-label less-bold-text '>Nombre</div>
              <PBInput
                    // label='Nombre'
                    placeholder="Nombre"
                    value={state.name}
                    onChange={(text) => inpChange(text, "name")}
                    // onBlur={(text) => onBlur(text, "name")}
                    error={state.nameError ? true : false}
                    errorMessage={state.nameError ? state.nameErrorMessage : ""}
                    containerStyle={{
                      padding: "0",
                 
                    }}
                    inputClass={{
                      padding: "5px",
                    }}
                  />
            </div>

            <div className='contact-input-item '>
              <div className='contact-input-label less-bold-text '>
                Teléfono
              </div>
														<PBInput
                    // label='Nombre'
                    placeholder="9 XXXX XXXX"
                    value={state.phone}
                    onChange={(text) => inpChange(text, "phone")}
                    // onBlur={(text) => onBlur(text, "name")}
                    error={state.nameError ? true : false}
                    errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
                    containerStyle={{
                      padding: "0",
                    
                    }}
                    inputClass={{
                      padding: "5px",
                    }}
                  />
            </div>

            <div className='contact-input-item'>
              <div className='contact-input-label less-bold-text '>Email</div>
              <PBInput
                    // label='Nombre'
                    placeholder="abc@gmail.com"
                    value={state.email}
                    onChange={(text) => inpChange(text, "email")}
                    // onBlur={(text) => onBlur(text, "name")}
                    error={state.emailError ? true : false}
                    errorMessage={state.emailError ? state.emailErrorMessage : ""}
                    containerStyle={{
                      padding: "0",
                   
                    }}
                    inputClass={{
                      padding: "5px",
                    }}
                  />
            </div>

            <div className='contact-input-item'>
              <div className='contact-input-label less-bold-text '>
                Comentario
              </div>
														<PBInput
                    // label='Nombre'
                    placeholder="Comentario"
                    value={state.comment}
                    onChange={(text) => inpChange(text, "comment")}
                    // onBlur={(text) => onBlur(text, "name")}
                    error={state.commentError ? true : false}
                    errorMessage={state.commentError ? state.commentErrorMessage : ""}
                    containerStyle={{
                      padding: "0",
                    
                    }}
                    inputClass={{
                      padding: "5px",
                    }}
                  />
            </div>

            {/* <style>
          .btn{'{'}
          background:
          {operator &&
            operator.color_codes &&
            operator.color_codes.button_color &&
            operator.color_codes.button_color}
          {'}'}
          .btn:hover{'{'}
          background:
          {operator &&
            operator.color_codes &&
            operator.color_codes.secondary_color &&
            operator.color_codes.secondary_color}
          {'}'}
        </style> */}

            <div div className='contact-input-item center '>
												<button onClick={onSubmit}
                class='btn submit-btn  bold-text'
                style={{
                  background:
                    operator &&
                    operator.color_codes &&
                    operator.color_codes.button_color &&
                    operator.color_codes.button_color,
                  color: 'white',
                }}
              >
                ENVIAR
              </button>
														<CarpoolSuccessFailureModal
          showModal={state.showModal}
          success={state.status ? true : false}
          smallIcon={true}
          bodyText={state.responseMessage}
          buttonText={"OK"}
          onButtonPress={() => {
            setState({
              showModal: false,
            });
            props.history.push("/");
          }}
        />
            </div>
          </div>
        </div>

        <ThemeEightFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeEightContactUsScreen);
