import React from 'react';
import { Link } from 'react-router-dom';

const TopStripBackHome = (props) => {
  return (
    <div className="model-top-strip">
      <img
        onClick={this.props.onHide}
        src="../../assets/carpool/arrow-back.png"
      />
      <div className="font10 model-top-strip-label">Ciuade di origin</div>
      <Link to={"/home"}>
        <img onClick={this.props.onHide} src="/assets/carpool/home.svg" />
      </Link>
      {/* {this.modelShow? this.setState({modelShow:true}): this.setState({modelShow:false})} */}
    </div>
  );
};

export default TopStripBackHome;