import React from "react";
import { connectData } from "../../../redux";
import { CommonService } from "../../../services";

export const ThemeTenTitle = ({ title, style, data ,fontSize}) => {
  const operator = CommonService.getLocal("op") || data.operatorr;

  return (
    <div
      className="favorite-destinations-heading d-flex align-center justify-center my-4 less-bold-text"
      style={style}
    >
      <span
        className="d-inline-block title-dash-line"
        style={{
          background:
            operator &&
            operator.color_codes &&
            operator.color_codes.primary_color,
          width: "50px",
          height: "1px",
        }}
      ></span>
      <div
        className="px-2 text-uppercase m-0"
        style={{
          color:
            operator &&
            operator.color_codes &&
            operator.color_codes.secondary_color,
          fontSize:fontSize ? fontSize: "22px",
          fontWeight: "bold",
          margin:'20px 10px',
        }}
      >
        {title}
      </div>
      <span
        className="d-inline-block title-dash-line"
        style={{
          background:
            operator &&
            operator.color_codes &&
            operator.color_codes.primary_color,
          width: "50px",
          height: "1px",
        }}
      ></span>
    </div>
  );
};

export default connectData()(ThemeTenTitle);
