// passing the path into variables

const banner1 = '/assets/erbuc/Home/Erbuc-Website-Home-Slide-Desktop-1.jpg';
const banner2 = '/assets/erbuc/Home/Erbuc-Website-Home-Slide-Desktop-2.jpg';
const banner3 = '/assets/erbuc/Home/Erbuc-Website-Home-Slide-Desktop-3.jpg';

const destination1 =
  '/assets/erbuc/Destinations/Erbuc-Website-Destinations-1.jpg';
const destination2 =
  '/assets/erbuc/Destinations/Erbuc-Website-Destinations-2.jpg';
const destination3 =
  '/assets/erbuc/Destinations/Erbuc-Website-Destinations-3.jpg';
const destination4 =
  '/assets/erbuc/Destinations/Erbuc-Website-Destinations-4.jpg';

const gallery1 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-1.jpg';
const gallery2 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-2.jpg';
const gallery3 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-3.jpg';
const gallery4 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-4.jpg';
const gallery5 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-5.jpg';
const gallery6 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-6.jpg';
const gallery7 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-7.jpg';
const gallery8 = '/assets/erbuc/Gallery/Erbuc-Website-Gallery-8.jpg';

const banner = [banner1, banner2, banner3];

const destination = [destination1, destination2, destination3, destination4];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
];

const erbucSlides = {
  banner,
  destination,
  gallery,
};

export default erbucSlides;
