// passing the path into variables

const banner1 = '/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const banner2 = '/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner2.jpg';
const banner3 = '/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner3.jpg';
const banner4 = '/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner4.jpg';

const destination1 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination2 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination3 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination4 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination5 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination6 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination7 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';
const destination8 =
	'/assets/busnorte/Home/Ivergrama-Website-Home-MainBanner.jpg';



const gallery1 = '/assets/busnorte/gallery/gallery1.webp';
const gallery2 = '/assets/busnorte/gallery/gallery2.webp';
const gallery3 = '/assets/busnorte/gallery/gallery3.webp';
const gallery4 = '/assets/busnorte/gallery/gallery4.webp';
const gallery5 = '/assets/busnorte/gallery/gallery5.webp';
const gallery6 = '/assets/busnorte/gallery/gallery6.webp';
const gallery7 = '/assets/busnorte/gallery/gallery7.webp';
const gallery8 = '/assets/busnorte/gallery/gallery8.webp';

const banner = [banner1, banner2, banner3, banner4];
const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8];

const destination = [
	{
		one: destination1,
		two: destination2,
		three: destination3,
		four: destination4,
	},

	{
		one: destination5,
		two: destination6,
		three: destination7,
		four: destination8,
	},
];

const busnorteSlides = {
	banner,
	destination,
	gallery
};

export default busnorteSlides;
