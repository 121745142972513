import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import {
  PBInput,
  PBRadioGroup,
  PBCard,
  PButton,
  PBText,
  PBModal,
} from "../../components";
import { commonStyles, colors } from "../../theme";
import { CommonService } from "../../services";
import { IonPage, IonContent } from "@ionic/react";
import { Link } from "react-router-dom";

class EditAccountScreen extends PureComponent {
  idCardTypes = [];
  constructor(props) {
    super(props);
    for (let c in this.props.data.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.data.metaData.id_card_types[c],
        id: this.props.data.metaData.id_card_types[c],
        label: c,
        name: c,
        selected:
          this.props.data.metaData.id_card_types[c] ==
          this.props.data.loginData.identity_type,
      });
    }
    this.state = {
      name: this.props.data.loginData.name,
      mobile: this.props.data.loginData.phone,
      idCardType: this.props.data.loginData.identity_type,
      idCardNumber: this.props.data.loginData.identity_val,
      code: this.props.data.loginData.invite_code,
      nameError: null,
      mobileError: null,
      cardNumberError: null,
      codeError: null,
    };

    // CommonService.bindNav(this);
  }

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }

  renderHeader = (profileData) => {
    return (
      <div className="edit-profile-person-image">
        <img width={70} src="../../assets/icons/person.png" />
        <div className="edit-profile-account-info">
          <PBText
            fontSize={"font19"}
            fontStyle={"bold-font"}
            fontColor={"primary-font"}
          >
            {profileData && profileData.name}
          </PBText>
          <PBText fontColor={"primary-font"}>
            {profileData && profileData.email}
          </PBText>
          <PBText fontColor={"primary-font"}>
            {this.idCardTypes && this.idCardTypes.find((it) => it.selected)
              ? this.idCardTypes.find((it) => it.selected).name
              : ""}{" "}
            {profileData && profileData.identity_val}
          </PBText>
        </div>
      </div>
    );
  };

  inpChange = (text, inp) => {
    if (inp == "name") {
      this.setState({
        nameError: null,
        name: text,
      });
    } else if (inp == "mobile") {
      text = CommonService.phoneNumberFormat(text);
      this.setState({
        mobileError: null,
        mobile: text,
      });
    } else if (inp == "card_number") {
      this.setState({
        cardNumberError: null,
        cardNumber: text,
      });
    } else if (inp == "code") {
      this.setState({
        codeError: null,
        code: text,
      });
    }
  };

  idCardChange = (val) => {
    this.setState({
      cardNumberError: null,
      idCardNumber: null,
      idCardType: val.value,
    });
  };
  idCardNumberChange = (text) => {
    this.setState({
      cardNumberError: null,
    });
    if (text) {
      let cardNumber = CommonService.isRut(
        this.state.idCardType,
        this.idCardTypes
      )
        ? CommonService.formatRut(text)
        : text;
      this.setState({
        idCardNumber: cardNumber,
      });
    } else {
      this.setState({
        idCardNumber: text,
      });
    }
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "name") {
        this.setState({
          nameError: {
            message: "Nombre Completo",
          },
        });
      } else if (inp == "mobile") {
        this.setState({
          passwordError: {
            message: "Introduzca su número de móvil",
          },
        });
      } else if (inp == "card_number") {
        this.setState({
          cardNumberError: {
            message:
              "Por favor, introduzca el N° de documento de identificación.",
          },
        });
      }
    } else if (inp == "mobile") {
      if (this.state.mobile.toString().length != 9) {
        this.setState({
          mobileError: {
            message: "Introduzca su número de móvil de 9 dígitos",
          },
        });
      }
    } else if (inp == "card_number") {
      if (
        !CommonService.isRutValid(this.state.idCardNumber) &&
        this.state.idCardType == 7
      ) {
        this.setState({
          cardNumberError: {
            message: "Por favor ingrese RUT válido.",
          },
        });
      }
    }
  };

  isValid = () => {
    let errorCount = 0;
    if (!this.state.name) {
      this.setState({
        nameError: {
          message: "Nombre completo",
        },
      });
      errorCount++;
    }
    if (!this.state.mobile) {
      this.setState({
        mobileError: {
          message: "Introduzca su número de móvil",
        },
      });
      errorCount++;
    } else if (this.state.mobile.toString().length != 9) {
      this.setState({
        mobileError: {
          message: "Introduzca su número de móvil de 9 dígitos",
        },
      });
      errorCount++;
    }
    if (!this.state.idCardNumber) {
      this.setState({
        cardNumberError: {
          message:
            "Por favor, introduzca el N° de documento de identificación.",
        },
      });
      errorCount++;
    } else if (
      !CommonService.isRutValid(this.state.idCardNumber) &&
      this.state.idCardType == 7
    ) {
      this.setState({
        cardNumberError: {
          message: "Por favor ingrese RUT válido.",
        },
      });
      errorCount++;
    }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  update = () => {
    if (!this.isValid()) {
      return;
    }

    let data = {
      name: this.state.name,
      phone: this.state.mobile,
      identity_type: this.state.idCardType,
      identity_val: this.state.idCardNumber,
    }
    
    if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
      data['operator_id'] = CommonService.getOperatorId(this.props.data.operator)
    }

    this.props.updateCustomerProfile({
      callback: (result) => this.onResponse(result),
      data: data,
    });
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      //   this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "fracaso",
        modalBody: result.data.error,
        button1Text: "OK",
        modalIcon: "../../assets/icons/failure.png",
      });
      return;
    } else if (result.data) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        button1Text: "VOLVER",
        modalIcon: "../../assets/icons/success.png",
      });
      this.props.loginSuccess(result.data);
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      button1Text: "",
    });
  };

  render() {
    const profileData = this.props.data.loginData;
    {
      console.log("profile data", this.props);
    }
    return (
      <IonPage>
        <IonContent>
          <div className="model-top-strip" style={{ position: "fixed" }}>
            <Link to={"/profile"}>
              <img src="../../assets/carpool/arrow-back.png" />
            </Link>

            <div className="font14 model-top-strip-label">
              Selecciona tu servicio
            </div>

            <a
              onClick={() => {
                this.props.history.replace("", null);
                this.props.history.push("/");
              }}
            >
              <img src="/assets/carpool/home.svg" />
            </a>
          </div>
          <div className="screen-container">
            <div style={{ flex: 1 }}>
              <PBCard marginBottom={25}>
                {this.renderHeader(profileData)}
                <div className="edit-profile-input-container">
                  <PBInput
                    label="Nombre:"
                    placeholder="Nombre Completo"
                    value={this.state.name}
                    onTextChange={(text) => this.inpChange(text, "name")}
                    onBlur={(text) => this.onBlur(text, "name")}
                    error={this.state.nameError ? true : false}
                    errorMessage={
                      this.state.nameError ? this.state.nameError.message : ""
                    }
                    //   iconLeft={require('../../assets/icons/person-inp.png')}
                  ></PBInput>
                </div>
                <div className="edit-profile-input-container">
                  <PBInput
                    label="Teléfono:"
                    placeholder="9 xxxx xxxx"
                    type="phone-pad"
                    value={this.state.mobile}
                    onTextChange={(text) => this.inpChange(text, "mobile")}
                    onBlur={(text) => this.onBlur(text, "mobile")}
                    error={this.state.mobileError ? true : false}
                    errorMessage={
                      this.state.mobileError
                        ? this.state.mobileError.message
                        : ""
                    }
                    //   iconLeft={require('../../assets/icons/call.png')}
                  ></PBInput>
                </div>
                <div className="edit-profile-input-container">
                  <PBRadioGroup
                    radioButtons={this.idCardTypes}
                    size={18}
                    color={colors.primaryBG}
                    onPress={(label) => this.idCardChange(label)}
                    flexDirection={"row"}
                  />
                </div>

                <div className="edit-profile-input-container">
                  <PBInput
                    label="N° documento:"
                    placeholder="N°de documento"
                    value={this.state.idCardNumber}
                    onTextChange={(text) => this.idCardNumberChange(text)}
                    onBlur={(text) => this.onBlur(text, "card_number")}
                    error={this.state.cardNumberError ? true : false}
                    errorMessage={
                      this.state.cardNumberError
                        ? this.state.cardNumberError.message
                        : ""
                    }
                    //   iconLeft={require('../../assets/icons/rut-icon.png')}
                  ></PBInput>
                </div>

                <div className="edit-profile-input-container">
                  <PBInput
                    disable={true}
                    label="Código de invitación:"
                    placeholder="Código de invitación"
                    value={this.state.code}
                    onTextChange={(text) => this.inpChange(text, "code")}
                    onBlur={(text) => this.onBlur(text, "code")}
                    error={this.state.codeError ? true : false}
                    errorMessage={
                      this.state.codeError ? this.state.codeError.message : ""
                    }
                    //   iconLeft={require('../../assets/icons/person-inp.png')}
                  ></PBInput>
                </div>

                <div className="edit-profile-button-container">
                  <PButton
                    style={{ width: "50%", height: 40 }}
                    title={"GUARDAR"}
                    onPress={this.update}
                  />
                </div>
              </PBCard>
            </div>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.button1Text}
              button1Press={this.closeModal}
              icon={this.state.modalIcon}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  inputContainer: {
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
};

export default connectData()(EditAccountScreen);
