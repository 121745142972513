import './PullmanSanAndreasSafety.css';

import { IonContent, IonPage } from '@ionic/react';

import Footer6 from '../common/footer/Footer6';
import Header6 from '../common/header/Header6';
import { Link } from 'react-router-dom';
import PullmanBanners from './components/PullmanBanners';
import React from 'react';
import ThemeSixSlides from './components/ThemeSixSlides';
import { connectData } from '../../redux';
import getIcons from '../functions/getIcons/getIcons';
import getSlides from '../functions/getSlides';
import ThemeSixHeader from './components/ThemeSixHeader';

const sensorImage = '/assets/pullmanSanAndres/sensor.svg';

// SUP IMAGES
const BusItemSupImage1 = '/assets/pullmanSanAndres/sensorimage.png';
const BusItemSupImage2 = '/assets/pullmanSanAndres/systemimage.png';
const BusItemSupImage3 = '/assets/pullmanSanAndres/speedimage.png';
const BusItemSupImage4 = '/assets/pullmanSanAndres/controlimage.png';
const BusItemSupImage5 = '/assets/pullmanSanAndres/alertimage.png';
const BusItemSupImage6 = '/assets/pullmanSanAndres/brakeimage.png';
const BusItemSupImage7 = '/assets/pullmanSanAndres/brakecontrolimage.png';
const BusItemSupImage8 = '/assets/pullmanSanAndres/seatbeltimage.png';
const BusItemSupImage9 = '/assets/pullmanSanAndres/cameraimage.png';

const BottomBanner = '/assets/pullmanSanAndres/banner2.png';

// SUB IMAGES
// const safetyImage1 = '/assets/pullmanSanAndres/sensorimage.png';
// const safetyImage2 = '/assets/pullmanSanAndres/seatbelt.svg';
// const safetyImage3 = '/assets/pullmanSanAndres/benz-logo.svg';
// const safetyImage4 = '/assets/pullmanSanAndres/marcopolo-logo.svg';
// const safetyImage5 = '/assets/pullmanSanAndres/modasalogo.png';
// const safetyImage6 = '/assets/pullmanSanAndres/scania-logo.svg';
// const safetyImage7 = '/assets/pullmanSanAndres/buscarlogo.svg';

const backgroundDesign = '/assets/pullmanSanAndres/design.svg';

const busesDetails = [
  {
    name: 'SENSOR DE FATIGA Y SOMNOLENCIA',
    supImage: BusItemSupImage1,
    details: (
      <div className='d-flex flex-column align-items-center'>
        <p>
          El DSS es un sistema que registra los movimientos de rostro y ojos de
          un conductor, detectando cuando éste presenta eventos de somnolencia y
          distracción en la cabina.
        </p>
        <p>
          El sistema monitorea los ojos y posición de la cabeza del conductor,
          entregando un feedback inmediato a través de una alarma y vibración en
          la butaca del chofer cuando el sensor detecta el cansancio o
          distracción.
        </p>
        <img className='safety-items-sensor-image' src={sensorImage} alt='' />
      </div>
    ),
  },
  {
    name: 'SISTEMA DE CONTROL SATELITAL (GPS)',
    supImage: BusItemSupImage2,
    details: (
      <div>
        <p>
          Monitoreamos el trayecto y velocidad de todos nuestros buses mediante
          un sistema avanzado de monitoreo GPS, donde podemos identificar
          cualquier irregularidad en el trayecto de viaje y la velocidad de cada
          bus.
        </p>
        <p>Tu viaje es completamente seguro.</p>
      </div>
    ),
  },
  {
    name: 'CORTE DE INYECCIÓN',
    supImage: BusItemSupImage3,
    details: (
      <div>
        <p>
          Nuestras unidades están configuradas para no sobrepasar los 100 km/h,
          esto mediante el corte de inyección, que es una interrupción
          controlada del suministro de combustible al motor de combustión
          interna del vehículo, lo cual no permite al motor seguir acelerando,
          Este trabajo es realizado por el representante de la marca.
        </p>
        <h3 className='bold-text black-text'>
          INDICADOR DE VELOCIDAD INTERIOR
        </h3>
        <p>
          Con el propósito de evitar accidentes, nuestros buses cuentan con una
          pantalla en la parte interior, la cual indica la velocidad real,
          informando a nuestros pasajeros si el bus se encuentra en movimiento
          con velocidad ascendente, descendente o detenido.
        </p>
        <p>
          En esta pantalla además de la velocidad podrás encontrar la
          información del conductor que va manejando.
        </p>
      </div>
    ),
  },
  {
    name: 'SISTEMA DE CONTROL TERCER OJO',
    supImage: BusItemSupImage4,
    details: (
      <div>
        <p>
          Algunas de nuestras unidades cuentan con el sistema anticolisión para
          la prevención de accidentes Mobileye, el cual utiliza un sensor de
          visión inteligente que ve el camino por delante como si se tratara de
          un ojo biónico. identificando objetos en el camino que pueden
          representar posibles amenazas tales como otros vehículos, obstáculos
          peligrosos o peatones.
        </p>
        <p>
          El sistema mide continuamente la distancia y las velocidades relativas
          de estos objetos para calcular el riesgo de choque del conductor. El
          sistema Mobileye también es capaz de detectar las marcas del carril y
          señales de tránsito para alertar a los conductores cuando se desvían
          fuera debido a la distracción o fatiga, o cuando van a exceso de
          velocidad. Cuando se detecta un peligro inminente, el sistema emite
          alertas visuales y sonoras que advierten al conductor con tiempo
          suficiente para poder reaccionar y hacer las correcciones necesarias.
          Todo esto ocurre en tiempo real.
        </p>
      </div>
    ),
  },
  {
    name: 'ALERTA DE CAMBIO DE CARRIL',
    supImage: BusItemSupImage5,
    details: (
      <div>
        <p>
          Algunas de nuestras unidades cuentan con LDW, el cual consiste en una
          cámara especialmente ubicada en el parabrisas, la cual identifica las
          líneas del camino, ya sean las pintadas o las formas de la carpeta
          asfáltica. Si el sistema detecta que el bus se desvía hacia un costado
          hará sonar una fuerte alarma del lado hacia donde se está moviendo,
          para alertar al conductor.
        </p>
      </div>
    ),
  },
  {
    name: 'SISTEMA DE FRENOS AVANZADOS DE EMERGENCIA',
    supImage: BusItemSupImage6,
    details: (
      <div>
        <p>
          Nuestras últimas unidades adquiridas cuentan con el Sistema Avanzado
          de Frenado de Emergencia, el cual gracias a una cámara y un radar
          ubicados en el frontis del bus, avisa al conductor del riesgo de
          colisión frontal en relación a un vehículo en movimiento o parado
          delante, comienza a sonar una alarma visual y sonora para avisar al
          conductor del riesgo y si el conductor no reacciona, el asiento vibra
          y la velocidad del bus se reduce automáticamente, si estas alertas no
          son suficientes los frenos se accionan para disminuir progresivamente
          la velocidad.
        </p>
      </div>
    ),
  },
  {
    name: 'SISTEMA DE FRENOS ABS + CONTROL',
    supImage: BusItemSupImage7,
    details: (
      <div>
        <p>ABS (ANTI-LOCK BRAKING SYSTEM / CONTROL CONTRA DESLIZAMIENTO)</p>
        <p>
          Este sistema impide el bloqueo de las ruedas del vehículo en caso de
          una frenada de emergencia.
        </p>
        <p>
          Aunque en algunos casos la distancia de frenado aumenta, la principal
          ventaja que tiene este sistema es que, como las ruedas siguen girando
          sin bloquearse, permiten que el conductor mantenga el control de la
          dirección, por ejemplo, para esquivar un obstáculo.
        </p>
        <p>
          El sistema ABS es muy útil en situaciones de escasa adherencia como
          lluvia, nieve, barro, etc.
        </p>
        <p>
          En pavimentos muy deslizantes, como el hielo, con adherencia casi
          nula, el ABS cumple también su función.
        </p>
        <p>ASR-EDS (CONTROL DE TRACCIÓN)</p>
        <p>
          Este sistema impide que patinen una o ambas ruedas de cada eje motriz,
          por diferencia de adherencia o exceso de aceleración. El EDS
          (Electronic Differential Slip) frena la rueda que intenta girar más
          deprisa, mientras que el ASR (Anti Slip Regulation) regula la
          aceleración.
        </p>
        <p>ESP (ELECTRONIC STABILITY PROGRAM / CONTROL DE ESTABILIDAD)</p>
        <p>
          Este sistema detecta cuando existe riesgo de pérdida de control del
          vehículo. La unidad de mando coordina el empleo de los frenos
          individualmente y/o interrumpiendo el empuje del motor, y así mantiene
          el vehículo en la trayectoria fijada por el volante, evitando así que
          el bus salga del camino.
        </p>
      </div>
    ),
  },
  {
    name: 'CINTURÓN DE SEGURIDAD DE TRES PUNTAS',
    supImage: BusItemSupImage8,
    details: (
      <div>
        <p>
          El uso del cinturón de seguridad, además de ser obligatorio, es un
          dispositivo imprescindible que salva vidas. Tras un impacto con el
          vehículo, una persona que utiliza el cinturón de seguridad tiene cinco
          veces más probabilidades de sobrevivir que otra que no lo use.
        </p>
        <p>
          Este cinturón de tres puntas, está incluido en todas nuestras
          unidades, es el que encontramos generalmente en los vehículos y es
          considerado uno de los más seguros y fiables. Este cinturón parte de
          los dos puntos de anclaje antes mencionados pero, además, incluye un
          tercer punto de sujeción por encima de uno de los hombros del
          pasajero. De este modo, se evita el riesgo de la separación de la
          espina lumbar.
        </p>
      </div>
    ),
  },
  {
    name: 'CÁMARA DE RETROCESO',
    supImage: BusItemSupImage9,
    details: (
      <div>
        <p>
          Uno de los elementos de seguridad que inició como una inclusión en los
          vehículos de lujo actualmente ya es una necesidad.
        </p>
        <p>
          La cámara de retroceso mejora la visibilidad en más de un 45% tiene
          como objetivo reducir los accidentes en las maniobras de reversa,
          proteger nuestras unidades y nuestro entorno.
        </p>
      </div>
    ),
  },
];

function PullmanSanAndreasSafety(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <ThemeSixHeader history={props.history} operator={operator} />

        <div className='pullman-san-andreas-safety'>
          <PullmanBanners
            bgImage={getIcons('safetyBanner')}
            text={'Seguridad a Bordo'}
            textPosition={'end'}
          />
          <h1 className='bold-text black-text font-italic text-center main-heading'>
            Estándares de seguridad
          </h1>
          {busesDetails.map((item, key) => (
            <React.Fragment>
              <div className='new-container mt-4'>
                <div className='safety-items'>
                  <div className='safety-items-left'>
                    <div className='safety-items-left-sup-image'>
                      <img src={item.supImage} alt='' />
                    </div>
                  </div>
                  <div className='safety-items-right'>
                    <h3 className='bold-text black-text font-italic sub-heading'>
                      {item.name}
                    </h3>
                    <div className='safety-items-right-details bold-text'>
                      <div>{item.details}</div>
                    </div>
                  </div>
                </div>
              </div>
              {key !== busesDetails.length - 1 && (
                <div
                  style={{
                    height: 80,
                    background: `url(${backgroundDesign})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: '100%',
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
          <div className='new-container'>
            <div className='pullman-san-andreas-section-1-right-text1'>
              <p
                className='p1 bold-text font-italic text-center font24 '
                style={{
                  margin: 0,
                  padding: '0 20px',
                  color:
                    operator && operator.theme_color && operator.theme_color,
                }}
              >
                Empresas que confían en nosotros
              </p>
              <p
                className='bottom-para'
                style={{
                  margin: 0,
                }}
              >
                sé parte de{' '}
                <span className='bold-text'>Pullman San Andrés</span>
              </p>
            </div>
            <div className='pullman-san-andreas-section-1-right-companies'>
              <ThemeSixSlides
                slides={getSlides('Comapanies', operator)}
                noOfIcons={2}
              />
            </div>
            <Link to='/sanandres-contact' className='cursor-pointer'>
              <img className='mt-4 mb-4' src={BottomBanner} alt='' />
            </Link>
          </div>
        </div>
        <Footer6 />
      </IonContent>
    </IonPage>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators({ ...actions }, dispatch),
//   };
// }

export default connectData()(PullmanSanAndreasSafety);
