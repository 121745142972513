import React, { PureComponent } from 'react';
import PBText from './PBText';
import PButton from './PButton';
import PBTouchable from './PBTouchable';

class PBModal extends PureComponent {
  /**
   * type:string
   * showModal:bool
   * icon:imagePath
   * titleText:string
   * subtitle:string
   * bodyText:string
   * showButton1:bool
   * showButton2:bool
   * button1Text:string
   * button2Text:string
   * button1Press:func
   * button2Press:func
   * button1Bold:bool
   * button2Bold:bool
   * onBackButtonPress:func
   * onBackdropPress:func
   * buttonTextStyle
   * closeIconPress
   * showCloseIcon
   * onModalIconPress
   */

  constructor(props) {
    super(props);
  }

  // componentWillUnmount() {
  //   if (this.props.showModal)
  //     this.props.onBackButtonPress;
  // }

  getAlertIcon = () => {
    if (this.props.type == 'alert') {
      if (this.props.icon) {
        return (
          <div className='pb-modal-icon-container'>
            {this.props.onModalIconPress ? (
              <PBTouchable onPress={this.props.onModalIconPress}>
                <img height={60} src={this.props.icon} />
              </PBTouchable>
            ) : (
              <img height={60} src={this.props.icon} />
            )}
          </div>
        );
      }
    }
    return null;
  };
  getAlertBodyText = () => {
    if (this.props.bodyText) {
      return (
        <div className='text-center pb-modal-body-content'>
          <PBText
            textAlign='text-center'
            fontSize={'font14'}
            fontColor={'dark-font'}
          >
            {this.props.bodyText}
          </PBText>
        </div>
      );
    }
  };

  render() {
    return this.props.showModal ? (
      <div
        // backdropOpacity={this.props.opacity ? this.props.opacity : 0.5}
        // isOpen={this.props.showModal}
        // show-backdrop="true"
        // disabled={this.props.showModal}
        // animated="true"
        // isVisible={this.props.showModal}
        // onBackButtonPress={this.props.onBackButtonPress ? this.props.onBackButtonPress : () => {} }
        // onBackdropPress={this.props.onBackdropPress ? this.props.onBackdropPress : () => {} }
        // animationInTiming={this.props.animationTime || 300}
        // animationOutTiming={this.props.animationTime || 300}
        // backdropTransitionInTiming={this.props.animationTime || 300}
        // backdropTransitionOutTiming={this.props.animationTime || 300}
        className='pb-modal'
      >
        <div className='pb-modal-content' style={{ bottom: this.props.bottom }}>
          <div
            className={
              this.props.removeCard ? '' : 'kupos-card' + ' pb-modal-container'
            }
            style={{
              padding:
                this.props.padding === 0 || this.props.padding
                  ? this.props.padding
                  : 20,
              width:
                this.props.width === 0 || this.props.width
                  ? this.props.width
                  : '82vw',
              borderRadius:
                this.props.borderRadius === 0 || this.props.borderRadius
                  ? this.props.borderRadius
                  : 20,
              position: 'relative',
            }}
          >
            {this.getAlertIcon()}
            {this.props.titleText ? (
              <div style={styles.titleContainer}>
                <PBText
                  textAlign='text-center'
                  fontStyle={'bold-text'}
                  fontSize={'font15'}
                  fontColor={'dark-font'}
                >
                  {this.props.titleText}
                </PBText>
              </div>
            ) : null}
            {this.props.subtitle ? (
              <div className='subtitle-container'>
                <PBText
                  textAlign='text-center'
                  fontSize={'font14'}
                  fontColor={'dark-font'}
                >
                  {this.props.subtitle}
                </PBText>
              </div>
            ) : null}
            {this.getAlertBodyText()}
            <div style={styles.body}>{this.props.children}</div>
            {this.props.showButton1 || this.props.showButton2 ? (
              <div
                className={
                  this.props.buttonDirection + ' modal-buttons-container2'
                }
              >
                {this.props.showButton1 ? (
                  <div
                    className='modal-button-container'
                    style={this.props.button1Style}
                  >
                    <PButton
                      className={'font15'}
                      title={this.props.button1Text}
                      onPress={this.props.button1Press}
                      // buttonStyle={this.props.buttonTextStyle}
                      // fontSize={"font18"}
                      bold={this.props.button1Bold}
                      buttonSize={{ fontSize: 17 }}
                      fontColor={this.props.button1FontColor}
                      operator={this.props.operator}
                    />
                  </div>
                ) : null}
                {this.props.showButton2 ? (
                  <div
                    style={{ marginTop: 10 }}
                    className={
                      this.props.buttonDirection + ' modal-button-container'
                    }
                  >
                    <PButton
                      // secondary={true}
                      className={'font17 ' + this.props.button2ColorBg}
                      bold={this.props.button2Bold}
                      title={this.props.button2Text}
                      onPress={this.props.button2Press}
                      buttonSize={{ fontSize: 17 }}
                      fontColor={this.props.button2FontColor}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.props.showCloseIcon ? (
              <PBTouchable onPress={this.props.closeIconPress}>
                <div
                  style={{
                    padding: 5,
                    borderRadius: 16,
                    position: 'absolute',
                    top: 5,
                    right: 5,
                  }}
                >
                  <img height={22} src={'/assets/icons/close.png'} />
                </div>
              </PBTouchable>
            ) : null}
          </div>
        </div>
        <div
          className='modal-back-drop'
          onClick={
            this.props.onBackButtonPress
              ? this.props.onBackButtonPress
              : () => {}
          }
        ></div>
      </div>
    ) : null;
  }
}

const styles = {
  modalOuter: {
    width: '90%',
  },
  iconContainer: {
    width: '100%',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    display: 'flex',
  },
  titleContainer: {
    alignItems: 'center',
    padding: 0,
    marginTop: '10px',
  },
  titleText: {
    textAlign: 'center',
    fontSize: 16,
  },
  alertBodyTextContainer: {
    alignItems: 'center',
  },
  alertBodyText: {
    textAlign: 'center',
    fontSize: 15,
  },

  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '70%',
    alignSelf: 'center',
  },
};

export default PBModal;
