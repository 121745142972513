import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { CommonService } from "../../services";
import {
  BGCarpoolInnerBottom,
  PButton
} from "../../components";
import { IonContent, IonPage } from "@ionic/react";

class TransferSuccess extends PureComponent {
  constructor(props) {
    super(props);
    // CommonService.bindNav(this);
  }

  myTrips = () => {};

  contact = () => {
    this.props.history.push("/transactions");
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          {/* <div className="relative dark-font"> */}
          <BGCarpoolInnerBottom zIndex={"zIndex:1"} />
          <div className="cancel-success-screen">
            <div
              className="cancel-success-main-image"
              style={{ marginTop: 20 }}
            >
              <img
                height={100}
                src={
                  this.props.match.params.val == "1"
                    ? "/assets/carpool/icon_transfer_success.png"
                    : "/assets/icons/circular/icon-failure.png"
                }
              />
            </div>
            <div className="cancel-success-title dark-font bold-text font19">
              {this.props.match.params.val == "1"
                ? "¡Saldo compartido!"
                : "¡Tu transacción falló!"}
            </div>
            <div className="wallet-success-container">
              {this.props.match.params.val == "1" ? (
                <div className="cancel-success-text-container font14 text-center light-grey-font">
                  La transferencia de saldo ha sido realizada a la cuenta
                  seleccionada
                  <br />
                  <br />
                  Te enviamos un correo electrónico con los detalles de la
                  transacción.
                  <br />
                </div>
              ) : (
                <div className="cancel-success-text-container font14 text-center light-grey-font">
                  La transferencia solicitada no pudo ser procesada.
                  <br />
                  <br />
                  Por favor intenta nuevamente o contáctanos a nuestros canales
                  de atención.
                </div>
              )}
            </div>

            <div className="cancellation-button-container">
              <PButton
                title={
                  this.props.match.params.val == "1"
                    ? "VER SALDO"
                    : "REINTENTAR"
                }
                onPress={
                  this.props.match.params.val == "1"
                    ? this.contact
                    : () => this.props.history.goBack()
                }
              />
              <PButton
                style={{
                  borderRadius: 10,
                  color: "#FC8332",
                  backgroundColor: "#fff",
                }}
                title="VOLVER AL INICIO"
                className="white-button"
                onPress={() => this.props.history.goBack()}
              />
            </div>
          </div>
          {/* </div> */}
        </IonContent>
      </IonPage>
    );
  }
}

export default connectData()(TransferSuccess);