import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import getSlides from '../../functions/getSlides';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import ThemeSevenSlider from '../common/ThemeSevenSlider';
import './ThemeSevenGalleryScreen.css';

const ThemeSevenGalleryScreen = (props) => {
  return (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-gallery-screen'>
          <div id='gallery'>
            <div class='new-container'>
              <div class='heading'>
                <h1>Nuestras fotos</h1>
              </div>
            </div>
          </div>

          <div className='gallery'>
            <h3>
              <span>Galería</span>
            </h3>
          </div>
          <ThemeSevenSlider
            showDots
            slides={getSlides('Gallery', props.data.operator)}
          />
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  );
};
export default connectData()(ThemeSevenGalleryScreen);
