import React from "react";
import { colors } from "../theme";
import PBCard from "./PBCard";
import PBText from "./PBText";
import DateService from "../services/dateService";
import PBTouchable from "./PBTouchable";

const TransactionListCard = (props) => {
	const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
  const txn = props.transaction;
  console.log("Transaction to render----------", txn);
  return txn.id ? (
    <PBCard className="transaction-list-card">
      <PBTouchable onPress={props.onPress}>
        <div className="transaction-list-main-container">
          <div className="transaction-list-details-container">
            <div className="transaction-list-date-container">
              <PBText
                fontSize={"font17"}
                fontColor={"dark-font"}
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "new-bold-font"
																			: "bold-text"
															}
              >
                {DateService.getDateFromDate(txn.created_date, "yyyy-mm-dd")}
              </PBText>
              <PBText fontColor={"dark-font"}  fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}>
                {DateService.getMonthNameFromDate(
                  txn.created_date,
                  "yyyy-mm-dd"
                )}
              </PBText>
            </div>
            <div className="transaction-list-v-line"></div>
            <div className="transaction-list-type-container">
              {txn.type == "debit" && (
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  Cargo
                </PBText>
              )}
              {txn.type == "credit" && (
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  Abono
                </PBText>
              )}
              {txn.type == "in_process" && (
                <PBText
                  fontColor={"dark-font"}
                  fontSize={"font14"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  En proceso
                </PBText>
              )}
              {txn.type == "failed" && (
                <PBText
                  fontColor={"dark-font"}
                  fontSize={"font14"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  Recarga fallida
                </PBText>
              )}

              {txn.type == "pay_amount" && (
                <PBText
                  fontColor={"dark-font"}
                  fontSize={"font14"}
																		fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  Pendiente
                </PBText>
              )}
              <PBText fontColor={"dark-font"} fontSize={"font11"}
														 fontStyle={
																operator && operator.operator_name === "busnorte"
																		? "side-menu-new-bold-font"
																		: ""
														}
														>
                {txn.category_info && txn.category_info.label}
              </PBText>
            </div>
            <div className="transaction-list-v-line"></div>
            <div className="transaction-list-amount-container">
              {txn.type == "debit" && (
                <div className="display-flex">
                  <PBText fontSize={"font14"} fontColor={"red-font"}>
                    -
                  </PBText>
                  <div className="white-space"></div>
                  <PBText fontSize={"font14"} fontColor={"red-font"}
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "side-menu-new-bold-font"
																						: ""
																		}
																		>
                    {txn.amount}
                  </PBText>
                </div>
              )}
              {txn.type == "credit" && (
                <div className="display-flex">
                  <PBText fontSize={"font14"} fontColor={"green-font"}>
                    +
                  </PBText>
                  <div className="white-space"></div>
                  <PBText fontSize={"font14"} fontColor={"green-font"}>
                    {txn.amount}
                  </PBText>
                </div>
              )}
              {txn.type == "in_process" && (
                <PBText fontColor={"green-font"} fontSize={"font14"}>
                  {txn.amount}
                </PBText>
              )}
              {txn.type == "failed" && (
                <PBText fontColor={"green-font"} fontSize={"font14"}>
                  {txn.amount}
                </PBText>
              )}

              {txn.type == "pay_amount" && (
                <PBText fontColor={"grey-font"} fontSize={"font14"}>
                  {txn.amount}
                </PBText>
              )}
            </div>
          </div>
          {/* <div
              className="my-account-arrow-container"
              // style={[styles.arrowContainer,{backgroundColor:(txn.type == 'debit' ? '#5e5e5e' : (txn.type == 'credit' ? colors.primaryBG : (txn.type == 'in_process' || txn.type == 'failed' ? '#999' : '#5e5e5e' ) ))}]}
            >
              <img width={12} src={"../assets/icons/arrow-forward-white.png"} />
            </div> */}
        </div>
      </PBTouchable>
    </PBCard>
  ) : null;
};

const styles = {
  //TransactionListCard
  mainContainer: {
    width: "100%",
    flexDirection: "row",
  },
  detailsContainer: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
  },
  dateContainer: {
    width: 70,
    alignItems: "center",
  },
  vLine: {
    width: 1,
    borderRightWidth: 1,
    borderRightColor: "$primaryBG",
    height: "100%",
  },
  typeContainer: {
    flex: 1,
    paddingHorizontal: 5,
  },
  amountContainer: {
    marginRight: 5,
  },
  arrowContainer: {
    padding: 4,
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  inProcess: {
    color: "#aaa",
  },
};

export default TransactionListCard;
