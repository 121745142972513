import AndimarTermsAndConditions from "../theme1/AndimarPages/AndimarTermsAndConditions";
import React from "react";
import Theme11TnCScreen from "../theme11/Theme11TnCScreen";
import ThemeFiveTnCScreen from "../theme5/ThemeFivePages/ThemeFiveTnCScreen";
import ThemeFourTnCScreen from "../ThemeFour/ThemeFourPages/ThemeFourTnCScreen";
import ThemeSevenTnCScreen from "../ThemeSeven/ThemeSevenPages/ThemeSevenTnCScreen";
import ThemeTenTermsAndConditions from "../ThemeTen/ThemeTenPages/ThemeTenTermsAndConditions";
import ThemeThreeTnCScreen from "../ThemeThree/ThemeThreePages/ThemeThreeTnCScreen";
import { connectData } from "../../redux";
import ThemeTwoTnCScreen from "../ThemeTwo/common/ThemeTwoTnCScreen";

const CommonTnCPage = props => {
  const getPage = () => {
    const themeType =
      props.data && props.data.operator && props.data.operator.theme_type;
    if (themeType === 1) {
      return <AndimarTermsAndConditions />;
    } else if (themeType === 5) {
      return <ThemeFiveTnCScreen />;
    } else if (themeType === 4) {
      return <ThemeFourTnCScreen />;
    } else if (themeType === 3) {
     return <ThemeThreeTnCScreen />;
     } else if (themeType === 2) {
        return <ThemeTwoTnCScreen />;
    } else if (themeType === 7) {
      return <ThemeSevenTnCScreen />;
    } else if (themeType == 10) {
      return <ThemeTenTermsAndConditions />;
    } else if (themeType == 11) {
      return <Theme11TnCScreen />;
    }
  };

  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonTnCPage);
