import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { AndimarTitle } from '../../common/Title/AndimarTitle';
import Footer1 from '../../common/footer/Footer1';
import Footer2 from '../../common/footer/Footer2';
import Header from '../../common/header/Header';
import Header1 from '../../common/header/Header1';
import HeroSection from '../../common/HeroSection/HeroSection';
import KuposHelmet from '../../common/KuposHelmet';
import KuposTabs from '../../../components/KuposTabs';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import MultiSlider3 from '../../common/MultiSlider/MultiSlider3';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import getSlides from '../../functions/getSlides';

const FleetBanner = '/assets/busnorte/General/fleet.webp';
const FleetTv = '/assets/busnorte/fleet/icono-entretencioon.png';

const FleetToilet = '/assets/busnorte/fleet/icono-comodos-asientoss.png';

const FleetAc = '/assets/busnorte/fleet/icono-climatizacion.png';
// const FleetSnacks = '/assets/andimar/fleet/21.svg';
const FleetBed = '/assets/busnorte/fleet/icono-banos.png';

const BusImage = '/assets/busnorte/fleet/24.jpg';

const amenitiesItems = [
	{
		text: 'Entretención',
		image: FleetTv,
	},
	{
		text: 'Baños',
		image: FleetToilet,
	},
	{
		text: 'Climatización',
		image: FleetAc,
	},
	// {
	// 	text: 'Snacks',
	// 	image: FleetSnacks,
	// },
	{
		text: 'Semi cama',
		image: FleetBed,
	},
];

const firstFloor = [
	' Frazadas',
	'Almohadas',
	'Audífonos',
	'Pantalla touch',
	'Desayuno',
	'Cargador USB',
];
const secondFloor = [
	' Frazadas',
	'Almohadas',
	'Audífonos',
	'Desayuno',
	'Cargador USB',
];

const interStateFleetOne = [
	' Motor electrónico Volvo D11A con 450 CV.',
	'Computadora abordo con diagnóstico de fallas.',
	'Suspensión neumática con control electrónico.',
	'Sistema Volvo de frenos a disco EBS5: EBS (Electronic Braking System) con ABS y control de tracción.',
	'VEB – Freno Motor Volvo con 390 CV.',
	'ESP - Sistema de Control de Estabilidad.',
	' Tercer eje direccional.',
	'Caja de cambios inteligente Volvo I-Shift.',
];

const interStateFleetTwo = [
	'Motor con 410 HP, Ciclo diesel, 4 tiempos, 6 cilindros en línea, tapas de cilindros individual y 4 válvulas por cilindro.',
	'Computadora de abordo con diagnóstico de fallas.',
	'Suspensión neumática con capacidad máxima de eje de 7.500 Kg, barra estabilizadora. Suspensión trasera: Neumática con capacidad máxima en el eje de ( 17.500 Kg).',
	'Sistema Frenos Modelo: R660 de simple reducción. Reducción: 2.92 (opcionales disponibles). Arrastre máximo: 40.000 Kg.',
	'ESP - Sistema de Control de Estabilidad.',
	' Caja de cambios GR875. Descripción: Tipo sincronizada de 8 marchas. Doble gama. 1 marcha atrás. Sistema de cambios automatizado Scania Opticruise.',
];

const FlotaScreen = (props) => {
	const operator =
		JSON.parse(localStorage.getItem('op')) || props.data.operator;

	const initalState = {
		selectedTab: 1,
	};

	const [state, setState] = useState(initalState);

	const tabs = ['Volvo B450 R 6X2 Euro5', 'Scania K410 6X2 Euro3'];

	const onTabChange = (tab) => {
		setState({ ...state, selectedTab: tab });
	};

	const slides = getSlides('Gallery')
	console.log("🚀 ~ FleetScreen ~ slides:", slides)
	const interStateFleetDetails =
		state.selectedTab == 1 ? interStateFleetOne : interStateFleetTwo;

	const styles = {
		singleTab: {
			marginTop: 20,
			background:
				operator && operator.theme_color2 ? operator.theme_color2 : '',
			padding: 15,
			justifyContent: 'space-around',
			color: '#fff',
			borderRadius: 50,
			display: 'flex',
		},
		interCityContainer: {
			background: '#F3F7FF',
			// height: 300,
			marginTop: -40,
			borderRadius: 10,
			paddingTop: 50,
			paddingLeft: 20,
			paddingRight: 20,
			paddingBottom: 30,
		},
		whiteColor: {
			color: '#fff',
		},
	};

	return (
		<IonPage>
			{/* <KuposHelmet /> */}

			<IonContent>
				{/* <div
          style={{
            position: 'absolute',
            width: '100vw',
            zIndex: 1000,
          }}
        >
          <Header1 />
        </div> */}

				<Header />
				<HeroSection
					heroImage={FleetBanner}
					// height={'60vh'}
					height={'45vh'}
					title={'Nuestra Flota'}
					description={'Home / Nuestra Flota.'}
				/>

				{/* Amenities section  */}
				<AndimarTitle title={'Quiénes Somos'} />
				<div className='underline'><img src="/assets/busnorte/Home/underline.svg" alt="" style={{ width: 150 }} /></div>
				<div style={{ width: '90%', marginLeft: '5%' }}>
					<div className='light-blue-background'></div>
					<div
						className='take-care d-flex '
						style={{
							justifyContent: 'space-around',
							marginTop: -60,
						}}
					>
						{amenitiesItems.map((item) => (
							<div className='take-care-item d-flex flex-column align-items-center text-center'>
								<img src={item.image} alt='' style={{ width: 100 }} />
								<p className='mt-2 montserrat bold-text'>{item.text}</p>
							</div>
						))}
					</div>

					{/* <AndimarTitle title={`NUESTRAS  COMODIDADES`} />

					<div className='take-care-item d-flex flex-column align-items-center text-center'>
						<img
							src={BusImage}
							alt=''
							style={{ width: '100%', height: '100%' }}
						/>
					</div>

					<div className='font14 montserrat-bold' style={styles.singleTab}>
						<div style={styles.whiteColor}>Primer Piso</div>
						<div style={styles.whiteColor}>Segundo Piso</div>
					</div>

					<div
						className='font13 montserrat'
						style={{
							padding: 15,
							justifyContent: 'space-around',
							display: 'flex',
						}}
					>
						<div>
							{firstFloor.map((item) => {
								return (
									<div style={{ marginBottom: 8 }}>
										<span style={{ color: 'red' }}>-</span> {item}
									</div>
								);
							})}
						</div>
						<div>
							{secondFloor.map((item) => {
								return (
									<div style={{ marginBottom: 8 }}>
										<span style={{ color: 'red' }}>-</span> {item}
									</div>
								);
							})}
						</div>
					</div> */}

					{/* <AndimarTitle title={`NUESTRA FLOTA INTERURBANA `} />

					<KuposTabs
						tabs={tabs}
						onTabChange={onTabChange}
						selectedTab={state.selectedTab}
						operator={operator}
						width={'100px'}
					/> */}

					{/* <div style={styles.interCityContainer}>
						{interStateFleetDetails.map((item) => {
							return (
								<div
									className='font13'
									style={{
										marginBottom: 10,
										lineHeight: 1.3,
									}}
								>
									<span style={{ color: 'red' }}>{'- '}</span>
									{item}
								</div>
							);
						})}
					</div> */}

					<AndimarTitle title={'GALERÍAs'} bold={false} />
					<div className='underline'><img src="/assets/busnorte/Home/underline.svg" alt="" style={{ width: 150 }} /></div>
				</div>
				{/* Amenities section ends here  */}
				{/* <p>Hello</p> */}
				<MultiSlider3
					// smallHeading="Conoce"
					slideType={1}
					// boldHeading="GALERIA"

					multiSlides={getSlides('Gallery')}
					norteArrowStyle
					width={'80%'}
					height={"auto"}

				/>

				<Footer2 operator={operator} />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(FlotaScreen);
