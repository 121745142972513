import React, { PureComponent } from "react";

// import ViewShot from "react-native-div-shot";
import { connectData } from "../../redux";
import { dimensions, commonStyles, colors } from "../../theme";
import { CommonService } from "../../services";
import {
  PBTouchable,
  PBText,
  BGCarpoolInnerBottom,
  PBModalSliding,
  CarpoolHeader,
  PButton,
  PBModal,
} from "../../components";
import { Link } from "react-router-dom";
import { IonContent, IonPage } from "@ionic/react";
// import { NEW_SCAN_QR_SCREEN } from '../../navigation';

class NewMyQRScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.qrCode = "";
    // CommonService.bindNav(this);
    this.props.getQrImage();
    this.state = {
      showInfoModal: false,
      isLogged: true,
    };
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator)
    }
    this.props.getCustomerProfile({
      callback: (result) => this.onProfileLoad(result),
      operator_id: query
    });
  }

  onProfileLoad = (result) => {
    if (result.statusCode == 200) {
    } else if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "../../../assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  goToLogin = () => {
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      // CommonService.setRoot(MAIN_HOME_SCREEN);
      this.props.history.push("/");
      setTimeout(() => {
        this.props.history.push("/login-options");
        // CommonService.goToScreen(global.homeComponentId,NEW_LOGIN_SCREEN,'Ingresa a tu cuenta');
      }, 150);
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  // componentDidMount() {
  //   if(this.props.data.loginData) {
  //     this.setState({isLogged:true})
  //     console.log("logged in");
  //   }else{
  //     this.setState({isLogged:false})
  //     this.props.history.push("/login");
  //   }
  // }

  //   share = () => {
  //     this.refs.viewShot.capture().then(uri => {
  //         const shareOptions = {
  //           type: 'image/jpg',
  //           title: '',
  //           url: uri
  //         };
  //         Share.open(shareOptions)
  //           .then(res => console.log(res))
  //           .catch(err => console.error(err));
  //     });
  //   }

  scanCode = () => {
    this.props.history.push("/my-account/qr/scan");
    //   CommonService.goToScreenHideTopBar(this.props.componentId,NEW_SCAN_QR_SCREEN)
  };

  showInfo = () => {
    this.setState({
      showInfoModal: false,
    });
  };

  renderInfoModal = () => {
    return (
      <PBModalSliding
        showModal={this.state.showInfoModal}
        onBackButtonPress={() => this.setState({ showInfoModal: false })}
        onBackdropPress={() => this.setState({ showInfoModal: false })}
        // onSwipeComplete={() => this.setState({ showInfoModal: false })}
        // swipeDirection="down"
      >
        <div className="qr-info-modal-content">
          <div className="qr-modal-icon-container">
            <img height={60} src="../../assets/carpool/scan-circle.png" />
          </div>
          <div>
            <PBText
              textAlign={"text-center"}
              fontColor={"dark-font"}
              fontStyle={"bold-font"}
              fontSize={"font15"}
            >
              {"¿Para qué sirve el código QR?\n"}
            </PBText>
            <PBText
              textAlign={"text-center"}
              fontSize={"font14"}
              fontColor={"light-grey-font"}
              style={{ marginTop: 20 }}
            >
              {"Comparte el tuyo o escanea el de otros.\n\n"}
            </PBText>
            <PBText
              textAlign={"text-center"}
              fontSize={"font14"}
              fontColor={"light-grey-font"}
              style={{ marginTop: 20 }}
            >
              {
                "Puedes escanear o compartir tu código QR para cobrar, pagar un servicio, compartir o recibir saldo."
              }
            </PBText>
          </div>
        </div>
      </PBModalSliding>
    );
  };

  share = () => {
    // var shareData = {
    //   files: this.props.data.qrImage.img_src,
    //   url: window.location.href,
    // };

    // if (navigator.canShare && navigator.canShare(shareData)) {
    // Adding title afterwards as navigator.canShare just
    // takes files as input
    // shareData.title = "Bits and pieces";

    // navigator
    //   .share(shareData)
    //   .then(() => console.log("Share was successful."))
    //   .catch((error) => console.log("Sharing failed", error));
    // } else {
    //   console.log("Your system doesn't support sharing files.");
    // }
    try {
      // if (navigator.canShare()) {
      navigator
        .share({
          title: "Mi QR",
          text: `Nombre: ${
            this.props.data &&
            this.props.data.loginData &&
            this.props.data.loginData.name
          } \nRUT:${
            this.props.data &&
            this.props.data.loginData &&
            this.props.data.loginData.identity_val
          }\n`,
          // url: this.props.data.qrImage.img_src,
          // url: "",
          url: window.location.href,
        })
        .then(() => console.log("Successful share! 🎉"))
        .catch((err) => console.error(err));
      // }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          {this.st}
          <div className="full-flex qr-white-BG">
            <BGCarpoolInnerBottom zIndex={"zIndex:1"} />

            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                />
              </div>

              <div className="font14 my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}>
                  Mi código
                </PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
                  fontStyle={"bold-font"}
                >
                  QR
                </PBText>
              </div>

              <div
                className="my-account-flex-end"
                onClick={() => this.setState({ showInfoModal: true })}
              >
                <img src="../../assets/carpool/sidemenu/info.png" />
              </div>
            </div>
            <div className="qr-code-container">
              {/* <ViewShot ref="viewShot" options={{ format: "jpg", quality: 0.9 }}> */}
              <div>
                {this.props.data.qrImage ? (
                  <div className="qr-shot-container">
                    <div className="qr-qr-code-container-main">
                      {/* <QRCode
                                value={this.props.data.loginData.identity_val}
                                size={200}
                                bgColor='#000000'
                                fgColor='#ffffff'/> */}
                      <img
                        className="qr-image"
                        src={this.props.data.qrImage.img_src}
                      />
                    </div>
                    <div className="qr-rut-container">
                      <PBText textAlign={"text-center"} fontSize={"font14"}>
                        {this.props.data &&
                          this.props.data.loginData &&
                          this.props.data.loginData.name}{" "}
                        {this.props.data &&
                          this.props.data.loginData &&
                          this.props.data.loginData.last_name}
                      </PBText>
                      <PBText style={{ textAlign: "right" }}>
                        {this.props.data &&
                          this.props.data.loginData &&
                          this.props.data.loginData.identity_val}
                      </PBText>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* </ViewShot> */}
            </div>
            <div className="qr-buttons-container">
              <div className="qr-button-container">
                <PButton
                  onPress={this.share}
                  style={{ borderRadius: 10 }}
                  title={"COMPARTIR MI CÓDIGO"}
                />
              </div>
              {false && (
                <div className="qr-button-container">
                  <PButton
                    onPress={() => {}}
                    style={{
                      borderRadius: 10,
                      color: "#FC8332",
                      backgroundColor: "#fff",
                    }}
                    className="white-button"
                    textColor={"#FC8332"}
                    title={"ESCANEAR CÓDIGO"}
                  />
                </div>
              )}
            </div>
            {this.renderInfoModal()}

            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.modalButtonText}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.goBack
              }
            ></PBModal>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  qrCodeContainer: {
    flex: 1,
    alignItems: "center",
  },
  bottomButtons: {
    width: "100%",
    height: 50,
  },
  bottomButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primaryBG,
  },
  shotContainer: {
    width: dimensions.vw * 100,
    alignItems: "center",
    marginTop: 40,
    backgroundColor: "#fff",
  },
  qrContainer: {
    // width:200,
    // height:200,
  },
  rutContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: dimensions.vw * 70,
    paddingTop: 20,
    marginTop: 25,
    borderTopWidth: 1,
    borderTopColor: "#ddd",
  },
  buttonContainer: {
    marginTop: -10,
  },
  buttonsContainer: {
    width: dimensions.vw * 100 - 60,
    marginHorizontal: 30,
    position: "absolute",
    bottom: 10,
  },

  infoModalContent: {
    padding: 20,
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  modalIconContainer: {
    alignItems: "center",
    marginBottom: 20,
    marginTop: 10,
  },
  textCenter: {
    textAlign: "center",
  },
};

export default connectData()(NewMyQRScreen);
