import { IonContent, IonPage } from "@ionic/react";

import React from "react";
import TarapacaGallery from "./common/TarapacaGallery";
import TarapacaHeader from "./components/TarapacaHeader";
import { connectData } from "../../redux";
import { withRouter } from "react-router-dom";

const operator = { operator_name: "tarapaca" || "biaggini" };

const TarapacaGalleryScreen = ({ history }) => {
	return (
		<IonPage>
			<IonContent>
				<TarapacaHeader
					title={"       "}
					boldTitle={"Galería"}
					handleHomeSubmit={() => history.push("/")}
					handleBackSubmit={() => history.goBack()}
				/>
				<div className="new-container">
					{/* <ThemeTwoGallery /> */}
					<TarapacaGallery operator={operator} />
				</div>
			</IonContent>
		</IonPage>
	);
};
export default withRouter(connectData()(TarapacaGalleryScreen));