const email = '/assets/busnorte/General/Ivergrama-Website-Email-Icon.svg';
// const PopupIcon = '/assets/busnorte/gallery/gallery5.jpg';
const BookingActive =
	'/assets/busnorte/Home/Ivergrama-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
	'/assets/busnorte/Home/Ivergrama-Website-Home-SearchBar-CancelTicket.svg';
const OriginIcon =
	'/assets/chilebus/origen.svg';
const DestinationIcon =
	'/assets/chilebus/destino.svg';
const CalendarIcon =
	'/assets/chilebus/calendar-one.svg';
const blueCalendar = '/assets/chilebus/calendar-two.svg';
// const SearchIcon = '/assets/busnorte/Home/Ivergrama-Website-Home-SearchBar-SearchIcon.svg';
const titleUnderline = '/assets/busnorte/Home/underline.svg';

const BG =
	'/assets/chilebus/bg.webp';

const sendBtn = '/assets/busnorte/Home/Ivergrama-Website-Home-SendContact.svg';
const contactFormImg =
	'/assets/busnorte/Home/Ivergrama-Website-Home-BannerSpecialServices.jpg';

const fb =
	'/assets/chilebus/whitefb.svg';
const fbHover =
	'/assets/busnorte/Contact/facebook.svg';
const twitter =
	'/assets/busnorte/Contact/instagram.svg';
const twitterHover =
	'/assets/busnorte/Contact/instagram.svg';
const titleRightIcon = '/assets/chilebus/star.svg';
const titleLeftIcon = '/assets/chilebus/star.svg';
const LocationIcon = '/assets/busnorte/General/LocationIcon.svg';
const PhoneIcon = '/assets/chilebus/phoneicon.svg';
const HeaderLogo = '/assets/busnorte/General/Header-BusesTepualLogo.svg';
const FooterLogo = '/assets/busnorte/General/Footer-BusesTepualLogo.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';

const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const pnr =
	'/assets/chilebus/wheretofindmypnr.jpeg';

const contactMail =
	'/assets/chilebus/email.svg';
const contactAddress =
	'/assets/chilebus/phone.svg';

const aboutUsImage =
	'/assets/chilebus/aboutsmall.webp';
// banners
const destinationBanner = '/assets/chilebus/destination.svg';
const serviceBanner =
	'/assets/busnorte/Services/Ivergrama-Website-Services-MainBanner.jpg';
const contactUsBanner =
	'/assets/chilebus/contact-banner.svg';
const aboutUsBanner =
	'/assets/chilebus/about.svg';
const tncBanner =
	'/assets/chilebus/tnc.svg';
const officeBanner = '/assets/chilebus/offices.svg';

const OfficePhoneIcon = '/assets/busnorte/General/officePhone.svg';

const contactFb =
	'/assets/chilebus/fb.svg';
// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/chilebus/blue/IllustratedIcons-MyCards-Blue.svg';
const viajes = '/assets/chilebus/blue/IllustratedIcons-MyTrips-Blue.svg';
const movimientos = '/assets/chilebus/blue/IllustratedIcons-MyMovements-Blue.svg';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/chilebus/blue/IllustratedIcons-Camera-Blue.svg';
const nortecamera = '/assets/busnorte/Home/login.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/chilebus/blue/IllustratedIcons-Tick-Blue.svg';
const downArrow = '/assets/chilebus/blue/IllustratedIcons-DropDown-Blue.svg';
const loginblue = '/assets/chilebus/blue/IllustratedIcons-Login-Blue-phone.svg';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/chilebus/blue/IllustratedIcons-Tick-Blue.svg';
const failure = '/assets/chilebus/blue/MyAccount-IllustratedIcons-Information-Blue.svg';
const warning = '/assets/chilebus/blue/IllustratedIcons-Information-Blue.svg';
const userIcon = '../assets/chilebus/blue/IllustratedIcons-user.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const contactHeadphone = '/assets/chilebus/headphone.svg'
//  pet seat 
const contactpin = '/assets/chilebus/pin.png'
const petseatref = '/assets/busnorte/pet/Ivergrama-ResultPage-SeatPick-PetsOnBoard_Pet Paw Icon (Amenite).svg';
const petseatModal = '/assets/busnorte/pet/PopUp.png';
const petfilteroff = '/assets/busnorte/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_off.svg';
const petfilteron = '/assets/busnorte/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_On.svg';
const operatorPay = '/assets/santamaria/SMPaypng.png'
// const operatorPay = '/assets/busnorte/iverpay.png'

const chilebusIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: titleLeftIcon, name: 'titleLeftIcon' },
	{ icon: titleRightIcon, name: 'titleRightIcon' },
	{ icon: blueCalendar, name: 'blueCalendar' },
	{ icon: contactHeadphone, name: 'contactHeadphone' },
	{ icon: contactpin, name: 'pin' },
	{ icon: contactFb, name: 'contactFb' },
	{ icon: PhoneIcon, name: 'phone' },
	{ icon: email, name: 'email' },
	{ icon: LocationIcon, name: 'location' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: contactFormImg, name: 'contactFormImg' },
	{ icon: titleUnderline, name: 'titleUnderline' },

	{ icon: sendBtn, name: 'sendBtn' },
	{ icon: fb, name: 'fb' },
	{ icon: fbHover, name: 'fbHover' },
	{ icon: twitter, name: 'twitter' },
	{ icon: twitterHover, name: 'twitterHover' },
	{ icon: BG, name: 'BG' },

	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	// { icon: SearchIcon, name: 'Search' },
	// { icon: PopupIcon, name: 'Popup' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },

	{ icon: contactMail, name: 'contactMail' },
	{ icon: contactAddress, name: 'contactAddress' },
	{ icon: destinationBanner, name: 'destinationBanner' },
	{ icon: serviceBanner, name: 'serviceBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: aboutUsImage, name: 'aboutUsImage' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: tncBanner, name: 'tncBanner' },
	{ icon: officeBanner, name: 'officeBanner' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
	{ icon: nortecamera, name: 'nortecamera' },

	//  pet seat 
	{ icon: petseatref, name: 'petseatref' },
	{ icon: petseatModal, name: 'petseatModal' },
	{ icon: petfilteron, name: 'petfilteron' },
	{ icon: petfilteroff, name: 'petfilteroff' },

	{ icon: operatorPay, name: 'operatorPay' },

];

export default chilebusIcons;
