import "./TarapacaServices.css";

import React from "react";

const TarapacaServices = ({ title, operator, className }) => {
	const data = [
		{
			icon: "/assets/tarapaca/Services-icon-1.svg",
			heading: "Amplios asientos",
			text: "Contamos con buses Salón Cama y Buses Suite Premium 180°.",
		},
		{
			icon: "/assets/tarapaca/Services-icon-2.svg",
			heading: "Convenios con empresas",
			text: "Accede a precios preferenciales al contratar servicios corporativos.",
		},
		{
			icon: "/assets/tarapaca/services-icon.png",
			heading: "Viajes de turismo",
			text: "Cotiza y contrata viajes privados con nosotros.",
		},
	];
	return (
		<div className="services-main-div">
			{data.map(item => {
				return (
					<div className="services-inner-div">
						<div className="services-img-div">
							<img src={item.icon} />
						</div>
						<div className="services-text-div">
							<span className="bold-text" style={{ marginBottom: "5px" }}>
								{item.heading}
							</span>
							<span style={{ width: "215px", fontSize: "16px" }}>{item.text}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default TarapacaServices;
