import {
	BGCarpoolInnerBottom,
	CarpoolHeader,
	CarpoolSuccessFailureModal,
	PBCard,
	PBCarpoolBG,
	PBModal,
	PBText,
	PBTouchable,
	PButton,
} from "../../components";
import {
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	withIonLifeCycle,
} from "@ionic/react";
import React, { PureComponent } from "react";
import { commonStyles, dimensions } from "../../theme";

import { CommonService } from "../../services";
import { Link } from "react-router-dom";
import { connectData } from "../../redux";
import getIcons from "../../themes/functions/getIcons/getIcons";

// import { MY_QR_CODE_SCREEN, CARPOOL_MY_TRIPS_SCREEN, MAIN_HOME_SCREEN, NEW_LOGIN_SCREEN } from '../../../navigation';

class CarpoolMyAccountScreen extends PureComponent {
	idCardTypes = [];
	constructor(props) {
		super(props);
		this.state = {};
		if (
			this.props.data.metaData &&
			this.props.data.loginData &&
			this.props.data.metaData.id_card_types
		)
			for (let c in this.props.data.metaData.id_card_types) {
				this.idCardTypes.push({
					value: this.props.data.metaData.id_card_types[c],
					id: this.props.data.metaData.id_card_types[c],
					label: c,
					name: c,
					selected:
						this.props.data.metaData.id_card_types[c] ==
						this.props.data.loginData.identity_type,
				});
			}
		// this.props.getTxnHistory({callback:(result) => this.onHistoryLoad(result)});
	}

	componentDidMount() { }

	ionViewWillEnter() {
		localStorage.setItem("isChangeTicket", false);
		this.getProfile();
	}

	getProfile = event => {
		const operator =
			JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
		let query;
		if (CommonService.shouldPassOperatorId(operator)) {
			query = "operator_id=" + CommonService.getOperatorId(operator);
		}
		this.props.getCustomerProfile({
			callback: result => this.onProfileLoad(result, event),
			operator_id: query,
		});
	};

	onProfileLoad = (result, event) => {
		if (event) {
			event.detail.complete();
		}
		if (result.statusCode == 200) {
			CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
		} else if (result.statusCode == 401 && result.data.error) {
			this.setState({
				showModal: true,
				modalTitle: result.data.error,
				modalIcon: null,
				modalButtonText: "Iniciar Sesión",
				authFailed: true,
				modalButtonTap: this.goToLogin,
				modalBackButtonPress: this.goToHome,
			});
		} else if (result.data && result.data.error) {
			this.setState({
				showModal: true,
				modalTitle: "¡Lo Sentimos!",
				modalBody: result.data.error,
				modalIcon: "../../../assets/icons/cross-grey.png",
				modalButtonText: "OK",
				modalButtonTap: this.closeModal,
				modalBackButtonPress: this.closeModal,
			});
		}
	};

	goToLogin = () => {
		let authFailed = this.state.authFailed;
		this.closeModal();
		if (authFailed) {
			this.logout();
			CommonService.storeLoginDetails(null, this.props.loginSuccess);
			// CommonService.setRoot(MAIN_HOME_SCREEN);
			this.props.history.push("/");
			setTimeout(() => {
				this.props.history.push("/login-options");
				// CommonService.goToScreen(global.homeComponentId,NEW_LOGIN_SCREEN,'Ingresa a tu cuenta');
			}, 150);
		}
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	renderAccountCardInfo = profileData => {
		const operator = JSON.parse(localStorage.getItem("op"));
		return (
			<PBCard className="my-account-main-card" marginTop={35}>
				<div className="my-account-dp-container">
					<div
						className="my-account-img-circle"
						style={{
							backgroundImage: `url(${profileData.image_link || getIcons("userIcon")
								// "/assets/carpool/user-circle.png"||
								})`,
						}}
					></div>
					<div className="my-account-main-details-container">
						<PBText
							fontStyle={
								operator && operator.operator_name === "busnorte"
									? " new-bold-font"
									: "bold-font"
							}
							fontColor={"dark-font"}
							textAlign={"text-center"}
							fontSize={"font15"}
						>
							{CommonService.capitalize(profileData.name)}{" "}
							{profileData.last_name
								? CommonService.capitalize(profileData.last_name)
								: ""}
						</PBText>

						<div
							className={
								operator && operator.operator_name === "busnorte"
									? "side-menu-new-bold-font display-flex justify-center"
									: "display-flex justify-center"
							}
							style={{ marginBottom: 1 }}
						>
							{profileData && (
								<PBText fontColor={"dark-font"}>
									{this.idCardTypes &&
										this.idCardTypes.find(
											it => it.value == profileData.identity_type,
										)
										? this.idCardTypes.find(
											it => it.value == profileData.identity_type,
										).name
										: ""}
								</PBText>
							)}

							<PBText textAlign={"text-center"}>:</PBText>
							<div className="white-space"></div>

							<PBText
								textAlign={"text-center"}
								fontStyle={
									operator && operator.operator_name === "busnorte"
										? " side-menu-new-bold-font"
										: ""
								}
							>
								{profileData.identity_val}
							</PBText>
						</div>

						<PBText
							textAlign={"text-center"}
							fontStyle={
								operator && operator.operator_name === "busnorte"
									? " side-menu-new-bold-font"
									: ""
							}
						>
							Email : {profileData.email}
						</PBText>
					</div>
				</div>
				<div className="my-account-h-line"></div>
				{operator && operator.is_pay_enabled && (
					<div className="my-account-wallet-strip">
						<div className="display-flex">
							<PBText
								fontSize={"font14"}
								fontStyle={"bold-font"}
								fontColor={"dark-font"}
								fontStyle={
									operator && operator.operator_name === "busnorte"
										? " side-menu-new-bold-font"
										: "bold-font"
								}
							>
								Mi saldo disponible
							</PBText>
						</div>
						<div className="flex-end">
							<div className="display-flex">
								<PBText
									fontColor={"dark-font"}
									fontStyle={"bold-font"}
									fontSize={"font14"}
									fontStyle={
										operator && operator.operator_name === "busnorte"
											? " side-menu-new-bold-font"
											: "bold-font"
									}
								>
									{profileData.wallet_moneys &&
										profileData.wallet_moneys.currency &&
										profileData.wallet_moneys.currency.toUpperCase()}
								</PBText>

								<div className="white-space"></div>

								<PBText
									fontColor={"dark-font"}
									fontStyle={"bold-font"}
									fontSize={"font14"}
									fontStyle={
										operator && operator.operator_name === "busnorte"
											? " side-menu-new-bold-font"
											: "bold-font"
									}
								>
									{" " +
										CommonService.currency(
											profileData.wallet_moneys &&
											profileData.wallet_moneys.amount,
										)}
								</PBText>
							</div>
						</div>
					</div>
				)}
			</PBCard>
		);
	};

	renderMenuCard = (icon, name, gotoScreen) => {
		return (
			<div
				className="my-account-menu-card"
				onClick={
					gotoScreen == "/my-account/qr"
						? this.props.data.loginData &&
							this.props.data.loginData.identity_type == 7
							? () => this.props.history.push(gotoScreen)
							: () =>
								this.setState({
									showSuccessFailureModal: true,
									showSuccessFailureModalStatus: false,
									showSuccessFailureModalBodyText:
										"Esta función solo está disponible para usuarios registrados con RUT",
								})
						: !gotoScreen == ""
							? () => this.props.history.push(gotoScreen)
							: null
				}
			>
				<div className="menu-card-inner">
					<div className="my-account-menu-icon-container">
						<img src={icon} />
					</div>
					<div
						className={
							this.props.data.operator &&
								this.props.data.operator.operator_name === "busnorte"
								? "my-account-card-image-container side-menu-new-bold-font"
								: "my-account-card-image-container"
						}
					>
						<PBText fontSize={"font12"}>{name}</PBText>
					</div>
					{/* <div className="my-account-card-image-container">
                <PBText fontSize={"font12"}>{name.split("\n")[1]? name.split("\n")[1] : null}</PBText>
              </div> */}
				</div>
			</div>
		);
	};

	gotoScreen = screen => {
		if (screen) {
			// if(screen == MY_QR_CODE_SCREEN){
			//     CommonService.goToScreen(this.props.componentId,screen)
			// }else{
			//     CommonService.goToScreenHideTopBar(this.props.componentId,screen)
			// }
		}
	};

	logout = () => {
		this.setState({
			showLogoutModal: false,
		});
		try {
			let googleAccessToken = localStorage.getItem("googleAccessToken");
			if (googleAccessToken) {
				this.props.logoutGoogle({ token: googleAccessToken });
				localStorage.removeItem("googleAccessToken");
			}
			window.FB.logout();
		} catch (e) {
			console.log(e);
		}
		// CommonService.goToHome(this.props.componentId)
		sessionStorage.removeItem("oAuthData");
		localStorage.removeItem("loginData");
		localStorage.removeItem("googleAccessToken");
		CommonService.storeLoginDetails(null, this.props.loginSuccess);
		this.props.history.push("/");
		setTimeout(() => {
			// CommonService.toast.show("sesion cerrado exitosamante!");
		}, 300);
	};

	render() {
		const operator =
			JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
		return (
			<IonPage>
				{/* <PBCarpoolBG /> */}
				<div className="my-account-header">
					<div
						className="header-back-button"
						onClick={() => this.props.history.push("/")}
					>
						<img
							className="qr-back-arrow"
							src="../../assets/carpool/icon_arrow_back_white.png"
							alt=""
						/>
					</div>
					<div className="my-account-header-label display-flex">
						<PBText fontSize={"font19"} fontColor={"white-font"}>
							Mi
						</PBText>
						<div className="white-space"></div>
						<PBText
							fontSize={"font19"}
							fontColor={"white-font"}
							fontStyle={"bold-font"}
						>
							cuenta
						</PBText>
					</div>
					<div
						className="my-account-flex-end"
						onClick={() => this.setState({ showLogoutModal: true })}
						style={{ filter: "invert(1)" }}
					>
						<img src="../../assets/carpool/icon_logout_white.png" alt="" />
					</div>
				</div>

				<IonContent className="content-transparent">
					<div
					// style={[styles.flex,commonStyles.whiteBG]}
					>
						{this.props.data.loginData
							? this.renderAccountCardInfo(this.props.data.loginData)
							: null}
						<div className="my-account-title-container">
							<PBText
								fontSize={"font15"}
								fontStyle={
									operator && operator.operator_name === "busnorte"
										? "new-bold-font"
										: "bold-font"
								}
								fontColor={"dark-font"}
								textAlign={"text-center"}
							>
								Registro de movimientos
							</PBText>

							<PBText
								textAlign={"text-center"}
								fontSize={"font14"}
								fontStyle={
									operator && operator.operator_name === "busnorte"
										? " side-menu-new-bold-font"
										: ""
								}
							>
								Tus viajes, gastos, estadísticas y más.
							</PBText>
						</div>
						<div className="my-account-menu-card-row">
							{/* {this.renderMenuCard(
                '../../../assets/carpool/scan-circle.png',
                'Mi QR',
                '/my-account/qr'
              )} */}
							{/* {this.renderMenuCard(
                "../../../assets/carpool/bip-circle.png",
                "Mi bip!",
                // "/my-account/bip-home"
                "/bip-coming-soon"
              )} */}
							{this.renderMenuCard(
								// "../../../assets/carpool/wallet-circle.png",
								getIcons("tarjetas"),
								"Mis tarjetas",
								"/my-account/my-cards",
							)}
							{this.renderMenuCard(
								// "../../../assets/carpool/numberplate-circle.png",
								getIcons("viajes"),

								"Mis viajes",
								"/my-account/my-trips",
							)}
						</div>
						<div className="my-account-menu-card-row">
							{this.renderMenuCard(
								// "../../../assets/carpool/pigi-bank-circle.png",
								getIcons("movimientos"),
								"Mis\nmovimientos",
								"/transactions",
							)}
							{CommonService.showExtraMyAccountTabs(operator) &&
								this.renderMenuCard(
									// '../assets/icons/menu/wallet.png',
									getIcons("wallet"),

									"Recargar\nwallet",
									"/recharge-wallet",
								)}

							{/* {this.renderMenuCard(
                '../../../assets/carpool/environment-circle.png',
                'Impacto\nambiental',
                '/carboon-emission'
              )} */}
						</div>
						<div className="my-account-menu-card-row">
							{CommonService.showExtraMyAccountTabs(operator) &&
								this.renderMenuCard(
									// '../assets/icons/menu/transfer-money.png',
									getIcons("shareMoney"),

									"Compartir\nSaldo",
									"/recharge-transfer",
								)}
							{operator.is_couponera_enabled &&
								CommonService.showExtraMyAccountTabs(operator) &&
								this.renderMenuCard(
									// '../assets/icons/menu/transfer-money.png',
									getIcons("cuponeraIcon"),

									"Mis cuponeras",
									"/my-account/my-coupons",
								)}
						</div>

						<PBModal
							showModal={this.state.showModal}
							titleText={this.state.modalTitle}
							bodyText={this.state.modalBody}
							type="alert"
							showButton1={true}
							button1Text={this.state.modalButtonText}
							button1Press={
								this.state.modalButtonTap
									? this.state.modalButtonTap
									: this.closeModal
							}
							icon={this.state.modalIcon}
							onBackButtonPress={
								this.state.modalBackButtonPress
									? this.state.modalBackButtonPress
									: this.goBack
							}
						></PBModal>
						<PBModal
							showModal={this.state.showLogoutModal}
							titleText="¿Está seguro que desea cerrar su sesión?"
							buttonDirection={"display-flex-column"}
							showButton1={true}
							button1ColorBg={"primary-color"}
							button1FontColor={"white-font"}
							button1Text="SÍ, CERRAR SESIÓN"
							button1Press={this.logout}
							showButton2={true}
							// button2ColorBg={'primary-light-color-bg'}
							button2FontColor={"primary-color-font"}
							button2Text="NO, VOLVER"
							button2Press={() => {
								this.setState({ showLogoutModal: false });
							}}
							onBackButtonPress={() =>
								this.setState({ showLogoutModal: false })
							}
						/>
						<CarpoolSuccessFailureModal
							showModal={this.state.showSuccessFailureModal}
							success={this.state.showSuccessFailureModalStatus}
							smallIcon={true}
							bodyText={this.state.showSuccessFailureModalBodyText}
							buttonText={"OK, VOLVER"}
							onButtonPress={() => {
								this.setState({
									showSuccessFailureModal: false,
									showSuccessFailureModalBodyText: null,
								});
							}}
						/>
					</div>
					<IonRefresher slot="fixed" onIonRefresh={this.getProfile}>
						<IonRefresherContent
							pullingText="Desliza para actualizar"
							refreshingSpinner="circles"
							refreshingText="Cargando..."
						></IonRefresherContent>
					</IonRefresher>
				</IonContent>
			</IonPage>
		);
	}
}

const styles = {
	flex: {
		flex: 1,
	},
	dpContainer: {
		alignItems: "center",
		marginBottom: 20,
		marginTop: 30,
	},
	boxShadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.6,
		shadowRadius: 14,

		elevation: 10,
		backgroundColor: "#fff",
	},
	imgCircle: {
		width: 20,
		height: 20,
		borderRadius: 20,
		overflow: "hidden",
		borderColor: "#fff",
		borderWidth: 2,
	},
	hLine: {
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
		marginTop: 10,
		marginBottom: 10,
		width: "100%",
	},
	titleContainer: {
		alignItems: "center",
		marginVertical: 25,
	},
	menuCardsRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
		marginHorizontal: 30,
	},
	menuCard: {
		borderRadius: 12,
		width: "30%",
		backgroundColor: "#fff",
		height: 40,
		overflow: "hidden",
	},
	menuIconContainer: {
		alignItems: "flex-end",
		position: "absolute",
		right: 0,
		top: 0,
	},
};

export default connectData()(withIonLifeCycle(CarpoolMyAccountScreen));
