const destination1 = '/assets/liquine/Home/Liquiñe-Website-Destinations-01.png';
const destination2 = '/assets/liquine/Home/Liquiñe-Website-Destinations-02.png';
const destination3 = '/assets/liquine/Home/Liquiñe-Website-Destinations-03.png';
const destination4 = '/assets/liquine/Home/Liquiñe-Website-Destinations-04.png';
const destination5 = '/assets/liquine/Home/Liquiñe-Website-Destinations-05.png';

const banner1 = '/assets/liquine/Home/Liquiñe-Website-Home-Desktop-01.jpg';

const santiagoImage1 = '/assets/liquine/Offices/Liquiñe-Oficinas-Zona-sur-Santiago.png';

const zonasurImage1 = '/assets/liquine/Offices/Liquiñe-Oficinas-Zona sur-Villarica.png';
const zonasurImage2 = '/assets/liquine/Offices/Liquiñe-Oficinas-Zona sur-Coñaripe.png';
const zonasurImage3 = '/assets/liquine/Offices/Liquiñe-Oficinas-Zona sur-Lican Ray.png';



const destination = [
  destination1,
  destination2,
  destination3,
  destination4,
  destination5,
];


const banner =[banner1]



const santiagoTabDetails = [
  {
    id: 1,
    image: santiagoImage1,
    mapLink: 'https://goo.gl/maps/z7qgw1HbEwbWdW7LA',
    address: `Av. Libertador Bernardo O’Higgins 3850, Estación Central, Santiago, Chile`,
    // phone: `(56) 2 2779 9412`,
  },
];

const zonarSurTabDetails = [
  {
    id: 1,
    image: zonasurImage1,
    mapLink: 'https://goo.gl/maps/gpKCEGCAyvwqT8uF9',
    address: `Anfión Munoz 745, Villarrica, La Araucanía,Chile`,
  },

  {
    id: 2,
    image: zonasurImage2,
    mapLink: 'https://goo.gl/maps/N6hLgu2GSnhweYri7',
    address: `C. Guido Beck de Ramberga 703, Coñaripe, Los Ríos, Chile`,
    // phone: `(56) 9 7215 2261`,
  },
  {
    id: 3,
    image: zonasurImage3,
    mapLink: 'https://goo.gl/maps/nfQ4UcBvZ6C8dqzi9',
    address: `General Urrutia 405, Lican Ray, La Araucanía, Chile`,
    // phone: `(56) 9 7793 4988`,
  },
];

const AgencyTabs = {
  tabs: [
    { id: 0, label: 'Santiago' },
    { id: 1, label: 'Zona Sur' },
  ],
  0: santiagoTabDetails,
  1: zonarSurTabDetails,
};



const liquineSlides = {
  destination,
  banner,
  AgencyTabs,
};

export default liquineSlides;
