import { IonContent, IonPage } from "@ionic/react";

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import KuposHeader from "../../components/KuposHeader";
import { PBText } from "../../components";
// import HeroSection from "../../common/HeroSection/HeroSection";
import React from "react";
import { connectData } from "../../redux";

const Theme11Tariff = ({ operator, history }) => {
	return (
		<IonPage>
				<IonContent>
				<KuposHeader
            // data={props.data}
            boldTitle={"Tarifas "}
           
            handleHomeSubmit={() => history.push("/")}
            handleBackSubmit={() => history.goBack()}
          />
						{/* <HeroSection
								title={"Términos y condiciones"}
								height={"30vh"}
								description={"Home / Términos y condiciones"}
								heroImage={getIcons("tncBanner")}
						/> */}
						
						<div className="new-container mb10 mt10" >
								<div className="lh-1-3 font14 justify-text"
								>
										<PBText>
										Las tarifas corresponden al traslado del <strong>recorrido completo, </strong>pudiendo el pasajero optar por descender o abordar en alguna de las <strong>paradas autorizadas </strong>según corresponda.
										</PBText>
								</div>
						</div>
						{/* <Footer2 operator={operator} /> */}
				</IonContent>
		</IonPage>
);
}

export default Theme11Tariff