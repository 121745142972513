import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import Footer2 from '../../common/footer/Footer2';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeFiveOfficeSection from '../common/ThemeFiveOfficeSection';

function ThemeFiveOfficesScreen(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('officeBanner')}
          height={'30vh'}
          title={'Oficinas'}
          description={'Home / Oficinas'}
        />

        <div style={{ marginTop: 20 }}>
          {/* <OfficesList officeLocations={operator.office_data} /> */}
          <ThemeFiveOfficeSection />
        </div>

        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(ThemeFiveOfficesScreen);
