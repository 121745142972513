// passing the path into variables

const banner1 = '/assets/centraltour/Home/CentralTour-Website-Home-Slide-Desktop-01.jpg';
const banner2 = '/assets/centraltour/Home/CentralTour-Website-Home-Slide-Desktop-02.jpg';

const destination1 = '/assets/centraltour/Destinations/CentralTour-Website-Destinations-01.jpg';
const destination2 = '/assets/centraltour/Destinations/CentralTour-Website-Destinations-02.jpg';
const destination3 = '/assets/centraltour/Destinations/CentralTour-Website-Destinations-03.jpg';
const destination4 = '/assets/centraltour/Destinations/CentralTour-Website-Destinations-04.jpg';
const destination5 = '/assets/centraltour/Destinations/CentralTour-Website-Destinations-05.jpg';

const gallery1 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-01.jpg';
const gallery2 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-02.jpg';
const gallery3 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-03.jpg';
const gallery4 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-04.jpg';
const gallery5 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-05.jpg';
const gallery6 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-06.jpg';
const gallery7 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-07.jpg';
const gallery8 = '/assets/centraltour/Gallery/CentralTour-Website-Gallery-08.jpg';

const banner = [banner1, banner2];

const destination = [destination1, destination2, destination3, destination4, destination5];

const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8];


const centralTourSlides = {
  // banner,
  destination,
  gallery,
};

export default centralTourSlides;
