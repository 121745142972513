import React from 'react';
import HeroSection from '../../common/HeroSection/HeroSection';
import { IonContent, IonPage } from '@ionic/react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import Footer2 from '../../common/footer/Footer2';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeFiveTnCScreen = (props) => {
  const operator = props.data.operator;

  const createMarkup = (data) => {
    return { __html: data };
  };

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          title={'Términos y condiciones'}
          height={'30vh'}
          description={'Home / Términos y condiciones'}
          heroImage={getIcons('tncBanner')}
        />
        <div className='new-container mb10 mt10' style={{ padding: 20 }}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              operator.terms ? operator.terms : ''
            )}
            className='lh-1-3 font11 justify-text'
          />
        </div>
        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeFiveTnCScreen);
