import { IonContent, IonPage } from "@ionic/react";

import React from "react";
import TarapacaAboutUs from "./common/TarapacaAboutUs";
import TarapacaHeader from "./components/TarapacaHeader";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";

// import getIcons from "../functions/getIcons/getIcons";

const TarapacaAboutUsScreen = ({ props, history }) => {
	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	return (
		<IonPage>
			<IonContent>
				<div className="">
					<TarapacaHeader
						title={"Nuestra"}
						boldTitle={"Empresa"}
						handleHomeSubmit={() => history.push("/")}
						handleBackSubmit={() => history.goBack()}
					/>
				</div>

				<div className="new-container">

					<TarapacaAboutUs
						aboutUsBody={operator && operator.about_us ? operator.about_us : ""}
						imageSource={getIcons("aboutUsImage")}
						props={props}
					/>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default connectData()(TarapacaAboutUsScreen);
