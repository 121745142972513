import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { AndimarTitle } from '../../common/Title/AndimarTitle';
import Footer1 from '../../common/footer/Footer1';
import Footer2 from '../../common/footer/Footer2';
import Header from '../../common/header/Header';
import Header1 from '../../common/header/Header1';
import HeroSection from '../../common/HeroSection/HeroSection';
import KuposTabs from '../../../components/KuposTabs';
import MultiSlideType3 from '../../common/MultiSlider3/MultiSlideType3';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import MultiSlider3 from '../../common/MultiSlider/MultiSlider3';
// import address1 from '../../assets/andimar/favoriteDestinations/address1.jpg';
// import address10 from '../../assets/andimar/favoriteDestinations/address10.jpg';
// import address11 from '../../assets/andimar/favoriteDestinations/address11.jpg';
// import address12 from '../../assets/andimar/favoriteDestinations/address12.jpg';
// import address2 from '../../assets/andimar/favoriteDestinations/address2.jpg';
// import address3 from '../../assets/andimar/favoriteDestinations/address3.jpg';
// import address4 from '../../assets/andimar/favoriteDestinations/address4.jpg';
// import address5 from '../../assets/andimar/favoriteDestinations/address5.jpg';
// import address6 from '../../assets/andimar/favoriteDestinations/address6.jpg';
// import address7 from '../../assets/andimar/favoriteDestinations/address7.jpg';
// import address8 from '../../assets/andimar/favoriteDestinations/address8.jpg';
// import address9 from '../../assets/andimar/favoriteDestinations/address9.jpg';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import getSlides from '../../functions/getSlides';

const officeBanner =
	'/assets/busnorte/General/office.webp';

const stepOneItem = [
	{
		image: '/assets/busnorte/destinations/santiago.webp',
		address: ' Nicasio Retamales 017, Estación Central Santiago',
		phone: ' 56-02-7761960',

		// timing: ' Horario: 5:30 Hrs. - 23:30 Hrs.',
		mapLink:
			'https://maps.app.goo.gl/uWMX1tBo7oW1KX7d7',
	},
	{
		image: '/assets/busnorte/destinations/santiago2.webp',
		address: 'Nicasio Retamalas 42, Estación Central Santiago Oficina 72',
		phone: ' 56-02-7795433',

		mapLink:
			'https://maps.app.goo.gl/piP3EQxAfx4qv5uV6',
	},
	{
		image: '/assets/busnorte/destinations/rancagua.webp',
		address: " Av. Bernardo O'higgins 0484 Oficina Número 5",
		phone: ' 56-72-221455',

		mapLink:
			'https://maps.app.goo.gl/vnoYE9W1bDhpw4NJ6',
	},

];

// const stepTwoItem = [
// 	{
// 		image:
// 			'/assets/andimar/favoriteDestinations/AndimarWebsite-Offices-Santiago-SanBorja1.jpg',
// 		address: 'San Borja Nº184, of. 37B, 49B y 65',
// 		phone: '(+56 2) 276 48 902',
// 		timing: 'Horario: 6:30 Hrs. - 23:00 Hrs.',
// 		mapLink:
// 			'https://www.google.com/maps/place/San+Francisco+de+Borja+184,+Santiago,+Estaci%C3%B3n+Central,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4533945,-70.6821419,17z/data=!3m1!4b1!4m5!3m4!1s0x9662c4f9cb22860d:0x61d107f0e42444b5!8m2!3d-33.4533945!4d-70.6799532?shorturl=1',
// 	},
// 	{
// 		image:
// 			'/assets/andimar/favoriteDestinations/AndimarWebsite-Offices-Santiago-TerminalSur2.jpg',
// 		address: ' Avda. Lib. Bernardo O. Nº3850, Ofs. 15, 20, 21, 132, 147 y 148',
// 		phone: ' (+56 2) 2779 38 10',
// 		timing:
// 			'Horario: Lunes a viernes: 4:30 Hrs. – 23:00 Hrs. Sábados y domingos: 5:30 Hrs. - 23:00 Hrs.',
// 		mapLink:
// 			'https://www.google.com/maps/place/Santiago/@-33.4543722,-70.6903149,17z/data=!3m1!4b1!4m5!3m4!1s0x9662c4f5cd039dad:0x7b9be22dd33b4d73!8m2!3d-33.4545765!4d-70.6880542',
// 	},
// ];

const stepThreeItem = [
	{
		image: '/assets/busnorte/destinations/valdivia.webp',
		address: '  Anfión Muñoz 360 oficina Número 3',
		phone: ' 56-63-212800',
		// timing:
		// 	' Horario: 4:30 Hrs. - 20:30 Hrs. Lunes apertura a las 4:00 Hrs. y domingos a las 6:00 Hrs.',
		mapLink:
			'https://maps.app.goo.gl/a2cm4YyY9tAfirTaA',
	},
	{
		image: '/assets/busnorte/destinations/union.png',
		address: ' Augusto Grop 1880 Oficina Número 2',
		phone: ' 56-64-322785',
		// timing:
		// 	' Horario: 4:30 Hrs. - 20:30 Hrs. Lunes apertura a las 4:00 Hrs. y domingos a las 6:00 Hrs.',
		mapLink:
			'https://maps.app.goo.gl/iRFkTruJv5sY7TAT6',
	},
	{
		image: '/assets/busnorte/destinations/osorno.webp',
		address: ' Errazuriz 1400 Oficina 3 ',
		phone: '56-64-233319',
		// timing:
		// 	' Horario: 4:30 Hrs. - 20:30 Hrs. Lunes apertura a las 4:00 Hrs. y domingos a las 6:00 Hrs.',
		mapLink:
			'https://maps.app.goo.gl/VVcxYPSd4agop2LR8',
	},
	{
		image: '/assets/busnorte/destinations/puerto-montt.webp',
		address: ' Av. Diego Portales s/n Oficina 6',
		phone: ' 56-65-252783',
		// timing:
		// 	' Horario: 4:30 Hrs. - 20:30 Hrs. Lunes apertura a las 4:00 Hrs. y domingos a las 6:00 Hrs.',
		mapLink:
			'https://maps.app.goo.gl/KuUpVVB7utMvZNnM7',
	},
];

const NorteOffices = () => {
	const operator = JSON.parse(localStorage.getItem('op'));

	const initalState = {
		selectedTab: 1,
	};

	const [state, setState] = useState(initalState);

	const tabs = ['Zona Centro', 'Zona Sur'];

	const onTabChange = (tab) => {
		setState({ ...state, selectedTab: tab });
	};

	return (
		<IonPage>
			<IonContent>
				{/* <div style={{ position: 'absolute', width: '100vw', zIndex: 1000 }}>
          <Header1 />
        </div> */}
				<Header />
				<HeroSection
					heroImage={officeBanner}
					// height={'60vh'}
					height={'45vh'}
					title={'Oficinas'}
					description={'Home / Oficinas'}
				/>
				{/* <div>
					<img src="/assets/busnorte/Home/underline.svg" alt="" style="width: 150px;" /></div> */}
				<AndimarTitle title={'  OFICINAS  '} />
				<div className='underline'><img src="/assets/busnorte/Home/underline.svg" alt="" style=
					{{ width: 150 }} /></div>
				<KuposTabs
					tabs={tabs}
					onTabChange={onTabChange}
					selectedTab={state.selectedTab}
					operator={operator}
					width={'27vw'}
				/>

				<div style={{ marginTop: 20 }}>
					<MultiSlider3
						multiSlides={
							state.selectedTab == 1
								? stepOneItem
								: state.selectedTab == 2
									// ? stepTwoItem
									? stepThreeItem
									: ''
						}
						textCard={true}
						hideArrow
						norteArrowStyle
						width={'80%'}
					/>
				</div>

				<Footer2 operator={operator} />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(NorteOffices);
