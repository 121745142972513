import "./Theme11Company.css";

import { IonContent, IonPage } from "@ionic/react";

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import HeroSection from "../common/HeroSection/HeroSection";
import KuposHeader from "../../components/KuposHeader";
import { PBText } from "../../components";
import React from "react";
import { connectData } from "../../redux";
// import HeroSection from "../../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";

const Theme11Company = ({ operator, history }) => {

return (
<IonPage>
   <IonContent>
			<KuposHeader
            // data={props.data}
            title={"Nuestra "}
            boldTitle={"- empresa"}
            handleHomeSubmit={() => history.push("/")}
            handleBackSubmit={() => history.goBack()}
          />
   
      <div className="new-container mb10 mt10" style={{ padding: 20 }}>
      <p div	className="lh-1-3 font14 center-text">
         <strong>Consolidados como una parte clave de los viajes de millones de personas</strong><br/>
      </p>
      <div	className="lh-1-3 font14 justify-text">
         <p>	El servicio de transporte de pasajeros en buses, desde y hacia el aeropuerto internacional Arturo Merino Benítez de Santiago, <strong>comenzó en 1990</strong> al adjudicarse la licitación pública a nuestro servicio, anteriormente denominado Metropuerto y ahora, <strong>Centropuerto Ltda.</strong>
         </p><br/>
         <div	className="lh-1-3 font14 center-text yellow-text">
            <p> <img src={getIcons("HandsIcon")} alt="" /><br/><strong>Confiabilidad</strong></p>
         </div>
         <p>            Desde la adjudicación de la licitación pública,<b> Centropuerto Ltda.</b> ha llevado a cabo una labor impecable tanto desde el punto de vista operacional como de nivel de servicio. En  más de 30 años transportando pasajeros y funcionaros desde y hacia el aeropuerto de Aantiago, jamás ha habido interrupción de servicios ni problemas técnicos o administrativos que generaran suspensiones de los servicios acordados con la concesionaria “SCL Terminal Aéreo de Santiago S.A.”, y con la actual concesionaria “Nuevo Pudahuel”.
            Durante más tres décadas sus funcionarios, como los millones de usuarios que han utilizado el servicio de transporte, han estado exentos de todo tipo de accidentes mayores y, las diferentes flotas de buses utilizadas han estado constantemente en excelente estado, tanto en aspectos mecánicos, estéticos y administrativos, cumpliendo a cabalidad con la legislación vigente, en todos sus aspectos, lo que le ha permitido adjudicarse todas las licitaciones existentes a la fecha.
         </p>
         <div	className="lh-1-3 font14 center-text yellow-text">
            <p>    <img src={getIcons("BricksIcon")} alt="" /><br/><b>Consolidación</b></p>
         </div>
         <p>  Esta vasta trayectoria le ha permitido a <strong>Centropuerto Ltda.</strong> ganar la confianza y el cariño de sus millones de pasajeros a lo largo del tiempo, lo que se traduce en un grato respaldo de sus usuarios y colaboradores, entregando en todo momento calidad y eficiencia en el servicio.
         </p><br/>
      </div>
      </div>
      {/* 
      <Footer2 operator={operator} />
      */}
   </IonContent>
</IonPage>
);
}
export default Theme11Company