import React, { PureComponent } from "react";

import { connectData } from "../redux";
// import { Navigation } from 'react-native-navigation';
// import { LOGIN_SCREEN, RECHARGE_WALLET_SCREEN, RECHARGE_MAP_SCREEN, TNC_SCREEN, MY_TICKETS_SCREEN, MY_ACCOUNT_SCREEN, TXN_HISTORY_SCREEN, CONTACT_SCREEN, GLOSARY_SCREEN, RECHARGE_TRANSFER_SCREEN, MY_QR_CODE_SCREEN, VIRTUAL_MONEY_SCREEN } from '../navigation/Screens';
import colors from "../theme/colors";
import PBText from "./PBText";
import PBModal from "./PBModal";
import { CommonService } from "../services";
import PBTouchable from "./PBTouchable";

const menuItems = [
  {
    id: "1",
    name: "Mi Cuenta",
    src: "../assets/icons/menu/my-account.png",
    // "screen":MY_ACCOUNT_SCREEN,
    title: "Mi Cuenta",
  },
  {
    id: "2",
    name: "Compra en efectivo | PB Cash",
    src: "../assets/icons/menu/wallet.png",
    // "screen":RECHARGE_WALLET_SCREEN,
    title: "Recarga tu PB Cash",
  },
  {
    id: "13",
    name: "Mi QR",
    src: "../assets/icons/menu/qr-code.png",
    // "screen":MY_QR_CODE_SCREEN,
    title: "Mi QR",
  },
  {
    id: "5",
    name: " Comparte Saldo",
    src: "../assets/icons/menu/transfer-money.png",
    // "screen":RECHARGE_TRANSFER_SCREEN,
    title: "Compartir Saldo",
  },
  {
    id: "12",
    name: "PB Crédito",
    src: "../assets/icons/menu/promotion.png",
    // "screen":VIRTUAL_MONEY_SCREEN,
    title: "PB Crédito",
  },
  {
    id: "4",
    name: "Mis Movimientos",
    src: "../assets/icons/menu/my-movements.png",
    // "screen":TXN_HISTORY_SCREEN,
    title: "Historial de movimientos",
  },
  {
    id: "3",
    name: "Mis Pasajes",
    src: "../assets/icons/menu/my-tickets.png",
    // "screen":MY_TICKETS_SCREEN,
    title: "Mis Pasajes",
  },
];

const menuItems2 = [
  {
    id: "9",
    name: "Contáctanos",
    src: "../assets/icons/menu/contact-us.png",
    // "screen":CONTACT_SCREEN,
    title: "Contáctanos",
  },
  {
    id: "10",
    name: "Glosario",
    src: "../assets/icons/menu/glossary.png",
    // "screen":GLOSARY_SCREEN,
    title: "Glosario",
  },
  {
    id: "11",
    name: "Términos y condiciones",
    src: "../assets/icons/menu/terms.png",
    // "screen":TNC_SCREEN,
    title: "Términos y condiciones",
  },
];
const menuItems3 = [
  {
    id: "7",
    name: "Iniciar Sesión / Registrarse",
    src: "../assets/icons/menu/my-account.png",
    // "screen":LOGIN_SCREEN,
    title: "Ingresa a tu cuenta",
  },
  // {
  //     "id": "8",
  //     "name": " Cerrar Sesión",
  //     "src": '../assets/icons/menu/log-out.png',
  //     "screen":LOGIN_SCREEN
  // }
];
const menuItems4 = [
  {
    id: "9",
    name: "Mi QR",
    src: "../assets/icons/menu/qr-code.png",
    // "screen":MY_QR_CODE_SCREEN,
    title: "Mi QR",
  },
];

const MenuItem = (props) => {
  return (
    <PBTouchable onPress={props.onMenuItemPresed}>
      <div style={styles.menuItem}>
        <div style={styles.iconContainer}>
          <img width={18} style={styles.icon} src={props.icon} alt="" />
        </div>
        <div style={styles.labelContainer}>
          <PBText style={styles.label}>{props.label}</PBText>
        </div>
        {props.cashbackInfo &&
        Object.keys(props.cashbackInfo).length &&
        props.id == 2 ? (
          <div style={styles.offer}>
            <img
              height={35}
              style={{ position: "absolute" }}
              src={"../assets/icons/menu/cashback.png"}
              alt=""
            />
            <PBText
              style={{ color: "#fff", fontSize: 12, textAlign: "center" }}
            >
              {props.cashbackInfo.type == 1 ? "$" : ""}
              {props.cashbackInfo.value}
              {props.cashbackInfo.type == 2 ? "%" : ""}{" "}
              {props.cashbackInfo.label}
            </PBText>
          </div>
        ) : null}
      </div>
    </PBTouchable>
  );
};

const WelcomeContainer = (props) => {
  return (
    <div style={styles.welcomeContainer}>
      {CommonService.isLoggedIn(props.loginData) ? (
        <div style={styles.welcomeImageContainer}>
          <img
            height={60}
            style={styles.welcomeImage}
            src={"../assets/icons/menu/profile.png"}
            alt=""
          />
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 34,
            width: "100%",
          }}
        >
          <img
            height={100}
            style={styles.welcomeImage}
            src={"../assets/images/pb_logo_circle.png"}
            alt=""
          />
        </div>
      )}
      {CommonService.isLoggedIn(props.loginData) ? (
        <div style={styles.welcomeTextContainer}>
          <PBText style={styles.welcomeText}>{props.welcomeText}</PBText>
        </div>
      ) : null}
    </div>
  );
};

const FooterContainer = (props) => {
  return (
    <div>
      <div>
        <PBTouchable onPress={() => props.onIconClick("fb")}>
          <img
            height={50}
            style={styles.footerImage}
            src={"../assets/icons/menu/fb.png"}
          />
        </PBTouchable>
      </div>
      <div>
        <PBTouchable onPress={() => props.onIconClick("tw")}>
          <img
            height={50}
            style={styles.footerImage}
            src={"../assets/icons/menu/tw.png"}
          />
        </PBTouchable>
      </div>
      <div>
        <PBTouchable onPress={() => props.onIconClick("in")}>
          <img
            height={50}
            style={styles.footerImage}
            src={"../assets/icons/menu/in.png"}
          />
        </PBTouchable>
      </div>
      <div>
        <PBTouchable onPress={() => props.onIconClick("bl")}>
          <img
            height={50}
            style={styles.footerImage}
            src={"../assets/icons/menu/bl.png"}
          />
        </PBTouchable>
      </div>
    </div>
  );
};

class SideMenu extends PureComponent {
  constructor(props) {
    super(props);
    console.log("side menu ----------------jjjj", this.props);
    this.state = {
      showModal: false,
    };
  }

  onMenuItemPresed = (mItem) => {
    // switch(id){
    //     case 1:

    // }
    // Navigation.mergeOptions('leftSideDrawer', {
    //     sideMenu: {
    //         left: {
    //             visible: false
    //         }
    //     }
    // });

    console.log("TESTING MITEM---", mItem);
    console.log("TESTING Login Data---", this.props.data.loginData);

    if (+mItem.id <= 5 || +mItem.id == 12 || +mItem.id == 13) {
      if (!CommonService.isLoggedIn(this.props.data.loginData)) {
        // this.goToScreen(LOGIN_SCREEN,"Ingresa a tu cuenta")
      } else {
        if (mItem.id == "1") {
          CommonService.goToScreen(
            "AppRoot",
            mItem.screen,
            mItem.title,
            {},
            true,
            true
          );
        } else {
          this.goToScreen(mItem.screen, mItem.title);
        }
      }
    } else if (mItem.id == "6") {
      this.rate();
    } else if (mItem.id != "8") {
      this.goToScreen(mItem.screen, mItem.title);
    } else if (mItem.id == "8") {
      this.setState({
        showModal: true,
      });
    }
  };

  goToScreen = (screenName, title) => {
    CommonService.goToScreen("AppRoot", screenName, title);
  };

  logout = () => {
    CommonService.storeLoginDetails(null, this.props.loginSuccess);
    this.setState({
      showModal: false,
    });
    setTimeout(() => {
      CommonService.toast.show("sesion cerrado exitosamante!");
    }, 300);
  };

  // openSocialPage(page) {
  //     switch (page) {
  //       case "fb":
  //         Linking.openURL(this.props.data.metaData.social_media_urls.facebookUrl);
  //         break;
  //       case "tw":
  //         Linking.openURL(this.props.data.metaData.social_media_urls.twitterUrl);
  //         break;
  //       case "bl":
  //         Linking.openURL(this.props.data.metaData.social_media_urls.blogUrl);
  //         break;
  //       case "in":
  //         Linking.openURL("https://www.instagram.com/pasajebus/");
  //         break;
  //       default:
  //         Linking.openURL(this.props.data.metaData.social_media_urls.iosUrl);
  //         break;
  //     }
  //   }

  rate = () => {
    // if(Platform.OS == 'ios'){
    //     Linking.openURL(this.props.data.metaData.social_media_urls.iosUrl)
    // }else if(Platform.OS == 'android'){
    // Linking.openURL(this.props.data.metaData.social_media_urls.androidUrl)
    // }
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <div contentContainerStyle={styles.container}>
          <WelcomeContainer
            welcomeText={
              CommonService.isLoggedIn(this.props.data.loginData)
                ? this.props.data.loginData.name
                : "Welcome"
            }
            loginData={this.props.data.loginData}
          />
          <div style={styles.menuContainer}>
            {menuItems.map((item, key) =>
              item.id != 13 ||
              !this.props.data.loginData ||
              this.props.data.loginData.identity_type == 7 ? (
                <MenuItem
                  key={key}
                  id={item.id}
                  icon={item.src}
                  label={item.name}
                  onMenuItemPresed={() => this.onMenuItemPresed(item)}
                  cashbackInfo={
                    this.props.data.metaData &&
                    this.props.data.metaData.cashback_info
                  }
                />
              ) : null
            )}
            {/* {CommonService.isLoggedIn(this.props.data.loginData) && <MenuItem id={menuItems3[1].id} icon={menuItems3[1].src} label={menuItems3[1].name} onMenuItemPresed={() => this.onMenuItemPresed(menuItems3[1])} />} */}
          </div>
          <div style={styles.menuHeader}>
            <PBText style={styles.menuHeaderText}>Centro de ayuda</PBText>
          </div>
          <div style={styles.menuContainer}>
            {menuItems2.map((item, key) => (
              <MenuItem
                key={key}
                id={item.id}
                icon={item.src}
                label={item.name}
                onMenuItemPresed={() => this.onMenuItemPresed(item)}
              />
            ))}
          </div>
        </div>
        <FooterContainer
        // onIconClick={(page) => this.openSocialPage(page)}
        />
        <PBModal
          showModal={this.state.showModal}
          titleText="¿Está seguro que desea cerrar su sesión?"
          showButton1={true}
          button1Text="CANCELAR"
          button1Press={() => {
            this.setState({ showModal: false });
          }}
          showButton2={true}
          button2Text="SÍ"
          button2Press={this.logout}
        />
        {/* <Toast ref="toast"/> */}
      </div>
    );
  }
}

const styles = {
  mainContainer: {
    flex: 1,
    backgroundColor: "$greyBG",
    justifyContent: "space-between",
  },
  // container: {
  //     paddingVertical: 10
  // },
  welcomeContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  welcomeImageContainer: {
    padding: 20,
    paddingVertical: 25,
  },
  welcomeImage: {
    width: 80,
    height: 80,
  },
  welcomeTextContainer: {
    flex: 1,
  },
  welcomeText: {
    color: "$primaryBG",
    fontSize: 17,
  },

  menuContainer: {
    paddingLeft: 15,
    paddingRight: 10,
    backgroundColor: "$menuItemBG",
  },
  menuHeader: {
    paddingTop: 25,
    paddingLeft: 10,
  },
  menuHeaderText: {
    fontSize: 14,
  },
  menuItem: {
    width: "100%",
    flexDirection: "row",
    height: 40,
    alignItems: "center",
    paddingLeft: 10,
    backgroundColor: "$menuItemBG",
    borderBottomWidth: 1,
    borderBottomColor: "#aaa",
  },
  iconContainer: {
    width: 20,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -5,
  },
  icon: {
    width: 20,
    height: 20,
  },
  labelContainer: {
    marginLeft: 10,
  },
  label: {
    fontSize: 14,
  },
  offer: {
    position: "absolute",
    right: 25,
    top: 5,
    alignItems: "center",
    paddingTop: 5,
  },
  footerContainer: {
    height: 70,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  footerIos: {
    width: 280,
  },
  footerImage: {
    width: 50,
    height: 50,
    marginLeft: 2,
    marginRight: 2,
  },
};

export default connectData()(SideMenu);
