// import React from "react";
// import { connectData } from "../../../redux";
// import "./ThemeTwoOffices.css";

// const ThemeTwoOffices = ({ offices }) => {
// 	JSON.parse(localStorage.getItem("op")) || this.props.data.operator;

//   return (
//     <div className="offices-main-div">
//       {offices.map((item, key) => {
//         return (
//           <div key={key} className="offices-inner-div">
//             <p className="bold-text offices-inner-para">{item.title}</p>
//             {item.subOffices.map((subOffice, subKey) => (
//               <div key={subKey} className="offices-sub-inner-div">
//                 <div className="offices-dest-div">
//                   <img src="assets/tarapaca/tarapaca-destination-icon.svg" />
//                   <span style={{ marginLeft: "10px", marginTop:"10px"}}>
//                     {subOffice.terminal}
//                   </span>
//                 </div>

//                 {subOffice.phone && (
//                   <div className="offices-phone-div">
//                     <img src="assets/tarapaca/tarapaca-headphone-icon.svg" />
//                     <span>{subOffice.phone}</span>
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default connectData()(ThemeTwoOffices);


// import React from "react";
// import { connectData } from "../../../redux";
// import "./ThemeTwoOffices.css";

// const ThemeTwoOffices = ({ offices, data }) => {
//   // Retrieve the operator from localStorage or props
//   const operator = JSON.parse(localStorage.getItem("op")) || data.operator;

//   // Function to get the image source based on operator name
//   const getImageSrc = (operatorName, type) => {
//     const basePath = "assets/tarapaca/";
//     const images = {
//       destination: {
//         tarapaca: `${basePath}tarapaca-destination-icon.svg`,
//         biaggini: `assets/biaggini/icons/location_icon.svg`,
//         default: `${basePath}default-destination-icon.svg`,
//       },
//       headphone: {
//         tarapaca: `${basePath}tarapaca-headphone-icon.svg`,
//         biaggini: `${basePath}biaggini-headphone-icon.svg`,
//         default: `${basePath}default-headphone-icon.svg`,
//       },
//     };

//     return images[type][operatorName] || images[type].default;
//   };

//   return (
//     <div className="offices-main-div">
//       {offices.map((item, key) => (
//         <div key={key} className="offices-inner-div">
//           <p className="bold-text offices-inner-para">{item.title}</p>
//           {item.subOffices.map((subOffice, subKey) => (
//             <div key={subKey} className="offices-sub-inner-div">
//               <div className="offices-dest-div">
//                 <img
//                   src={getImageSrc(operator.operator_name, "destination")}
//                   alt="destination icon"
//                 />
//                 <span style={{ marginLeft: "10px", marginTop: "10px" }}>
//                   {subOffice.terminal}
//                 </span>
//               </div>

//               {subOffice.phone && (
//                 <div className="offices-phone-div">
//                   <img
//                     src={getImageSrc(operator.operator_name, "headphone")}
//                     alt="phone icon"
//                   />
//                   <span>{subOffice.phone}</span>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default connectData()(ThemeTwoOffices);







import React from "react";
import { connectData } from "../../../redux";
import "./ThemeTwoOffices.css";

const ThemeTwoOffices = ({ offices, data }) => {
  // Retrieve the operator from localStorage or props
  const operator = JSON.parse(localStorage.getItem("op")) || data.operator;

  // Function to get the image source based on operator name
  const getImageSrc = (operatorName, type) => {
    const basePath = "assets/tarapaca/";
    const images = {
      destination: {
        tarapaca: `${basePath}tarapaca-destination-icon.svg`,
        biaggini: `assets/biaggini/icons/location_icon.svg`,
        default: `${basePath}default-destination-icon.svg`,
      },
      headphone: {
        tarapaca: `${basePath}tarapaca-headphone-icon.svg`,
        biaggini: `assets/biaggini/icons/headphone_icon.svg`,
        default: `${basePath}default-headphone-icon.svg`,
      },
    };

    return images[type][operatorName] || images[type].default;
  };

  return (
    <div className="offices-main-div">
      {offices.map((item, key) => (
        <div key={key} className="offices-inner-div">
          <p
            className="bold-text offices-inner-para"
            style={{ color: operator.operator_name === "biaggini" ? "#0092c5" : "#e86b2a" }}
          >
            {item.title}
          </p>
          {item.subOffices.map((subOffice, subKey) => (
            <div key={subKey} className="offices-sub-inner-div">
              <div className="offices-dest-div">
                <img
                  src={getImageSrc(operator.operator_name, "destination")}
                  alt="destination icon"
                />
                <span style={{ marginLeft: "10px", marginTop: "10px" }}>
                  {subOffice.terminal}
                </span>
              </div>

              {subOffice.phone && (
                <div className="offices-phone-div">
                  <img
                    src={getImageSrc(operator.operator_name, "headphone")}
                    alt="phone icon"
                  />
                  <span>{subOffice.phone}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default connectData()(ThemeTwoOffices);
