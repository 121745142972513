// import { PASSENGER_DETAILS_SCREEN, SERVICE_LIST_RETURN_SCREEN } from '../../navigation/Screens';
import { CommonService, DateSerivce, storageService } from "../../services";
import { IonContent, IonPage, IonToast, withIonLifeCycle } from "@ionic/react";
import {
  Loader,
  PBModal,
  PBText,
  SeatLayout,
  SelectedSeats,
  StagesSelection,
  TrainSeatLayout,
} from "../../components";
import React, { PureComponent } from "react";

import DateService from "../../services/dateService";
import KuposHeader from "../../components/KuposHeader";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connectData } from "../../redux";

// import {
//   View,
//   Text
// } from 'react-native';
// import EStyleSheet from 'react-native-extended-stylesheet';
// import Toast, {DURATION} from 'react-native-easy-toast'
// import { Navigation } from 'react-native-navigation';

class ServiceDetailsScreenV2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      floor: 0,
      getServicesDetailsPending: true,
    };
    setTimeout(() => {
      this.setState({
        getServicesDetailsPending: false,
      });
    }, 2500);
    // CommonService.bindNav(this);
  }

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange,
    );
  }

  componentDidMount() {
    if (
      this.props.data.selectedOrigin &&
      this.props.data.selectedDestination &&
      this.props.data.selectedServiceOnward
    ) {
      this.serviceDetailsCalled = true;
    }
  }

  ionViewWillEnter() {
    localStorage.removeItem("paymentGatewayOpened");

    this.props.selectSeatOnward({
      totalBookingAmount: 0,
      netBookingAmount: 0,
      discountAmount: 0,
      selectedSeatsToBook: null,
      selectedSeatsNumbers: null,
      selectedSeatTypes: null,
    });
    const { dateOnward } = this.props.match.params;
    // this.getServiceDetails(this.scheduleId);
  }

  componentDidUpdate() {
    const { origin, dest, dateOnward, dateReturn, scheduleId } =
      this.props.match.params;
    this.scheduleId = scheduleId;
    this.setOriginDestDateFromUrl(origin, dest, dateOnward, dateReturn);
  }

  setOriginDestDateFromUrl = (origin, dest, dateOnward, dateReturn) => {
    if (!this.servicesCalled && this.props.data.citiesUnique) {
      let checkOriginCity = origin
        .replace("--", "+")
        .replace("-", " ")
        .replace("+", "-")
        .toLowerCase();
      let checkDestCity = dest
        .replace("--", "+")
        .replace("-", " ")
        .replace("+", "-")
        .toLowerCase();
      let filterCities = this.props.data.citiesUnique.filter(it => {
        return (
          it[0].toLowerCase() == checkOriginCity ||
          it[0].toLowerCase() == checkDestCity
        );
      });
      if (
        filterCities &&
        !this.props.data.selectedOrigin &&
        !this.props.data.selectedDestination
      ) {
        let originCity = filterCities.find(it => {
          return it[0].toLowerCase() == checkOriginCity;
        });
        let selectedCityOrigin = {
          id: originCity[1],
          name: originCity[0].split(",")[0],
          country: originCity[0].split(",")[1],
          full_name: originCity[0],
        };
        this.props.selectOrigin(selectedCityOrigin);

        let destCity = filterCities.find(it => {
          return it[0].toLowerCase() == checkDestCity;
        });
        let selectedCityDest = {
          id: destCity[1],
          name: destCity[0].split(",")[0],
          country: destCity[0].split(",")[1],
          full_name: destCity[0],
        };
        this.props.selectDestination(selectedCityDest);
        this.props.onwardDateChange(dateOnward);
        this.props.returnDateChange(dateReturn);
        this.serviceDetailsCalled = true;
        // this.getServices(dateOnward, selectedCityOrigin, selectedCityDest);
      }
    }
  };

  getServices = (newDate, origin = null, dest = null) => {
    console.log("New Date is", newDate);

    this.routesFull = null;
    let path =
      "origin_id=" + (origin ? origin.id : this.props.data.selectedOrigin.id);
    path +=
      "&destination_id=" +
      (dest ? dest.id : this.props.data.selectedDestination.id);
    path +=
      "&travel_date=" +
      DateSerivce.changeDateFormat(newDate, "dd-mm-yyyy", "yyyy-mm-dd");
    path += "&show_only_available_services=false&show_injourney_services=true";
    this.props.getServices({ path: path, callback: this.setServices });
  };

  setServices = services => {
    this.props.getServicesSuccess(services);
    this.getServiceDetails(this.scheduleId);
  };

  getServiceDetails(scheduleId) {
    // store
    this.routesFull = this.props.data.serviceList
      ? this.props.data.serviceList.routes
      : [];
    let route = this.routesFull.find(it => it.id == scheduleId);
    let data = {
      returnTrip: this.props.data.selectedReturnDate ? true : false,
      returnTripSearch: false,
      scheduleId: scheduleId,
    };
    this.props.getServiceDetailsV3({
      callback: (success, message, data) => {
        this.setState({ getServicesDetailsPending: false });
        if (success) this.onServiceDetailsResponse(success, scheduleId, data);
        else {
          this.setState({
            showToast1: true,
            toastMessage:
              message || "Agotado - Este servicio ya no está disponible",
          });

          setTimeout(() => {
            this.setState({
              showToast1: false,
            });
          }, 3000);
          this.props.history.goBack();
        }
        // this.getServices(this.props.data && this.props.data.selectedOnwardDate, null, null, null, true);
      },
      data,
    });
  }

  onServiceDetailsResponse = (success, scheduleId, route) => {
    if (success) {
      console.log(route, this.props.data.serviceDetails);
      setTimeout(() => {
        if (
          this.props.data.serviceDetails &&
          this.props.data.serviceDetails.total_available_seats > 0
        ) {
          this.props.serviceSelectedOnward(
            CommonService.setSeatLayout(this.props.data.serviceDetails, route),
          );
        }
      }, 1000);
    }
  };

  floorChange = floor => {
    this.setState({
      floor: floor,
    });
  };

  wagonChange = wagon => {
    this.setState({
      floor: wagon,
    });
  };

  onSeatTap = bookingDetails => {
    console.log("Seat tapped-----", bookingDetails);

    this.setState({ motMaxSeatError: false });
    //if user selectes 2nd seat in case of OT/MOT
    if (bookingDetails === "MAX_SEATS_ERROR_MOT") {
      this.setState({ motMaxSeatError: true });
      return;
    }

    //if user clicks on booked seat
    if (bookingDetails === undefined) {
      this.setState({
        showToast1: true,
        toastMessage: "Selecciona un asiento disponible (color naranja)",
      });

      setTimeout(() => {
        this.setState({
          showToast1: false,
        });
      }, 3000);
      return true;
    }
    if (bookingDetails != "MAX_SEATS_ERROR") {
      this.props.selectSeatOnward(bookingDetails);
    } else {
      this.setState({
        showToast1: true,
        toastMessage: `${
          (this.props.data &&
            this.props.data.serviceDetails.res_details &&
            this.props.data.serviceDetails.res_details
              .no_of_seats_to_be_booked) ||
          (this.props.data.metaData.max_seats_allowed_by_op[
            this.props.data.operator.operator_id
          ] &&
            this.props.data.metaData.max_seats_allowed_by_op[
              this.props.data.operator.operator_id
            ]) ||
          this.props.data.metaData.max_seats_allowed
        } asientos permitidos por compra`,
      });

      setTimeout(() => {
        this.setState({
          showToast1: false,
        });
      }, 3000);
    }
    // else
    // this.refs.toast.show('Máximo ' + this.props.data.bookingDetailsOnward.selectedSeatsToBook.length + ' asientos permitidos por compra');
  };

  stageChanged = (type, stage) => {
    console.log("DStage change called ------------", type, stage);
    let serviceDetails = this.props.data.selectedServiceOnward;
    if (type == 1) {
      serviceDetails.boardingStage = stage;
    } else if (type == 2) {
      serviceDetails.droppingStage = stage;
    }
    this.props.serviceSelectedOnward(serviceDetails);
  };

  showToast = msg => {
    this.setState({
      showToast1: true,
      toastMessage: msg,
    });

    setTimeout(() => {
      this.setState({
        showToast1: false,
      });
    }, 3000);
  };

  checkReturnServiceAvailability = () => {
    let all_service = CommonService.fullServiceListV2;

    if (
      all_service &&
      (all_service.return_schedules == null ||
        all_service.return_schedules.length < 1) &&
      this.props.data.selectedReturnDate
    ) {
      this.props.returnDateChange(null);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo sentimos!",
        modalIcon: "/assets/carpool/warning.png",
        modalBody:
          "No hay servicios disponibles para tu viaje de regreso, ¿deseas comprar tu pasaje de ida?",
        showButton1: true,
        buttonText1: "SI, CONTINUAR",
        onButtonClick1: () => {
          this.continue();
          this.setState({ showModal: false });
        },
        showButton2: true,
        buttonText2: "NO, VOLVER AL INICIO",
        onButtonClick2: () => {
          this.setState({ showModal: false });
          this.props.history.push("/");
        },
      });
      return;
    } else {
      this.continue();
    }
  };

  continue = () => {
    if (!this.props.data.selectedReturnDate) {
      console.log("No return date ");
    }

    // return;
    if (
      !this.props.data.bookingDetailsOnward ||
      !this.props.data.bookingDetailsOnward.selectedSeatsToBook ||
      !this.props.data.bookingDetailsOnward.selectedSeatsToBook.length
    ) {
      // this.refs.toast.show('Selecciona tu asiento');
      console.log("Hello no seat selected");
      this.showToast("Por favor selecciona un asiento");
      return;
    }
    if (
      !this.props.data.selectedServiceOnward ||
      !this.props.data.selectedServiceOnward.boardingStage
    ) {
      // this.refs.toast.show('Seleccione punto de embarque');
      this.showToast("Seleccione punto de embarque");
      return;
    }
    if (
      !this.props.data.selectedServiceOnward ||
      !this.props.data.selectedServiceOnward.droppingStage
    ) {
      // this.refs.toast.show('Seleccione punto de llegada');
      this.showToast("Seleccione punto de llegada");
      return;
    }
    this.storeLocalData();
    if (!this.props.data.selectedReturnDate) {
      this.props.history.push(
        `/${
          CommonService.isTrain() ? "es/pasajes-tren" : "es/pasajes-bus"
        }/passenger-details`,
      );

      // CommonService.goToScreen(this.props.componentId,PASSENGER_DETAILS_SCREEN,'Detalles del pasajero')
    } else {
      let source = this.props.data.selectedOrigin.full_name
        .toLowerCase()
        .replace(/-/g, "_")
        .replace(/ /g, "-")
        .replace(/_/g, "--");
      let dest = this.props.data.selectedDestination.full_name
        .toLowerCase()
        .replace(/-/g, "_")
        .replace(/ /g, "-")
        .replace(/_/g, "--");
      let link = `/${
        CommonService.isTrain() ? "es/pasajes-tren" : "es/pasajes-bus"
      }/${source}/${dest}/${this.props.data.selectedOnwardDate}/${
        this.props.data.selectedReturnDate
      }/2`;
      this.props.history.push(link);
      // CommonService.goToScreen(this.props.componentId,SERVICE_LIST_RETURN_SCREEN,'Detalles del pasajero')
    }
  };

  storeLocalData = () => {
    storageService.removeItem("passengerData");
    this.props.setContactDetails({
      mobile: null,
      email: null,
      confirmEmail: null,
    });
    let data = {
      selectedOrigin: this.props.data.selectedOrigin,
      selectedDestination: this.props.data.selectedDestination,
      selectedOnwardDate: this.props.data.selectedOnwardDate,
      selectedReturnDate: this.props.data.selectedReturnDate,
      serviceDetails: this.props.data.serviceDetails,
      selectedServiceOnward: this.props.data.selectedServiceOnward,
      selectedServiceReturn: this.props.data.selectedServiceReturn,
      bookingDetailsOnward: this.props.data.bookingDetailsOnward,
      bookingDetailsReturn: this.props.data.bookingDetailsReturn,
      passengerDetailsOnward: [],
      passengerDetailsReturn: [],
      contactDetails: {
        mobile: null,
        email: null,
        confirmEmail: null,
      },
      bookingJSONDetails: this.props.data.bookingJSONDetails,
    };
    this.props.setPassengerDetailsOnward([]);
    storageService.setItem("passengerData", JSON.stringify(data));
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div
            className="main-seat-layout-container"
            // style={styles.flex}
          >
            <KuposHeader
              title="Selecciona"
              boldTitle="tu asiento"
              handleBackSubmit={() => this.props.history.goBack()}
              href="/"
            />

            <StagesSelection
              boardingStages={
                this.props.data.selectedServiceOnward
                  ? this.props.data.selectedServiceOnward.originStageDetails
                  : null
              }
              droppingStages={
                this.props.data.selectedServiceOnward
                  ? this.props.data.selectedServiceOnward
                      .destinationStageDetails
                  : null
              }
              selectedBoardingStage={
                this.props.data.selectedServiceOnward
                  ? this.props.data.selectedServiceOnward.boardingStage
                  : null
              }
              selectedDroppingStage={
                this.props.data.selectedServiceOnward
                  ? this.props.data.selectedServiceOnward.droppingStage
                  : null
              }
              onStageChange={(type, stage) => this.stageChanged(type, stage)}
            />

            <div className="seat-layout-h-line"> </div>
            {this.props.data.selectedServiceOnward &&
            this.props.data.selectedServiceOnward.coachLayoutJson &&
            CommonService.isTrainOrBus() ? (
              CommonService.isTrain() ||
              this.props.data.serviceDetails.travel_id == 272 ? (
                <TrainSeatLayout
                  metaData={this.props.data.metaData}
                  coachLayout={
                    this.props.data.selectedServiceOnward.coachLayoutJson
                  }
                  upperAvalibility={
                    this.props.data.selectedServiceOnward.upperAvalibility
                  }
                  selectedSeatsToBook={
                    this.props.data.bookingDetailsOnward
                      ? this.props.data.bookingDetailsOnward.selectedSeatsToBook
                      : null
                  }
                  // onFloorChange={(floor) => this.floorChange(floor)}
                  onWagonChange={wagon => this.floorChange(wagon)}
                  selectedWagon={this.state.floor}
                  onSeatTap={seat => this.onSeatTap(seat)}
                  selectedService={this.props.data.selectedServiceOnward}
                  serviceDetails={this.props.data.serviceDetails}
                  // operator={this.props.data.operator}
                />
              ) : (
                <SeatLayout
                  data={this.props.data}
                  metaData={this.props.data.metaData}
                  coachLayout={
                    this.props.data.selectedServiceOnward.coachLayoutJson
                  }
                  upperAvalibility={
                    this.props.data.selectedServiceOnward.upperAvalibility
                  }
                  selectedSeatsToBook={
                    this.props.data.bookingDetailsOnward
                      ? this.props.data.bookingDetailsOnward.selectedSeatsToBook
                      : null
                  }
                  onFloorChange={floor => this.floorChange(floor)}
                  selectedFloor={this.state.floor}
                  onSeatTap={seat => this.onSeatTap(seat)}
                  selectedService={this.props.data.selectedServiceOnward}
                  operator={this.props.data.operator}
                />
              )
            ) : null}
            {this.state.motMaxSeatError && (
              <div className="error-text error-msg-padding">
                El uso de cupones está permitido para un solo asiento, por favor
                seleccione solo un asiento
              </div>
            )}
            <SelectedSeats
              onPress={() => this.checkReturnServiceAvailability()}
              bookingDetails={this.props.data.bookingDetailsOnward}
            />

            {/* <button
              className="pb-button-else button-seat-selected"
              onClick={() => this.continue()}
            >
              <span className="pb-button-text font12">
                CONTINUAR ${this.props.bookingDetails?this.props.bookingDetails.netBookingAmount: null}
              </span>
            </button> */}
            {/* <Toast ref="toast" /> */}

            <IonToast
              isOpen={this.state.showToast1}
              // onDidDismiss={this.setState({ showToast: false })}
              message={this.state.toastMessage}
              duration={2000}
            />
            <Loader showSpinner={this.state.getServicesDetailsPending} />

            <PBModal
              showModal={this.state.showModal}
              bodyText={this.state.modalBody}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.buttonText1}
              button1Press={
                this.state.onButtonClick1
                  ? this.state.onButtonClick1
                  : this.closeModal
              }
              showButton2={this.state.showButton2}
              button2Text={this.state.buttonText2}
              button2Press={
                this.state.onButtonClick2
                  ? this.state.onButtonClick2
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              buttonTextStyle={{ fontSize: 15 }}
              // showCloseIcon={this.state.showCloseIcon}
              // closeIconPress={this.closeModal}
              onHide={this.closeModal}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
};

export default connectData()(withIonLifeCycle(ServiceDetailsScreenV2));
