import React, { PureComponent } from "react";
import { connectData } from "../../redux";
import {
  View,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  PBSelect,
} from "../../components";
import { commonStyles, dimensions } from "../../theme";
import { CommonService, AppData, DateSerivce } from "../../services";
import IonicDropdown from "../../components/IonicDropdown";
import { IonDatetime, IonPage, IonContent } from "@ionic/react";
import ReactDOM from "react-dom";
import ReactCrop from "react-image-crop";
import getIcons from "../../themes/functions/getIcons/getIcons";

const month = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

class ModifyProfileScreen extends PureComponent {
  genders = [
    {
      id: "male",
      name: "Masculino",
    },
    {
      id: "female",
      name: "Femenino",
    },
  ];
  idCardTypes = [];
  constructor(props) {
    super(props);
    for (let c in this.props.data.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.data.metaData.id_card_types[c],
        id: this.props.data.metaData.id_card_types[c],
        label: c,
        name: c,
        selected:
          this.props.data.metaData.id_card_types[c] ==
          this.props.data.loginData.identity_type,
      });
    }
    this.state = {
      name: this.props.data.loginData.name,
      lastName: this.props.data.loginData.last_name,
      mobile: this.props.data.loginData.phone,
      email: this.props.data.loginData.email,
      idCardType: this.props.data.loginData.identity_type,
      idCardNumber: this.props.data.loginData.identity_val,
      code: this.props.data.loginData.invite_code,
      dob: this.props.data.loginData.dob,
      nameError: null,
      mobileError: null,
      cardNumberError: null,
      codeError: null,
      gender: this.genders.find(
        (it) => it.id == this.props.data.loginData.gender
      ),
      crop: {
        unit: "%",
        width: 100,
        aspect: 1 / 1,
      },
    };

    //   CommonService.bindNav(this);
    const operator = JSON.parse(localStorage.getItem('op')) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator)
    }
    this.props.getCustomerProfile({
      callback: (result) => this.onProfileLoad(result),
      operator_id: query
    });
  }

  onProfileLoad = (result) => {
    if (result.statusCode == 200) {
      CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
      this.setState({
        name: result.data.name,
        lastName: result.data.last_name,
        mobile: result.data.phone,
        email: result.data.email,
        idCardType: result.data.identity_type,
        idCardNumber: result.data.identity_val,
        code: result.data.invite_code,
        dob: result.data.dob,
        nameError: null,
        lastNameError: null,
        mobileError: null,
        cardNumberError: null,
        codeError: null,
        password: null,
        gender: this.genders.find((it) => it.id == result.data.gender),
      });
    } else if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  goToLogin = () => {
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      // CommonService.setRoot(MAIN_HOME_SCREEN);
      this.props.history.push("/");
      setTimeout(() => {
        this.props.history.push("/login");
        // CommonService.goToScreen(global.homeComponentId,NEW_LOGIN_SCREEN,'Ingresa a tu cuenta');
      }, 150);
    }
  };

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }

  inpChange = (text, inp) => {
    if (inp == "name") {
      this.setState({
        nameError: null,
        name: text,
      });
    }
    if (inp == "lastName") {
      this.setState({
        lastNameError: null,
        lastName: text,
      });
    } else if (inp == "mobile") {
      text = CommonService.phoneNumberFormat(text);
      this.setState({
        mobileError: null,
        mobile: text,
      });
    } else if (inp == "email") {
      text = CommonService.phoneNumberFormat(text);
      this.setState({
        emailError: null,
        email: text,
      });
    } else if (inp == "card_number") {
      this.setState({
        cardNumberError: null,
        cardNumber: text,
      });
    } else if (inp == "code") {
      this.setState({
        codeError: null,
        code: text,
      });
    } else if (inp == "dob") {
      if (text) {
        console.log("dob seleted", text);
        this.setState({
          dob: text.substr(0, 10),
          dobError: null,
        });
      }
      this.setState({ showCalendar: false });
    } else if (inp == "password") {
      // this.setState({
      //   passwordError: null,
      //   password: text
      // });
    } else if (inp == "gender") {
      console.log("Gender seleted", text);
      this.setState({
        genderError: null,
        gender: text,
      });
    }
  };

  idCardChange = (val) => {
    this.setState({
      cardNumberError: null,
      idCardNumber: null,
      idCardType: val.value,
    });
  };
  idCardNumberChange = (text) => {
    this.setState({
      cardNumberError: null,
    });
    if (text) {
      let cardNumber = CommonService.isRut(
        this.state.idCardType,
        this.idCardTypes
      )
        ? CommonService.formatRut(text)
        : text;
      this.setState({
        idCardNumber: cardNumber,
      });
    } else {
      this.setState({
        idCardNumber: text,
      });
    }
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "name") {
        this.setState({
          nameError: {
            message: "Ingresa tu nombre",
          },
        });
      }
      if (inp == "lastName") {
        this.setState({
          lastNameError: {
            message: "Ingresa tu apellido",
          },
        });
      } else if (inp == "mobile") {
        this.setState({
          mobileError: {
            message: "Ingresa tu número de teléfono",
          },
        });
      } else if (inp == "card_number") {
        this.setState({
          cardNumberError: {
            message: "Ingresa tu número de documento",
          },
        });
      }
    } else if (inp == "mobile") {
      if (this.state.mobile.toString().length != 9) {
        this.setState({
          mobileError: {
            message: "El teléfono debe contener mínimo 9 dígitos",
          },
        });
      }
    } else if (inp == "card_number") {
      if (
        !CommonService.isRutValid(this.state.idCardNumber) &&
        this.state.idCardType == 7
      ) {
        this.setState({
          cardNumberError: {
            message: "Ingresa un RUT válido",
          },
        });
      }
    }
  };

  isValid = () => {
    let errorCount = 0;
    if (!this.state.name) {
      this.setState({
        nameError: {
          message: "Ingresa tu nombre",
        },
      });
      errorCount++;
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameError: {
          message: "Ingresa tu apellido",
        },
      });
      errorCount++;
    }
    // if (!this.state.mobile) {
    //   this.setState({
    //     mobileError: {
    //       message: "Ingresa tu número de teléfono",
    //     },
    //   });
    //   errorCount++;
    // } else if (this.state.mobile.toString().length != 9) {
    //   this.setState({
    //     mobileError: {
    //       message: "El teléfono debe contener mínimo 9 dígitos",
    //     },
    //   });
    //   errorCount++;
    // }
    // if (!this.state.idCardNumber) {
    //   this.setState({
    //     cardNumberError: {
    //       message: "Ingresa tu número de documento",
    //     },
    //   });
    //   errorCount++;
    // } else if (
    //   !CommonService.isRutValid(this.state.idCardNumber) &&
    //   this.state.idCardType == 7
    // ) {
    //   this.setState({
    //     cardNumberError: {
    //       message: "Ingresa un RUT válido",
    //     },
    //   });
    //   errorCount++;
    // }
    // if (!this.state.gender) {
    //   this.setState({
    //     genderError: {
    //       message: "Selecciona tu género",
    //     },
    //   });
    //   errorCount++;
    // }
    // if (!this.state.dob) {
    //   this.setState({
    //     dobError: {
    //       message: "seleccione fecha de nacimiento",
    //     },
    //   });
    //   errorCount++;
    // }
    // if (!this.state.password) {
    //   this.setState({
    //     passwordError: {
    //       message: "password",
    //     },
    //   });
    //   errorCount++;
    // }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  update = () => {
    if (!this.isValid()) {
      return;
    }

    let data = {
      name: this.state.name,
      last_name: this.state.lastName,
      // phone: this.state.mobile,
      // identity_type: this.state.idCardType,
      // identity_val: this.state.idCardNumber,
      gender: this.state.gender ? this.state.gender.id : "",
      dob: this.state.dob,
    };


    if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
      data['operator_id'] = CommonService.getOperatorId(this.props.data.operator)
    }

    if (!this.state.photo)
      this.props.updateCustomerProfile({
        callback: (result) => this.onResponse(result),
        data: data,
      });
    else this.updateWithUpload(data);
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      //   this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo sentimos!",
        modalBody:
          "Tus datos no pudieron ser actualizados. Por favor intenta nuevamente.",
        button1Text: "OK, VOLVER",
        // modalIcon: "../../assets/carpool/booking-failure-circle.png",
        modalIcon: getIcons('failure'),
      });
      return;
    } else if (result.data && result.data.auth_token) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        modalBody: "Tus datos fueron actualizados correctamente.",
        button1Text: "VOLVER",
        // modalIcon: "../../assets/carpool/booking-success-circle.png",
        modalIcon: getIcons('success'),

      });
      this.props.loginSuccess(result.data);
    } else {
      this.setState({
        showModal: true,
        modalTitle: "¡Lo sentimos!",
        modalBody: "¡Sus datos no se actualizaron!",
        button1Text: "OK, VOLVER",
        // modalIcon: "../../assets/carpool/booking-failure-circle.png",
        modalIcon: getIcons('failure'),

      });
    }
  };

  updateWithUpload = (data) => {
    const document = {
      uri: this.state.avatarSource,
      type: "image/jpeg",
      name: this.state.photo.name,
    };
    const form = new window.FormData();
    form.append("document", this.state.blob, this.state.blob.name);
    for (let d in data) {
      form.append(d, data[d]);
    }

    let options = {
      body: form,
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        Authorization: global.token,
        XACCESS: global.xAccess,
        "x-jwt-token": CommonService.jwt_key
      },
    };

    // delete options.he

    this.props.showLoader();
    fetch(AppData.BASE_URL + "api/customer/update_profile", options)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        this.props.hideLoader();
        console.log("ERROR ", error);
      })
      .then((responseData) => {
        this.props.hideLoader();
        console.log("Success", responseData);
        if (responseData) {
          if (responseData.error)
            this.setState({
              showModal: true,
              modalTitle: "¡Lo sentimos!",
              modalBody:
                "Tus datos no pudieron ser actualizados. Por favor intenta nuevamente.",
              button1Text: "OK, VOLVER",
              // modalIcon: "/assets/carpool/booking-failure-circle.png",
              modalIcon: getIcons('failure'),

              
            });
          else if (responseData.auth_token) {
            this.setState({
              showModal: true,
              modalTitle: "¡Listo!",
              modalBody: "Tus datos fueron actualizados correctamente.",
              button1Text: "VOLVER",
              modalIcon: "/assets/icons/success.png",
            });
            this.props.loginSuccess(responseData);
          }
        } else {
          this.setState({
            showModal: true,
            modalTitle: "¡Lo sentimos!",
            modalBody: "¡Sus datos no se actualizaron!",
            button1Text: "OK, VOLVER",
            // modalIcon: "/assets/carpool/booking-failure-circle.png",
            modalIcon: getIcons('failure'),

          });
        }
      });
    // .done();
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      button1Text: "",
      modalBody: "",
    });
  };

  changeImage = (event) => {
    this.setState({
      photo: event.target.files[0],
    });
    if (event.target.files[0]) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);

      reader.onloadend = function (e) {
        this.setState({
          rawImage: [reader.result],
        });
      }.bind(this);
    }
  };

  goToProfile = () => {
    this.setState({ showModal: false });
    this.props.history.push("/my-account");
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const avatarSource = await this.getCroppedImg(
        this.imageRef,
        crop,
        new Date().getTime() + ".jpeg"
      );
      this.setState({ avatarSource });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        this.setState({ blob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <View className="register-screen-container">
            <div className="my-account-header" style={{ marginBottom: 0 }}>
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                />
              </div>

              <div className="font14 my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}>
                  Modifica tu
                </PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
                  fontStyle={"bold-font"}
                >
                  perfil
                </PBText>
              </div>

              <div>&nbsp;</div>
            </div>

            {/* <CarpoolHeader
          backIcon={true}
          componentId={this.props.componentId}
          label="Modifica "
          labelBold={"perfil"}
          labelDark={true}
        /> */}

            <View className="full-flex">
              <View className="modify-profile-image-container">
                <View
                  className="modify-profile-image-container-inner"
                  style={{
                    imageOrientation: "from-image",
                    backgroundImage: `url(${
                      this.state.avatarSource
                        ? this.state.avatarSource
                        : this.props.data.loginData &&
                          this.props.data.loginData.image_link
                        ? this.props.data.loginData.image_link
                        // : "/assets/carpool/user-circle.png"
                        :getIcons('userIcon')
                    })`,
                  }}
                ></View>
                <View className="modify-profile-pencil-container">
                  <img
                    // onClick={this.changeImage}
                    width={25}
                    src="../../assets/carpool/change-dp.png"
                    alt=''
                  />
                  <input type="file" onChange={this.changeImage} />
                </View>
              </View>
              <div
                className="kupos-card modify-profile-body-container"
                style={{ marginTop: 15 }}
              >
                <View className="margin-bottom-10">
                  <PBInput
                    placeholder="Nombre"
                    value={this.state.name}
                    onChange={(text) => this.inpChange(text, "name")}
                    onBlur={(text) => this.onBlur(text, "name")}
                    error={this.state.nameError ? true : false}
                    errorMessage={
                      this.state.nameError ? this.state.nameError.message : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View>

                <View className="margin-bottom-10">
                  <PBInput
                    placeholder="Apellido"
                    value={this.state.lastName}
                    onChange={(text) => this.inpChange(text, "lastName")}
                    onBlur={(text) => this.onBlur(text, "lastName")}
                    error={this.state.lastNameError ? true : false}
                    errorMessage={
                      this.state.lastNameError
                        ? this.state.lastNameError.message
                        : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View>

                <View className="margin-bottom-10">
                  <PBInput
                    disable={true}
                    placeholder="RUT / DNI / Pasaporte"
                    value={this.state.idCardNumber}
                    onChange={(text) => this.idCardNumberChange(text)}
                    onBlur={(text) => this.onBlur(text, "card_number")}
                    error={this.state.cardNumberError ? true : false}
                    errorMessage={
                      this.state.cardNumberError
                        ? this.state.cardNumberError.message
                        : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View>
                <View className="margin-bottom-10">
                  <PBInput
                    disable={true}
                    type="email-address"
                    placeholder="Correo electrónico"
                    value={this.state.email}
                    onChange={(text) => this.inpChange(text, "email")}
                    onBlur={(text) => this.onBlur(text, "email")}
                    error={this.state.mobileError ? true : false}
                    errorMessage={
                      this.state.mobileError
                        ? this.state.mobileError.message
                        : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View>
                <View className="margin-bottom-10">
                  <PBInput
                    disable={true}
                    placeholder=""
                    value={this.state.mobile}
                    onChange={(text) => this.inpChange(text, "moble")}
                    onBlur={(text) => this.onBlur(text, "mobile")}
                    error={this.state.mobileError ? true : false}
                    errorMessage={
                      this.state.mobileError
                        ? this.state.mobileError.message
                        : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View>
                <View className="input-wrapper margin-bottom-10">
                  <IonicDropdown
                    placeholder={"Género"}
                    data={this.genders}
                    dropdownClass={"ion-dropdown"}
                    selectClass={"select-dropdown"}
                    value={this.state.gender}
                    onSelectChange={(val) => this.inpChange(val, "gender")}
                  />

                  {this.state.genderError ? (
                    <div style={styles.errorTextContainer}>
                      <span style={styles.errorText}>
                        {this.state.genderError.message}
                      </span>
                    </div>
                  ) : null}
                </View>
                <View className="input-wrapper margin-bottom-10">
                  {/* <PBInput
                    type={"date"}
                    label=""
                    placeholder="DOB"
                    calendarTitle={"Fecha de ida"}
                    selectedDate={this.state.dob}
                    onDateChange={day => this.inpChange(day, "dob")}
                    error={this.state.dobError ? true : false}
                    errorMessage={
                      this.state.dobError ? this.state.dobError.message : ""
                    }
                    // style={commonStyles.carpoolInput}
                    //   iconLeft={require('../../assets/icons/calendar-icon-cancel.png')}
                  ></PBInput> */}

                  <IonDatetime
                    doneText="OK"
                    cancelText="Cancelar"
                    displayFormat="YYYY/MM/DD"
                    placeholder="Fecha de nacimiento"
                    value={this.state.dob}
                    max={DateSerivce.getTodayString("yyyy-mm-dd")}
                    onIonChange={(e) => this.inpChange(e.detail.value, "dob")}
                    // onIonChange={e => console.log(e.detail.value)}
                  ></IonDatetime>

                  {this.state.dobError ? (
                    <div style={styles.errorTextContainer}>
                      <span style={styles.errorText}>
                        {this.state.dobError.message}
                      </span>
                    </div>
                  ) : null}
                </View>
                {/* <View className="margin-bottom-10">
                  <PBInput
                    password={true}
                    placeholder="Contraseña"
                    value={this.state.password}
                    onChange={text => this.inpChange(text, "password")}
                    onBlur={text => this.onBlur(text, "password")}
                    error={this.state.passwordError ? true : false}
                    errorMessage={
                      this.state.passwordError
                        ? this.state.passwordError.message
                        : ""
                    }
                    // style={commonStyles.carpoolInput}
                    // textStyle={styles.fontSize}
                  ></PBInput>
                </View> */}
                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={this.update}
                    // style={{ height: 50, borderRadius: 10 }}
                    noGradient={true}
                    title={"GUARDAR"}
                  />
                </View>
              </div>
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={() => {
                !this.state.showRegisterLink
                  ? this.goToProfile()
                  : this.setState({ showModal: false });
              }}
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </View>} */}
            </PBModal>
            {/* <Toast ref="toast"/> */}
          </View>
          {this.state.rawImage && (
            <div className="pic-cropper-div">
              <ReactCrop
                src={this.state.rawImage}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <div className="pic-cropper-buttons">
                <div
                  onClick={() =>
                    this.setState({
                      rawImage: null,
                      photo: null,
                      avatarSource: null,
                      blob: null,
                    })
                  }
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    this.setState({ rawImage: null });
                  }}
                >
                  OK
                </div>
              </div>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 14,
  },
  buttonContainer: {
    marginTop: 30,
  },
  socialContainer: {
    marginTop: 25,
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 30,
    width: dimensions.vw * 100 - 60,
  },
  socialItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    borderRadius: 10,
  },
  inputContainer: {
    marginBottom: 10,
  },
  errorTextContainer: {
    paddingHorizontal: 2,
  },
  errorText: {
    color: "#f00",
    fontSize: 11,
    fontFamily: "Carnas Regular",
  },
};

export default connectData()(ModifyProfileScreen);
