import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";

import { AndimarTitle } from "../../common/Title/AndimarTitle";
import ContactForm4 from "../../common/contact/ContactForm4";
import ContactFormService from "../../common/contact/ContactFormService";
import Footer1 from "../../common/footer/Footer1";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import KuposHelmet from "../../common/KuposHelmet";
import ThemeTenTitle from "./ThemeTenTitle";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";
import { CarpoolHeader } from "../../../components";

const FleetBanner = "/assets/traveltur/service/servicios-especiales.webp";
const ThemeTenServiciosEspeciales = props => {
  console.log("🚀 ~ ThemeTenServiciosEspeciales ~ props:", props)
  const operator =
    JSON.parse(localStorage.getItem("op")) || props.data.operator;
  return (
    <IonPage>
      {/* <KuposHelmet /> */}

      <IonContent>
        {/* <Header /> */}
        {/* <HeroSection
							heroImage={FleetBanner}
							// height={'60vh'}
							height={"45vh"}
							title={"Travel Norte"}
							description={"Home / Travel Norte"}
					/> */}

        {/* Amenities section  */}
        <CarpoolHeader
          backIcon={true}
          label="Servicios"
          labelBold="especiales"
          labelDark={true}
          history={props.history}
        />

        <div className="new-container justify-text">
          <p>
            En <strong>Traveltur</strong> ponemos a tu disposición nuestro
            servicio de viajes especiales, que te permite acceder a traslados
            exclusivos para tu empresa, organización o viaje grupal. Este
            servicio es ideal para grupos de al menos 7 pasajeros,
            garantizándoles un servicio excepcional y personalizado, en buses de
            última generación y con los más altos estándares de segundad.
          </p>
          <div className="service-form">
            <ContactFormService operator={operator} props={props} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ThemeTenServiciosEspeciales;
