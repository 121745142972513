import './PullmanSanAndreasServices.css';

import { IonContent, IonPage } from '@ionic/react';

import Footer6 from '../common/footer/Footer6';
import Header6 from '../common/header/Header6';
import PullmanBanners from './components/PullmanBanners';
import React from 'react';
import ThemeSixOfficeSection from './components/ThemeSixOfficeSection';
import ThemeSixSlides from './components/ThemeSixSlides';
import { connectData } from '../../redux';
import getIcons from '../functions/getIcons/getIcons';
import getSlides from '../functions/getSlides';
import ThemeSixHeader from './components/ThemeSixHeader';

const industryImage = '/assets/pullmanSanAndres/industrial.jpg';

function PullmanSanAndreasServices(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;
  return (
    <IonPage>
      <IonContent>
        <ThemeSixHeader history={props.history} operator={operator} />

        <div className='pullman-san-andreas-services'>
          <PullmanBanners
            bgImage={getIcons('serviceBanner')}
            text={'Servicios'}
            textPosition={'center'}
          />
          <div className='new-container'>
            <h1 className='main-heading text-center bold-text font-italic'>
              Servicios Interregionales
            </h1>
            <p className='text-center sub-heading'>
              San Andrés es una empresa de viajes dedicada a darte una
              experiencia cómoda en todo momento. Queremos entregarte un
              servicio de calidad a través de nuestra flota y personal
              completamente preparados para tu satisfacción.
            </p>
            <p className='text-center sub-heading'>
              A través de nuestros viajes deseamos ganar la verdadera confianza
              y confort de nuestros clientes.
            </p>
            <p className='text-center sub-heading'>
              Contamos con distintas sucursales en el norte del país para
              conectarte con tus seres queridos.
            </p>
            <div className='pullman-san-andreas-services-offices'>
              <h1 className='main-heading text-center bold-text font-italic'>
                Sucursales
              </h1>
              <ThemeSixOfficeSection />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                style={{
                  width: '70%',
                }}
                src={getIcons('buyOnlineImage')}
                className='buy-online-image'
                alt=''
              />
            </div>
            <h1 className='division-industry-heading text-center bold-text font-italic font-22px'>
              División Industrial
            </h1>
            <p className='text-justify font-14px'>
              Contamos con una de las flotas más modernas del mercado, compuesta
              por buses de última generación, equipados con tecnología de punta
              en seguridad industrial y exigencias de estándar minero.
            </p>
            <div className='division-industry'>
              <div className='division-industry-left'>
                <img src={industryImage} alt='' />
              </div>
              <div className='division-industry-right'>
                <span className='head'>NUESTROS SERVICIOS</span>
                <br />
                <span>Traslado de ciudad a faena.</span>
                <br />
                <span>Traslado mini buses internos.</span>
                <br />
                <span>Convenio pasajes.</span>
                <br />
                <span>Convenio con los buses de acercamiento.</span>
                <br />
                <span className='bottom'>
                  Convenio mini buses acercamiento.
                </span>
                <br />
                <span className='head'>TRANSPORTE SEGURO</span>
                <br />
                <span>
                  Nuestra flota de buses cuenta con la más alta tecnología en
                  sistemas de seguridad, entre los que se destacan el contar con
                  buses de piso y medio como de dos pisos que cumplen la norma
                  europea más exigente en medición de la resistencia de la
                  carrocería (ECE R.66.02) y vehículos certificados por CEIMAS
                  que cumplen con la normativa legal vigente y los
                  requerimientos indicados en los estándares de control de
                  riesgos fatales de BHP.
                </span>
              </div>
            </div>
            <h3
              className='bold-text text-center font-italic font-24px'
              style={{
                margin: 0,
                color: operator && operator.theme_color && operator.theme_color,
              }}
            >
              Empresas que confían en nosotros
            </h3>
            <p
              className='bottom-para text-center font-italic font-18px'
              style={{
                margin: 0,
              }}
            >
              sé parte de <span className='bold-text'>Pullman San Andrés</span>
            </p>
            <ThemeSixSlides
              slides={getSlides('Comapanies', operator)}
              noOfIcons={2}
            />
          </div>
        </div>

        <Footer6 />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(PullmanSanAndreasServices);
