/* eslint-disable jsx-a11y/iframe-has-title */
import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenTicketSaleScreen.css';

const ThemeSevenTicketSaleScreen = (props) => {
  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-ticket-sale-screen'>
          <div id='ticket-sales'>
            <div class='new-container'>
              <div class='heading'>
                <h1>Servicios</h1>
                {/* <ul class='service-list hiiden-sm hidden-xs'>
                  <li>
                    <a href='/'>Venta de pasajes online</a>
                  </li>
                  <li class='active-color'>
                    <a href='/ticket-sales'>Venta de pasajes presencial</a>
                  </li>
                  <li>
                    <a href='/special-service'>Servicios especiales</a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>

          {/* ---------- */}

          <div class='new-container'>
            <div class='col-md-12'>
              <div class='ticket-sales'>
                <h3>
                  <span>Venta de pasajes presencial</span>
                </h3>
                <div class='cargo-details'>
                  {/* <h5>
                    Buses Fernández cuenta con oficinas de venta de pasajes en{' '}
                    <span>Punta Arenas</span> y <span>Puerto Natales;</span> en
                    ellas podrás gestionar tus pasajes con nuestro equipo de
                    operadores que te orientarán en la compra y te darán todas
                    las alternativas de destinos: <br /> Punta Arenas,
                    Aeropuerto Punta Arenas, Villa Tehuelches, Llanuras de Diana
                    (Hotel de Caja Los Andes), Puerto Natales y Conexiones con
                    buses con destino a Torres del Paine, incluyendo servicios
                    regulares y full day.
                  </h5> */}
                  <div class='iframe1 d-flex mb-70'>
                    <div class='col-md-4'>
                      <img
                        src='/assets/fernandez/services/BusesFernandez-Website-Services-PuntaArenas (1).png'
                        alt='punta-arenas'
                      />
                    </div>
                    <div class='col-md-8'>
                      <div class='support'>
                        <div class='c-flex-row se'>
                          <div className='flex'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
                              alt='location'
                            />
                            <span>Dirección: Armando Sanhueza #745</span>
                          </div>
                          <div className='flex'>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
                              alt='support'
                            />
                            <span>
                              Teléfonos: +56 61 2221 812 / +56 61 2242 313
                            </span>
                          </div>
                        </div>
                      </div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2392.32299604803!2d-70.91057488424771!3d-53.15824367993995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdb2637c2f63d307%3A0x890b66db8c956357!2sArmando%20Sanhueza%20745%2C%20Punta%20Arenas%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena%2C%20Chile!5e0!3m2!1sen!2sin!4v1618231042334!5m2!1sen!2sin'
                        width='100%'
                        height='300'
                        // style="border:0;"
                        allowfullscreen=''
                        loading='lazy'
                      ></iframe>
                    </div>
                    <div class='clearfix'></div>
                  </div>
                  <div class='iframe2 d-flex mb-70'>
                    <div class='col-md-8'>
                      <div class='support'>
                        <div class='c-flex-row se'>
                          <div>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
                              alt='location'
                            />
                            <span>Dirección: Av. España #1455</span>
                          </div>
                          <div>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
                              alt='support'
                            />
                            <span>
                              Teléfonos: +56 61 2723 355 / +56 61 2411 111
                            </span>
                          </div>
                        </div>
                      </div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.245030395993!2d-72.49032528430357!3d-51.728553479672065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdafe8010b744225%3A0xd2ed29e6da8db338!2sAvenida%20Espa%C3%B1a%201455%2C%20Puerto%20Natales%2C%20Natales%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena%2C%20Chile!5e0!3m2!1sen!2sin!4v1618232211261!5m2!1sen!2sin'
                        width='100%'
                        height='300'
                        // style="border:0;"
                        allowfullscreen=''
                        loading='lazy'
                      ></iframe>
                    </div>
                    <div class='col-md-4'>
                      <img
                        src='/assets/fernandez/services/BusesFernandez-Website-Services-PuertoNatales1 (1).png'
                        alt='punta-natales'
                      />
                    </div>
                    <div class='clearfix'></div>
                  </div>
                  <div class='iframe3 d-flex '>
                    <div class='col-md-4'>
                      <img
                        src='/assets/fernandez/services/BusesFernandez-Website-Services-PuertoNatales2 (1).png'
                        alt='punta-natales'
                      />
                    </div>
                    <div class='col-md-8'>
                      <div class='support'>
                        <div class='c-flex-row se'>
                          <div>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Location.svg'
                              alt='location'
                            />
                            <span>Dirección: Eleuterio Ramirez #399</span>
                          </div>
                          <div>
                            <img
                              src='/assets/fernandez/contact/BusesFernandez-Website-Contact-Telephone.svg'
                              alt='support'
                            />
                            <span>
                              Teléfonos: +56 61 2414 786 / +56 61 2242 313
                            </span>
                          </div>
                        </div>
                      </div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.2801391669655!2d-72.50164318430352!3d-51.72791127967205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdafe81cadbcf9cd%3A0x67831c713b1b200!2sEleuterio%20Ram%C3%ADrez%20399%2C%20Puerto%20Natales%2C%20Natales%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena%2C%20Chile!5e0!3m2!1sen!2sin!4v1618231159478!5m2!1sen!2sin'
                        width='100%'
                        height='300'
                        // style="border:0;"
                        allowfullscreen=''
                        loading='lazy'
                      ></iframe>
                    </div>
                    <div class='clearfix'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenTicketSaleScreen);
