const menuDot = '/assets/lineaazul/general/dots.svg';
const BG = '/assets/lineaazul/general/banner.webp';
const OriginIcon = '/assets/lineaazul/general/origin.svg';
const DestinationIcon = '/assets/lineaazul/general/destination.svg';
const CalendarIcon = '/assets/lineaazul/general/calendar.svg';
const HeadPhone = '/assets/lineaazul/general/headphone-orange.svg';
const Banner = '/assets/lineaazul/general/contact-banner.webp';
const pnr = '/assets/lineaazul/general/find-my-pnr.svg';
const Whatsapp = '/assets/lineaazul/general/whatsapp-orange.svg';
const Email = '/assets/lineaazul/general/email-orange.svg';
const YellowLine = '/assets/kennybus/icons/yellow-line.svg';





const PopupIcon = '/assets/kennybus/general/Pop-up.jpg';





const HeaderLogo = '/assets/kennybus/general/kennybus-header-logo.png';
const FooterLogo = '/assets/kennybus/general/kennybus-footer-logo-white.png';
const BookingInActive = '/assets/kennybus/general/booking_white.svg';
const BookingActive = '/assets/kennybus/general/booking.svg';
const CancelInActive = '/assets/kennybus/general/cancel_white.svg';
const CancelActive = '/assets/kennybus/general/cancel.svg';





//  Common Assets as we have in tepual
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';


const checkboxIcon = '/assets/tepual/checkbox.png';
const close = '/assets/kennybus/icons/close.svg';


// accounts section

const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';
const tarjetas = '/assets/blue/IllustratedIcons-MyCards-Blue.png';
const viajes = '/assets/blue/IllustratedIcons-MyTrips-Blue.png';
const movimientos = '/assets/blue/IllustratedIcons-MyMovements-Blue.png';
const wallet = '/assets/blue/MyAccount-IllustratedIcons-RechargeWallet-Blue.png';
const shareMoney = '/assets/blue/MyAccount-IllustratedIcons-ShareMoney-Blue.png';
const camera = '/assets/blue/IllustratedIcons-Camera-Blue.png';
const checkmark = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/blue/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/blue/IllustratedIcons-DropDown-Blue.png';
const loginblue = '/assets/blue/IllustratedIcons-Login-Blue-c.png';
const recoverpswd = '/assets/blue/IllustratedIcons-RecoverPassword-Blue.png';
const success = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const failure = '/assets/blue/IllustratedIcons-Information-Blue.png';
const warning = '/assets/blue/IllustratedIcons-Information-Blue.png';
const userIcon = '/assets/blue/IllustratedIcons-user.png';



const heartOn = '/assets/blue/IllustratedIcons-HeartOn-Blue.svg';
const heartOff = '/assets/blue/IllustratedIcons-HeartOff-Blue.svg';
const registrationSuccess = '/assets/blue/IllustratedIcons-RightDocument-Blue.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'

const lineaazulIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: Banner, name: 'Banner' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: Whatsapp, name: 'Whatsapp' },
	{ icon: Email, name: 'Email' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: YellowLine, name: 'Line' },


	{ icon: menuDot, name: 'menuDot' },
	{ icon: BG, name: 'BG' },

	//   common assets
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },


];

export default lineaazulIcons;
