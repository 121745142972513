const fbIcon = '/assets/pullmanSanAndres/fb.svg';
const instaIcon = '/assets/pullmanSanAndres/insta.svg';
const whatsAppIcon = '/assets/pullmanSanAndres/whatsapp.svg';

const OriginIcon = '/assets/pullmanSanAndres/newOrigin.png';
const DestinationIcon = '/assets/pullmanSanAndres/newDestination.png';
const CalenderIcon = '/assets/pullmanSanAndres/calendar.svg';
const Banner2 = '/assets/pullmanSanAndres/banner2.png';
const Banner3 = '/assets/pullmanSanAndres/banner3.png';

const HomeHead2 = '/assets/pullmanSanAndres/home-head2.png';
const HomeHead3 = '/assets/pullmanSanAndres/home-head3.png';
const fleetBanner = '/assets/pullmanSanAndres/fleet-banner.jpg';
const aboutBanner = '/assets/pullmanSanAndres/about-us-banner.jpg';
const safetyBanner = '/assets/pullmanSanAndres/safety-banner.jpg';
const serviceBanner = '/assets/pullmanSanAndres/service-banner.jpg';
const buyOnlineImage = '/assets/pullmanSanAndres/buyonline.svg';
const contactBanner = '/assets/pullmanSanAndres/contact-banner.jpg';
const locationIcon = '/assets/pullmanSanAndres/location1.svg';
const animation =
  '/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const pnr =
  '/assets/pullmanSanAndres/PullmanSanAndres-Website-Home-WhereToFindMyPNR.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const BG = '/assets/pullmanSanAndres/PullmanSanAndres-Banner-MobileVersion.jpg';
const close = '/assets/kennybus/icons/close.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png'; 
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';

  //  pet seat 
const petseatref = '/assets/icons/seats/SanAndres-ResultPage-PetsOnBoard-Ammenity.png';
const petseatModal = '/assets/icons/seats/Popup-definitivo-SanAndres.jpg';
const petfilteron = '/static/images/seats/On-Button-Filter.svg';
// const petfilteroff = '/static/images/seats/Off-Button-Filter.svg';
const petfilteroff = '/assets/ivergrama/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_off.svg';

const operatorPay = '/assets/pullmanSanAndres/sanandrespay.png'

const pullmanSanAndreasIcons = [
  { icon: BG, name: 'BG' },
  { icon: fbIcon, name: 'fbIcon' },
  { icon: instaIcon, name: 'instaIcon' },
  { icon: whatsAppIcon, name: 'whatsAppIcon' },
  { icon: close, name: 'close' },
  { icon: OriginIcon, name: 'Origin' },
  { icon: DestinationIcon, name: 'Destination' },
  { icon: CalenderIcon, name: 'Calendar' },
  { icon: Banner2, name: 'Banner2' },
  { icon: Banner3, name: 'Banner3' },
  { icon: HomeHead2, name: 'HomeHead2' },
  { icon: HomeHead3, name: 'HomeHead3' },
  { icon: fleetBanner, name: 'fleetBanner' },
  { icon: aboutBanner, name: 'aboutBanner' },
  { icon: safetyBanner, name: 'safetyBanner' },
  { icon: serviceBanner, name: 'serviceBanner' },
  { icon: buyOnlineImage, name: 'buyOnlineImage' },
  { icon: contactBanner, name: 'contactBanner' },
  { icon: locationIcon, name: 'locationIcon' },

  { icon: animation, name: 'animation' },
  { icon: pnr, name: 'pnr' },
  { icon: checkboxIcon, name: 'Checkbox' },

  // { icon: HeaderLogo, name: 'headerLogo' },
  // { icon: FooterLogo, name: 'footerLogo' },
  // { icon: ContactBg, name: 'ContactBg' },
  // { icon: BookingInActive, name: 'BIA' },
  // { icon: BookingActive, name: 'BA' },
  // { icon: CancelInActive, name: 'CIA' },
  // { icon: CancelActive, name: 'CA' },
  // { icon: OriginIcon, name: 'Origin' },
  // { icon: DestinationIcon, name: 'Destination' },
  // { icon: CalendarIcon, name: 'Calendar' },
  // { icon: SearchIcon, name: 'Search' },
  // { icon: PopupIcon, name: 'Popup' },
  // { icon: DestinationBanner, name: 'DestinationBanner' },
  // { icon: ContactIcon, name: 'ContactIcon' },
  // { icon: TelephoneIcon, name: 'TelephoneIcon' },
  // { icon: TelephoneIcon, name: 'TelephoneIcon' },
  // { icon: BlueArrow, name: 'BlueArrow' },
  // { icon: ClockIcon, name: 'ClockIcon' },
  // { icon: FleetBanner, name: 'FleetBanner' },
  // { icon: FleetTv, name: 'FleetTv' },
  // { icon: FleetToilet, name: 'FleetToilet' },
  // { icon: FleetAc, name: 'FleetAc' },
  // { icon: FleetSnacks, name: 'FleetSnacks' },
  // { icon: FleetBed, name: 'FleetBed' },
  // { icon: AmenitiesLeftImage, name: 'AmenitiesLeftImage' },
  // { icon: EmailIcon, name: 'EmailIcon' },
  // { icon: BuyIconWhite, name: 'BuyIconWhite' },
  // { icon: BuyIconBlue, name: 'BuyIconBlue' },
  // { icon: CancelIconWhite, name: 'CancelIconWhite' },
  // { icon: CancelIconBlue, name: 'CancelIconBlue' },
  // { icon: TelephoneWhite, name: 'TelephoneWhite' },
  // { icon: EmailWhite, name: 'EmailWhite' },
  // { icon: checkboxIcon, name: 'Checkbox' },
  // { icon: animation, name: 'animation' },

   // accounts section
   { icon: pay, name: 'pay' },
   { icon: backimage, name: 'backimage' },
   { icon: tarjetas, name: 'tarjetas' },
   { icon: viajes, name: 'viajes' },
   { icon: movimientos, name: 'movimientos' },
   { icon: wallet, name: 'wallet' },
   { icon: shareMoney, name: 'shareMoney' },
   { icon: camera, name: 'camera' },
   { icon: checkmark, name: 'checkmark' },
   { icon: tick, name: 'tick' },
   { icon: downArrow, name: 'downArrow' },
   { icon: loginblue, name: 'loginblue' },
   { icon: recoverpswd, name: 'recoverpswd' },
   { icon: success, name: 'success' },
   { icon: failure, name: 'failure' },
   { icon: heartOn, name: 'heartOn' },
   { icon: heartOff, name: 'heartOff' },
   { icon: registrationSuccess, name: 'registrationSuccess' },
   { icon: userIcon, name: 'userIcon' },
   { icon: warning, name: 'warning' },


     //  pet seat 
  { icon: petseatref, name: 'petseatref' },
  { icon: petseatModal, name: 'petseatModal' },
  { icon: petfilteron, name: 'petfilteron' },
  { icon: petfilteroff, name: 'petfilteroff' },

  { icon: operatorPay, name: 'operatorPay' },

   
];

export default pullmanSanAndreasIcons;
