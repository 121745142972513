// passing the path into variables

const banner1 = '/assets/fernandez/home/BusesFernandez-Website-Home-Slide1.jpg';
const banner2 =
  '/assets/fernandez/home/BusesFernandez-Website-Home-Slide1-TextModify.jpg';
const banner3 =
  '/assets/fernandez/home/BusesFernandez-Website-Home-Slide2-TextModify.jpg';
const banner4 =
  '/assets/fernandez/home/BusesFernandez-Website-Home-Slide3-TextModify.jpg';

const destination1Img =
  '/assets/fernandez/general/BusesFernandez-Home-Destinations-LlanurasDeDiana.png';
const destination2Img =
  '/assets/fernandez/general/BusesFernandez-Home-Destinations-PuntaArenas.png';
const destination3Img =
  '/assets/fernandez/general/BusesFernandez-Home-Destinations-TorresDelPaine.png';
const destination4Img =
  '/assets/fernandez/general/BusesFernandez-Home-Destinations-PuertoNatales.png';

const popup1Img =
  '/assets/fernandez/general/BusesFernandez-Popup-web-LlanurasdeDiana.jpg';
const popup2Img =
  '/assets/fernandez/general/BusesFernandez-Popup-web-PuntaArenas.jpg';
const popup3Img =
  '/assets/fernandez/general/BusesFernandez-Popup-web-TorresdelPaine.jpg';
const popup4Img =
  '/assets/fernandez/general/BusesFernandez-Popup-web-PuertoNatales.jpg';

const gallery1 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-1.png';
const gallery2 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-2.png';
const gallery3 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-3.png';
const gallery4 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-4.png';
const gallery5 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-5.png';
const gallery6 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-6.png';
const gallery7 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-7.png';
const gallery8 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-8.png';
const gallery9 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-9.png';
const gallery10 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-10.png';
const gallery11 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-11.png';
const gallery12 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-12.png';
const gallery13 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-13.png';
const gallery14 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-14.png';
const gallery15 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-15.png';
const gallery16 =
  '/assets/fernandez/gallery/BusesFernandez-Website-Gallery-Photo-16.png';

const banner = [banner1, banner2, banner3, banner4];

const destination = [
  { img: destination1Img, popup: popup1Img },
  { img: destination2Img, popup: popup2Img },
  { img: destination3Img, popup: popup3Img },
  { img: destination4Img, popup: popup4Img },
];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
];

const fernandezSlides = {
  banner,
  destination,
  gallery,
};

export default fernandezSlides;
