import './ThemeSixHeader.css';

import React, { useState } from 'react';

const account_header_links = [
	{
		label: 'MI CUENTA',
		url: '/my-account',
		fontSize: '17px',
		id: 1,
		bold: 'bold-text',
	},
	{
		label: 'Mis viajes',
		url: '/my-account/my-trips',
		fontSize: '12px',
		id: 2,
	},
	{
		label: 'Mis movimientos',
		url: '/transactions',
		fontSize: '12px',
		id: 3,
	},
];
const header_links = [

	{
		label: 'INICIO',
		url: '/',
		fontSize: '17px',
		id: 4,
		bold: 'bold-text',
	},
	{
		label: 'DESTINOS',
		fontSize: '17px',
		url: '',
		bold: 'bold-text',
		id: 5,
	},
	{
		label: 'CONÓCENOS',
		url: '',
		fontSize: '17px',
		id: 6,
		bold: 'bold-text',
		disable: true,
	},
	{
		label: 'Nuestra flota',
		url: '/fleet',
		fontSize: '12px',
		id: 7,
	},
	{
		label: 'Quiénes somos',
		fontSize: '12px',
		id: 8,
		url: '/sanandres-about',
	},
	{ label: 'Seguridad a bordo', url: '/safety', fontSize: '12px' },

	{
		label: 'SERVICIOS',
		fontSize: '17px',
		id: 9,
		bold: 'bold-text',

		url: '/inter-regional-travel',
	},
	{
		id: 10,
		label: 'Viajes interregionales',
		fontSize: '12px',
		url: '/inter-regional-travel',
	},

	{
		id: 10,
		label: ' Viajes especiales',
		fontSize: '12px',
		url: '/inter-regional-travel',
	},

	{
		id: 10,
		label: 'CONTACTO',
		fontSize: '17px',
		bold: 'bold-text',
		url: '/contact',
	},
];

const ThemeSixHeader = ({ operator, history }) => {
	const [expandMenu, setExpandMenu] = useState(false);

	return (
		<div>
			<div
				style={{
					background:
						operator &&
							operator.color_codes &&
							operator.color_codes.sub_header_color
							? operator.color_codes.sub_header_color
							: 'dba447',
					padding: '5px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'relative',
				}}
			>
				<div className='new-container'>
					<div
						className='font-14px bold-text'
						style={{
							color: '#FFF',
							marginBottom: '5px',
						}}
					>
						Seguimos conectando el norte de Chile, de la manera más segura.
					</div>
					<div
						style={{ fontSize: '13px', display: 'flex', alignItems: 'center' }}
					>
						<span
							style={{ display: 'flex', alignItems: 'center' }}
						//   className='mx-1'
						>
							<a
								href='https://www.facebook.com/pullmansanandres.cl'
								target='_black'
							>
								<span className='san-andreas-facebook '></span>
							</a>
							<a
								href='https://www.instagram.com/pullmansanandres.cl/'
								target='_black'
							>
								<span className='san-andreas-insta mx-1'></span>
							</a>
							<span className='' style={{ color: 'white' }}>
								|
							</span>
							<a
								href='https://api.whatsapp.com/send?phone=56933798168'
								target='_blank'
							>
								<span className='san-andreas-whatsapp mx-1'></span>
							</a>

							<span className='font-17px' style={{ color: 'white' }}>
								{operator.phone_number}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div
				className='themes-header home-container'
				style={{
					background:
						operator &&
							operator.color_codes &&
							operator.color_codes.header_color
							? operator.color_codes.header_color
							: '#db7838',
					border: `1px solid ${operator &&
						operator.color_codes &&
						operator.color_codes.header_color
						? operator.color_codes.header_color
						: '#db7838'
						}`,
					// opacity: 0.9,
					justifyContent: 'center',
					minHeight: '80px',
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							// justifyContent: 'center',
							marginLeft: 15,
							marginRight: 15,
						}}
					>
						<div
							onClick={() => setExpandMenu(!expandMenu)}
							style={styles.toggleButton}
						>
							<div style={styles.line}></div>
							<div style={styles.line}></div>
							<div style={styles.line}></div>
						</div>
						<div style={{ marginLeft: 25 }}>
							<a href='/'>
								<img
									src={operator && operator.header_logo}
									alt=''
									id='logo'
									height={'auto'}
									width='200px'
								/>
							</a>
						</div>
					</div>
					{expandMenu && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 20,
								paddingLeft: '30px',
							}}
						>

							{operator && operator.has_user_account && account_header_links &&
								account_header_links.map((val, i) => {
									return (
										<div
											className={`nav-item ${val.active ? 'active' : ''}${val.bold
												}`}
											onClick={() => {
												// history.push(val.url);
											}}
											style={{
												color: 'white',
												marginBottom: '8px',
												fontSize: val.fontSize,
												borderBottom:
													window.location.pathname === val.url
														? '2px solid white'
														: '',
												width: 'fit-content',
											}}
											key={i}
										>
											{val.disable ? (
												<span style={{ color: 'white' }}>{val.label}</span>
											) : (
												<a style={{ color: 'white' }} href={val.url}>
													{val.label}
												</a>
											)}
										</div>
									);
								})}


							{header_links &&
								header_links.map((val, i) => {
									return (
										<div
											className={`nav-item ${val.active ? 'active' : ''}${val.bold
												}`}
											onClick={() => {
												// history.push(val.url);
											}}
											style={{
												color: 'white',
												marginBottom: '8px',
												fontSize: val.fontSize,
												borderBottom:
													window.location.pathname === val.url
														? '2px solid white'
														: '',
												width: 'fit-content',
											}}
											key={i}
										>
											{val.disable ? (
												<span style={{ color: 'white' }}>{val.label}</span>
											) : (
												<a style={{ color: 'white' }} href={val.url}>
													{val.label}
												</a>
											)}
										</div>
									);
								})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const styles = {
	line: {
		width: 17,
		height: 2,
		background: '#FFF',
		margin: 3,
	},
	toggleButton: {
		width: 50,
		height: 35,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		border: '1.4px solid #fff',
		borderRadius: 5,
		//   paddingTop:5,
		//   paddingBottom:5
	},
	blackLine: {
		height: 1,
		background: '#000',
		width: '80%',
		margin: 'auto',
	},
};
export default ThemeSixHeader;
