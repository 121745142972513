import React, { Component, useState } from "react";
import { connectData } from "../../../redux";
import { CarpoolSuccessFailureModal, PBInput } from "../../../components";
import { CommonService } from "../../../services";
// import getIcons from '../../functions/getIcons';
// import getFonts from '../../functions/getFonts';
// import { KuposButton, KuposInput } from '../../../common';
const Iframe = props => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
};

const initialState = {
  name: "",
  nameError: "",
  email: "",
  emailError: "",
  phone: "",
  phoneError: "",
  comment: "",
  commentError: "",
  showModal: false,
};

const ContactForm4 = ({ operator, showMap, isSanantonio, props }) => {
  const [state, setState] = useState(initialState);

  const validate = () => {
    let error = 0;
				if (!state.name) {
					setState(prevData => ({
							...prevData,
							nameError: "Ingresa tu nombre y apellido",
					}));
					error++;
			}

			if (!state.email) {
				setState(prevData => ({
						...prevData,
						emailError: "Ingresa tu correo electrónico",
				}));
				error++;
		} else if (!CommonService.isEMailValid(state.email)) {
				setState(prevData => ({
						...prevData,
						emailError: "Ingrese un email valido",
				}));
				error++;
		}

		if (!state.comment) {
			setState(prevData => ({
					...prevData,
					commentError: "Ingresa los detalles de tu solicitud",
			}));
			error++;
	}

	if (!state.phone) {
		setState(prevData => ({
				...prevData,
				phoneError: "Ingresa tu número de teléfono",
		}));
		error++;
}

return error;

    
  };

  const opname = operator && operator.operator_name;
  const getColor = () => {
    if (opname === "sanantonio" || opname === "traveltur") {
      return {
        contactBG: operator && operator.theme_color2,
        dashline: "#fff",
      };
    }
    if (opname === "tacoha") {
      return {
        contactBG: operator && operator.color_codes.secondary_color,
        dashline:
          operator && operator.color_codes && operator.color_codes.button_color,
      };
    }
    if (opname === "liquine") {
      return {
        contactBG: operator && operator.color_codes.header_color,
        dashline:
          operator &&
          operator.color_codes &&
          operator.color_codes.secondary_color,
      };
    }
    return {
      contactBG: "#161d4f",
      dashline: "#fff",
    };
  };

  const getIframe = () => {
    if (opname === "sanantonio") {
      return `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.653754800368!2d-70.0918028!3d-20.2731897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915215192cfe93cd%3A0x2a4432f03e68651!2sLas%20Parcelas%201234%2C%20Alto%20Hospicio%2C%20Tarapac%C3%A1%2C%20Chile!5e0!3m2!1sen!2sin!4v1660722437917!5m2!1sen!2sin" width="100%" height="200" style="border:0; border-radius:10px" allowfullscreen="" loading="lazy"></iframe>`;
    }
    if (opname === "tacoha") {
      return `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.963165429253!2d-70.6924551!3d-33.4466965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c45dfaf0828d%3A0x36716f555c559cc!2sAp%C3%B3stol%20Santiago%20386%2C%208500995%20Quinta%20Normal%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sus!4v1624087497051!5m2!1sen!2sus" width="100%" height="200" style="border:0; border-radius:10px" allowfullscreen="" loading="lazy"></iframe>`;
    }
    if (opname === "traveltur") {
      return `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.377759740472!2d-70.65124399999999!3d-33.43946340000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5a3f56e4607%3A0x2a8a8976a151a288!2sHuerfanos%201055%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sin!4v1688407960571!5m2!1sen!2sin" width="100%" height="200" style="border:0;"  border-radius:10px" allowfullscreen="" loading="lazy" "></iframe>`;
    }
    if (opname === "liquine") {
      return null;
    }
    return `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.268741576728!2d-70.6963341852367!3d-33.442304304479485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c467e3d139b9%3A0xeef42616c7bc46f0!2sAndimar%20Vip!5e0!3m2!1sen!2sin!4v1642448360398!5m2!1sen!2sin" width="100%" height="200" style="border:0; border-radius:10px" allowfullscreen="" loading="lazy"></iframe>`;
  };

  //raqib- added for gtags
  const submit = e => {
    e.preventDefault();
    if (props.andimar) {
      window.dataLayer.push({
        event: "formContacto",
      });
    }

    if (validate()) {
      return;
    }

    let data = {
      name: state.name,

      email: state.email,
      phone: state.phone,
      comments: state.comment,
      operator_id: operator.operator_id,
      operator_name: opname,
    };

    props.opsiteContactInfo({
      callback: result => onContactInfoResponse(result),
      data: data,
    });
  };

  const onContactInfoResponse = res => {
    console.log("response from contact info", res);
    if (res && res.data && res.data.message) {
      setState({
        ...state,
        showModal: true,
        responseMessage: res.data.message,
        status: res.data.status,
      });
    }
  };

  const onChange = (value, type) => {
    if (type == "mobile") {
      value = CommonService.phoneNumberFormat(value);
      setState({
        ...state,
        [type]: value,
        [type + "Error"]: false,
      });
    } else {
      setState({ ...state, [type]: value, [type + "Error"]: false });
    }
  };
  return (
    <div className="contact-form w-50 montserrat-bold ">
      <style>
        {`
						.PB-input-container .input-wrapper {
							padding: 0;
							backgroundColor: white

						}
						// .form-group input, .form-group textarea {
						// 	padding-left: 10px !important;
						// }
					
						`}
      </style>
      {showMap && getIframe() && (
        <div
          style={{
            height: 200,
            width: "100%",
            background: isSanantonio ? "#F4F4F4" : "#43469C",
            marginBottom: 30,
            borderRadius: 10,
          }}
        >
          <Iframe iframe={getIframe()} />
        </div>
      )}
      <div
        className="contact-form__head text-uppercase px-5 py-3 montserrat-bold font15 avenir-semi"
        style={{
          // background: isSanantonio ? operator && operator.theme_color2 : "#161d4f",
          background: getColor().contactBG,
        }}
      >
        CONTÁCTANOS
      </div>

      <div
        className="contact-form__container py-4"
        style={{ background: isSanantonio ? "#F4F4F4" : "" }}
      >
        <form className="form-4">
          <div className="form-group w-100 px-5 font13 avenir-semi">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  isSanantonio &&
                  operator.color_codes &&
                  operator.color_codes.sub_footer_color &&
                  operator.color_codes.sub_footer_color,
              }}
            >
              Nombre
            </label>
            <PBInput
              className="kupos-border"
              type="text"
              placeholder="Nombre y apellido"
              value={state.name}
              onChange={value => onChange(value, "name")}
              error={state.nameError}
              errorMessage={state.nameError}
            />
          </div>

          <div className="form-group w-100 px-5 font13 avenir-semi">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  isSanantonio &&
                  operator.color_codes &&
                  operator.color_codes.sub_footer_color &&
                  operator.color_codes.sub_footer_color,
              }}
            >
              Teléfono
            </label>
            <PBInput
              className="kupos-border"
              type="text"
              value={state.phone}
              onChange={value => onChange(value, "phone")}
              error={state.phoneError}
              errorMessage={state.phoneError}
            />
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  isSanantonio &&
                  operator.color_codes &&
                  operator.color_codes.sub_footer_color &&
                  operator.color_codes.sub_footer_color,
              }}
            >
              Email
            </label>
            <PBInput
              className="kupos-border"
              type="text"
              placeholder="example@gmail.com"
              value={state.email}
              onChange={value => onChange(value, "email")}
              error={state.emailError}
              errorMessage={state.emailError}
            />
          </div>

          <div className="form-group w-100 px-5 avenir-semi">
            <label
              className="w-100 font13 "
              htmlFor=""
              style={{
                color:
                  isSanantonio &&
                  operator.color_codes &&
                  operator.color_codes.sub_footer_color &&
                  operator.color_codes.sub_footer_color,
              }}
            >
              Comentario
            </label>
            <PBInput
              className="kupos-border"
              type="text"
              placeholder="Mensaje"
              value={state.comment}
              onChange={value => onChange(value, "comment")}
              error={state.commentError}
              errorMessage={state.commentError}
            />
          </div>

          <div className="form-group w-100 px-5 ">
            <button
              className="w-100 font14"
              style={{
                background:
                  operator &&
                  operator.color_codes &&
                  operator.color_codes.button_color,
              }}
              onClick={submit}
            >
              ENVIAR
            </button>
          </div>
          <CarpoolSuccessFailureModal
            showModal={state.showModal}
            success={true}
            smallIcon={true}
            bodyText={state.responseMessage}
            buttonText={"OK"}
            onButtonPress={() => {
              window.location.reload();

              setState({
                showModal: false,
              });
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default connectData()(ContactForm4);
