// import * as actions from '../../redux/actions';
import React, { Component } from 'react';

import { AboutUs1 } from '../../common/about-us/AboutUs1';
import { AboutUsIconSection } from '../../common/about-us/AboutUsIconSection';
import CommonService from '../../../services/commonService';
import HeroSection from '../../common/HeroSection/HeroSection';
import { OfficesList } from '../../common/OfficeList/OfficesList';
import { IonContent, IonPage, } from "@ionic/react";
import { connectData } from '../../../redux';
import teupalIcon1 from '../../assets/tepual/icons/camera-about-us.svg';
import teupalIcon2 from '../../assets/tepual/icons/urban-about-us.svg';
import teupalIcon3 from '../../assets/tepual/icons/location-about-us.svg';
import singleBusImage from '../../assets/tepual/about-us/AboutUs-ParagraphImage.jpg';
import mainBanner from '../../assets/tepual/about-us/AboutUs-MainBanner.jpg';
import doubleBusImage from '../../assets/tepual/icons/double-bus-about-us.jpg';
import Header from '../../common/header/Header';
import Footer2 from '../../common/footer/Footer2';


const TepualAboutUs = ({ history }) => {

  const operator = JSON.parse(localStorage.getItem('op'))

  const teupalIconsAndTexts = [
    { icon: teupalIcon1, caption: 'Giras de estudio' },
    { icon: teupalIcon2, caption: 'Transporte interurbano' },
    { icon: teupalIcon3, caption: 'Viajes especiales' },
  ];

  const officeLocations = [
    { city: 'santiago' },
    { city: 'talc' },
    { city: 'temocu' },
    { city: 'puerto montt' },
    { city: 'osorno' },
    { city: 'calbuco' },
    { city: 'valdivia' },
  ];

  return (
    <IonPage>
      <IonContent>
        <Header />

        <HeroSection
          title={'Nosotros'}
          height={'30vh'}
          description={'Home / Nosotros'}
          heroImage={mainBanner}
        />

        <div className="new-container">
          <AboutUs1
            // smallHeading={'This is small heading Text'}
            // boldHeading={'This is bold heading Text'}
            aboutUsBody={operator.about_us}
            imageSource={singleBusImage}
          />

          <AboutUsIconSection
            smallHeading={'Conoce'}
            boldHeading={'NUESTROS SERVICIOS'}
            iconsAndTexts={teupalIconsAndTexts}
          />


          {/* <OfficesList officeLocations={officeLocations} image={doubleBusImage} operator={operator} /> */}
        </div>
        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
};


export default connectData()(TepualAboutUs);
