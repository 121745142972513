import React from 'react';
import { connectData } from '../../../redux';
import { menuController } from '@ionic/core';

const Header6 = ({ showBackground = true, data }) => {
	const operator = JSON.parse(localStorage.getItem('op')) || data.operator;

	return (
		<div
			className="themes-header home-container"
			style={{
				height: '70px',
				background:
					showBackground &&
						operator &&
						operator.color_codes &&
						operator.color_codes.header_color
						? operator.color_codes.header_color
						: '',
			}}
		>
			<div onClick={() => menuController.open()}>
				<img
					className="qr-back-arrow"
					src="../../assets/carpool/menu-dot.png"
					alt=""
				/>
			</div>
			<div className="new-header_logo">
				<a href="/">
					<img
						style={{
							width: '235px',
							// height: 50,
							height: 'auto',
						}}
						src={
							operator && operator.header_logo
							// getIcons('headerLogo')
						}
						alt=""
						id="logo"
					/>
				</a>
			</div>
			<div>&nbsp;</div>
		</div>
	);
};

export default connectData()(Header6);
