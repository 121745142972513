import React from 'react';

const HeroSection = (props) => {
	console.log("🚀 ~ HeroSection ~ props:", props)
	return (
		<div style={{ height: props.height || '50vh' }}>
			<div className='hero-section-container'>
				<div className='hero-image-container'>
					<img
						src={props.heroImage}
						alt='hero-view'
						style={{ objectFit: 'cover', width: '100%' }}
					/>
				</div>

				{props.title && (
					<div className='hero-overlap'>
						<div className='punch-line'>
							<div className='punch-line-container' style={{ textAlign: 'center' }}>
								<span className={props.operator && props.operator.operator_name === "busnorte" ? "font26 bold-text general-text white-text new-bold-font" : "font26 bold-text general-text white-text"}>

									{props.title}
								</span>
							</div>

							{props.description && (
								<div className='baseline avenir-semi'>
									<span className={props.operator && props.operator.operator_name === "busnorte" ? "font14 general-text white-text new-bold-font" : "font14 general-text white-text"}>

										{props.description}
									</span>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default HeroSection;
