import React, { Component, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { connectData } from '../../../redux';
import { AppData } from '../../../services';
import './officesList.css'




const services = [
  { name: 'Santiago' },
  { name: 'Talc' },
  { name: 'Temocu' },
  { name: 'Puerto montt' },
  { name: 'Osorno' },
  { name: 'Calbuco' },
];




export const OfficesList = props => {
  const [selectedOffice, setSelectedOffice] = useState(props.officeLocations[0]);

  const renderOpertaorServices = () => {
    return services.map(service => {
      return (
        <div className="service-item lh-1-3">
          <div style={{ height: 2, width: 8, backgroundColor: props.operator && props.operator.theme_color2 ? props.operator.theme_color2 : '', marginRight: 5 }}></div>
          <span className="font11">{service.name}</span>
        </div>
      );
    });
  };

  return (
    <div className="themes-offices">
      <div className="new-container" style={{ paddingLeft: 20, paddingRight:20 }}>
        <div className="capitalize font10 less-bold-text mb10">conoce</div>
        <div className="capitalize font13 bold-text mb5 black-text-color" style={{marginBottom:10}}>nuestras oficinas</div>
        <div className="offices">

          <ul className="office__list">
            {props.officeLocations.map((item, index) => (
              // <>
              <li
                onClick={() => setSelectedOffice(item)}
                className={`cursor-pointer  office__item
                                        ${index % 2 === 0 ? 'background-light-green' : 'background-dark-green'}
                                        ${item.city === selectedOffice.city && 'selected-location'}
                                   `}
              >
                <span className="office__name font18">{item.city}</span>
              </li>
              // </>
            ))}
          </ul>
          <div className="office__map">
            <div className="office__map-view">
              {props.image ? (
                <img src={props.image} className="about-service-image" />
              ) : (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: AppData.MAP_API_KEY }}
                  defaultCenter={{
                    lat: parseFloat(selectedOffice.latitude),
                    lng: parseFloat(selectedOffice.longitude),
                  }}
                  defaultZoom={11}
                >
                  {/* <AnyReactComponent lat={59.955413} lng={30.337844} text={'Kreyser Avrora'} /> */}
                </GoogleMapReact>
              )}
            </div>
            <div className="office__map-details">
              <div className="office__map-details-text">
                <span className="selected-location-name">{selectedOffice.city}</span>
                {!props.image ? (
                  <div>
                    <span className="selected-location-contact-no font14">{selectedOffice.phone}</span>
                    <br />
                    <span className="selected-location-address font14">{selectedOffice.address}</span>
                  </div>
                ) : (
                  renderOpertaorServices()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
// function mapStateToProps(state) {
//   return {
//     themes: state.themes,
//   };
// }

/* istanbul ignore next */
// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators({ ...actions }, dispatch),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(OfficesList);

export default connectData()(OfficesList);
