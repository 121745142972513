import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeroSection from '../../common/HeroSection/HeroSection';
import MultiSlider from '../../common/MultiSlider/MultiSlider';
import getIcons from '../../functions/getIcons/getIcons';
import { connectData } from '../../../redux';
import getSlides from '../../functions/getSlides';
import { AndimarTitle } from '../../common/Title/AndimarTitle';
import Footer1 from '../../common/footer/Footer1';
import Header1 from '../../common/header/Header1';
import Header from '../../common/header/Header';
import MultiSlider3 from '../../common/MultiSlider/MultiSlider3';

const destinationBanner = '/assets/andimar/destinations/destino_banner.jpg';

const AndimarDestinations = () => {
  const operator = JSON.parse(localStorage.getItem('op'));

  return (
    <IonPage>
      <IonContent>
        {/* <div style={{ position: 'absolute', width: '100vw', zIndex: 1000 }}>
          <Header1 />
        </div> */}
        <Header />
        <HeroSection
          heroImage={destinationBanner}
          // height={'60vh'}
          height={'45vh'}
          title={'DESTINOS'}
          description={'Home / Destinos'}
        />
        <AndimarTitle title={'  DESTINOS  '} />
        <MultiSlider3
          // smallHeading="Conoce"
          // boldHeading="GALERIA"
          multiSlides={getSlides('Destination')}
          andrimarArrowStyle
          width={'80%'}
        />

        <div style={{ marginTop: '50px' }}></div>

        <Footer1 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(AndimarDestinations);
