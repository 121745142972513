import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenTnCScreen.css';

const ThemeSevenTnCScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;
  return (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-tn-c-screen '>
          <HeroSection
            height='300px'
            title={'Viajero frecuente'}
            heroImage={getIcons('tncBanner', operator)}
          />

          <div class='terms new-container'>
            <h3>
              <span>Términos y condiciones</span>
            </h3>
            <div class='sub-terms'>
              <h5>
                <span>Artículo 10 viajero frecuente</span>
              </h5>
              <p>
                El Programa Viajero Frecuente, en adelante “El Programa”, es
                desarrollado por Buses Fernández Ltda., en adelante “La Empresa”
                en virtud del cual las compras de pasajes efectuadas por los
                socios del programa Viajero Frecuente, acumularán kilómetros una
                cuenta personal e intransferible, en adelante “La Cuenta”, los
                que podrán ser canjeados acorde al cumplimiento de las
                condiciones contenidas en el presente instrumento. El canje de
                kilómetros por pasajes que otorga el Programa Viajero Frecuente,
                constituyen una mera liberalidad de La Empresa.
              </p>
              <h5>
                <span>Artículo 20 personas que pueden participar</span>
              </h5>
              <p class='bold'>Socio del programa Viajero Frecuente, es:</p>
              <p>
                <span class='bold'>a) </span>Persona natural mayor de 18 años,
                que cumpla con todos los requisitos del programa. Este programa
                no está dirigido a trabajadores de Buses Fernandez Ltda.,
                empresas, sociedades, o algún Rut identificado con un giro
                comercial.
              </p>
              <p>
                <span class='bold'>b) </span>Que complete, el formulario de
                incorporación en las condiciones y términos de este Reglamento.
              </p>
              <p>
                <span class='bold'>c) </span>Que su calidad de socio no haya
                sido revocada o dejada sin efecto conforme a las normas del
                presente Reglamento.
              </p>
              <p>
                <span class='bold'>d) </span>Adicionales menores de 18 años
                (hijos del socio).
              </p>{' '}
              <br />
              <p>
                El formulario de solicitud de incorporación al Programa, solo se
                encuentra a disposición del público en las agencias tanto de
                Punta Arenas, como de Puerto Natales.
              </p>
              <p>
                Viajero Frecuente, se reserva el derecho de revisar y/o
                comprobar la veracidad de la información proporcionada por el
                socio, cualquiera que haya sido el mecanismo de inscripción
                utilizado. La Empresa, en cualquier tiempo, incluso luego de
                verificada la inscripción, podrá dejarla sin efecto o ponerle
                término por inexactitud o falsedad de los antecedentes
                entregados o aportados por el socio. Los datos entregados en la
                solicitud de incorporación, son de exclusiva responsabilidad del
                socio. Cualquier cambio de los datos entregados, incluyendo la
                actualización de datos, deberá solicitarse en las oficinas de
                Buses Fernández Ltda. No se aceptarán solicitudes de cambios por
                otro medio.
              </p>
              <h5>
                <span>Artículo 30 costo tarjeta</span>
              </h5>
              <p>
                Al solicitar ser socio Viajero Frecuente, se cobrará un monto de
                $3.000, valor que corresponde al costo de entrega de la tarjeta,
                en caso que el socio requiera de una nueva tarjeta por extravío
                de esta, tendrá que pagar nuevamente el importe indicado
                anteriormente. No hay monto de mantención.
              </p>
              <h5>
                <span>Artículo 40 comunicación</span>
              </h5>
              <p>
                La forma y periodicidad de envío de información de beneficios a
                los socios, y demás comunicaciones, la determinará libremente La
                Empresa; pudiendo utilizar med/ios físicos (correo público o
                privado), o electrónicos (tales como cuenta e-mail, SMS u
                otros). La Empresa entregará información relativa a promociones,
                canje de kilómetros y otros, a través de su página
                www.busesfernandez.com. Por el hecho de firmar el formulario de
                Incorporación al Programa Viajero Frecuente, y/o utilizar otros
                medios que incorpore la Empresa para acceder al Programa, el
                socio acepta recibir en su domicilio y/o en el e-mail o celular
                de contacto entregado por él, información de beneficios y demás
                comunicaciones que La Empresa envíe periódicamente, sea que se
                relacionen o no con el programa Viajero Frecuente. Sin perjuicio
                de lo señalado precedentemente, por el hecho de firmar el
                formulario de Incorporación o incorporarse al Programa a través
                de algún otro medio que defina la Empresa, el socio acepta y
                autoriza que ésta pueda disponer libremente de los datos
                contenidos en el formulario o entregados al momento de solicitar
                su incorporación,sin perjuicio de lo dispuesto en las leyes
                aplicables sobre protección de la vida privada.
              </p>
              <h5>
                <span>Artículo 50 kilómetros</span>
              </h5>
              <p class='bold'>
                La unidad de medida del Programa son Kilómetros. Los kilómetros
                presentan las siguientes características y están sujetos a las
                siguientes condiciones:
              </p>
              <p>
                <span class='bold'>a) </span>Son intransmisibles. En caso de
                fallecimiento del socio, la cuenta del titular quedará
                inhabilitada para efectuar canjes y se cerrará con cero
                kilómetros.
              </p>
              <p>
                <span class='bold'>b) </span>Son intransferibles. En caso de
                cierre de la cuenta, los kilómetros acumulados en una cuenta no
                podrán ser transferidos a otra. La cuenta del titular quedará
                inhabilitada para efectuar canjes y se cerrará con cero
                kilómetros, por lo que el cliente deberá cobrar los kilómetros
                con anterioridad al cierre de su cuenta.
              </p>
              <p>
                <span class='bold'>c) </span>No son canjeables por dinero.
              </p>
              <p>
                <span class='bold'>d) </span>Los kilómetros solo se acumularan
                para los tramos de Punta Arenas a Puerto Natales y viceversa.
              </p>
              <h5>
                <span>Artículo 60 acumulación de kilómetros</span>
              </h5>
              <p class='bold'>
                Es condición esencial para la participación en el Programa, el
                hecho que la acumulación de kilómetros se efectúe de acuerdo con
                los términos y condiciones de este Programa y en pleno
                cumplimiento de la normativa que sea aplicable. La acumulación
                de los kilómetros en la cuenta del Socio se efectuará respetando
                las siguientes reglas:
              </p>
              <p>
                <span class='bold'>a) </span>Cada compra de pasajes efectuada
                por un socio en Viajero Frecuente, otorgará al primero una
                cantidad de kilómetros que ingresarán a su cuenta, la que se
                encuentra asociada al folio de su tarjeta.
              </p>
              <p>
                <span class='bold'>b) </span>Las compras efectuadas a través de
                internet, acumulan kilómetros al identificarse el socio como
                tal, para lo cual debe tener registrado y actualizado su mail en
                la base de datos de Viajero Frecuente.
              </p>
              <p>
                <span class='bold'>c) </span>Los pasajes comprados en ruta, no
                acumulan kilómetros.
              </p>
              <p>
                <span class='bold'>d) </span>Los errores de acumulación que se
                incorporen en la cuenta del cliente en cuanto a la cantidad de
                kilómetros acumulados y que sean detectados por el cliente o por
                la empresa, serán analizados y en caso que corresponda serán
                corregidos por los medios que sean adecuados en un plazo de 20
                días hábiles, sin que por ello pueda surgir algún tipo de
                responsabilidad para Viajero Frecuente. Será de exclusiva
                responsabilidad del socio verificar que los kilómetros
                correspondientes a sus compras están acreditados correctamente
                en su cuenta. Para esto, el socio deberá guardar todos sus
                documentos de viaje a fin de poder reclamar una acreditación
                retroactiva. El plazo para reclamar por la no acreditación de
                kilómetros en la cuenta, es de 30 días corridos de realizado el
                servicio a través de los canales habilitados para atención de
                clientes.
              </p>
              <p>
                <span class='bold'>e) </span>El actual Programa puede ser
                ampliado o restringido en cualquier momento, con respecto al
                ámbito de su aplicación. La Empresa comunicará a los Clientes
                estas modificaciones por los medios que estime más conveniente.
              </p>
              <p>
                <span class='bold'>f) </span>Se acumularán 250 kilómetros por
                compra de pasajes.
              </p>
              <p>
                <span class='bold'>g) </span>Al inscribirse como Viajero
                Frecuente, obtendrá inmediatamente sin la necesidad de comprar
                pasajes 250 kilómetros.
              </p>
              <p>
                <span class='bold'>h) </span>Para el cumpleaños del socio
                Viajero Frecuente la empresa le regalará 250 kilómetros.
              </p>
              <p>
                <span class='bold'>i) </span>El descuento y acumulación
                kilómetros no son acumulables con promociones y precios
                preferenciales.
              </p>
              <p>
                <span class='bold'>j) </span>Consultas y dudas sobre los
                kilómetros acumulados, al correo
                viajerofrecuente@busesfernandez.com.
              </p>
              <h5>
                <span>Artículo 70 vigencia de los kilómetros</span>
              </h5>
              <p>
                Los kilómetros obtenidos por los socios en cada compra
                realizada, tendrán una vigencia de 24 meses, contado desde la
                fecha en que realizó el servicio. Los kilómetros no canjeados al
                finalizar dicho periodo caducarán y serán descontados de la
                cuenta. No es responsabilidad de la empresa informar la fecha de
                expiración de los kilómetros. No acumulan kilómetros los boletos
                emitidos bajo la modalidad de canje.
              </p>
              <h5>
                <span>
                  Artículo 80 clientes habilitados para efectuar canjes
                </span>
              </h5>
              <p>
                Sujeto al cumplimiento de los procedimientos consignados en el
                presente Reglamento, los socios que podrán efectuar canjes de
                pasajes con cargo a sus kilómetros serán los siguientes: Todo
                socio que no se encuentre Inhabilitado o con cuenta cerrada por
                los distintos motivos que se exponen en estas bases, solo el
                titular de la cuenta puede canjear. Cada 12.000 kilómetros
                acumulados,tendrá derecho de solicitar el canje por un pasaje (1
                tramo).
              </p>
              <h5>
                <span>
                  Artículo 90 clientes inhabilitados para efectuar canjes
                </span>
              </h5>
              <p class='bold'>
                Los socios que no podrán efectuar canjes de pasajes con cargo a
                sus kilómetros serán los siguientes:
              </p>
              <p>
                <span class='bold'>a) </span>Socio con cuenta Inhabilitada.
              </p>
              <p>
                <span class='bold'>b) </span>Funcionarios de Buses Fernández
                Ltda.
              </p>
              <p>
                <span class='bold'>c) </span>Socio con cuenta cerrada.
              </p>
              <p>
                <span class='bold'>d) </span>Si el socio deja de cumplir
                cualquiera de los términos y condiciones aquí referidos, o si el
                uso del Programa o si la acumulación de kilómetros se realiza
                con infracción a la ley o normativa aplicables.
              </p>
              <p>
                <span class='bold'>e) </span>Si el socio, a sabiendas, entrega
                cualquier información incorrecta o errónea, ya sea en la
                solicitud de incorporación o al momento de solicitar canje de
                kilómetros o al solicitar beneficios con los Participantes.
              </p>
              <p>
                <span class='bold'>f) </span>Si el socio hace un uso inadecuado
                o impropio de los kilómetros y / o beneficios entregados por los
                Participantes.
              </p>
              <p>
                <span class='bold'>g) </span>Por petición expresa del socio, lo
                que puede hacer en cualquier momento, por escrito. Por
                fallecimiento del socio.
              </p>
              <p>
                <span class='bold'>h) </span>En todos aquellos casos en que a
                juicio de La Empresa el socio ha cometido un ilícito o una
                irregularidad contraria a la naturaleza propia del reglamento.
              </p>
              <p>
                <span class='bold'>i) </span>En caso de término de la
                participación en el Programa o de término del mismo, cualquiera
                sea la causa, todos los kilómetros acumulados serán cancelados y
                no podrán ser utilizados bajo ningún concepto, ni podrán ser
                canjeados por boletos.
              </p>
              <h5>
                <span>Artículo 100 procedimiento de canje de kilómetros</span>
              </h5>
              <p>
                Para solicitar un canje de Kilómetros, el Socio deberá hacerlo
                en las Oficinas de Buses Fernández Ltda., El canje es nominativo
                para lo cual deberá exhibir su cédula de identidad y Tarjeta de
                Viajero Frecuente, que acredite la identidad del socio. La
                solicitud de un canje de kilómetros podrá hacerla una persona
                distinta del socio respectivo, presentando un poder debidamente
                suscrito por él ante Notario Público, indicando: nombre completo
                del Socio, número de cédula nacional de identidad y
                especificación del servicio que solicita.
              </p>
              <h5>
                <span>Artículo 110 identificación de socio</span>
              </h5>
              <p>
                Bastará para identificarse como socio Viajero Frecuente,
                presentando la cédula de identidad y la Tarjeta de Viajero
                Frecuente. El pasaje que sea comprado a través del programa, se
                confirme en forma inmediata o no, deberá quedar registrado como
                pasajero, el socio o sus adicionales menores de 18 años (hijos).
              </p>
              <h5>
                <span>Artículo 120 confirmación boletos socios</span>
              </h5>
              <p>
                No está permitida la emisión de pasajes abiertos, es decir, sin
                fecha de salida o llegada. El pasaje que se canjee debe tener
                indicada la fecha de salida y de llegada, la hora de inicio del
                servicio y el asiento. Será de exclusiva responsabilidad del
                Socio informarse adecuadamente sobre todas las restricciones en
                la solicitud y el uso de los pasajes a la fecha de su
                utilización.
              </p>
              <h5>
                <span>Artículo 130 sanciones</span>
              </h5>
              <p>
                El abuso, uso indebido de los elementos del Programa, el
                incumplimiento de lo establecido en el presente Reglamento o
                cualquier acción u omisión que implique algún tipo de fraude en
                contra de Buses Fernández Ltda., o uno de los Participantes,
                producirá la inhabilitación para acumular kilómetros, efectuar
                canjes, el cierre de la cuenta y/ o la expulsión inmediata del
                socio del Programa, sin perjuicio del ejercicio de las acciones
                legales que pudieren corresponder.
              </p>
              <h5>
                <span>Artículo 140 modificaciones</span>
              </h5>
              <p>
                La participación en el Programa Viajero Frecuente está sujeta a
                los términos y condiciones aquí establecidas, las que pueden
                cambiar, periódicamente, a sola discreción de la Empresa. Todo
                cambio en los términos y condiciones será de observación
                obligatoria para los socios a los 30 días corridos contados a
                partir de la fecha en que se haya publicado el nuevo texto de
                este reglamento en la página web www.busesfernandez.com, lo que
                también podrá informarse a través de los medios generales de
                comunicación que utiliza la Empresa. Esto incluye el derecho a
                modificar el sistema de acumulación de kilómetros, la
                participación de otras empresas en el Programa, suspender el
                Programa Viajero frecuente, o modificar cualquier parte del
                mismo, incluyendo toda clase de mecanismo de canjes y/ o
                Beneficios.
              </p>
              <h5>
                <span>
                  Artículo 150 término del programa por parte de la empresa
                </span>
              </h5>
              <p>
                La Empresa podrá poner término al Programa en cualquier momento,
                con o sin expresión de causa, bastando para ello dar aviso a sus
                socios de tal situación, a través de los medios que estime
                pertinente y/o a través de los medios generales de comunicación
                que utiliza. La Empresa informará a los socios del plazo de que
                dispondrán para proceder al canje de los kilómetros que tuvieron
                acumulados a esa fecha. Si no se cobra en ese plazo, la cuenta
                se cierra con cero kilómetros, sin derecho a reclamo ni
                indemnización alguna. Sin perjuicio de lo anterior, en caso de
                que el motivo del término del programa se deba al cese de las
                operaciones de Buses Fernández Ltda.,, como empresa de
                transportes, o a la declaración de quiebra o celebración de
                convenio, la Empresa podrá terminar o suspender el Programa
                Viajero Frecuente, cancelar y dejar sin valor alguno todos los
                puntos y beneficios de inmediato, sin notificación previa a los
                socios.
              </p>
              <h5>
                <span>Artículo 160 renuncia</span>
              </h5>
              <p>
                Todo Cliente que quiera renunciar al presente Programa de
                Fidelidad, podrá hacerlo informando expresamente a la Empresa de
                tal decisión a través los canales de atención a clientes
                disponibles en el momento. En este caso, la cuenta se cerrará
                con cero kilómetros, guardando registro de la identificación del
                Cliente.
              </p>
              <h5>
                <span>Artículo 170 generalidades</span>
              </h5>
              <p>
                La Empresa no será responsable, en ningún caso, por pérdidas o
                daños que pudieran resultar de la conclusión del Programa
                Viajero Frecuente o cambios o modificaciones del mismo. La
                empresa es la única responsable por la interpretación y
                aplicación de los presentes términos y condiciones. Los
                presentes términos y condiciones, se rigen por las leyes de la
                República de Chile y, cualquier dificultad, contienda o litigio
                que suscite su aplicación, cumplimiento, interpretación o
                validez, será resuelta por sus Tribunales Ordinarios de
                Justicia.
              </p>
              <h5>
                <span>Artículo 180 canjes irregulares de kilómetros</span>
              </h5>
              <p class='bold'>Se considerará como canje irregular:</p>
              <p>
                Pasajero que intentó reiteradamente realizar más de dos canjes
                diarios esto en un mismo o diferente kilómetros de venta Buses
                Fernández Ltda. Cajero que intente reiteradamente realizar más
                de dos canjes diarios asociados a un número Rut único.
              </p>
              <h5>
                <span>Artículo 190 vigencia</span>
              </h5>
              <p>
                El presente Reglamento, será de duración indefinida, y entrará
                en vigencia a contar del 26 de Octubre de 2015.
              </p>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeSevenTnCScreen);
