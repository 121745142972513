import './PullmanSanAndreasAbout.css';

import { IonContent, IonPage } from '@ionic/react';

import Footer6 from '../common/footer/Footer6';
import Header6 from '../common/header/Header6';
import PullmanBanners from './components/PullmanBanners';
import React from 'react';
import ThemeSixSlides from './components/ThemeSixSlides';
import { connectData } from '../../redux';
import getIcons from '../functions/getIcons/getIcons';
import getSlides from '../functions/getSlides';
import ThemeSixHeader from './components/ThemeSixHeader';

function PullmanSanAndreasAbout(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;
  return (
    <IonPage>
      <IonContent>
        <ThemeSixHeader history={props.history} operator={operator} />
        <div className='pullman-san-andreas-about'>
          <PullmanBanners
            bgImage={getIcons('aboutBanner')}
            text={'Nosotros'}
            textPosition={'center'}
          />
          <div className='new-container'>
            <h2 className='black-text text-center about-us-heading-main font-italic font-22px'>
              ¿Quiénes somos?
            </h2>
            <div className='about-us-text font-14px'>
              <p>
                Somos una empresa de servicios dedicada a brindarte una
                experiencia cómoda en todo momento. Queremos entregar un
                servicio de calidad a través de nuestra flota y personal
                completamente preparados para tu satisfacción. A través de
                nuestros servicios deseamos ganar la verdadera confianza y
                confort de nuestros clientes.
              </p>
              <p>
                Contamos con más de 10 años de experiencia en el rubro en la
                primera Región, ampliando nuestras fronteras hacia otras
                regiones del Norte del País. Actualmente disponemos de una flota
                de equipos capaz de cubrir todas las necesidades para el
                traslado de su capital humano. Poseemos altos estándares de
                seguridad y calidad en nuestros equipos responsabilidad con el
                medio ambiente y el inigualable compromiso de nuestro personal,
                los que ascienden a más de 300 trabajadores, lo que hace a
                Origen San Andrés una empresa de excelencia.
              </p>
              <p>
                Nuestra organización se compromete a desarrollar únicamente los
                servicios que podemos realizar satisfactoriamente, de forma que
                todo el personal de Origen San Andrés sea capaz de generar la
                confianza necesaria en el cliente.
              </p>
            </div>
            <h2 className='black-text text-center about-us-heading font-italic font-22px'>
              Misión
            </h2>
            <div className='about-us-text font-14px'>
              <p>
                Para el logro de nuestras metas como empresa integraremos
                profesionales calificados para desarrollar una estructura
                organizacional sólida y con proyección de crecimiento capaz de
                abordar proyectos de gran envergadura, ocupándonos de las
                necesidades de nuestros clientes y de cada persona que integra
                esta empresa, con un plan de trabajo basado en el bienestar,
                seguridad y el medio ambiente.
              </p>
              <p>
                La gerencia mantiene una preocupación por entender y saber las
                necesidades de nuestros clientes para desarrollar alternativas
                sustentables económicamente y con mejoras en la entrega del
                servicio.
              </p>
              <p>
                Desarrollaremos un área para la atención de las personas que
                forman parte de esta empresa con un plan de trabajo enfocado a
                sensibilizar, entregar las mejores condiciones y ambiente
                laboral.
              </p>
            </div>

            <h2 className='black-text text-center about-us-heading font-italic font-22px'>
              Visión
            </h2>
            <div className='about-us-text font-14px'>
              <p>
                Nuestra visión como empresa, es ser la más grande de la región
                manteniendo y mejorando nuestra calidad y prestigio en la
                entrega de nuestros servicios, con el más alto respeto y
                compromiso con la vida de las personas, seguridad y medio
                ambiente.
              </p>
              <br />
              <p>
                Siempre innovando en la manera y costumbre de hacer negocios,
                buscando relaciones comerciales duraderas en el tiempo, lo que
                nos permitirá desarrollar un ambiente laboral de excelencia,
                donde cada persona se sienta orgullosa de pertenecer a esta
                empresa.
              </p>
            </div>
            <h3
              className='bold-text text-center font-italic font-24px margin-0 padding-x-10px'
              style={{
                color: operator && operator.theme_color && operator.theme_color,
              }}
            >
              Empresas que confían en nosotros
            </h3>
            <p className='bottom-para text-center font-italic font-18px margin-0'>
              sé parte de <span className='bold-text'>Pullman San Andrés</span>
            </p>
            <ThemeSixSlides
              slides={getSlides('Comapanies', operator)}
              noOfIcons={1}
            />
          </div>
        </div>

        <Footer6 />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(PullmanSanAndreasAbout);
