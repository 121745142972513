import { IonContent, IonPage } from '@ionic/react';

// import Header from '../common/header/Header';
// import HeroSection from '../common/HeroSection/HeroSection';
// import KuposHeader from '../../components/KuposHeader';
import React from 'react';
import TarapacaHeader from './components/TarapacaHeader';
import TarapacaServices from './common/TarapacaServices';
import { connectData } from '../../redux';

// import getIcons from '../functions/getIcons/getIcons';

const TarapacaServiceScreen = ({ props, history }) => {
	// const operator = CommonService.getLocal('op') || props.data.operator;
	return (
		<IonPage>
			<IonContent>
				<TarapacaHeader
					title={"Nuestros"}
					boldTitle={"servicios"}
					handleHomeSubmit={() => history.push("/")}
					handleBackSubmit={() => history.goBack()}
				/>
				<div className="new-container">





					<TarapacaServices />
				</div>

			</IonContent>
		</IonPage>
	);
};

export default connectData()(TarapacaServiceScreen);
