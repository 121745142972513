import React from "react";
import getIcons from "../themes/functions/getIcons/getIcons";

const PBCarpoolBG = props => {
  const operator =
    JSON.parse(localStorage.getItem("op"))
  // const operator = JSON.parse(localStorage.getItem('op'));
  return props.short ? (
    <img
      // src={'/assets/carpool/bgs/home_short.png'}

      className="main-bg-short"
      alt=""
      src={getIcons("backimage")}
    />
  ) : // <img src={operator ? getIcons('BG') : "/assets/carpool/bgs/main.webp"} className="mainBgImg" />
  // operator && operator.operator_name === "busnorte" ? (
  //   <div></div>
  // ) : (
    <img
      src={getIcons("CentroBanner")}
      // src={operator ?  : "/assets/carpool/bgs/main.webp"}
      className="mainBgImg"
      alt=""
      src={getIcons("BG")}
    />
  // );
};

// const styles = {
//     mainBgImg:{
//         width:dimensions.vw * 100,height:dimensions.vh*30,resizeMode:'stretch',position:'absolute',
//     }
// }

export default PBCarpoolBG;
