import { IonSpinner } from '@ionic/react';
import React from 'react';
import { connectData } from '../redux';

const KuposTabs = ({ tabs, onTabChange, selectedTab, operator, width }) => {
	const styles = {
		tabPadding: {
			paddingTop: 15,
			paddingBottom: 15,
			width: width,
			paddingLeft: 10,
			paddingRight: 10,
		},
		active: {
			backgroundColor: '#fff',
			borderRadius: '75px',
			textAlign: 'center',
		},
	};

	if (operator.operator_name === 'busnorte') {
		return (
			<div className='recharge-tabs-container avenir-semi nortestyles'>
				<div
					className='map-container-selector font11'
					style={{
						marginTop: 0,
						background:
							operator && operator.theme_color ? operator.theme_color : '',
						color: '#fff',
					}}
				>
					{tabs.map((tab, key) => {
						return (
							<div
								className={
									'selector-item ' + (selectedTab == key + 1 ? 'active' : '')
								}
								onClick={() => onTabChange(key + 1)}
								style={styles.tabPadding}
							>
								{tab}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
	else {
		return (
			<div className='recharge-tabs-container avenir-semi'>
				<div
					className='map-container-selector font11'
					style={{
						marginTop: 0,
						background:
							operator && operator.theme_color ? operator.theme_color : '',
						color: '#fff',
					}}
				>
					{tabs.map((tab, key) => {
						return (
							<div
								className={
									'selector-item ' + (selectedTab == key + 1 ? 'active' : '')
								}
								onClick={() => onTabChange(key + 1)}
								style={styles.tabPadding}
							>
								{tab}
							</div>
						);
					})}
				</div>
			</div>
		);
	}

};

export default connectData()(KuposTabs);
