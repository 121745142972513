import './PullmanSanAndreasFleet.css';

import { IonContent, IonPage } from '@ionic/react';

import Footer6 from '../common/footer/Footer6';
import Header6 from '../common/header/Header6';
import PullmanBanners from './components/PullmanBanners';
import React from 'react';
import { connect } from 'react-redux';
import { connectData } from '../../redux';
import getIcons from '../functions/getIcons/getIcons';
import ThemeSixHeader from './components/ThemeSixHeader';

// import Header6 from '../common/header/Header6';

// import CommonService from '../../services/commonService';

const backgroundDesign = '/assets/pullmanSanAndres/design.svg';

// SUP IMAGES
const BusItemSupImage1 = '/assets/pullmanSanAndres/marcopolo.png';
const BusItemSupImage2 = '/assets/pullmanSanAndres/taxi-buses.png';
const BusItemSupImage3 = '/assets/pullmanSanAndres/sprinter.png';
const BusItemSupImage8 = '/assets/pullmanSanAndres/van.png';

// SUB IMAGES
const BusItemSubImage1 = '/assets/pullmanSanAndres/5stars.svg';
const BusItemSubImage2 = '/assets/pullmanSanAndres/seatbelt.svg';
const BusItemSubImage3 = '/assets/pullmanSanAndres/benz-logo.svg';
const BusItemSubImage4 = '/assets/pullmanSanAndres/marcopolo-logo.svg';
const BusItemSubImage5 = '/assets/pullmanSanAndres/modasalogo.png';
const BusItemSubImage6 = '/assets/pullmanSanAndres/scania-logo.svg';
const BusItemSubImage7 = '/assets/pullmanSanAndres/buscarlogo.svg';

// Equipment Icons
const equipmentIcons1 = '/assets/pullmanSanAndres/equipments/seat-belt.svg';
const equipmentIcons2 = '/assets/pullmanSanAndres/equipments/gps.svg';
const equipmentIcons3 = '/assets/pullmanSanAndres/equipments/ac.svg';
const equipmentIcons4 = '/assets/pullmanSanAndres/equipments/bathroom.svg';
const equipmentIcons5 = '/assets/pullmanSanAndres/equipments/usb.svg';
const equipmentIcons6 = '/assets/pullmanSanAndres/equipments/minesite.svg';

const BottomBanner = '/assets/pullmanSanAndres/banner2.png';

const equipmentIcons = [
  equipmentIcons1,
  equipmentIcons2,
  equipmentIcons3,
  equipmentIcons4,
  equipmentIcons5,
  equipmentIcons6,
];

const busesDetails = [
  {
    name: 'Mercedes Benz / O500 RSD2441 Euro V',
    supImage: BusItemSupImage1,
    subImage: [
      BusItemSubImage1,
      BusItemSubImage2,
      BusItemSubImage3,
      BusItemSubImage4,
    ],
    details: [
      { key: 'Buses tipo Mixto', value: '' },
      { key: 'Marca carrosería:', value: 'Marcopolo' },
      { key: 'Modelo:', value: 'Paradiso 1800 DD' },
      { key: 'Capacidad:', value: '60 Pasajeros' },
      { key: 'Salón Cama:', value: '12 Disponibles' },
      { key: 'Semi-Cama:', value: '48 Disponibles' },
      { key: 'Año de Fabricación:', value: '2018' },
      { key: 'Cantidad disponible:', value: '25 Vehículos' },
    ],
  },
  {
    name: 'Mercedes Benz / O500 RSD2441 Euro V',
    supImage: BusItemSupImage1,
    subImage: [
      BusItemSubImage1,
      BusItemSubImage2,
      BusItemSubImage3,
      BusItemSubImage5,
    ],
    details: [
      { key: 'Buses tipo Cama', value: '' },
      { key: 'Marca carrosería:', value: 'Modasa' },
      { key: 'Modelo:', value: 'Zeus III' },
      { key: 'Capacidad:', value: '43 Pasajeros' },
      { key: 'Salón Cama:', value: '43 Disponibles' },
      { key: 'Semi-Cama:', value: '48 Disponibles' },
      { key: 'Año de Fabricación:', value: '2018' },
      { key: 'Cantidad disponible:', value: '20 Vehículos' },
    ],
  },
  {
    name: 'Scania / K 360 Euro V',
    supImage: BusItemSupImage1,
    subImage: [
      BusItemSubImage1,
      BusItemSubImage2,
      BusItemSubImage6,
      BusItemSubImage7,
    ],
    details: [
      { key: 'Buses Semi Cama', value: '' },
      { key: 'Marca carrosería:', value: 'Busscar' },
      { key: 'Modelo:', value: 'Vissta Bus 340' },
      { key: 'Capacidad:', value: '42 Pasajeros' },
      { key: 'Semi-Cama:', value: '42 Disponibles' },
      { key: 'Año de Fabricación:', value: '2015-2019' },
      { key: 'Cantidad disponible:', value: '17 Vehículos' },
    ],
  },
  {
    name: 'TAXIBUSES',
    supImage: BusItemSupImage2,
    details: [
      { key: 'Marca carrosería:', value: 'Mercedes Benz' },
      { key: 'Modelo:', value: 'LO0916' },
      { key: 'Marca chasis:', value: 'Marcopolo' },
      { key: 'Modelo:', value: 'Senior' },
      { key: 'Capacidad:', value: '24 Pasajeros' },
      { key: 'Año de Fabricación:', value: '2015-2019' },
      { key: 'Cantidad disponible:', value: '18 Vehículos' },
    ],
  },
  {
    name: 'SPRINTER',
    supImage: BusItemSupImage3,
    details: [
      { key: 'Marca carrosería:', value: 'Sprinter' },
      { key: 'Modelo:', value: '513' },
      { key: 'Marca chasis:', value: 'Mercedes Benz' },
      { key: 'Capacidad:', value: '15 Pasajeros' },
      { key: 'Año de Fabricación:', value: '2015-2019' },
      { key: 'Cantidad disponible:', value: '20 Vehículos' },
    ],
  },
  {
    name: 'VAN',
    supImage: BusItemSupImage8,
    details: [
      { key: 'Marca carrosería:', value: 'Mercedes Vito' },
      { key: 'Marca chasis:', value: 'Mercedes Benz' },
      { key: 'Capacidad:', value: '4 Pasajeros' },
      { key: 'Año de Fabricación:', value: '2015-2019' },
      { key: 'Cantidad disponible:', value: '12 Vehículos' },
    ],
  },
];

function PullmanSanAndreasFleet(props) {
  const operator =
    JSON.parse(localStorage.getItem('op')) || props.data.operator;
  console.log('props============', props, operator);

  return (
    <IonPage>
      <IonContent>
        <ThemeSixHeader history={props.history} operator={operator} />

        <div
          className='pullman-san-andreas-fleet'
          style={{
            marginTop: '-70px !important',
          }}
        >
          <PullmanBanners
            bgImage={getIcons('fleetBanner')}
            text={'Nuestra Flota'}
            textPosition={'end'}
          />
          <h1 className='bold-text black-text text-center mt-4 mb-4 font-22px'>
            Conoce nuestros buses
          </h1>
          {busesDetails.map((item, key) => (
            <React.Fragment>
              <div className='new-container mt-4'>
                <div className='buses-items'>
                  <div className='buses-items-left'>
                    <div className='buses-items-left-sup-image'>
                      <img src={item.supImage} alt='' />
                    </div>
                    <div className='buses-items-left-sub-image'>
                      {item.subImage &&
                        item.subImage.map((img) => <img src={img} alt='' />)}
                    </div>
                  </div>
                  <div className='buses-items-right'>
                    <h3 className='bold-text black-text font-20px'>
                      {item.name}
                    </h3>
                    <div className='buses-items-right-details'>
                      {item.details.map((detail) => (
                        <li className='bold-text buses-items-right-details-list font-13px'>
                          <span className='first-span'>{detail.key}</span>
                          <span>{detail.value && detail.value}</span>
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {key !== busesDetails.length - 1 && (
                <div
                  style={{
                    height: 80,
                    background: `url(${backgroundDesign})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: '100%',
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
          <div className='new-container'>
            <h2 className='bold-text black-text text-center font-italic mb-4 font-22px'>
              Equipamiento
            </h2>
            <div className='equipment-list mt-2 mb-4'>
              {equipmentIcons.map((item, key) => (
                <img
                  className='equipment-list-item'
                  src={item}
                  key={key}
                  alt=''
                />
              ))}
            </div>
            <img
              className='mt-4 mb-4 pullman-san-andreas-fleet-bottom-banner'
              src={BottomBanner}
              alt=''
            />
          </div>
        </div>
        <Footer6 />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(PullmanSanAndreasFleet);
