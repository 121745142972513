import React, { PureComponent } from 'react';
// import HTMLdiv from 'react-native-htmlview';

import PBText from './PBText';
import PBModal from './PBModal';
import { commonStyles, dimensions } from '../theme';
// import Modal from "react-native-modal";
// import HTML from 'react-native-render-html';
import PBTouchable from './PBTouchable';
import getIcons from '../themes/functions/getIcons/getIcons';

const bodyText = "Le recordamos que tras realizar una anulación de pasaje es posible que se retenga un porcentaje del total de su devolución. Este porcentaje varía dependiendo de la empresa con la que viaja."

class CancelWarningModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showTnc: false
    }
  }
  tnc = () => {
    // CommonService.goToScreen(this.props.componentId,TNC_SCREEN,'Términos y condiciones')
    // this.props.gotoTNC()
    this.setState({
      showTnc: true
    })
  }

  closeTnc = () => {
    this.setState({
      showTnc: false
    })
  }

  render() {
    return (
      <div>
        <PBModal
          showModal={this.props.showModal}
          titleSize={20}
          titleText="¡Casi listo!"
          type="alert"
          showButton1={true}
          button1Text={"SÍ, CONTINUAR"}
          button1Press={() => this.props.onButtonPress()}
          showButton2={true}
          button2Text={"NO, VOLVER"}
          button2Press={() => this.props.closeModal()}
          onBackButtonPress={() => this.props.closeModal()}
          onBackdropPress={() => this.props.closeModal()}
          // icon={"/assets/icons/circular/warning.png"}
          icon={getIcons('warning')}
          buttonContainerStyle={{ maxWidth: "80%" }}
          buttonTextStyle={{ fontSize: 13 }}
        >
          <div style={styles.bodyText} className="font14 text-center">
            <div className="light-grey-font">
              Por favor recuerda que al cancelar un pasaje, es posible que se
              retenga un porcentaje del total de la devolución, así como otros
              costos administrativos.
            </div>
            <div style={{ marginTop: 20 }} className="light-grey-font">
              Al continuar{" "}
              <span className="bold-text">
                estás aceptando los Términos y Políticas de Cancelación.
              </span>
            </div>
          </div>
        </PBModal>

        {/* <Modal
                style={{ margin: 0 }}
                isVisible={this.state.showTnc}
                onBackButtonPress={this.closeTnc}
                onBackdropPress={this.closeTnc}
                >
                <div style={styles.calendarTopBar}>
                    <div style={styles.calendarTopBarTextContainer}>
                        <PBText style={[styles.calendarTopBarText]}>Términos y condiciones</PBText>
                    </div>
                    <PBTouchable
                        onPress={this.closeTnc} >
                        <div style={styles.calendarTopBarIconContainer}>
                            <img style={styles.calendarTopBarIcon} height={50} src={'../assets/icons/back_button.png'} />
                        </div>  
                    </PBTouchable> 
    
                </div>
                <div style={styles.tncContainer}>
                    <HTML html={this.props.tnc} imagesMaxWidth={dimensions.vw * 100} />
                    <HTMLdiv
                        value={this.props.tnc}
                        stylesheet={htmlStyles} />
                </div>
                
            </Modal> */}
      </div>
    );
  }
}


const styles = {
  bodyText: {
    marginTop: 10,
    alignItems: 'center',
  },
  calendarContainer: {
    flex: 1,
    backgroundColor: '$pageBG',
  },
  calendarTopBar: {
    flexDirection: 'row',
    backgroundColor: '$primaryBG',
    width: '100%',
    height: 54,
    alignItems: 'center'
  },
  calendarTopBarIconContainer: {
    padding: 15
  },
  calendarTopBarTextContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1
  },
  calendarTopBarText: {
    color: '#fff'
  },
  tncContainer: {
    padding: 15,
    flex: 1,
    backgroundColor: '#fff'
  }
}

// const htmlStyles = StyleSheet.create({
//     p: {
//       color:'#777',
//       fontFamily:  'Carnas Regular',// make links coloured pink
//     },
//     a: {
//       color:'#222',
//       fontFamily:'Carnas Regular',// make links coloured pink
//       textDecorationLine: 'underline',
//       fontWeight:'bold'
//     },
//     li: {
//       color:'#777',
//       fontFamily: 'Carnas Regular',// make links coloured pink
//     },
//     div: {
//       color:'#777',
//       fontFamily: 'Carnas Regular',// make links coloured pink
//     },
//   });


export default CancelWarningModal;