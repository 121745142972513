import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import { PBModal } from '../../../components';
import './ThemeSevenSlider.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ThemeSevenSlider = (props) => {
  const [state, setState] = useState('');
  return (
    <div className='themes-theme-seven-slider new-container'>
      {props.destinoTitle ? (
        <h2 className='bold-text'>
          <span>Nuestros destinos</span>
        </h2>
      ) : null}
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        showDots={props.showDots ? true : false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        customTransition='all .5'
        transitionDuration={3000}
        removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
      >
        {props.slides &&
          (props.slides.length || []) > 0 &&
          props.slides.map((slide, index) => {
            return (
              <SlideItem slide={slide} index={index} setState={setState} />
            );
          })}
      </Carousel>
      {/* <PBModal
        ariaLabel="tnc-pnr-modal-outer"
        size={'lg'}
        onHide={() => setState('')}
        showModal={state ? true : false}
        className
        noPadding
      >
        <div className="tnc-pnr-modal">
          {state.popup && <img src={state.popup} alt="" />}
          <div className="close-button pointer" onClick={() => setState('')}>
            <SvgHome name="close" color="white" />
            
          </div>
        </div>
      </PBModal> */}
    </div>
  );
};

const SlideItem = (props) => {
  return (
    <div className='slide-item' key={props.index}>
      <div className='image-container'>
        <img
          className='image pointer'
          onClick={() => {
            props.slide.popup && props.setState(props.slide);
          }}
          src={props.slide.img ? props.slide.img : props.slide}
          alt='slide-item'
        />
      </div>
    </div>
  );
};

export default ThemeSevenSlider;
