import { IonContent, IonPage } from "@ionic/react";

import { CommonService } from "../../../services";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import IvergramaFooter from "../common/IvergramaFooter";
import IvergramaTitle from "../common/IvergramaTitle";
import React from "react";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const romanToNumericMap = {
	I: 1,
	II: 2,
	III: 3,
	IV: 4,
	V: 5,
	VI: 6,
};

const replaceRomanNumerals = text => {
	return text
		.replace(
			/\b(I{1,3}|IV|V|VI{0,3}|IX|X|XI{0,3}|XIV|XV|XVI{0,3}|XIX|XX)\. /g,
			match => {
				const romanNumeral = match.trim().slice(0, -1);
				const numericValue = romanToNumericMap[romanNumeral];
				return numericValue !== undefined ? numericValue + ". " : match;
			},
		)
		.replace(/\{C\}/g, "");
};

const IvergramaFaqScreen = props => {
	const operator = CommonService.getLocal("op") || props.data.operator;

	const createMarkup = data => {
		// if (operator.operator_name === "aeroquinta") {
		// 	data = data.replace(/^,/, "").replace(/\{C\}/g, "");

		// 	let paragraphs = data.split("<p>");

		// 	if (paragraphs.length > 1) {
		// 		paragraphs.splice(1, 1);
		// 		data = paragraphs.join("<p>");
		// 	}

		// 	data = replaceRomanNumerals(data);
		// 	data = applyDMSansBoldToStrongTags(data);
		// }

		return { __html: data };
	};

	const applyDMSansBoldToStrongTags = html => {
		const regex = /<strong\b[^>]*>(.*?)<\/strong>/g;
		html = html.replace(
			regex,
			'<strong style="font-family: DMSans-Bold;">$1</strong>',
		);
		return html;
	};

	return (
		<IonPage>
			<IonContent>
				<Header />
				<HeroSection
					title="Preguntas frecuentes
"
					height={"45vh"}
					description="Home / Preguntas frecuentes
        "
					heroImage={getIcons("tncBanner", operator)}
				/>

				<IvergramaTitle operator={operator} title="Preguntas frecuentes
" />
				<div className="themes-faqs new-container">
					<div className="faq">
						<strong > <span>1.</span> ¿Puedo abordar en algún paradero?</strong>
						<p>Sí, pero debes acercarte a alguna oficina física para comprar tu pasaje con embarque en algún paradero ESTABLECIDO. Si ya tienes tu pasaje debes acercarte a alguna oficina física para actualizar tu embarque.</p>
					</div>

					<div className="faq">
						<strong ><span>2.</span>  ¿Puedo devolver mi boleto? </strong>
						<p>Sí, con un plazo de 4 horas de anticipación ante la salida del bus solo se devolverá un 85%.</p>
					</div>
					<div className="faq">
						<strong ><span>3.</span>  ¿Puedo cambiar mi hora de salida (embarque)? </strong>
						<p>Puedes trasladarte de horario, pero debes acercarte a la oficina donde adquiriste tu boleto con un plazo mínimo de 4 horas ante la salida del bus y esto está sujeto a disponibilidad de horario y asiento.</p>
					</div>

					<div className="faq">
						<strong ><span>4.</span>  ¿Puedo cambiar la fecha de viaje? </strong>
						<p>Puedes cambiar la fecha de tu viaje con un mínimo de 4 horas de anticipación a la salida de tu pasaje.</p>
					</div>

					<div className="faq">
						<strong ><span>5.</span>  ¿Puedo viajar con mi mascota?</strong>
						<p>Si, pero debes comprarle un pasaje a tu mascota, el tamaño solo pequeño y mediano, además, debe ir con su canil y bozal, es responsabilidad del pasajero el cuidado y limpieza de su mascota y desechos.</p>
					</div>
				</div>
				{/* <div className="new-container mb10 mt10">
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator.terms ? operator.terms : "",
						)}


						className="lh-1-3 justify-text"
					/>
				</div> */}
				<IvergramaFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(IvergramaFaqScreen);
