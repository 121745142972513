import React from 'react';
import getIcons from '../../functions/getIcons/getIcons';
import './ThemeThreeTitle.css';

const ThemeThreeTitle = ({ title, operator, className }) => {
  return (
    <div
      className={`themes-theme-three-title new-container bold-text ${className}`}
      style={{
        color:
          operator &&
          operator.color_codes &&
          operator.color_codes.primary_text_color,
      }}
    >
      <h2>
        {title}
        <div>
          <img
            src={getIcons('titleUnderline', operator)}
            alt=''
            style={{ width: '150px' }}
          />
        </div>
      </h2>
    </div>
  );
};

export default ThemeThreeTitle;
