import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import ThemeThreeFooter from '../common/ThemeThreeFooter';
import ThemeThreeTitle from '../common/ThemeThreeTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import IvergramaFooter from '../../Ivergrama/common/IvergramaFooter';

const boldTexts = [
	"seguro y confiable, sino tambi&eacute;n acogedor y personalizado",
	"fundamos la agencia de viajes Vip Travel Spa",
	"brindando un servicio de alta calidad que se adapta a sus necesidades espec&iacute;ficas",
	"cada traslado sea una experiencia &uacute;nica y satisfactoria.",
	"comodidad, puntualidad y trato amigable.",
];

const makeBold = (text, operator) => {
	if (operator.operator_name === 'arzola') {
		return text;
	}

	boldTexts.forEach((boldText) => {
		const regex = new RegExp(`(${boldText})`, 'g');
		text = text.replace(regex, '<strong style="font-family: DMSans-Bold;">$1</strong>');
	});

	return text;
};

const createMarkup = (data, operator) => {
	const processedData = makeBold(data, operator);
	return { __html: processedData };
};

const ArzolaAboutus = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	const tabs = ['Misión', 'Visión', 'New']; // Added "New" tab
	const [selectedTab, setSelectedTab] = useState(1); // Direct state management

	const onTabChange = (tab) => {
		setSelectedTab(tab); // Simplify state update
	};

	return (
		<IonPage>
			<IonContent>
				<Header />

				<HeroSection
					heroImage={getIcons('aboutUsBanner', operator)}
					title={'Quiénes somos'}
					height={'45vh'}
					description={'Home / Quiénes somos'}
				/>

				<ThemeThreeTitle operator={operator} title='Quiénes somos' />

				<div className='new-container' style={{ gap: '30px' }}>
					<div>
						<img src={getIcons('aboutUsImage', operator)} alt='' />
					</div>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator?.about_us?.split('///')[0] || '',
							operator
						)}
						className='lh-1-3 justify-text'
						style={{ fontSize: '14px' }}
					/>
				</div>

				<KTabs
					tabs={tabs}
					onTabChange={onTabChange}
					selectedTab={selectedTab}
					operator={operator}
				/>

				<div
					className='new-container tabs-data'
					style={{
						textAlign: 'center',
						background: '#ccc',
						borderRadius: '10px',
						padding: '30px 60px',
					}}
				>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator?.about_us
								? operator.about_us.split('///')[selectedTab] || ''
								: '',
							operator
						)}
						className='lh-1-3 justify-text'
						style={{ flex: 1, fontSize: '14px' }}
					/>
				</div>
				<IvergramaFooter/>
			</IonContent>
		</IonPage>
	);
};

const KTabs = ({ tabs, onTabChange, selectedTab, operator, width }) => {
	const styles = {
		tabPadding: {
			paddingTop: 15,
			paddingBottom: 15,
			width: width || '110px',
			paddingLeft: 20,
			paddingRight: 20,
		},
	};

	return (
		<div className='recharge-tabs-container less-bold-text'>
			<div
				className='map-container-selector'
				style={{
					margin: '20px 0',
					color: operator?.color_codes?.secondary_text_color,
					backgroundColor: operator?.color_codes?.primary_color,
					width: width || 400,
					fontSize: '14px',
				}}
			>
				<style>
					{`
                     .active {
                         background: ${operator?.color_codes?.secondary_color} !important;
                     }
                 `}
				</style>
				{tabs.map((tab, index) => (
					<div
						key={index}
						className={`selector-item ${selectedTab === index + 1 ? 'active' : ''}`}
						onClick={() => onTabChange(index + 1)}
						style={styles.tabPadding}
					>
						{tab}
					</div>
				))}
			</div>
		</div>
	);
};

export default connectData()(ArzolaAboutus);