/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonContent, IonPage } from "@ionic/react";

import { AndimarTitle } from "../../common/Title/AndimarTitle";
import ContactForm4 from "../../common/contact/ContactForm4";
import Footer1 from "../../common/footer/Footer1";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import KuposHelmet from "../../common/KuposHelmet";
import React from "react";
import ThemeTenTitle from "./ThemeTenTitle";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const getLink = (operator) => {
  const opname = operator && operator.operator_name;
  if (opname === "tacoha") {
    return {
      fb: "https://m.facebook.com/profile.php?id=312792345524109&eav=AfYgkHnJP0-n0YtyfVSxGXGDoh6o9sahtN65X43pwDTqwo51-XWG1sJNkVDjGU9t9bY&paipv=0",
      insta: "https://www.instagram.com/tacohacl/",
    };
  } else if (opname == "traveltur") {
    return {
      fb: "https://www.facebook.com/busestraveltur",
      insta: "https://www.instagram.com/travelturchile/",
    };
  }
  else if (opname === 'liquine') {
    return {
      fb: 'https://www.facebook.com/busesliquine',
      insta: 'https://www.instagram.com/busesliquine',
    };
  }
  else {
    return {
      fb: "https://www.facebook.com/busessantonio",
      insta: "https://www.instagram.com/busessanantonio",
    };
  }
};

const ThemeTenContactUs = (props) => {
  const operator = JSON.parse(localStorage.getItem("op"));

  const socialMedia = [
    {
      icon: getIcons("fb"),
      link: getLink(operator).fb,
    },
    {
      icon: getIcons("insta"),
      link: getLink(operator).insta,
    },
  ];

  const contactByArr = [
    {
      text: `${operator && operator.phone_number}`,
      image: getIcons("Support"),
    },
    {
      text: `${operator && operator.email}`,
      image: getIcons("ContactEmail"),
    },
    {
      text: `${operator && operator.address}`,
      image: getIcons("ContactLocation"),
    },
  ];

  return (
    <IonPage>
      {/* <KuposHelmet /> */}

      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons("ContactBanner", operator)}
          // height={'60vh'}
          height={"45vh"}
          title={"Contacto"}
          description={"Home / Contacto"}
        />
        <ThemeTenTitle title={"  CONTACTO  "} />
        <div
          style={{
            width: "100%",
            // marginLeft: '10%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ContactForm4 operator={operator} showMap isSanantonio props={props} />

          <div className="contact-by w-50 d-flex justify-center align-items-start flex-column ">
            {contactByArr.map((item, key) => {

              return item.text && (
                <div
                  className="contact-by-item d-flex "
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    alignItems: "center",
                    lineHeight: 1.5,
                    borderBottom: key != 2 ? "2px solid #919191" : "",
                    padding: 25,
                    // width: "80%",
                    margin: "auto",
                  }}
                >
                  <img src={item.image} alt="" style={{ width: 40 }} />
                  <span className="lh-1-3 font13" style={{ marginLeft: 10 }}>
                    {item.text}
                  </span>
                </div>
              )
            })}
            <div
              className="social-media"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                className="font16"
                style={{
                  color:
                    operator &&
                    operator.color_codes &&
                    operator.color_codes.sub_footer_color,
                  marginBottom: 10,
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                SÍGUENOS EN REDES SOCIALES
              </div>
              <div>
                {socialMedia.map((item) => (
                  <a href={item.link} target="_blank">
                    <img
                      src={item.icon}
                      alt=""
                      style={{
                        width: 40,
                        margin: 5,
                      }}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          {operator && operator.operator_name === 'liquine' ?
            <React.Fragment>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  lineHeight: 1.3,
                  margin: 30,
                }}
              >
                <div style={{ textAlign: 'center', }}>
                  Para reclamos, sugerencias o felicitaciones te invitamos a acceder a nuestro sistema
                  de
                  <span
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}Servicio al Cliente aquí
                  </span>
                </div>
              </div>
              <a
                // href="#"
                target="_blank"
                style={{
                  fontSize: '15px',
                  background: operator.color_codes && operator.color_codes.button_color,
                  color: 'white',
                  fontWeight: 'bold'
                }}
                className="andimar-btn-link less-bold-text"
              >
                Ingresa al sistema Aquí
              </a>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  lineHeight: 1.3,
                  margin: 30,
                }}
              >
                <div style={{ textAlign: 'center', }}>
                  En caso de problemas con compras en línea, envía tu solicitud a
                  <span style={{ color: operator.theme_color2 && operator.theme_color2 }}>
                    {' '}
                    {operator && operator.email}
                    {' '}

                  </span>
                  o comunícate al
                  <br />{' '}
                  <span style={{ color: operator.theme_color2 && operator.theme_color2 }}>
                    {operator && operator.phone_number}
                  </span>
                </div>
              </div>
            </React.Fragment> : null
          }

        </div>

        <Footer1 operator={operator} isSanantonio />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTenContactUs);
