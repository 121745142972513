import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import getIcons from '../../functions/getIcons/getIcons';
import './ThemeFourTitle.css';

const ThemeFourTitle = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;
  return (
    <div
      className='themes-theme-four-title new-container'
      style={{
        color:
          operator &&
          operator.color_codes &&
          operator.color_codes.primary_text_color &&
          operator.color_codes.primary_text_color,
      }}
    >
     {getIcons('titleLeftIcon')&& <img src={getIcons('titleLeftIcon')} alt='' className='title-icon' />}
      <span className='less-bold-text'> {props.title} </span>
      {getIcons('titleRightIcon') &&   <img src={getIcons('titleRightIcon')} alt='' className='title-icon' />}
    </div>
  );
};

export default connectData()(ThemeFourTitle);
