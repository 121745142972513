// passing the path into variables

const banner1 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-1.png';
const banner2 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-2.png';
const banner3 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-3.png';

const destination1 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-01.png';
const destination2 ='/assets/pullmansur/General/PullmandelSur-Website-Destinations-02.png';
const destination3 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-03.png';
const destination4 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-04.png';
const destination5 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-05.png';
const destination6 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-06.png';
const destination7 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-07.png';
const destination8 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-08.png';
const destination9 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-09.png';
const destination10 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-10.png';
const destination11 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-11.png';

const banner = [banner1, banner2, banner3];

const destination = [destination1, destination2, destination3, destination4,destination5,destination6,destination7,destination8,destination9,destination10,destination11];

// const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

const pullmandelsurSlides = {
  banner,
  destination,
  // gallery
};

export default pullmandelsurSlides;
