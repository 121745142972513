import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import './ThemeEightFooter.css';

const ThemeEightFooter = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;

  return (
    operator && (
      <div className='themes-theme-eight-footer'>
        <p
          style={{
            // color:
            //   operator &&
            //   operator.color_codes &&
            //   operator.color_codes.secondary_text_color &&
            //   operator.color_codes.secondary_text_color,
            background:
              operator &&
              operator.color_codes &&
              operator.color_codes.primary_color &&
              operator.color_codes.primary_color,
          }}
          className='footer'
        >
          <span className='bold-text'>
            {operator.name ? operator.name : ''} {new Date().getFullYear()}.
          </span>
          <span className=''> Todos los derechos reservados © Powered by </span>
          <a
            href='https://kupos.cl'
            target='_blank'
            className='bold-text'
            style={{ color: 'white' }}
          >
            Kupos.cl
          </a>
        </p>
      </div>
    )
  );
};

export default connectData()(ThemeEightFooter);
