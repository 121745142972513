import "./Theme11Faq.css";

import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from 'react'

import Footer2 from "../common/footer/Footer2";
import Header from "../common/header/Header";
import KuposHeader from "../../components/KuposHeader";
import { PBText } from "../../components";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";

// import HeroSection from "../../common/HeroSection/HeroSection";


const Theme11Faq = ({ operator, history }) => {
	const [accordionData, setAccordionData] = useState([
		{
			id: 1,
			heading: '¿Dónde encuentro el bus en el aeropuerto?',
			content:
				operator && operator.terms
					? operator.terms
					: 'El andén de buses se encuentra en la estación de buses o Centro de Transportes (CTR) ubicado en el sector poniente entre los terminales T1 (nacional) y T2 (internacional).<br /><br/>Si vienes de un vuelo nacional: Debes caminar por el primer nivel del aeropuerto en dirección a la salida N°6, y pocos metros más al poniente encontrarás la estación de buses o Centro de Transportes (CTR).<br /><br />Si vienes de un vuelo internacional: Puedes abordar el servicio en la 3ra pista del primer nivel, entre salidas 4 y 5.',
			isOpen: false,
		},
		{
			id: 2,
			heading: '¿Cuánto cuesta el pasaje y cómo lo pago?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'El boleto de ida tiene un valor de CLP$2.000 y, el de ida y regreso de CLP$3.000.<br /><br /> ¡TE RECOMENDAMOS PREVER LA COMPRA DE TU PASAJE A TRAVÉS DE NUESTRA PÁGINA WEB!<br /><br />Como segunda opción, desde Santiago el ticket también puede ser adquirido con tarjeta bancaria al momento de abordar el bus a través de nuestro personal. Desde el aeropuerto podrás adquirir tu ticket en efectivo o con tarjeta bancaria en los counters ubicados en salida nacional (T1), Estación de Buses o en andén el sector internacional (T2).<br /><br />Los tickets adquiridos en counters de venta en aeropuerto, pueden ser utilizados en el transcurso del día en el que fueron adquiridos, en cualquiera de nuestros servicios en la ruta indicada y, es por ello que, el horario indicado corresponde al horario de impresión y no de ejecución del viaje a realizar.					<br /><br />Todos los tickes adquiridos en counters y boleterías cuentan con un código QR, que debes cuidar para que no se deteriore y pueda ser leído correctamente al momento de abordar el bus.<br /><br />Recuerda exigir y conservar tu ticket como comprobante de pago de acuerdo a la tarifa cancelada.',
			isOpen: false,
		},
		{
			id: 3,
			heading: '¿Puedo usar el boleto de regreso otro día? ',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'Al adquirir un boleto de ida y regreso, el ticket de regreso tiene una validez de 30 días desde la fecha de compra. Recuerda que el ticket se valida a través de un código QR que debe estar en óptimas condiciones para ser leído.<br /><br />La venta de tickets de ida y regreso solo está disponible en counters del aeropuerto.',
			isOpen: false,
		},
		{
			id: 4,
			heading: '¿Cuánto demora el trayecto?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'El trayecto total en ambos sentidos tiene una duración estimada de 40 minutos.',
			isOpen: false,
		},
		{
			id: 5,
			heading: '¿Es posible generar reservas?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'Nuestra frecuencia de salidas permite que no sea necesario realizar reservas previas.<br/><br/>La oferta de boletos ofrecidos a través de canales externos no tiene relación con la oferta de boletos ofrecidos directamente por la empresa.',
			isOpen: false,
		},
		{
			id: 6,
			heading: '¿Puedo llevar equipaje?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'El traslado de equipaje está definido por la disponibilidad de espacio destinado para ello que esté disponible en el bus que desea abordar. Está permitido transportar hasta 2 equipajes por persona y el tamaño máximo no puede exceder el de una maleta grande.',
			isOpen: false,
		},
		{
			id: 7,
			heading: '¿Puedo trasladar mascotas?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'El traslado de mascotas está permitido siempre que estas vayan en jaulas o cajas de traslado correspondientes, las que deberán ser ubicadas en el sector para traslado de equipajes ubicado en el interior del bus, o bien sobre las piernas del pasajero dependiendo de su tamaño.<br /><br />No está permitido el traslado de mascotas sueltas o en maleteros inferiores.',
			isOpen: false,
		},
		{
			id: 8,
			heading:
				'¿Qué pasa si necesito viajar y no hay más capacidad de pasajeros o equipaje en el bus?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'Deberás abordar el siguiente bus que deberá pasar en la parada autorizada en el tiempo ajustado a la frecuencia ofrecida. Recuerda que la frecuencia en paraderos intermedios puede variar de acuerdo a las condiciones de tránsito y seguridad en la ruta.',
			isOpen: false,
		},
		// {
		//   id: 8,
		//   heading: '¿Puedo llevar equipaje?',
		//   content:
		//     operator && operator.privacy_policy
		//       ? operator.privacy_policy
		//       : 'El traslado de equipaje está definido por la disponibilidad de espacio destinado para ello que esté disponible en el bus que desea abordar. Está permitido transportar hasta 2 equipajes por persona y el tamaño máximo no puede exceder el de una maleta grande.',
		//   isOpen: false,
		// },

		// {
		//   id: 10,
		//   heading:
		//     '¿Qué pasa si necesito viajar y no hay más capacidad de pasajeros o equipaje en el bus?',
		//   content:
		//     operator && operator.privacy_policy
		//       ? operator.privacy_policy
		//       : 'Deberás abordar el siguiente bus que deberá pasar en la parada autorizada en el tiempo ajustado a la frecuencia ofrecida. Recuerda que la frecuencia en paraderos intermedios puede variar de acuerdo a las condiciones de tránsito y seguridad en la ruta.',
		//   isOpen: false,
		// },
		{
			id: 9,
			heading: '¿Cuáles son las credenciales autorizadas para acceder a la tarifa funcionario?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'Corresponden a la credencial TICA entregada a funcionarios aeroportuarios por la Dirección General de Aeronáutica Civil (DGAC) o credencial de empresas que cuenten con convenio realizado con Centropuerto.',
			isOpen: false,
		},
		{
			id: 10,
			heading:
				'¿Cómo realizo un convenio de mi empresa con Centropuerto?',
			content:
				operator && operator.privacy_policy
					? operator.privacy_policy
					: 'La persona encargada de este tipo de gestiones debe comunicarse con nosotros a través del correo Info@centropuerto.cl o acercarse a nuestras oficinas administrativas ubicadas en el Terminal de Buses del Aeropuerto frente al andén 24.',
			isOpen: false,
		},
	]);


	const toggleAccordion = id => {
		setAccordionData(prevAccordionData =>
			prevAccordionData.map(accordion => ({
				...accordion,
				isOpen: accordion.id === id ? !accordion.isOpen : false,
			})),
		);
	};

	return (
		<div>
			<KuposHeader
				// data={props.data}
				title={"Preguntas "}
				boldTitle={"frecuentes"}
				handleHomeSubmit={() => {
					window.location.href = '/'
				}}
				handleBackSubmit={() => history.goBack()}
			/>

			<div class="scroll-div">
				<div className="themes-theme-eleven-faq new-container" >




					<div className="new-container-compact mb15 mt10 ">
						{accordionData.map((accordion, index) => (
							<div key={accordion.id} className="accord-block">
								<span onClick={() => toggleAccordion(accordion.id)} className="accordion-toggle">
									<div className="accord-heading">
										<p className="bold-text font14">{accordion.heading}</p>
									</div>

									<div className="accord-icon ">
										{accordion.isOpen ? (
											// <span class="yellow-icon">
											<span>
												<img alt="white-arrow" src={getIcons('AccordOpenIcon', operator)} />
											</span>
										) : (
											<img alt="white-arrow" src={getIcons('AccordCloseIcon', operator)} />
										)}
									</div>
								</span>
								{accordion.isOpen && (
									<div
										dangerouslySetInnerHTML={{ __html: accordion.content }}
										className="accord-data font14"
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Theme11Faq