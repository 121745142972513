const BG =
  '/assets/pullmansur/Home/PullmandelSur-Banner-Mobile.jpg';

const PhoneIcon =
  '/assets/saopaulo/General/BusesSaoPaulo-Website-Header-PhoneIcon.svg';
// const PopupIcon = '/assets/saopaulo/about/aboutus.jpg';

const BookingActive =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-SearchBar-CancelTicket.svg';

const OriginIcon =
  '/assets/pullmansur/Home/PullmandelSur-Website-Home-SearchBar-OriginIcon.png';
const DestinationIcon =
  '/assets/pullmansur/Home/PullmandelSur-Website-Home-SearchBar-DestinationIcon.png';
const CalendarIcon =
  '/assets/pullmansur/Home/PullmandelSur-Website-Home-SearchBar-CalendarIcon.png';
const pnr =
  '/assets/pullmansur/General/PullmandelSur-Website-WhereToFindMyPNR.jpg';
// const SearchIcon = '/assets/saopaulo/Home/SearchBar-SearchIcon.svg'
// Title icons

// const titleLeftIcon =
//   '/assets/saopaulo/General/BusesSaoPaulo-Website-Titles-Icon1.svg';
// const titleRightIcon =
//   '/assets/saopaulo/General/BusesSaoPaulo-Website-Titles-Icon2.svg';

const ContactBg =
  '/assets/saopaulo/Home/BusesSaoPaulo-Website-Home-Background-ContactSection.jpg';

// footerIcons

// const fb = '/assets/saopaulo/General/BusesSaoPaulo-Website-Footer-SocialMedia-Facebook.svg';
const insta = '/assets/saopaulo/General/BusesSaoPaulo-Website-Footer-SocialMedia-Instagram.svg';

// const contactFb = '/assets/pullmansur/Contact/PullmandelSur-Website-Contact-SocialMedia-Facebook.png';
const contactInsta =
  '/assets/pullmansur/Contact/PullmandelSur-Website-Contact-SocialMedia-Instagram.png';
// -------------

// banners
const destinationBanner =
'/assets/pullmansur/Destinations/PullmandelSur-Website-Destinations-Banner.png';
const contactUsBanner = '/assets/pullmansur/Contact/PullmandelSur-Website-Contact-Banner.png';
const aboutUsBanner = '/assets/pullmansur/company/PullmandelSur-Website-TheCompany-Banner.png';
const tncBanner = '/assets/pullmansur/tnc/PullmandelSur-Website-TermsandConditions-Banner.png';
const officeBanner = '/assets/pullmansur/Offices/PullmandelSur-Website-Offices-Banner.png';
// const aboutUsSecondBanner =
// '/assets/pullmansur/company/PullmandelSur-Website-TheCompany-BannerHistory.png';

const aboutUsImage = '/assets/pullmansur/company/PullmandelSur-Website-TheCompany-AboutUs.jpg';

const contactMail = '/assets/pullmansur/Contact/PullmandelSur-Website-Contact-Email-Icon.png';
const contactHeadphone =
  '/assets/pullmansur/Offices/PullmandelSur-Website-Offices-PhoneNumber-Icon.png';

const pin = '/assets/pullmansur/Offices/PullmandelSur-Website-Offices-Pin-Icon.png';
const checkboxIcon = '/assets/tepual/checkbox.png';
const animation =
  '/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';

// accounts section


const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const heartOn = '/assets/Gray/MyAccount-IllustratedIcons-HeartOn-Gray.svg';
const heartOff = '/assets/Gray/MyAccount-IllustratedIcons-HeartOff-Gray.svg';
const downArrow = '/assets/Gray/MyAccount-IllustratedIcons-DropDown-Gray.svg';
const registrationSuccess = '/assets/Gray/MyAccount-IllustratedIcons-RightDocument-Gray.svg';
const userIcon = '/assets/Gray/MyAccount-IllustratedIcons-Login-Gray.svg';
const trips = '/assets/Gray/MyAccount-IllustratedIcons-MyTrips-Gray.svg';
const cards = '/assets/Gray/MyAccount-IllustratedIcons-MyCards-Gray.svg';
const wallet = '/assets/Gray/MyAccount-IllustratedIcons-RechargeWallet-Gray.svg';
const transaction = '/assets/Gray/MyAccount-IllustratedIcons-ShareMoney-Gray.svg';
const otp = '/assets/Gray/MyAccount-IllustratedIcons-RestorePassword-Gray.svg';
const paso_1 = '/assets/Gray/MyAccount-IllustratedIcons-RechargeWallet-Gray.svg';
const paso_2 = '/assets/Gray/MyAccount-IllustratedIcons-RechargePoints-Gray.svg';
const paso_3 = '/assets/Gray/MyAccount-IllustratedIcons-ID-Gray.svg';
const paso_4 = '/assets/Gray/MyAccount-IllustratedIcons-Tickets-Gray.svg';

const tarjetas = '/assets/Gray/MyAccount-IllustratedIcons-MyCards-Gray.svg';
const viajes = '/assets/Gray/MyAccount-IllustratedIcons-MyTrips-Gray.svg';
const movimientos = '/assets/Gray/MyAccount-IllustratedIcons-RechargeWallet-Gray.png'; 
const shareMoney = '/assets/Gray/MyAccount-IllustratedIcons-ShareMoney-Gray.svg';
const camera = '/assets/Gray//MyAccount-IllustratedIcons-Login-Gray.png';
const checkmark = '/assets/Gray/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/Gray/MyAccount-IllustratedIcons-HeartOn-Gray.svg';
const loginblue = '/assets/Gray//MyAccount-IllustratedIcons-Login-Gray.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/Gray/MyAccount-IllustratedIcons-CheckMark-Gray.svg';
const failure = '/assets/Gray/MyAccount-IllustratedIcons-Information-Gray.svg';
const warning = '/assets/Gray/MyAccount-IllustratedIcons-Information-Gray.svg';
const operatorPay = '/assets/pullmansur/General/pullman-del-Sur-Pay.png'






const pullmandelsurIcons = [
  { icon: BG, name: 'BG' },
  { icon: operatorPay, name: 'operatorPay' },

  //   { icon: PopupIcon, name: 'Popup' },
  { icon: PhoneIcon, name: 'phone' },
  { icon: BookingActive, name: 'BA' },
  { icon: CancelActive, name: 'CA' },
  { icon: OriginIcon, name: 'Origin' },
  { icon: DestinationIcon, name: 'Destination' },
  { icon: CalendarIcon, name: 'Calendar' },
  { icon: pnr, name: 'pnr' },

  // Title icons
  // { icon: titleLeftIcon, name: 'titleLeftIcon' },
  // { icon: titleRightIcon, name: 'titleRightIcon' },

  { icon: ContactBg, name: 'ContactBg' },

  // footerIcons
  // { icon: fb, name: 'fb' },
  { icon: insta, name: 'insta' },

  // { icon: contactFb, name: 'contactFb' },
  { icon: contactInsta, name: 'contactInsta' },

  { icon: destinationBanner, name: 'destinationBanner' },
  { icon: contactUsBanner, name: 'contactUsBanner' },
  { icon: aboutUsBanner, name: 'aboutUsBanner' },
  { icon: tncBanner, name: 'tncBanner' },
  { icon: officeBanner, name: 'officeBanner' },
  // { icon: aboutUsSecondBanner, name: 'aboutUsSecondBanner' },

  { icon: contactMail, name: 'contactMail' },
  { icon: contactHeadphone, name: 'contactHeadphone' },

  { icon: aboutUsImage, name: 'aboutUsImage' },

  { icon: checkboxIcon, name: 'Checkbox' },
  { icon: animation, name: 'animation' },
  { icon: pnr, name: 'pnr' },
  { icon: pin, name: 'pin' },


  // accounts section
  { icon: pay, name: 'pay' },
  { icon: backimage, name: 'backimage' },
  { icon: tarjetas, name: 'tarjetas' },
  { icon: viajes, name: 'viajes' },
  { icon: movimientos, name: 'movimientos' },
  { icon: wallet, name: 'wallet' },
  { icon: shareMoney, name: 'shareMoney' },
  { icon: camera, name: 'camera' },
  { icon: checkmark, name: 'checkmark' },
  { icon: tick, name: 'tick' },
  { icon: downArrow, name: 'downArrow' },
  { icon: loginblue, name: 'loginblue' },
  { icon: recoverpswd, name: 'recoverpswd' },
  { icon: success, name: 'success' },
  { icon: failure, name: 'failure' },
  { icon: heartOn, name: 'heartOn' },
  { icon: heartOff, name: 'heartOff' },
  { icon: registrationSuccess, name: 'registrationSuccess' },
  { icon: userIcon, name: 'userIcon' },
  { icon: warning, name: 'warning' },
  
];

export default pullmandelsurIcons;
