import { CommonService } from "../services";
import { PBText } from ".";
import React from "react";
import { menuController } from "@ionic/core";

const KuposHeader = ({
	data,
	homeScreenHeader,
	handleBackSubmit,
	handleHomeSubmit,
	href,
	title,
	boldTitle,
	logo,
	backIconStyle,
	whiteFont = false,
	operator
}) => {
	return (
		<>
			{homeScreenHeader ? (
				<div className="new-home-header">
					<div onClick={() => menuController.open()}>
						{!data.thirdPartyApp ? (
							<img
								className="qr-back-arrow"
								src="../../assets/carpool/menu-dot.png"
								alt=""
							/>
						) : null}
					</div>

					{CommonService.isTrain() ? (
						<div className="font14 my-account-header-label display-flex">
							<PBText fontSize={"font19"}>EFE Trenes</PBText>
							<PBText fontSize={"font19"} fontStyle={"bold-font"}>
								&nbsp;de Chile
							</PBText>
						</div>
					) : (
						!(CommonService.isAbcApp() || CommonService.isBancoEstado()) && (
							<div className="font14 my-account-header-label display-flex">
								<PBText fontSize={"font19"}>PasajeBus.</PBText>
								<PBText fontSize={"font19"} fontStyle={"bold-font"}>
									com
								</PBText>
							</div>
						)
					)}

					<div>&nbsp;</div>
				</div>
			) : (
				<div className="my-account-header">
					<div className="header-back-button" onClick={handleBackSubmit}>
						<img
							className={operator && operator.operator_name === "tacoha" ? "" : "qr-back-arrow"}
							src="/assets/carpool/icon_back_arrow.png"
							alt=""
							style={backIconStyle}
						/>
					</div>

					<div
						className={`font19 flex-center display-flex ${whiteFont ? "white-font" : "dark-font"
							}`}
					>
						{logo && <img style={{ width: "140px" }} src={logo} />}

						<span>{title}</span>&nbsp;
						{/* <span>{title}</span> */}
						<span className="bold-text flex-center">{boldTitle}</span>
					</div>
					{/* <div>&nbsp;</div> */}

					{handleHomeSubmit || href ? (
						<a
							href={href}
							onClick={() => (handleHomeSubmit ? handleHomeSubmit() : "")}
						>
							<img src="/assets/carpool/home.svg" alt="" />
						</a>
					) : null}
				</div>
			)}
		</>
	);
};

export default KuposHeader;
