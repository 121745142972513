const Banner = '/assets/jetsur/Jetsur-Website-Banner-Desktop.jpg';
// const HeaderLogo = '/assets/jetsur/jetsur-header-logo.png';
// const FooterLogo = '/assets/jetsur/jetsur-footer-logo-white.png';
const BookingInActive =
	'/assets/jetsur/Jetsur-Website-SearchBar-BuyATicket-Inactive.svg';
const BookingActive =
	'/assets/jetsur/Jetsur-Website-SearchBar-BuyATicket-Active.svg';
const CancelInActive =
	'/assets/jetsur/Jetsur-Website-SearchBar-CancelATicket-Inactive.svg';
const CancelActive =
	'/assets/jetsur/Jetsur-Website-SearchBar-CancelATicket-Active.svg';

const OriginIcon = '/assets/jetsur/Jetsur-Website-SearchBar-Origin.svg';
const DestinationIcon =
	'/assets/jetsur/Jetsur-Website-SearchBar-Destination.svg';
const CalendarIcon = '/assets/jetsur/Jetsur-Website-SearchBar-Calendar.svg';

const Whatsapp = '/assets/jetsur/whatsapp.svg';
const Email = '/assets/jetsur/Jetsur-Website-Contact-Email.svg';
const HeadPhone = '/assets/jetsur/Jetsur-Website-Contact-Phone.svg';
const YellowLine = '/assets/jetsur/Jetsur-Website-Line-Yellow.svg';

const pnr = '/assets/jetsur/Jetsur-Website-WhereToFindMyPNR.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';

const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
// const PopupIcon = '/assets/jetsur/Jetsur-Website-WhereToFindMyPNR.jpg';

const BG = '/assets/jetsur/Jetsur-Website-Banner-Mobile.jpg';

// Icons Section
const ist = '/assets/jetsur/Jetsur-Website-TheCompany-Seats.svg';
const second = '/assets/jetsur/Jetsur-Website-TheCompany-Technology.svg';
const third = '/assets/jetsur/Jetsur-Website-TheCompany-PrintTicket.svg';
const close = '/assets/jetsur/close.svg';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const menuDot = '/assets/jetsur/Jetsur-Website-MobileVersion-SideMenuIcon.svg';
const address = '/assets/jetsur/Jetsur-Website-Contact-Address.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'

const jetsurIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: Banner, name: 'Banner' },
	{ icon: menuDot, name: 'menuDot' },

	{ icon: BG, name: 'BG' },
	{ icon: address, name: 'address' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },

	// { icon: HeaderLogo, name: 'headerLogo' },
	// { icon: FooterLogo, name: 'footerLogo' },

	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },

	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },

	// { icon: PopupIcon, name: 'Popup' },

	{ icon: Whatsapp, name: 'Whatsapp' },
	{ icon: Email, name: 'Email' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: YellowLine, name: 'Line' },

	{ icon: animation, name: 'animation' },
	{ icon: checkboxIcon, name: 'Checkbox' },

	// Icons Section
	{ icon: ist, name: 'ist' },
	{ icon: second, name: 'second' },
	{ icon: third, name: 'third' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },


	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },

];

export default jetsurIcons;
