import './ThemeSixOfficeSection.css';

import React, { useState } from 'react';

import GoogleMapReact from 'google-map-react';
import getIcons from '../../functions/getIcons/getIcons';
import { AppData } from '../../../services';

const offices = [
  {
    name: 'Arica',
    location: 'TERMINAL RODOVIARIO DE ARICA, ARICA.',
    phoneNo: '+56 9 3244 6283',
    latitude: -18.47250376818072,
    longitude: -70.30405315052646,
  },
  {
    name: 'Iquique',
    location: 'TERMINAL RODOVIARIO DE IQUIQUE, PATRICIO LYNCH S/N, IQUIQUE.',
    phoneNo: '+56 57 2 428 128',
    latitude: -20.20798854974633,
    longitude: -70.15041073120659,
  },
  {
    name: 'Alto Hospicio',
    location: 'AUTOP. HUMBERSTONE - IQUIQUE 3808-3888, ALTO HOSPICIO..',
    phoneNo: '+56 9 3944 1899',
    latitude: -20.279637419667193,
    longitude: -70.10120193120532,
  },
  {
    name: 'Calama',
    location: 'BALMACEDA 1902, CALAMA.',
    phoneNo: '+56 9 3944 1886',
    latitude: -22.479077898722593,
    longitude: -68.92600933116208,
  },
  {
    name: 'Antofagasta',
    location: 'TERMINAL DE BUSES CARDENAL CARLOS OVIEDO CAVADA, ANTOFAGASTA.',
    phoneNo: '+56 9 3455 0517',
    latitude: -23.617477320546318,
    longitude: -70.38845844463056,
  },
  {
    name: 'La Serena',
    location: 'TERMINAL DE BUSES LA SERENA, LA SERENA.',
    phoneNo: '+56 9 3944 1896',
    latitude: -29.90910576552797,
    longitude: -71.25888861063203,
  },
  {
    name: 'Coquimbo',
    location: 'TERMINAL DE BUSES COQUIMBO, OFICINA 11.',
    phoneNo: '+56 9 3944 1902',
    latitude: -29.955923475165253,
    longitude: -71.3375985868104,
  },
  {
    name: 'Santiago',
    location: 'TERMINAL DE BUSES SAN BORJA, SAN BORJA 184, SANTIAGO.',
    phoneNo: '+56 9 3944 1904',
    latitude: -33.454279251366984,
    longitude: -70.67983977322515,
  },
];

const AnyReactComponent = ({ img }) => (
  <div>
    <img
      style={{
        display: 'inline-block',
        width: '20px',
      }}
      src={img}
      alt=''
    />
  </div>
);
function ThemeSixOfficeSection() {
  const [selectedOffice, setSelectedOffice] = useState(offices[0]);

  return (
    <div className='theme-six-office-section'>
      <div className='theme-six-office-section-left'>
        {offices.map((item, key) => (
          <div className='d-flex align-items-center theme-six-offices-list font-17px'>
            <li
              className='theme-six-office-section-left-item bold-text text-capitalize'
              onClick={() => {
                setSelectedOffice(item);
              }}
            >
              {item.name}
            </li>
            {selectedOffice.name === item.name && (
              <span className='selected-office-mark' />
            )}
          </div>
        ))}
      </div>
      <div className='theme-six-office-section-right'>
        <div className='theme-six-office-section-right-top'>
          <span className='bold-text'>UBICACIÓN:</span>
          <br />
          <span className='text-uppercase d-flex align-items-center selected-address'>
            {selectedOffice.location}{' '}
            <img
              src={getIcons('locationIcon')}
              //   style={{
              //     width: 15,
              //     // marginLeft: 10,
              //   }}
              alt=''
            />
          </span>
        </div>
        <div className='theme-six-office-section-right-bottom'>
          <div className='theme-six-office-section-right-bottom-left'>
            <span className='bold-text'>TELÉFONO:</span>
            <br />
            <span className='text-uppercase '>{selectedOffice.phoneNo}</span>
          </div>
          <div className='theme-six-office-section-right-bottom-right'>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: AppData.MAP_API_KEY,
              }}
              center={{
                lat: parseFloat(selectedOffice.latitude),
                lng: parseFloat(selectedOffice.longitude),
              }}
              defaultZoom={7}
            >
              <AnyReactComponent
                lat={parseFloat(selectedOffice.latitude)}
                lng={parseFloat(selectedOffice.longitude)}
                img='/assets/bahia/General/pin.png'
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThemeSixOfficeSection;
