import React from 'react'
import PBModal from './PBModal';
import PButton from './PButton';
import PBText from './PBText';
import View from './View';
import getIcons from '../themes/functions/getIcons/getIcons';

const CarpoolSuccessFailureModal = (props) => {
    const {showModal,onBackButtonPress,success,titleText,bodyText,bodyText2,onButtonPress,buttonText,showButton1,showButton2,button1Press,button2Press,button1Text,button2Text} = props
    return (
      <PBModal
        showModal={showModal}
        titleText={""}
        showButton1={showButton1}
        showButton2={showButton2}
        button1Text={button1Text}
        button2Text={button2Text}
        button1Press={() => (button1Press ? button1Press() : null)}
        button2Press={() => (button2Press ? button2Press() : null)}
        onBackButtonPress={onBackButtonPress ? onBackButtonPress() : () => {}}
        cardStyle={{ marginTop: "-35vh" }}
      >
        <View style={{ padding: 10 }}>
          <img
            style={{
              margin: "0px auto 20px",
              alignSelf: "center",
              width: "20vw",
              display: "block",
            }}
            src={
              success?
                // ? "/assets/carpool/booking-success-circle.png"
                // : "/assets/carpool/booking-failure-circle.png"
                getIcons('success'):
                getIcons('failure')

            }
            alt=''
          />
          <View style={{ marginBottom: 5 }}>
            <PBText
              bold={true}
              dark={true}
              style={{ textAlign: "center", fontSize: 15 }}
            >
              {titleText ? titleText : success ? "¡Listo!" : "¡Lo sentimos!"}
            </PBText>
          </View>
          {bodyText ? (
            <View style={{ marginTop: 10, marginBottom: 20 }}>
              <PBText style={{ textAlign: "center", fontSize: 14 }}>
                {bodyText}
              </PBText>
            </View>
          ) : null}
          {bodyText2 ? (
            <View
              style={{ marginTop: 20, marginBottom: !showButton1 ? 25 : 0 }}
            >
              <PBText style={{ textAlign: "center", fontSize: 14.5 }}>
                {bodyText2}
              </PBText>
            </View>
          ) : null}

          {!showButton1 ? (
            <View>
              <View>
                <PButton
                  onPress={onButtonPress}
                  style={{ borderRadius: 10 }}
                  noGradient={true}
                  title={buttonText ? buttonText : "OK, CONTINUAR"}
                />
              </View>
            </View>
          ) : null}
        </View>
      </PBModal>
    );
}


export default CarpoolSuccessFailureModal;
