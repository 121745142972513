import React from 'react';
import Carousel from 'react-multi-carousel';
import { connectData } from '../../../redux';
import './ThemeFourSlider.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ThemeFourSlider = (props) => {
  return (
    <div className='themes-theme-four-slider  new-container'>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        customTransition='all .5'
        transitionDuration={3000}
        removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
      >
        {props.slides &&
          (props.slides.length || []) > 0 &&
          props.slides.map((slide, index) => {
            return <SlideItem slide={slide} index={index} />;
          })}
      </Carousel>
    </div>
  );
};

const SlideItem = (props) => {
  return (
    <div className='slide-item' key={props.index}>
      <div className='image-container'>
        <img className='image' src={props.slide} alt='slide-item' />
      </div>
    </div>
  );
};

export default connectData()(ThemeFourSlider);
