// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";

import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  CarpoolSuccessFailureModal,
  View,
} from "../../components";
import { CommonService } from "../../services";
import { IonPage, IonContent } from "@ionic/react";
import getIcons from "../../themes/functions/getIcons/getIcons";
// const countries = '../../../components/CountryPicker/data/cca2');

class MobileRegisterScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobile: null,
      mobileError: null,
      selectedCountry: {
        cca2: "CL",
        currency: "CLF",
        callingCode: "56",
        flag: undefined,
        name: "Chile",
      },
    };
  }

  componentDidMount(){
    this.props.registerSetUserPhoneDataForOtp(null);
  }

  mobileChange = (text) => {
    if (text) {
      text = CommonService.phoneNumberFormat(text);
    }
    this.setState({
      mobileError: null,
      mobile: text,
    });
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "mobile") {
        this.setState({
          mobileError: {
            message: "Ingresa tu número de teléfono",
          },
        });
      }
    } else if (inp == "mobile") {
      if (this.state.mobile.toString().length != 9) {
        this.setState({
          mobileError: {
            message: "El teléfono debe contener mínimo 9 dígitos",
          },
        });
      }
    }
  };

  onSubmit = () => {
    let errorCount = 0;
    if (!this.state.mobile) {
      this.setState({
        mobileError: {
          message: "Ingresa tu número de teléfono",
        },
      });
      errorCount++;
    } else if (this.state.mobile.toString().length != 9) {
      this.setState({
        mobileError: {
          message: "El teléfono debe contener mínimo 9 dígitos",
        },
      });
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }

    this.props.generateOtp({
      data: {
        country_code: "+" + this.state.selectedCountry.callingCode,
        mobile_number: this.state.mobile.toString(),
        cat_type: "sign_up",
      },
      callback: (res) => {
        console.log("Data of signup response ", res);

        if (res.success && res.data && res.data.status === 200) {
          let data = {
            mobile: this.state.mobile,
            country: this.state.selectedCountry,
          };
          this.props.registerSetUserPhoneDataForOtp(data);
          this.props.history.push("/otp");
        } else {
          this.setState({
            mobileError: {
              message: res.data.message,
            },
          });
          // this.setState({showSuccessFailureModal:true,showSuccessFailureModalStatus:false,showSuccessFailureModalBodyText:res.data.message})
        }
      },
    });

    // this.props.forgotPassword({callback:(result) => this.onResponse(result),data:data})
  };

  onResponse = (result) => {
    console.log(result);
    if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        showRegisterLink: true,
      });
      return;
    } else if (result.data.message) {
      this.setState({
        showModal: true,
        modalTitle: "¡Listo!",
        modalBody: result.data.message,
        modalIcon: "/assets/icons/success.png",
        showRegisterLink: false,
      });
    }
  };

  goBack = () => {
    let modalTitle = this.state.modalTitle;
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    if (modalTitle != "¡Lo Sentimos!") this.props.history.goBack();
  };

  onSelect = (country) => {};

  render() {
    const selectedTxn = this.props.selectedTxn;
    return (
      <IonPage>
        <IonContent>
          <View>
            <CarpoolHeader
              backIcon={true}
              componentId={this.props.componentId}
              label=" "
              labelDark={true}
              history={this.props.history}
            />

            <div>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <img
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    marginLeft: "36vw",
                    width: "28vw",
                  }}
                  // src={"/assets/icons/circular/k.png"}
                  src={getIcons('userIcon')}
                  alt=''
                />
              </View>

              <View style={{ alignItems: "center" }}>
                <PBText
                  dark={true}
                  bold={true}
                  style={{ textAlign: "center", fontSize: 15 }}
                >
                  Regístrate
                </PBText>
              </View>
              <View
                style={{
                  alignItems: "center",
                  padding: "0 20px",
                  marginTop: 5,
                }}
              >
                <PBText style={{ textAlign: "center", fontSize: 14 }}>
                  Al registrarte podrás disfrutar de todos los servicios de
                  transporte en un solo lugar.
                </PBText>
              </View>
              <View
                className="kupos-card"
                style={{
                  paddingVertical: 30,
                  marginTop: 20,
                  marginBottom: 20,
                  paddingBottom: 24,
                }}
              >
                <View className="flex-row phone-inp-with-flag">
                  <View
                    className="input-flag"
                    style={{
                      paddingHorizontal: 10,
                      display: "flex",
                    }}
                  >
                    <img src={"/assets/carpool/img/chile-flag.png"} />
                  </View>
                  <View style={styles.flex}>
                    <PBInput
                      type={"tel"}
                      placeholder="Ingresa tu número telefónico"
                      value={this.state.mobile}
                      onTextChange={(text) => this.mobileChange(text)}
                      onBlur={(text) => this.onBlur(text, "mobile")}
                      style={{ borderWidth: 0, height: 36 }}
                    ></PBInput>
                  </View>
                </View>
                {this.state.mobileError ? (
                  <PBText
                    style={(styles.fontSize, styles.errorText)}
                    // primary={true}
                  >
                    {this.state.mobileError.message}
                  </PBText>
                ) : null}
                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={this.onSubmit}
                    style={{ borderRadius: 10 }}
                    noGradient={true}
                    title={"REGÍSTRATE"}
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PBText>¿Ya tienes una cuenta? &nbsp;</PBText>
                <a onClick={this.goBack}>
                  <PBText primary={true}>Inicia sesión.</PBText>
                </a>
              </View>
            </div>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={
                !this.state.showRegisterLink ? this.goBack : this.register
              }
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </View>} */}
            </PBModal>
            {/* <CountryPicker
            {...{
              countryCode:this.state.slectedCountry.cca2,
              withFilter:true,
              withFlag:true,
              withCountryNameButton:false,
              withAlphaFilter:false,
              withCallingCode:true,
              withEmoji:true,
              onSelect:this.onSelect,
            }}
            visible
          /> */}
            <CarpoolSuccessFailureModal
              showModal={this.state.showSuccessFailureModal}
              success={this.state.showSuccessFailureModalStatus}
              smallIcon={true}
              bodyText={this.state.showSuccessFailureModalBodyText}
              buttonText={"OK, CONTINUAR"}
              onButtonPress={() => {
                this.setState({
                  showSuccessFailureModal: false,
                  showSuccessFailureModalBodyText: null,
                });
              }}
            />
          </View>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 12,
  },
  inputContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 6,
    height: "11vw",
  },
  buttonContainer: {
    marginTop: 20,
  },
  errorText: {
    color: "#f00",
    fontSize: 11,
    marginTop:10
  },
};

export default connectData()(MobileRegisterScreen);
