import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";

import TarapacaHeader from "./components/TarapacaHeader";
import TarapacaOffices from "./common/TarapacaOffices";
//import TarapacaHeader from "./components/TarapacaHeader";
//import TarapacaOffices from "./common/TarapacaOffices";
import { connectData } from "../../redux";

//import { connectData } from "../../../redux";



const TarapacaOfficesScreen = (props) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		props.getOfficeDetails({ callback: res => onResponse(res) });

	}, []);

	const onResponse = res => {
		if (
			res &&
			res.data.data &&
			res.data.data.offices &&
			res.data.data.offices.length > 0
		) {
			setData(res.data.data.offices);
		}
	};

	return (
		<IonPage>
			<IonContent>
				<TarapacaHeader
					title={"   "}
					boldTitle={"Oficinas"}
					handleHomeSubmit={() => props.history.push("/")}
					handleBackSubmit={() => props.history.goBack()}
				/>
				<div className="new-container">
					<TarapacaOffices offices={data} />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default connectData()(TarapacaOfficesScreen);
