import AndimarContactUs from "../theme1/AndimarPages/AndimarContactUs";
import PullmanSanAndreasContact from "../theme6/PullmanSanAndreasContact";
import React from "react";
import Theme11Contact from "../theme11/Theme11Contact";
import ThemeEightContactUsScreen from "../ThemeEight/ThemeEightPages/ThemeEightContactUsScreen";
import ThemeFiveContactUsScreen from "../theme5/ThemeFivePages/ThemeFiveContactUsScreen";
import ThemeFourContactUsScreen from "../ThemeFour/ThemeFourPages/ThemeFourContactUsScreen";
import ThemeSevenContactScreen from "../ThemeSeven/ThemeSevenPages/ThemeSevenContactScreen";
import ThemeTenContactUs from "../ThemeTen/ThemeTenPages/ThemeTenContactUs";
import ThemeThreeContactUsScreen from "../ThemeThree/ThemeThreePages/ThemeThreeContactUsScreen";
import ThemeTwoContactUsScreen from "../ThemeTwo/ThemeTwoContactUsScreen";
import { connectData } from "../../redux";

const CommonContactUsPage = props => {
  console.log("props in commoncontact", props);
  const getPage = () => {
    const themeType =
      props.data && props.data.operator && props.data.operator.theme_type;
    if (themeType === 1) {
      return <AndimarContactUs />;
    } else if (themeType === 5) {
      return <ThemeFiveContactUsScreen history={props.history} />;
    } else if (themeType === 2 || themeType === 9) {
      return <ThemeTwoContactUsScreen history={props.history} />;
    } else if (themeType === 4) {
      return <ThemeFourContactUsScreen />;
    } else if (themeType === 3) {
      return <ThemeThreeContactUsScreen />;
    } else if (themeType === 6) {
      return <PullmanSanAndreasContact />;
    } else if (themeType === 7) {
      return <ThemeSevenContactScreen />;
    } else if (themeType === 8) {
      return <ThemeEightContactUsScreen />;
    } else if (themeType === 10) {
      return <ThemeTenContactUs props={props}/>;
    } else if (themeType === 11) {
      return <Theme11Contact props={props}/>;
    }
    return <div />;
  };

  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonContactUsPage);
