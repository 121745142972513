
// passing the path into variables

// import chilebusSlides from "./chilebusSlides";

// const banner1 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-1.png';
// const banner2 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-2.png';
// const banner3 = '/assets/pullmansur/Home/PullmandelSur-Website-Home-Slide-MainBanner-3.png';

const destination1 = '/assets/chilebus/dest-one.webp';
const destination2 = '/assets/chilebus/dest-two.webp';
const destination3 = '/assets/chilebus/dest-three.webp';
const destination4 = '/assets/chilebus/dest-four.webp';
const destination5 = '/assets/chilebus/dest-five.webp';
const destination6 = '/assets/chilebus/dest-six.webp';

const destination7 = '/assets/chilebus/dest-seven.webp';
const gallery1 = '/assets/chilebus/dest-one.webp';
const gallery2 = '/assets/chilebus/dest-two.webp';
const gallery3 = '/assets/chilebus/dest-three.webp';
const gallery4 = '/assets/chilebus/dest-four.webp';
const gallery5 = '/assets/chilebus/dest-five.webp';
const gallery6 = '/assets/chilebus/dest-six.webp'
// const destination2 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-02.png';
// const destination3 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-03.png';
// const destination4 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-04.png';
// const destination5 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-05.png';
// const destination6 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-06.png';
// const destination7 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-07.png';
// const destination8 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-08.png';
// const destination9 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-09.png';
// const destination10 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-10.png';
// const destination11 = '/assets/pullmansur/General/PullmandelSur-Website-Destinations-11.png';

// const banner = [banner1, banner2, banner3];

const destination = [destination1, destination2, destination3, destination4, destination5, destination6, destination7];
// const destination = [
// 	{
// 		one: destination1,
// 		two: destination2,
// 		three: destination3,
// 		four: destination4,
// 	},

// 	{
// 		one: destination5,
// 		two: destination6,

// 	},
// ];
const gallery = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

const chilebusSlides = {
	// banner,
	destination,
	gallery
};

export default chilebusSlides;
