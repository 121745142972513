// -------------
const Banner = '/assets/viatur/home/viatur-banner.png';
const OriginIcon = '/assets/viatur/home/origin.svg';
const DestinationIcon = '/assets/viatur/home/destination.svg';
const CalendarIcon = '/assets/viatur/home/calendar.svg';
const BookingActive = '/assets/viatur/home/ticket-orange.svg';
const BookingInActive = '/assets/viatur/home/ticket-white.svg';
const CancelActive = '/assets/viatur/home/cancel-orange.svg';
const CancelInActive = '/assets/viatur/home/cancel-white.svg';

const HeaderLogo = '/assets/viatur/home/main-logo.svg';
const FooterLogo = '/assets/viatur/home/main-logo-white.svg';
const BG = '/assets/viatur/home/mobile-banner.jpg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const pnr = '/assets/viatur/home/find-my-pnr.svg';
const PopupIcon = '/assets/viatur/home/banner-popup.jpg';
const animation =
	'/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const office = '/assets/viatur/icons/home.svg';
const HeadPhone = '/assets/viatur/icons/online-sale.svg';
const MenuDot = '/assets/viatur/icons/viatur-sidemenu.svg';
const BottomFooterKuposLogo = '/assets/viatur/Footer-kuposLogo.svg';
const close = '/assets/viatur/icons/close.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png';
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santamaria/SMPaypng.png'


const viaturIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: Banner, name: 'Banner' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: BG, name: 'BG' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: office, name: 'office' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: MenuDot, name: 'menuDot' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: close, name: 'close' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },

];

export default viaturIcons;
