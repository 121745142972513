import React from 'react';

const ThemeTwoQuienesSomos = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'flex-start',
    marginTop: '30px',
  };

  const pairStyle = {
    display: 'flex', 
    flexDirection: 'row', 
    marginBottom: '20px', 
  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    marginRight: '30px', 
    marginTop: '16px',
}

  const textStyle = {
    flex: 1,
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '1.4',
  };

  return (
    <div style={containerStyle} className="container">
      {/* First Pair */}
      <div style={pairStyle} className="pair">
        <div className="image">
          <img
            src="/assets/biaggini/icons/viajes_interurbanos.svg"
            alt="Description"
            style={imageStyle}
            className="custom-image" 
          />
        </div>
        <div className="text" style={textStyle}>
          <p className="custom-text">
            <strong>Viajes interurbanos e <br/>interprovinciales</strong><br/>
            Con una amplia flota y los mejores<br/> buses del mercado.
          </p>
        </div>
      </div>

      {/* Second Pair */}
      <div style={pairStyle} className="pair">
        <div className="image">
          <img
            src="/assets/biaggini/icons/convenios_empresa.svg"
            alt="Description"
            style={imageStyle}
            className="custom-image" 
          />
        </div>
        <div className="text" style={textStyle}>
          <p className="custom-text">
            <strong>Convenios con empresas</strong><br/>
           Cotiza tus viajes privados.<br/> Viaja seguro y cómodo.
          </p>
        </div>
      </div>

      {/* Third Pair */}
      <div style={pairStyle} className="pair">
        <div className="image">
          <img
            src="/assets/biaggini/icons/servicios_de_encomienda.svg"
            alt="Description"
            style={imageStyle}
            className="custom-image" 
          />
        </div>
        <div className="text" style={textStyle}>
          <p className="custom-text">
            <strong>Servicio de encomiendas</strong><br/>
            Deja tus paquetes en manos<br/> expertas.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ThemeTwoQuienesSomos;
