import React, { Component } from "react";
import { dimensions, colors } from "../../theme";
import ModalDropdown from "../ModalDropdown";
import PBText from "../PBText";
import { DateSerivce } from "../../services";
import PBTouchable from "../PBTouchable";
import { IonIcon } from "@ionic/react";
import IonicDropdown from "../IonicDropdown";
import getIcons from "../../themes/functions/getIcons/getIcons";
import ReactDropdown from "../KuposDropdown";

class StageSelectBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: null,
      value: "",
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.length >= 1) {
      this.setState({
        index: 0,
        value: this.props.data[0],
      });
    }
  }

  onValueChange = (index, value) => {
    this.setState({
      index: index,
      value: value,
    });
  };

  displayRow = (rowData, rowID, highlighted) => {
    return (
      <PBTouchable>
        <div style={styles.listItem}>
          <img style={styles.listItemIcon} height={16} src={this.props.icon} />
          <PBText
            style={{
              ...styles.listItemText,
              color: highlighted ? colors.primaryBG : "#666",
            }}
          >
            {rowData.name + " - " + DateSerivce.ampm(rowData.time, true)}
          </PBText>
        </div>
      </PBTouchable>
    );
  };

  render() {
    let dropdownLocal =
      this.props.data &&
      this.props.data.map((val) => {
        return {
          id: val.id,
          name: val.name + " - " + val.time,
          time: val.time,
        };
      });
    return (
      <div style={styles.stageSelectionContainer}>
        {/* <ModalDropdown 
                disabled={this.props.data && this.props.data.length == 1}
                ref="dropdown_2"
                options={this.props.data}
                onSelect={(index,value) => this.props.onChange(value)}
                style={styles.dropDownButton}
                dropdownStyle={styles.listStyle}
                dropdownTextHighlightStyle={styles.listTextStyle}
                renderRow={(rowData, rowID, highlighted) => this.displayRow(rowData, rowID, highlighted)}
              > */}
        <div
          className="button-inner-container"
          // style={styles.buttonInnerContainer}
        >
          <img
            className="drop-down-button-icon"
            // style={styles.dropDownButtonIcon}
            height={20}
            src={getIcons(this.props.icon)}
          />
          {/* <div
            className="selected-item-text-container"
            // style={styles.selectedItemTextContainer}
          >
            <PBText
              fontColor={"dark-font"}
              // style={styles.dropDownButtonText}
            >
              {this.props.selectedItem
                ? this.props.selectedItem.name +
                  " - " +
                  DateSerivce.ampm(this.props.selectedItem.time, true)
                : this.props.selectText}
            </PBText>
          </div> */}
          {/* {this.props.data && this.props.data.length == 1 ? null : (
            <IonIcon
              name={"md-arrow-dropdown"}
              size={20}
              color={colors.primaryBG}
            />
          )} */}

          {this.props.data && this.props.data.length > 1 ? (
            // <IonicDropdown
            //   value={this.props.selectedItem}
            //   data={this.props.data && this.props.data}
            //   onSelectChange={(value) => this.props.onChange(value)}
            //   disabled={
            //     this.props.data && this.props.data.length == 1 ? true : false
            //   }
            //   placeholder={this.props.selectText}
            // />
            <ReactDropdown
              placeholder={this.props.selectText}
              data={dropdownLocal && dropdownLocal}
              value={this.props.selectedItem}
              onSelectChange={(value) => this.props.onChange(value)}
              disabled={
                dropdownLocal && dropdownLocal.length === 1 ? true : false
              }
              noBorder={true}
            />
          ) : (
            this.props.selectedItem && (
              <div style={{ padding: 8 }}>
                <span>
                  {this.props.selectedItem.name +
                    " - " +
                    DateSerivce.ampm(this.props.selectedItem.time, true)}
                </span>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  //stages
  stageSelectionContainer: {
    width: "100%",
  },
  stageSelectBoxContainer: {},
  dropDownButton: {
    width: "100%",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "$primaryBG",
  },
  dropDownButtonText: {
    color: "$primaryBG",
    fontSize: 12,
  },
  buttonInnerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  dropDownButtonIcon: {
    marginRight: 7,
  },
  listStyle: {
    width: dimensions.vw * 90,
  },
  listTextStyle: {
    color: "$primaryBG",
  },
  listItem: {
    flexDirection: "row",
    padding: 10,
  },
  listItemIcon: {
    marginRight: 6,
  },
  listItemText: {
    marginLeft: 10,
    fontSize: 16,
  },
  selectedItemTextContainer: {
    flex: 1,
  },

  //-----
};

export default StageSelectBox;
