const Banner = "/assets/expresosantacruz/general/main-banner.jpg";
const HeaderLogo = "/assets/combarbala/general/header-logo.png";
const FooterLogo = "/assets/combarbala/general/footer-logo.png";
const BookingInActive = "/assets/combarbala/general/booking-inactive.svg";
const BookingActive = "/assets/combarbala/general/booking-active.svg";
const CancelInActive = "/assets/combarbala/general/cancel-inactive.svg";
const CancelActive = "/assets/combarbala/general/cancel-active.svg";

const Whatsapp = "/assets/expresosantacruz/icons/whatsapp.svg";
const Email = "/assets/expresosantacruz/icons/email.svg";
const HeadPhone = "/assets/expresosantacruz/icons/headphone.svg";
const YellowLine = "/assets/expresosantacruz/icons/gray-line.svg";

const OriginIcon = "/assets/expresosantacruz/icons/origin.svg";
const DestinationIcon = "/assets/expresosantacruz/icons/destination.svg";
const pin = "/assets/expresosantacruz/icons/destination.svg";
const CalendarIcon = "/assets/combarbala/icons/calender.svg";

const checkboxIcon = "/assets/tepual/checkbox.png";

const animation =
  "/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif";
const pnr = "/assets/expresosantacruz/general/where-to-find-pnr.svg";
const BG = "/assets/expresosantacruz/general/mobile-banner.jpg";

const PopupIcon = "/assets/kennybus/general/Pop-up.jpg";

// Icons Section
const ist = "/assets/combarbala/icons/icon-1.svg";
const second = "/assets/combarbala/icons/icon-2.svg";
const third = "/assets/combarbala/icons/icon-3.svg";
const close = "/assets/combarbala/icons/close.png";
const address = "/assets/combarbala/icons/address.svg";
const menuDot = "/assets/expresosantacruz/icons/side-menu-icon.svg";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

//  pet seat 
const petseatref = '/assets/expresosantacruz/icons/Expresso Santa Cruz-SeatPick-PetsOnBoard_Pet Paw Icon (Amenite).png';
const petseatModal = '/assets/expresosantacruz/icons/Expreso santa Cruz-PopUp-mascotas-no button.png';
const petfilteroff = '/assets/traveltur/pet/PetsOnBoard-Button-Filter_Off.png';
const petfilteron = '/static/images/seats/On-Button-Filter.svg';

const tarjetas = "/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
const viajes = "/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const movimientos = "../../../assets/carpool/pigi-bank-circle.png";
const wallet =
  "/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg";
const shareMoney =
  "/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
const success =
  "/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg";
const failure =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const warning =
  "/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "../assets/carpool/user-circle.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";
const operatorPay = "/assets/images/cormarpay-logo.jpg";

const expresoSantaCruzIcons = [
  { icon: Banner, name: "Banner" },
  { icon: menuDot, name: "menuDot" },

  { icon: HeaderLogo, name: "headerLogo" },
  { icon: FooterLogo, name: "footerLogo" },
  { icon: address, name: "address" },

  { icon: OriginIcon, name: "Origin" },
  { icon: DestinationIcon, name: "Destination" },
  { icon: pin, name: "pin" },
  { icon: CalendarIcon, name: "Calendar" },

  { icon: BookingInActive, name: "BIA" },
  { icon: BookingActive, name: "BA" },
  { icon: CancelInActive, name: "CIA" },
  { icon: CancelActive, name: "CA" },

  { icon: PopupIcon, name: "Popup" },

  { icon: BG, name: "BG" },

  { icon: Whatsapp, name: "Whatsapp" },
  { icon: Email, name: "Email" },
  { icon: HeadPhone, name: "Headphone" },
  { icon: YellowLine, name: "Line" },

  { icon: animation, name: "animation" },
  { icon: checkboxIcon, name: "Checkbox" },

  // Icons Section
  { icon: ist, name: "ist" },
  { icon: second, name: "second" },
  { icon: third, name: "third" },
  { icon: pnr, name: "pnr" },
  { icon: close, name: "close" },


	 // accounts section
	 { icon: pay, name: 'pay' },
	 { icon: backimage, name: 'backimage' },
	 { icon: tarjetas, name: 'tarjetas' },
	 { icon: viajes, name: 'viajes' },
	 { icon: movimientos, name: 'movimientos' },
	 { icon: wallet, name: 'wallet' },
	 { icon: shareMoney, name: 'shareMoney' },
	 { icon: camera, name: 'camera' },
	 { icon: checkmark, name: 'checkmark' },
	 { icon: tick, name: 'tick' },
	 { icon: downArrow, name: 'downArrow' },
	 { icon: loginblue, name: 'loginblue' },
	 { icon: recoverpswd, name: 'recoverpswd' },
	 { icon: success, name: 'success' },
	 { icon: failure, name: 'failure' },
	 { icon: heartOn, name: 'heartOn' },
	 { icon: heartOff, name: 'heartOff' },
	 { icon: registrationSuccess, name: 'registrationSuccess' },
	 { icon: userIcon, name: 'userIcon' },
	 { icon: warning, name: 'warning' },
   { icon: operatorPay, name: "operatorPay" },

	   //  pet seat 
	   { icon: petseatref, name: 'petseatref' },
	   { icon: petseatModal, name: 'petseatModal' },
	   { icon: petfilteron, name: 'petfilteron' },
	   { icon: petfilteroff, name: 'petfilteroff' },
	 
];

export default expresoSantaCruzIcons;
