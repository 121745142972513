import { CommonService, DateSerivce } from "../../services";
import { commonStyles, dimensions } from "../../theme";

import DateService from "../../services/dateService";
import { IonIcon } from "@ionic/react";
import PBText from "../PBText";
import PBTouchable from "../PBTouchable";
import Rating from "./Rating";
import React from "react";
import getIcons from "../../themes/functions/getIcons/getIcons";
import { get } from "http";

// import Icon from 'react-native-vector-icons/AntDesign';

const getAmenitiesImage = name => {
	switch (name) {
		case "air_condtion.png": {
			return "/assets/svgs/amenities/air_condtion.svg";
		}
		case "baggage.png": {
			return "/assets/svgs/amenities/baggage.svg";
		}
		case "charging_plug.png": {
			return "/assets/svgs/amenities/charging_plug.svg";
		}
		case "coffee.png": {
			return "/assets/svgs/amenities/coffee.svg";
		}
		case "food_new_icon.png": {
			return "/assets/svgs/amenities/food_new_icon.svg";
		}
		case "gaming.png": {
			return "/assets/svgs/amenities/gaming.svg";
		}
		case "handicap.png": {
			return "/assets/svgs/amenities/handicap.svg";
		}
		case "mobile_ticket.png": {
			return "/assets/svgs/amenities/mobile_ticket.svg";
		}
		case "movie.png": {
			return "/assets/svgs/amenities/movie.svg";
		}
		case "restrooms.png": {
			return "/assets/svgs/amenities/restrooms.svg";
		}
		case "snacks_new.png": {
			return "/assets/svgs/amenities/snacks_new.svg";
		}
		case "wifi.png": {
			return "/assets/svgs/amenities/wifi.svg";
		}
		default: {
			return "";
		}
	}
};

const getSeatName = rawSeat => {
	console.log("Raw seat".rawSeat);
	switch (rawSeat) {
		case "SALON CAMA":
			return "Salón Cama";
			break;
		case "CLASICO":
			return "Clásico";
			break;
		case "Salon Sin Vent":
			return "Salón sin vent";
			break;
		case "Salon Preferente":
			return "Salón Preferente";
			break;
		case "SALON PREFERENTE":
			return "Salón Preferente";
			break;
		case "SALON":
			return "Salón ";
			break;
		case "Salon":
			return "Salón ";
			break;
		default:
			return CommonService.capitalize(rawSeat);
			break;
	}
};

const ServiceListItem = props => {
	let service = props.service;
	let color = service.soldRoute ? { color: "#ccc" } : {};

	const isPetSeat =
		(Object.keys(props?.service?.pet_seat_info) || []).length > 0 &&
		props.operator &&
		props.operator.is_petseat_enabled;

	const showAminities = props?.operator?.operator_name !== "pullmansanandres";

	let seatTypes = () => {
		// console.log("hello ", service.seatType);
		let seatTypes = service.seatType.map((type, i) => (
			<div
				className={"seat-type-container "}
				// style={styles.seatTypeContainer}
				key={i}
			>
				<span className="font13 dark-font dark-font">
					{getSeatName(type.split(":")[0])}
				</span>
				<span
					className={
						"font13 bold-text " +
						(service.soldRoute ? "sold-service dark-font" : "secondary-font")
					}
					style={{
						color:
							props.operator && props.operator.button_color
								? `${props.operator.button_color} !important`
								: "",
					}}
				>
					{CommonService.currency(type.split(":")[1]).split(",")[0]}
				</span>
			</div>
		));
		return seatTypes;
	};

	// let amenities = () => {
	//   console.log("service is", service)
	//   let amenities = service &&  service.operator_details[3].map((am, i) =>
	//     i < 3 ? (
	//       <img
	//         key={i}
	//         className="amenity"
	//         // style={styles.amenity}
	//         height={15}
	//         src={getAmenitiesImage(props.amenities[am].toLowerCase())}
	//       />
	//     ) : null
	//   );
	//   return amenities;
	// };

	// let amenities = () => {
	//   let amenities =
	//     service &&
	//     service.operator_details &&
	//     Array.isArray(service.operator_details) &&
	//     service.operator_details[3] &&
	//     service.operator_details[3].length > 0 &&
	//     service.operator_details[3].map((am, i) =>
	//       i < 3 ? (
	//         <img
	//           key={i}
	//           className='amenity'
	//           height={15}
	//           src={getAmenitiesImage(props.amenities[am].toLowerCase())}
	//           alt='icon'
	//         />
	//       ) : null
	//     );
	//   return amenities;
	// };

	let amenities = () => {
		let amenities = service.operator_details[3].map((am, i) =>
			i < (isPetSeat ? 2 : 3)
				? // <img
				//   key={i}
				//   className="amenity"
				//   // style={styles.amenity}
				//   height={15}
				//   src={getAmenitiesImage(props.amenities[am].toLowerCase())}
				// />
				null
				: null,
		);
		if (isPetSeat)
			amenities.unshift(
				<img
					className="amenity"
					// style={styles.amenity}
					height={25}
					// src={
					//   '/assets/icons/seats/SanAndres-ResultPage-PetsOnBoard-Ammenity.png'
					// }
					src={getIcons("petseatref")}
					alt=""
				/>,
			);
		return amenities;
	};

	return (
		<div>
			<div
				className="item-container-outer kupos-card relative"
				onClick={props.onSerivceClicked}
			// style={styles.itemContainerOuter}
			>
				{props.service.is_direct_trip && (
					<div className="direct-service-ui">
						<img
							src="/assets/images/Icon-Mobile-Background.png"
							alt=""
							className="bg"
						/>
						<div className="wrapper">
							<img
								src="/assets/images/Icon-Mobile.png"
								alt=""
								className="direct-arrow"
							/>
							<span className="directo bold-text">DIRECTO</span>
						</div>
					</div>
				)}
				<div
					className="item-container "
				// style={styles.itemContainer}
				>
					<div className="op-name-row flex-row">
						<img
							className={
								"operator-image" + (service.soldRoute ? " sold-service" : "")
							}
							src={service.operator_details[0]}
							alt=""
						/>
						{/* <div
              className={
                "font12 on-name" + (service.soldRoute ? " sold-service" : "")
              }
              // style={{ position: "absolute", top: "10", left: "100", ...color }}
            >
              {service.operator_service_name.toUpperCase()}
            </div> */}
						<div
							className={
								"rating black-text dark-font font13" +
								(service.soldRoute ? " sold-service" : "")
							}
						>
							<img src="/assets/icons/Icon-star-full-orange.png" alt="" />
							{service.operator_details[1].toFixed(1)}
						</div>
					</div>
					<div className="service-details-row flex-row">
						<div className="amenities-date">
							<div className="amenities-date-row-1">
								<div className="flex-row align-center">
									<img
										src={getIcons("Origin")}	
										className="neworigin"
										alt=""
									/>
									<span className="bold-text font12">
										{DateService.getServiceItemDate(service.travel_date)}
									</span>
								</div>
								<div className="flex-row align-center amenities-date-row-1-time-box">
									<span className="dot"></span>
									<span className="bold-text amenities-date-row-1-time font12">
										{service.dep_time.lastIndexOf(":") > 4
											? service.dep_time.substr(0, 5)
											: service.dep_time}
										<span className="ampm">
											{DateService.ampmOnly(
												service.dep_time.lastIndexOf(":") > 4
													? service.dep_time.substr(0, 5)
													: service.dep_time,
											)}
										</span>
									</span>
								</div>
							</div>
							<div className="amenities-date-row-2">
								<div className="flex-row align-center">
									<img
										src={getIcons("Destination")}
										className="destination"
										alt=""
									/>
									<span className="bold-text font12">
										{DateService.getServiceItemDate(service.arrival_date)}
									</span>
								</div>
								<div className="flex-row align-center amenities-date-row-2-time-box">
									<span className="dot"></span>
									<span className="bold-text amenities-date-row-2-time font12">
										{service.arr_time.lastIndexOf(":") > 4
											? service.arr_time.substr(0, 5)
											: service.arr_time}
										<span className="ampm">
											{DateService.ampmOnly(
												service.arr_time.lastIndexOf(":") > 4
													? service.arr_time.substr(0, 5)
													: service.arr_time,
											)}
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className="amenities-date">
              <div className="time-row flex-row">
                <div className="time-info black-text dark-font">
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.dep_time.lastIndexOf(":") > 4
                      ? service.dep_time.substr(0, 5)
                      : service.dep_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.dep_time.lastIndexOf(":") > 4
                        ? service.dep_time.substr(0, 5)
                        : service.dep_time,
                    )}
                  </span>
                </div>
                <div
                  className={
                    "time-seperator font10 light-text" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span className={service.soldRoute ? " sold-service" : ""}>
                    {service.duration} horas
                  </span>
                  <img src="/assets/svgs/two-ways-arrow.svg" />
                </div>

                <div
                  className={
                    "time-info black-text dark-font" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.arr_time.lastIndexOf(":") > 4
                      ? service.arr_time.substr(0, 5)
                      : service.arr_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.arr_time.lastIndexOf(":") > 4
                        ? service.arr_time.substr(0, 5)
                        : service.arr_time,
                    )}
                  </span>
                </div>
              </div>

              <div
                className={
                  "date-row flex-row font11 light-text space-between  light-text" +
                  (service.soldRoute ? " sold-service" : "")
                }
              >
                <span className="">
                  {DateService.getServiceItemDate(service.travel_date)}
                </span>
                <span className="">
                  {DateService.getServiceItemDate(service.arrival_date)}
                </span>
              </div>
              <div
                className="view-flex amenities-rating-container"
                // style={{ ...styles.viewFlex, paddingTop: 4 }}
              >
                <div
                  className="amenities-container"
                  // style={styles.amenitiesContainer}
                >
                  {amenities()}
                </div>
              </div>
            </div> */}
						<div
							className={
								"view-flex seat-type-container-inner " +
								(service.soldRoute ? "sold-service" : "")
							}
						// style={{ ...styles.viewFlex, ...styles.marginTop }}
						>
							{/* <PBText style={{...styles.opName,...styles.hidden}}>J</PBText> */}
							{seatTypes()}
							{service.soldRoute ? (
								<div
									className={
										"sold-out-text " + (service.soldRoute ? "sold-service" : "")
									}
								// style={styles.soldOutText}
								>
									<span
										className={"font12"}
									// style={{ ...color, ...styles.textMarginTop }}
									>
										Agotado
									</span>
								</div>
							) : null}
						</div>
					</div>
					<div className="service-details-row-2">
						<div className="service-name font11">
							{service.operator_service_name}
						</div>
						{isPetSeat && (
							<div className="service-pet">
								<img
									src="/assets/icons/pet_friendly.png"
									width={17}
									height={17}
									className="pet-icon"
									alt=""
								/>
								<span className="font11">Pet Friendly</span>
							</div>
						)}
						<div className="service-time">
							<img
								src="/assets/carpool/time_up.svg"
								className="duration-icon"
								alt=""
							/>
							<span className="font11">{service.duration} hrs</span>
						</div>
					</div>
				</div>
			</div>
			{service.offer_text && (
				<div className="offer-text font11">
					<span className="offer-percent font13 bold-text">%</span>
					<span>
						{service.offer_text.split("[[")[0]}{" "}
						{service.offer_text.split("[[")[1] ? (
							<span className="bold-text">
								{" "}
								{`"${service.offer_text.split("[[")[1]}"`}
							</span>
						) : null}{" "}
						{service.offer_text.split("[[")[2]}
					</span>
				</div>
			)}
		</div>
	);
};

const styles = {
	itemContainerOuter: {
		width: "100%",
		display: "flex",
	},
	itemContainer: {
		width: "100%",
		flexDirection: "row",
		backgroundColor: "#fff",
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
	},
	viewFlex: {
		width: dimensions.vw * 31,
		padding: 5,
		paddingVertical: 10,
		alignItems: "center",
	},
	arrow: {
		padding: 3,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	amenitiesContainer: {
		flexDirection: "row",
		alignItems: "flex-start",
		width: "100%",
		marginTop: 3,
		paddingLeft: 7,
	},
	amenity: {
		marginRight: 8,
	},
	serviceDetails: {
		alignItems: "flex-start",
		marginTop: 321,
	},
	opName: {
		fontSize: 12,
		position: "absolute",
		top: 9,
		left: 100,
	},
	marginTop: {
		marginTop: 16,
	},
	time: {
		fontSize: 10,
	},
	duration: {
		fontSize: 10,
	},
	hidden: {
		color: "#fff",
	},
	textMarginTop: {},
	seatTypeContainer: {
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	seatType: {
		fontSize: 10,
	},
	seatFare: {
		fontSize: 10,
	},
	soldOutText: {
		width: "100%",
		alignItems: "flex-start",
		fontSize: 12,
	},
};

export default ServiceListItem;
