const destination1 = '/assets/tacoha/Home/Tacoha-website-Destination1.png';
const destination2 = '/assets/tacoha/Home/Tacoha-website-Destination2.png';
const destination3 = '/assets/tacoha/Home/Tacoha-website-Destination3.png';
const destination4 = '/assets/tacoha/Home/Tacoha-website-Destination4.png';
const destination5 = '/assets/tacoha/Home/Tacoha-website-Destination5.png';

const banner1 = '/assets/tacoha/Home/Tacoha-Website-Home-Desktop.png';

const santiagoImage1 = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Agency1-santiago.png';
const santiagoImage2 = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Agency2-santiago.png';

const zonasurImage1 = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Agency1-south zone.png';
const zonasurImage2 = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Agency2-south zone.png';
const zonasurImage3 = '/assets/tacoha/Agencies/Tacoha-Website-Agencies-Agency3-south zone.png';

const destination = [destination1, destination2, destination3, destination4, destination5];

const banner = [banner1];

const santiagoTabDetails = [
  {
    id: 1,
    image: santiagoImage1,
    mapLink:
      'https://goo.gl/maps/hmq2VWaoX9V5DNWY9',
  },

  {
    id: 2,
    image: santiagoImage2,
    mapLink:
      'https://goo.gl/maps/KtDQkgM47RCkXya88',
  },
];


const zonarSurTabDetails = [
  {
    id: 1,
    image: zonasurImage1,
    mapLink: 'https://goo.gl/maps/B3pBx5Y2PGJf6fJCA'
  },

  {
    id: 2,
    image: zonasurImage2,
    mapLink:
      'https://goo.gl/maps/HiQaJv8KVAG97Zc48'
  },
  {
    id: 3,
    image: zonasurImage3,
    mapLink:
      ' https://goo.gl/maps/JDqWU7TJtCZoR9cH8'
  },
];

// const AgencyTabs = ['Santiago', 'Zona Sur'];

const AgencyTabs = {
  tabs: [
    { id: 0, label: 'Santiago' },
    { id: 1, label: 'Zona Sur' },
  ],
  0: santiagoTabDetails,
  1: zonarSurTabDetails,
};

const tacohaSlides = {
  destination,
  banner,
  AgencyTabs,
};

export default tacohaSlides;
