import './ThemeFourAboutIconSection.css';

import { CommonService } from '../../../services';
import React from 'react';
import { connectData } from '../../../redux';

const saopauloIcon1 =
	'/assets/saopaulo/about-us/BusesSaoPaulo-Website-TheCompany-Vision-Icon.svg';
const saopauloIcon2 =
	'/assets/saopaulo/about-us/BusesSaoPaulo-Website-TheCompany-Values-Icon.svg';
const chilebusIcon1 = '/assets/chilebus/flag.svg';
const chilebusIcon2 = '/assets/chilebus/heart.svg';
const santajuanaIcon1 = '/assets/santajuana/about-us/icon-nuestra-vision.png';
const santajuanaIcon2 = '/assets/santajuana/about-us/icon-nuestros-valores.png';

const getIconsAndTexts = (operator) => {
	const lightBg =
		operator && operator.color_codes && operator.color_codes.light_primary_color
			? operator.color_codes.light_primary_color
			: '#f7f7f7';
	const darkBg =
		operator && operator.color_codes && operator.color_codes.primary_color;

	const saopauloIconsAndTexts = [
		{
			image: saopauloIcon1,
			title: 'Servicios privados',
			des: 'Ofrecer la mejor y moderna opción de transporte de pasajeros en la región, asegurando un buen servicio, con puntualidad, seguridad, confort y cortesía, poniendo a disposición del público la flota más moderna de la región con buses de primera línea.',
			background: lightBg,
		},
		{
			image: saopauloIcon2,
			title: 'Turismo por la carretera Austral',
			des: 'La honestidad, humildad, responsabilidad y compromiso con nuestros clientes es lo que nos impulsa cada día a ser mejores para entregar un servicio de calidad por medio de un personal competente y comprometido con la organización.',
			background: darkBg,
		},
	];
	const santajuanaIconsAndTexts = [
		{
			image: santajuanaIcon1,
			title: 'Nuestra visión',
			des:
				'Pretendemos ser artífices de la construcción y operación del nuevo y más moderno sistema de transporte público rural de la Región del BioBio, siendo pioneros en la implementación de tecnología de vanguardia en nuestros buses y nuestras operaciones.',
			background: lightBg,
		},
		{
			image: santajuanaIcon2,
			title: 'Nuestros valores',
			des: '- Compromiso            - Respeto\n' +
				'- Seguridad             - Inclusión\n' +
				'- Responsabilidad       - Trabajo en equipo',
			background: darkBg,
		},
	];
	const chilebusIconsAndTexts = [
		{
			image: chilebusIcon1,
			title: 'Nuestra visión',
			des:
				'Ser líder en el mercado de transporte de pasajeros, carga y encomiendas, desarrollando recursos humanos y técnicos para brindar altos estándares de calidad y servicio diferenciado a nuestros clientes.',
			background: lightBg,
		},
		{
			image: chilebusIcon2,
			title: 'Nuestra misión:',
			des: `Proveer soluciones de traslado de personas, carga y encomiendas en el norte de Chile. Satisfacer las necesidades de transporte de pasajeros y carga a personas y empresas, de manera rentable, confiable y eficiente, con la mejor utilización de nuestra infraestructura, flota, cobertura, equipo humano y tecnología.`,
			background: darkBg,
		},
	];

	const name = operator && operator.operator_name;
	if (name === 'saopaulo') {
		return saopauloIconsAndTexts;
	}
	if (name === 'santajuana') {
		return santajuanaIconsAndTexts;
	} if (name === 'chilebus') {
		return chilebusIconsAndTexts;
	} else {
		return [];
	}
};

const ThemeFourAboutIconSection = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;

	const renderIconsAndText = (item, key) => {
		return (
			<div
				className='icons-item'
				key={key}
				style={{
					background: item.background,
				}}
			>
				<div>
					<img src={item.image} alt='Giras de estudio' className='icon' />
				</div>
				<div
					className='title bold-text'
					style={{
						color:
							operator &&
							operator.color_codes &&
							operator.color_codes.primary_text_color,
					}}
				>
					{item.title}
				</div>

				<div className="des">
					{item.des.split('\n').map((point, index) => (
						<p key={index} style={{ margin: '15px', }}>{point}</p>
					))}
				</div>
			</div>
		);
	};

	return (
		<div className='themes-theme-four-about-icon-section new-container'>
			{getIconsAndTexts(operator).map((item, key) => {
				return renderIconsAndText(item, key);
			})}
		</div>
	);
};

export default connectData()(ThemeFourAboutIconSection);
