import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourOfficeSection from '../common/ThemeFourOfficeSection';
import ThemeFourTitle from '../common/ThemeFourTitle';

function ThemeFourOfficeScreen(props) {
  const operator = CommonService.getLocal('op') || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('officeBanner', operator)}
          title={'Oficinas'}
          description={'Home / Oficinas'}
        />

        <ThemeFourTitle title='Encuentra nuestras oficinas' />

        <ThemeFourOfficeSection office />
        <ThemeFourFooter />
      </IonContent>
    </IonPage>
  );
}

export default connectData()(ThemeFourOfficeScreen);
